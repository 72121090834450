import React, { useCallback, useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card,
  Flex,
  Spinner,
  Box,
  EmptyState,
  Button,
} from '@procore/core-react';
import { PendingPaymentAdminsApi } from '@procore/ipa-nt-api-client-ts';
import type { PendingPaymentAdminsDto } from '@procore/ipa-nt-api-client-ts';
import { getApi } from 'utils/api';
import TableComponent from 'components/Table';
import type { TableHeader } from 'components/Table/table.interface';
import { AppContext } from 'contexts/AppContext';
import { PageConfig } from 'pages/page-config.service';
import { IpaAlertContext } from 'contexts/IpaAlertContext';
import { can } from 'utils/permissions.helper';
import { ConfirmModal } from 'components/ConfirmModal';
import { getRouteForEnv } from 'pages/Payments/payments.helper';

const PendingPaymentsAdminsTab = () => {
  const { userConfig, env } = useContext(AppContext);
  const alert = useContext(IpaAlertContext);
  const { t } = useTranslation(['payments']);
  const { t: tCommon } = useTranslation(['common']);
  const [pendingPaymentAdmins, setPendingPaymentAdmins] = useState<
    PendingPaymentAdminsDto[]
  >([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isInit, setIsInit] = useState<boolean>(true);
  const pageConfig = PageConfig.getPage(t, 'Payments', 'PendingPaymentsAdmins');
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [selectedPaymentAdmin, setSelectedPaymentAdmin] =
    useState<PendingPaymentAdminsDto>({} as PendingPaymentAdminsDto);

  const getPendingPaymentAdmins = useCallback(async () => {
    alert.closeAlert();
    setLoading(true);

    try {
      const api = getApi(PendingPaymentAdminsApi);
      const res = await api.pendingPaymentAdminsControllerGetPendingPayments(
        'pending',
        env,
      );
      setPendingPaymentAdmins(res.data);
    } catch (e: any) {
      alert.error(e);
    } finally {
      setLoading(false);
    }
  }, []);

  const approveConfirm = async (reason: string) => {
    try {
      const api = getApi(PendingPaymentAdminsApi);
      await api.pendingPaymentAdminsControllerApprovePaymentAdmin(env, {
        companyId: Number(selectedPaymentAdmin.company_id),
        userId: selectedPaymentAdmin.id,
        externalUUID: selectedPaymentAdmin.user_id,
        userRole: 'PAYMENT_ADMIN',
        createdBy: selectedPaymentAdmin.created_by_okta_id,
        reason: reason,
      });
      getPendingPaymentAdmins();
      alert.success(
        t('PENDING_PAYMENTS_ADMINS_TAB.APPROVE_SUCCESS', {
          username: selectedPaymentAdmin.user_name,
        }),
      );
    } catch (e: any) {
      alert.error(e);
    } finally {
      setOpenConfirmModal(false);
    }
  };

  const approveCell = (item: PendingPaymentAdminsDto) => {
    return (
      <Button
        variant="secondary"
        size="sm"
        data-qa="approve-button"
        onClick={() => {
          setSelectedPaymentAdmin(item);
          setOpenConfirmModal(true);
        }}
        disabled={
          !can(
            { user: userConfig },
            pageConfig.permissions.approve_payments_admins,
          )
        }
      >
        {t('PENDING_PAYMENTS_ADMINS_TAB.APPROVE')}
      </Button>
    );
  };

  const tableHeaders: TableHeader[] = [
    {
      title: '',
      node: '',
      display_func: approveCell,
    },
    {
      title: t('PENDING_PAYMENTS_ADMINS_TAB.NAME'),
      node: 'user_name',
      is_text: true,
      is_sortable: true,
    },
    {
      title: t('PENDING_PAYMENTS_ADMINS_TAB.COMPANY_NAME'),
      node: 'company_name',
      is_link: true,
      url: `${getRouteForEnv(env, '/business-entity/{company_id}')}`,
      is_sortable: true,
    },
    {
      title: t('PENDING_PAYMENTS_ADMINS_TAB.COMPANY_ACCOUNT_TYPE'),
      node: 'company_account_type',
      is_text: true,
      is_sortable: false,
    },
    {
      title: t('PENDING_PAYMENTS_ADMINS_TAB.SUBMITTED_BY'),
      node: 'created_by_email',
      is_text: true,
      is_sortable: false,
    },
    {
      title: t('PENDING_PAYMENTS_ADMINS_TAB.SUBMITTED_ON'),
      node: 'created_on',
      is_datetime: true,
      is_sortable: true,
    },
  ];

  useEffect(() => {
    getPendingPaymentAdmins();
  }, [getPendingPaymentAdmins]);

  return (
    <Card>
      <Box padding="lg">
        {!isInit && !isLoading && (
          <EmptyState>
            <EmptyState.NoItems />
            <EmptyState.Title>{tCommon('NO_DATA')}</EmptyState.Title>
          </EmptyState>
        )}
        {isLoading && (
          <Flex justifyContent="center">
            <Spinner loading={isLoading} />
          </Flex>
        )}

        {isInit && !isLoading && (
          <TableComponent
            data={pendingPaymentAdmins}
            default_sort_column="created_on"
            default_sort_direction="asc"
            headers={tableHeaders}
            no_data_message={tCommon('NO_DATA')}
            show_no_data_message={true}
          />
        )}
        <ConfirmModal
          isOpen={openConfirmModal}
          modalTitle={t('PENDING_PAYMENTS_ADMINS_TAB.CONFIRM_HEADER')}
          modalCopy={t('PENDING_PAYMENTS_ADMINS_TAB.CONFIRM_COPY', {
            username: selectedPaymentAdmin.user_name,
            companyName: selectedPaymentAdmin.company_name,
            companyId: selectedPaymentAdmin.company_id,
          })}
          closeModal={() => setOpenConfirmModal(false)}
          confirmModal={(values) => approveConfirm(values.reason)}
          includeReason={true}
        />
      </Box>
    </Card>
  );
};

export default PendingPaymentsAdminsTab;
