import type { SectionConfig } from 'pages/page-config.interfaces';

export default <SectionConfig>{
  id: 'ERP Support',
  i18n: {
    namespace: 'company',
  },
  permissions: {
    view: ['company_tab_view'],
    edit: ['company_tab_view'],
  },
  pages: {
    ErpSupportTools: {
      route: {
        path: '/v2/erp-support',
      },
      i18n: {
        keyPrefix: 'ERP',
      },
      label: 'SUPPORT_TOOLS_TITLE',
      cards: {
        StopStuckExport: {
          label: 'STOP_STUCK_EXPORT',
          fields: {},
          endpoints: {
            edit: ['erpToolsControllerStopStuckExport'],
          },
        },
        ArchiveImportableEntities: {
          label: 'ARCHIVE_IMPORTABLE_ENTITIES',
          fields: {},
          endpoints: {
            edit: ['erpToolsControllerArchiveImportableEntities'],
          },
        },
        ResetJob: {
          label: 'RESET_JOB',
          fields: {},
          endpoints: {
            edit: ['erpToolsControllerResetJob'],
          },
        },
        ResetStdCostCodeList: {
          label: 'RESET_STD_COST_CODES_LIST',
          fields: {},
          endpoints: {
            edit: ['erpToolsControllerResetCostCodeList'],
          },
        },
        LinkJob: {
          label: 'LINK_JOB',
          fields: {},
          endpoints: {
            edit: ['erpToolsControllerLinkJob'],
          },
        },
        ResetSubJob: {
          label: 'RESET_SUB_JOB',
          fields: {},
          endpoints: {
            edit: ['erpToolsControllerResetSubJob'],
          },
        },
        ResetBudget: {
          label: 'RESET_BUDGET',
          fields: {},
          endpoints: {
            edit: ['erpToolsControllerResetBudget'],
          },
        },
        ResetCommitments: {
          label: 'RESET_COMMITMENTS',
          fields: {},
          endpoints: {
            edit: ['erpToolsControllerResetCommitments'],
          },
        },
        UnapproveChangeOrder: {
          label: 'UNAPPROVE_CHANGE_ORDER',
          fields: {},
          endpoints: {
            edit: ['erpToolsControllerUnapproveChangeOrder'],
          },
        },
        ResetCommitmentChangeOrders: {
          label: 'RESET_COMMITMENT_CHANGE_ORDERS',
          fields: {},
          endpoints: {
            edit: ['erpToolsControllerResetCommitmentChangeOrders'],
          },
        },
        StandardCategoryDelete: {
          label: 'STANDARD_CATEGORY_DELETE',
          fields: {},
          endpoints: {
            edit: ['erpToolsControllerDeleteStandardCategories'],
          },
        },
        StandardCostCodeDependencyAudit: {
          label: 'STD_COST_CODE_DEPENDENCY_AUDIT',
          fields: {},
          endpoints: {
            edit: ['erpToolsControllerAuditStandardCostCodes'],
          },
        },
        ProjectCostCodeDependencyAudit: {
          label: 'COST_CODE_DEPENDENCY_AUDIT',
          fields: {},
          endpoints: {
            edit: ['erpToolsControllerAuditCostCodes'],
          },
        },
        StandardCostCodeUnlink: {
          label: 'STD_COST_CODE_UNLINK',
          fields: {},
          endpoints: {
            edit: ['erpToolsControllerUnlinkStandardCostCodes'],
          },
        },
        ProjectCostCodeDelete: {
          label: 'PROJECT_COST_CODE_DELETE',
          fields: {},
          endpoints: {
            edit: ['erpToolsControllerDeleteProjectCostCodes'],
          },
        },
        SubJobCostCodeDelete: {
          label: 'SUBJOB_COST_CODE_DELETE',
          fields: {},
          endpoints: {
            edit: ['erpToolsControllerDeleteSubjobCostCodes'],
          },
        },
        ResetPayments: {
          label: 'RESET_PAYMENTS',
          fields: {},
          endpoints: {
            edit: ['erpToolsControllerResetCommitmentPayments'],
          },
        },
        ResetInvoices: {
          label: 'RESET_INVOICES',
          fields: {},
          endpoints: {
            edit: ['erpToolsControllerResetInvoices'],
          },
        },
        ResetPrimeContractChangeOrders: {
          label: 'RESET_PRIME_CONTRACT_CHANGE_ORDERS',
          fields: {},
          endpoints: {
            edit: ['erpToolsControllerResetPrimeContractChangeOrders'],
          },
        },
        ResetPrimeContracts: {
          label: 'RESET_PRIME_CONTRACTS',
          fields: {},
          endpoints: {
            edit: ['erpToolsControllerResetPrimeContracts'],
          },
        },
      },
    },
  },
};
