import type { ContactContextProps } from 'contexts/ContactContext';

export const generateUrl = (page: string, context: ContactContextProps) => {
  let url = `/v2/company/${context.companyId}/contact/${context.contactId}/login/${context.loginId}/${page}`;
  let params = [`env=${context.env}`];
  if (context.projectId) {
    params.push(`projectId=${context.projectId}`);
  }
  return `${url}?${params.join('&')}`;
};

export const getContextQueryParams = (context: ContactContextProps) => {
  let params = [
    `env=${context.env}`,
    `contactId=${context.contactId}`,
    `loginId=${context.loginId}`,
  ];
  if (context.projectId) {
    params.push(`projectId=${context.projectId}`);
  }
  return params.join('&');
};
