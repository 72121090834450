import { Configuration, PermissionMap } from '@procore/ipa-nt-api-client-ts';
import axios from 'axios';
import type {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';
import { v4 as uuidv4 } from 'uuid';

type AxiosRequestConfigWithMetadata<T> = AxiosRequestConfig<T> & {
  metadata: {
    apiName: string;
    requestId: string;
    startTime: Date;
  };
};

/**
 * Create an instance of API Client of type `T`
 *
 * @param type Generated API Class
 * @returns
 */
type GeneratedApiClass<T> = {
  new (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance,
  ): T;
};
export const getApi = <T>(type: GeneratedApiClass<T>): T => {
  const axiosInstance = axios.create();
  const apiInstance = new type(
    undefined,
    window.location.origin,
    axiosInstance,
  );

  axiosInstance.interceptors.request.use(axiosRequestInterceptor(type.name));
  axiosInstance.interceptors.response.use(
    axiosResponseSuccessInterceptor(),
    axiosResponseErrorInterceptor(),
  );

  return apiInstance;
};

/**
 * Axios Request interceptor which adds additional metadata, request tracing,
 * and verbose logging
 *
 * @param apiName
 * @returns
 */
const axiosRequestInterceptor = (apiName: string): any => {
  return (_config: AxiosRequestConfig<any>) => {
    const config = _config as AxiosRequestConfigWithMetadata<any>;

    config.metadata = {
      apiName: apiName,
      requestId: uuidv4(),
      startTime: new Date(),
    };

    config.headers!['X-Request-Id'] = config.metadata.requestId;

    const logContext = {
      api: apiName,
      method: config.method?.toLocaleUpperCase(),
      url: config.url,
    };
    console.info('API Request', logContext);
    return config;
  };
};

const axiosResponseSuccessInterceptor = () => {
  return (response: AxiosResponse<any, any>) => {
    const config = response.config as AxiosRequestConfigWithMetadata<any>;
    const duration = +new Date() - +config.metadata.startTime;

    const logContext = {
      api: config.metadata.apiName,
      url: config.url,
      requestId: config.metadata.requestId,
      status: response.status,
      duration: duration,
    };

    console.log('API Response', logContext);
    return response;
  };
};

const axiosResponseErrorInterceptor = () => {
  return (_error: any) => {
    if (_error.isAxiosError) {
      const error = _error as AxiosError;
      const config = error.config as AxiosRequestConfigWithMetadata<any>;

      const logContext = {
        api: config.metadata.apiName,
        url: config.url,
        requestId: config.metadata.requestId,
        status: error.response?.status,
      };

      console.log('API Error', logContext, error.stack);
    } else {
      console.log('Unhandled API Client error:', _error.stack);
    }

    return Promise.reject(_error);
  };
};

export const getErrorMessage = (error: AxiosError): string => {
  if (!error.isAxiosError) {
    return '';
  }

  const data = error.response?.data as any;
  const messageInBody = data.message || data.error || data.errors;

  if (messageInBody && Array.isArray(messageInBody)) {
    return `Errors: ${messageInBody.join(', ')}`;
  }

  return (
    messageInBody || `${error.constructor.name}: HTTP ${error.response?.status}`
  );
};

export const getEndpointPermissions = (endpoint: string): string[] => {
  if (!PermissionMap[endpoint]) {
    throw new Error(`No permissions found for endpoint: ${endpoint}`);
  } else {
    return PermissionMap[endpoint];
  }
};
