import React, { useState, createContext } from 'react';
import { Flex, Spinner } from '@procore/core-react';
import GeneralInformationCard from './GeneralInformationCard';
import PayoutsCard from './PayoutsCard';
import type {
  DisbursementTabProps,
  DisbursementGeneralTabContextProps,
} from '../interfaces';

export const DisbursementGeneralTabContext = createContext(
  {} as DisbursementGeneralTabContextProps,
);

const GeneralTab = (props: DisbursementTabProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const dataSources: DisbursementGeneralTabContextProps['dataSources'] = {
    generalInformation: {
      id: 'generalInformation',
      data: props.disbursement,
    },
    payouts: {
      id: 'payments',
      data: props.disbursement?.payouts,
    },
  };

  return props.isLoading ? (
    <Flex justifyContent="center">
      <Spinner loading={isLoading} />
    </Flex>
  ) : (
    <DisbursementGeneralTabContext.Provider value={{ dataSources }}>
      <GeneralInformationCard
        id="generalInformation"
        canEdit={false}
        isCardLoading={isLoading}
      />
      <PayoutsCard id="payouts" />
    </DisbursementGeneralTabContext.Provider>
  );
};

export default GeneralTab;
