import type { PageConfig } from 'pages/page-config.interfaces';

export default <PageConfig>{
  label: 'TITLE',
  i18n: {
    namespace: 'audit-logs',
  },
  route: {
    path: '/v2/audit_logs',
  },
  endpoints: {
    view: ['auditLogControllerGet'],
  },
  cards: {},
};
