import React, { useContext } from 'react';
import type { ProjectSettingsProps } from '../interfaces';
import ProjectAdvancedCard from './ProjectAdvancedCard';
import { IpaAlertContext } from 'contexts/IpaAlertContext';
import { ProjectContext } from 'contexts/ProjectContext';
import type { IEditableCard } from 'components/EditableCard';
import { ProjectSuperuserSettings } from './ProjectSuperUserSettings';
import { ProjectDrawingLogSettings } from './ProjectDrawingLogSettings';
import ProjectInboundPhotosEmailSettings from './ProjectInboundPhotosEmailSettings';
import ProjectBudgetSettings from './ProjectBudgetSettings';
import ProjectSpecificationSettings from './ProjectSpecificationSettings';
import { getErrorMessage } from 'utils/api';
import { useTranslation } from 'react-i18next';
import { Box, Card } from '@procore/core-react';
import { PageConfig } from 'pages/page-config.service';
import type { CardConfigTranslated } from 'pages/page-config.interfaces';

const AdvancedTab = (props: ProjectSettingsProps) => {
  const alert = useContext(IpaAlertContext);
  const context = useContext(ProjectContext);

  const { t: tCommon } = useTranslation(['common']);
  const config = PageConfig.get(tCommon, 'Project.ProjectAdvanced');
  const t = config.translate;

  const canEdit = context.hasPermission(config.permissions.edit);

  const saveChanges = async (
    card: IEditableCard,
    values: any,
    reason?: string,
    changedKeys?: string[],
  ) => {
    alert.closeAlert();
    props.setCardLoading(card.id, true);
    const operations = [];
    if (values.projectSettings) {
      if (values.projectSettings.email_upload_address) {
        operations.push(
          props
            .updatePhotoImportConfigs(values.projectSettings, reason)
            .then(props.getProjectSettings),
        );
      } else {
        operations.push(
          props
            .updateProjectSettingsRequest(values.projectSettings, reason)
            .then(props.getProjectSettings),
        );
      }
    }
    if (values.drawingLogSettings) {
      operations.push(
        props
          .updateDrawingLogSettings(values.drawingLogSettings, reason)
          .then(props.getDrawingLogSettings),
      );
    }

    const results = await Promise.allSettled(operations);
    props.setCardLoading(card.id, false);

    const errors = results.flatMap((result, index: number) =>
      result.status === 'rejected'
        ? getErrorMessage(result.reason) ??
          t('THERE_WAS_ERROR', { ns: 'common' })
        : [],
    );

    if (errors.length > 0) {
      alert.error(errors.join('\n'));
    } else {
      alert.success(t('CARD_UPDATED', { CARD: card.label }));
    }
  };

  const cards = [
    {
      component: ProjectAdvancedCard,
      configKey: 'Project.ProjectAdvanced.Advanced',
    },
    {
      component: ProjectSuperuserSettings,
      configKey: 'Project.ProjectAdvanced.SuperUserSettings',
    },
    {
      component: ProjectDrawingLogSettings,
      configKey: 'Project.ProjectAdvanced.DrawingLogSettings',
    },
    {
      component: ProjectInboundPhotosEmailSettings,
      configKey: 'Project.ProjectAdvanced.InboundPhotosEmailSettings',
    },
    {
      component: ProjectBudgetSettings,
      hide: !canEdit,
      configKey: 'Project.ProjectAdvanced.BudgetSettings',
    },
    {
      component: ProjectSpecificationSettings,
      hide: !canEdit,
      configKey: 'Project.ProjectAdvanced.SpecificationSettings',
    },
  ];

  return (
    <React.Fragment>
      {cards.map((card, idx) => (
        <Box
          marginBottom="lg"
          key={idx}
          data-qa={`card-${card.component.name}`}
        >
          {!card.hide && (
            <Card>
              <Box padding="lg">
                {React.createElement(card.component, {
                  key: `card-${idx}`,
                  id: card.component.name,
                  cardConfig: PageConfig.get(
                    tCommon,
                    card.configKey,
                  ) as CardConfigTranslated,
                  save: saveChanges,
                })}
              </Box>
            </Card>
          )}
        </Box>
      ))}
    </React.Fragment>
  );
};

export default AdvancedTab;
