import React from 'react';
import type { ReactElement } from 'react';
import { CurrencyFormatter } from '@procore/globalization-toolkit';
import type { CurrencyDisplay } from '@procore/globalization-toolkit';
import { Flex, FlexList, Pill } from '@procore/core-react';
import type { PillColor } from '@procore/core-react';

export const disbursementStatusPills = [
  {
    id: 'FUNDING_NOT_INITIATED',
    label: 'FUNDING NOT INITIATED',
    color: 'yellow' as PillColor,
  },
  {
    id: 'FUNDING_PREPARING_SEND',
    label: 'FUNDING PREPARING SEND',
    color: 'yellow' as PillColor,
  },
  {
    id: 'FUNDING_REQUESTED',
    label: 'FUNDING REQUESTED',
    color: 'yellow' as PillColor,
  },
  {
    id: 'FUNDING_CANCELED',
    label: 'FUNDING CANCELED',
    color: 'red' as PillColor,
  },
  { id: 'FUNDING_FAILED', label: 'FUNDING FAILED', color: 'red' as PillColor },
  {
    id: 'FUNDING_COMPLETED',
    label: 'FUNDING COMPLETED',
    color: 'yelow' as PillColor,
  },
  {
    id: 'FUNDING_COMPLETED_AWAITING_PAYOUTS',
    label: 'FUNDING COMPLETED AWAITING PAYOUTS',
    color: 'yellow' as PillColor,
  },
  {
    id: 'PAYOUT_INITIATED',
    label: 'PAYOUT INITIATED',
    color: 'yellow' as PillColor,
  },
  {
    id: 'PAYOUT_COMPLETED',
    label: 'PAYOUT COMPLETED',
    color: 'green' as PillColor,
  },
  { id: 'PAYOUT_FAILED', label: 'PAYOUT FAILED', color: 'red' as PillColor },
  {
    id: 'CANCELLED',
    label: 'CANCELLED',
    color: 'red' as PillColor,
  },
];

export const payoutStatusPills = [
  {
    id: 'NOT_INITIATED',
    label: 'NOT INITIATED',
    color: 'yellow',
  },
  {
    id: 'REQUESTED',
    label: 'REQUESTED',
    color: 'yellow',
  },
  {
    id: 'FAILED',
    label: 'FAILED',
    color: 'red',
  },
  {
    id: 'COMPLETED',
    label: 'COMPLETED',
    color: 'green',
  },
  {
    id: 'CANCELLED',
    label: 'CANCELLED',
    color: 'red',
  },
];

const getDefaultStatusPill = (disbursementStatus: string) => {
  return {
    id: 'unknown',
    label: `${disbursementStatus}`,
    color: 'gray' as PillColor,
  };
};

export const formatCurrency = (
  currencyCode: string,
  amount: number,
): string => {
  const currencyOptions = {
    locale: 'en-US',
    currencyIsoCode: currencyCode,
    currencyDisplay: 'symbol' as CurrencyDisplay,
  };
  const formatter = new CurrencyFormatter(currencyOptions);
  return formatter.formatCurrency(amount);
};

export const formatEntityType = (entityType: string): ReactElement => {
  switch (entityType) {
    case 'receive_payments':
      return <span>Receive</span>;
    case 'send_payments':
      return <span>Send</span>;
    default:
      return <span>{entityType}</span>;
  }
};

export const getDisbursementStatus = (
  disbursementStatus: string,
  tStatus: any,
): ReactElement => {
  let statusPill = disbursementStatusPills.find(
    (status) => status.id.toLowerCase() === disbursementStatus.toLowerCase(),
  );

  if (statusPill === undefined) {
    statusPill = getDefaultStatusPill(disbursementStatus);
  }

  return (
    <Flex>
      <FlexList size="xs" direction="column">
        <Pill color={statusPill.color}>{tStatus(statusPill.id)}</Pill>
      </FlexList>
    </Flex>
  );
};

export const getTranslatedDisbursementStatus = (
  disbursementStatus: string,
  tStatus: any,
) => {
  let statusPill = disbursementStatusPills.find(
    (status) => status.id.toLowerCase() === disbursementStatus.toLowerCase(),
  );

  if (statusPill === undefined) {
    statusPill = getDefaultStatusPill(disbursementStatus);
  }

  return { ...statusPill, label: tStatus(statusPill.id) };
};

export const getPayoutStatus = (payoutStatus: string, tStatus: any) => {
  let statusPill = payoutStatusPills.find(
    (status) => status.id.toLowerCase() === payoutStatus.toLowerCase(),
  );

  if (statusPill === undefined) {
    statusPill = getDefaultStatusPill(payoutStatus);
  }

  return (
    <Flex>
      <FlexList size="xs" direction="column">
        <Pill color={statusPill.color as PillColor}>
          {tStatus(statusPill.id)}
        </Pill>
      </FlexList>
    </Flex>
  );
};

export const getRouteForEnv = (env: string, route: string) => {
  switch (env) {
    case 'PROD':
      return `/v2/payments${route}`;

    case 'INTEGRATOR_SANDBOX':
      return `/v2/payments-integrator-sandbox${route}`;

    default:
      return '/404';
  }
};
