import type { AccessLevel } from 'components/JitEscalateTearsheet/form.interface';

export const REASON_CATEGORIES = [
  {
    key: 'REASON_CUSTOMER_SUPPORT',
    id: 'Customer Support',
    salesforceCaseNumberRequired: true,
  },
  {
    key: 'REASON_IMPLEMENTATION_SUPPORT',
    id: 'Implementation Support',
    salesforceCaseNumberRequired: false,
  },
  {
    key: 'REASON_ERP_SUPPORT',
    id: 'ERP Support',
    salesforceCaseNumberRequired: true,
  },
  {
    key: 'REASON_ACCOUNT_REVIEW',
    id: 'Account Review / Meeting Prep',
    salesforceCaseNumberRequired: false,
  },
  {
    key: 'REASON_CUSTOM_SOLUTION_IMPLEMENTATION',
    id: 'Custom Solution Implementation',
    salesforceCaseNumberRequired: true,
  },
  {
    key: 'REASON_ENHANCED_REPORTING',
    id: 'Enhanced Reporting',
    salesforceCaseNumberRequired: false,
  },
  {
    key: 'REASON_PROCORE_ANALYTICS',
    id: 'Procore Analytics Implementation',
    salesforceCaseNumberRequired: false,
  },
  {
    key: 'REASON_TRIAL_SUPPORT',
    id: 'Trial Support',
    salesforceCaseNumberRequired: false,
  },
  {
    key: 'REASON_CLIENT_ACCOUNT_MANAGEMENT',
    id: 'Client Account Management',
    salesforceCaseNumberRequired: false,
  },
  {
    key: 'REASON_CLIENT_ACCOUNT_AUDIT',
    id: 'Client Account Audit',
    salesforceCaseNumberRequired: false,
  },
  {
    key: 'REASON_ISSUE_ESCALATION_TASK',
    id: 'Issue Escalation Task',
    salesforceCaseNumberRequired: false,
  },
  {
    key: 'REASON_OTHER',
    id: 'Other Internal Use',
    salesforceCaseNumberRequired: false,
  },
  {
    key: 'REASON_QA_TASK',
    id: 'QA Task',
    salesforceCaseNumberRequired: false,
  },
  {
    key: 'REASON_PROCESS_EXTRACTS',
    id: 'Process Extracts',
    salesforceCaseNumberRequired: false,
  },
];

export const SU_REASON_CATEGORIES = [
  {
    key: 'REASON_CUSTOMER_SUPPORT',
    id: 'Customer Support',
    salesforceCaseNumberRequired: true,
  },
  {
    key: 'REASON_IMPLEMENTATION_SUPPORT',
    id: 'Implementation Support',
    salesforceCaseNumberRequired: false,
  },
  {
    key: 'REASON_ERP_SUPPORT',
    id: 'ERP Support',
    salesforceCaseNumberRequired: true,
  },
  {
    key: 'REASON_ACCOUNT_REVIEW',
    id: 'Account Review / Meeting Prep',
    salesforceCaseNumberRequired: false,
  },
  {
    key: 'REASON_CUSTOM_SOLUTION_IMPLEMENTATION',
    id: 'Custom Solution Implementation',
    salesforceCaseNumberRequired: true,
  },
  {
    key: 'REASON_PROCORE_ANALYTICS',
    id: 'Procore Analytics Implementation',
    salesforceCaseNumberRequired: false,
  },
  {
    key: 'REASON_TRIAL_SUPPORT',
    id: 'Trial Support',
    salesforceCaseNumberRequired: false,
  },
  {
    key: 'REASON_CLIENT_ACCOUNT_MANAGEMENT',
    id: 'Client Account Management',
    salesforceCaseNumberRequired: false,
  },
  {
    key: 'REASON_CLIENT_ACCOUNT_AUDIT',
    id: 'Client Account Audit',
    salesforceCaseNumberRequired: false,
  },
  {
    key: 'REASON_ISSUE_ESCALATION_TASK',
    id: 'Issue Escalation Task',
    salesforceCaseNumberRequired: false,
  },
  {
    key: 'REASON_OTHER',
    id: 'Other Internal Use',
    salesforceCaseNumberRequired: false,
  },
  {
    key: 'REASON_QA_TASK',
    id: 'QA Task',
    salesforceCaseNumberRequired: false,
  },
];

export const ENVIRONMENT_OPTIONS = [
  {
    id: 'PROD',
    label: 'PROD',
  },
  {
    id: 'PROD_MONTHLY',
    label: 'PROD_MONTHLY',
  },
];

export const DAY_OPTIONS = [
  {
    id: '7',
    label: '7',
  },
  {
    id: '30',
    label: '30',
  },
  {
    id: '60',
    label: '60',
  },
  {
    id: '90',
    label: '90',
  },
];

export const JIT_ACCESS_LEVELS: AccessLevel[] = [
  {
    id: 'admin',
    label: 'COMPANY_ADMIN',
    disabled: false,
  },
  {
    id: 'admin-erp',
    label: 'COMPANY_ADMIN_ERP',
    disabled: false,
  },
  {
    id: 'read-only',
    label: 'READ_ONLY',
    disabled: false,
  },
  {
    id: 'impersonate',
    label: 'IMPERSONATE_USER',
    disabled: false,
  },
];

export const SESSION_TIMEOUTS = [null, 15, 30, 60, 120];
export const PASSWORD_RESET_AFTER = [null, 30, 60, 90];

export const ZONE_OPTIONS = [
  {
    id: 'US01',
    label: 'US01',
  },
  {
    id: 'US02',
    label: 'US02',
  },
  {
    id: 'UK',
    label: 'UK',
  },
];

export const DAYS_OF_WEEK = [
  { id: 0, label: 'SUNDAY' },
  { id: 1, label: 'MONDAY' },
  { id: 2, label: 'TUESDAY' },
  { id: 3, label: 'WEDNESDAY' },
  { id: 4, label: 'THURSDAY' },
  { id: 5, label: 'FRIDAY' },
  { id: 6, label: 'SATURDAY' },
];

export const TIMES_OF_DAY = [
  { id: 0, label: 'Midnight' },
  { id: 1, label: '01:00 AM' },
  { id: 2, label: '02:00 AM' },
  { id: 3, label: '03:00 AM' },
  { id: 4, label: '04:00 AM' },
  { id: 5, label: '05:00 AM' },
  { id: 6, label: '06:00 AM' },
  { id: 7, label: '07:00 AM' },
  { id: 8, label: '08:00 AM' },
  { id: 9, label: '09:00 AM' },
  { id: 10, label: '10:00 AM' },
  { id: 11, label: '11:00 AM' },
  { id: 12, label: 'Noon' },
  { id: 13, label: '01:00 PM' },
  { id: 14, label: '02:00 PM' },
  { id: 15, label: '03:00 PM' },
  { id: 16, label: '04:00 PM' },
  { id: 17, label: '05:00 PM' },
  { id: 18, label: '06:00 PM' },
  { id: 19, label: '07:00 PM' },
  { id: 20, label: '08:00 PM' },
  { id: 21, label: '09:00 PM' },
  { id: 22, label: '10:00 PM' },
  { id: 23, label: '11:00 PM' },
];

export const PROJECT_SCHEDULE_EMAIL_COVERAGE = [
  { id: 1, label: '1_WEEK' },
  { id: 2, label: '2_WEEKS' },
  { id: 3, label: '3_WEEKS' },
  { id: 4, label: '4_WEEKS' },
  { id: 5, label: '5_WEEKS' },
  { id: 6, label: '6_WEEKS' },
  { id: 7, label: '7_WEEKS' },
  { id: 8, label: '8_WEEKS' },
];
