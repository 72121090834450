import type { PageConfig } from 'pages/page-config.interfaces';

export default <PageConfig>{
  label: 'DASHBOARD_TITLE',
  route: {
    path: '/v2/jit-sync',
  },
  endpoints: {
    view: [],
  },
  cards: {},
};
