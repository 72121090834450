import React from 'react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from '@procore/core-react';
import { ContactContext } from 'contexts/ContactContext';
import type { IEditableCardProps } from 'pages/Contact/interfaces';
import { InfoTabContext } from '.';
import { EditableCard } from 'components/EditableCard';
import type { IEditableCard } from 'components/EditableCard';

export const AvailableCompanyToolsCard = (props: IEditableCardProps) => {
  const { companyId, pageConfig } = useContext(ContactContext);
  const config = pageConfig.card('AvailableCompanyTools');

  const {
    dataSources: { companyTools },
  } = useContext(InfoTabContext);

  const card: IEditableCard = {
    id: props.id,
    i18n: config.translate,
    label: config.label,
    canEdit: false,
    save: props.save,
    fieldsPerRow: 4,
    fields:
      companyTools.data?.map((tool, idx) => {
        return {
          source: companyTools.id,
          value: tool.is_entitled,
          name: tool.feature_id,
          label: tool.display_name,
          dataType: 'string',
          element: Form.Checkbox,
          editable: tool.can_modify,
        };
      }) ?? [],
  };

  return <EditableCard card={card} isCollapsible={true} modalWidth="lg" />;
};

export default AvailableCompanyToolsCard;
