import React, { useState, useEffect, useContext } from 'react';
import {
  Form,
  Flex,
  Box,
  Button,
  Card,
  FlexList,
  Spinner,
  Title,
  H2,
} from '@procore/core-react';
import fileDownload from 'js-file-download';
import { IpaAlertContext } from 'contexts/IpaAlertContext';
import { useTranslation } from 'react-i18next';
import { TableComponent } from 'components/Table';
import { ContactContext } from 'contexts/ContactContext';
import { CompanyContext } from 'contexts/CompanyContext';
import { CompaniesApi } from '@procore/ipa-nt-api-client-ts';
import type { RestV10InternalSuperIpaCompaniesCompanyIdOutboundEmailReportsGet200ResponseOutboundEmailReportsInnerApiDto } from '@procore/ipa-nt-api-client-ts';
import { getApi } from 'utils/api';
import { ipaAppStyles } from 'styles';
import MessageBanner from 'components/MessageBanner';
const { Parser } = require('json2csv');

export const OutboundEmailsReport = () => {
  const [endDate, setEndDate] = useState<Date>(null);
  const [startDate, setStartDate] = useState<Date>(null);
  const [initEndDate, setInitEndDate] = useState<Date>(null);
  const [page, setPage] = useState<number>(1);
  const [initStartDate, setInitStartDate] = useState<Date>(null);
  const [outboundEmails, setOutboundEmails] = useState<
    RestV10InternalSuperIpaCompaniesCompanyIdOutboundEmailReportsGet200ResponseOutboundEmailReportsInnerApiDto[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isGettingReport, setIsGettingReport] = useState<boolean>(false);
  const [initSearchQuery, setInitSearchQuery] = useState<string>('');
  const [initProjectId, setInitProjectId] = useState<number>(undefined);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [projectId, setProjectId] = useState<number>(undefined);

  const { t } = useTranslation('contact', {
    keyPrefix: 'OUTBOUND_EMAILS_REPORT',
  });
  const { t: tCommon } = useTranslation('common');
  const contactContext = useContext(ContactContext);
  const companyContext = useContext(CompanyContext);
  const context =
    Object.keys(contactContext).length > 0 ? contactContext : companyContext;
  const alert = useContext(IpaAlertContext);

  const tableHeaders = [
    {
      title: tCommon('DATE'),
      node: 'created_at',
      is_sortable: true,
      is_datetime: true,
    },
    {
      title: tCommon('PROJECT'),
      node: 'project_name',
      is_sortable: true,
      is_text: true,
    },
    {
      title: t('SUBJECT'),
      node: 'subject',
      is_sortable: true,
      is_text: true,
      col_hide_size: 'md',
    },
    {
      title: t('ITEM'),
      node: 'item_url',
      is_sortable: false,
      is_link: true,
    },
    {
      title: tCommon('TO'),
      node: 'to',
      is_sortable: true,
      is_text: true,
      col_hide_size: 'lg',
    },
    {
      title: tCommon('FROM'),
      node: 'from',
      is_sortable: true,
      is_text: true,
      col_hide_size: 'lg',
    },
    {
      title: t('INVALID_EMAIL_ADDRESS'),
      node: 'bounced_email_address',
      is_sortable: true,
      is_text: true,
      col_hide_size: 'lg',
    },
  ];

  useEffect(() => {
    setIsLoading(false);
  }, [context.dataSources]);

  const getReport = async (values: any) => {
    if (!values.start_date && !values.end_date) {
      return false;
    }
    setInitStartDate(values.start_date);
    setInitEndDate(values.end_date);
    setInitProjectId(values.project_id);
    setInitSearchQuery(values.query);
    setIsGettingReport(true);

    try {
      const reportData = await getReportData(1000);
      setOutboundEmails(reportData.data.outbound_email_reports);
      setIsGettingReport(false);
    } catch (e: any) {
      alert.error(e);
    } finally {
      setIsGettingReport(false);
    }
  };

  const getReportData = async (limit: number) => {
    const api = getApi(CompaniesApi);
    console.log('****', searchQuery, projectId);
    const result = await api.companyControllerGetOutboundEmailsReport(
      context.env,
      context.companyId,
      1,
      limit,
      startDate.toISOString().split('T')[0],
      endDate.toISOString().split('T')[0],
      searchQuery,
      projectId,
    );

    return result;
  };

  const downloadReport = async () => {
    const startDate = initStartDate
      .toISOString()
      .slice(0, 10)
      .replace(/-/g, '');
    const endDate = initEndDate.toISOString().slice(0, 10).replace(/-/g, '');
    try {
      const reportData = await getReportData(1000000);
      const json2csvParser = new Parser({
        quote: '',
        fields: [
          { label: tCommon('DATE'), value: 'created_at' },
          { label: tCommon('PROJECT'), value: 'project_name' },
          { label: t('SUBJECT'), value: 'subject' },
          { label: t('ITEM'), value: 'item_url' },
          { label: tCommon('TO'), value: 'to' },
          { label: tCommon('FROM'), value: 'from' },
          {
            label: t('INVALID_EMAIL_ADDRESS'),
            value: 'bounced_email_address',
          },
        ],
      });
      const csv = json2csvParser.parse(reportData.data?.outbound_email_reports);
      fileDownload(
        csv,
        `outbound_email_report_company_${context.companyId}_${startDate}_${endDate}.csv`,
      );
    } catch (e: any) {
      alert.error(e);
    }
  };

  const clearStartDateFilter = () => {
    setStartDate(initStartDate);
  };

  const clearEndDateFilter = () => {
    setEndDate(initEndDate);
  };

  const disableButton = (start: Date, end: Date, projectId: number, searchQuery: string) => {
    if (start === null || end === null) {
      return true;
    }

    return new Intl.DateTimeFormat('en-US').format(start) ===
      new Intl.DateTimeFormat('en-US').format(initStartDate) &&
      new Intl.DateTimeFormat('en-US').format(end) ===
        new Intl.DateTimeFormat('en-US').format(initEndDate) &&
      initProjectId === projectId &&
      initSearchQuery === searchQuery
      ? true
      : false;
  };

  useEffect(() => {
    getReport({ start_date: startDate, end_date: endDate, query: searchQuery });
  }, [page]);

  return (
    <Card>
      <Box padding="lg" marginBottom="lg">
        <Title style={ipaAppStyles.titleMargin}>
          <Title.Text>
            <H2>{t('TITLE')}</H2>
          </Title.Text>
        </Title>
        {isLoading && (
          <Box>
            <FlexList justifyContent="center">
              <Spinner loading={isLoading} />
            </FlexList>
          </Box>
        )}
        <Form
          view="create"
          enableReinitialize={true}
          initialValues={{
            end_date: initEndDate,
            start_date: initStartDate,
            project_id: projectId,
            query: initSearchQuery,
          }}
          onSubmit={(values) => getReport(values)}
        >
          {() => {
            return (
              <Form.Form>
                <Flex justifyContent="flex-start">
                  <Box marginRight="lg">
                    <Form.DateSelect
                      label={tCommon('START_DATE')}
                      name="start_date"
                      data-qa="outbound-emails-report-start-date"
                      onClear={clearStartDateFilter}
                      onChange={(date) => setStartDate(date)}
                      disabledDate={(date) =>
                        date?.getTime() > new Date()?.getTime() ||
                        date?.getTime() >= endDate?.getTime()
                      }
                    />
                  </Box>
                  <Box marginRight="lg">
                    <Form.DateSelect
                      label={tCommon('END_DATE')}
                      name="end_date"
                      data-qa="outbound-emails-report-end-date"
                      onClear={clearEndDateFilter}
                      onChange={(date) => setEndDate(date)}
                      disabledDate={(date) =>
                        date?.getTime() > new Date()?.getTime() ||
                        date?.getTime() <= startDate?.getTime()
                      }
                    />
                  </Box>
                  <Box marginRight="lg">
                    <Form.Text
                      name="project_id"
                      label={tCommon('PROJECT_ID')}
                      onChange={(e) => setProjectId(+e.target.value)}
                      data-qa="project-id"
                      type="text"
                    />
                  </Box>
                  <Box marginRight="lg">
                    <Form.Text
                      name="query"
                      label={t('FILTER_RESULTS')}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      data-qa="outbound-emails-report-query"
                      type="text"
                      tooltip={t('FILTER_TOOLTIP')}
                    />
                  </Box>
                  <Box marginTop="xl" paddingTop="xs">
                    <Button
                      type="submit"
                      variant="primary"
                      data-qa="get-outbound-emails-report-btn"
                      disabled={disableButton(startDate, endDate, projectId, searchQuery)}
                      loading={isGettingReport}
                    >
                      {t('GET_REPORT')}
                    </Button>
                  </Box>
                  <Box marginTop="xl" paddingTop="xs" marginLeft="auto">
                    <Button
                      variant="primary"
                      data-qa="outbound-emails-download-btn"
                      disabled={!outboundEmails?.length}
                      onClick={downloadReport}
                    >
                      {tCommon('DOWNLOAD_REPORT')}
                    </Button>
                  </Box>
                </Flex>
              </Form.Form>
            );
          }}
        </Form>
        <Box marginBottom="md">
          <MessageBanner
            banner_type="attention"
            banner_title=''
            banner_message= {t('MAX_RECORD_LIMIT')}
          />
        </Box>
        <Box>
          <TableComponent
            headers={tableHeaders}
            data={outboundEmails}
            default_sort_column="project_name"
            default_sort_direction="desc"
            show_no_data_message={true}
            show_search={true}
            search_columns={[
              'to',
              'from',
              'project_name',
              'subject',
              'item_url',
            ]}
            show_page_size_select={true}
          />
        </Box>
      </Box>
    </Card>
  );
};

export default OutboundEmailsReport;
