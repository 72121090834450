import styled from 'styled-components';

export const PillWrapper = styled.span`
  display: inline-block !important;
  text-transform: uppercase;
  margin-right: 0.5em;

  > span {
    font-weight: 600;
  }
`;
