import React, { useState, useContext } from 'react';
import {
  Page,
  Button,
  H2, Form, Box, Spinner, Flex,
} from '@procore/core-react';
import { IpaAlertContext } from 'contexts/IpaAlertContext';
import { PageConfig } from 'pages/page-config.service';
import { useTranslation } from 'react-i18next';
import { DevPortalApi } from '@procore/ipa-nt-api-client-ts';
import { getApi } from 'utils/api';
import { FormWrapper } from 'styles';
import * as yup from 'yup';

export const IntegratorSandboxNumberPage = () => {
  const { t: tCommon } = useTranslation(['common']);
  const alert = useContext(IpaAlertContext);
  const pageConfig = PageConfig.get(
    tCommon,
    'DevPortal.IntegratorSandboxConfig',
  );
  const [companyId, setCompanyId] = useState('');
  const [numberOfProjects, setNumberOfProjects] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const makeDevPortalAddFeaturesCall = async () => {
    alert.closeAlert();
    setIsLoading(true);

    try {
      const api = getApi(DevPortalApi);
      const options = { data: { maximum_number_of_active_projects: numberOfProjects } };

      const response: any = await api.devPortalControllerUpdateNumberOfProjects(companyId, options);
      const data = response.data;
      const status = data.status ?? response.status;

      if (status === 200) {
        alert.success(`${pageConfig.translate('ENV_INFO.SUCCESS_MESSAGE')} ${JSON.stringify(data)}`);
      } else if (status === 401) {
        alert.error(pageConfig.translate('ENV_INFO.UNAUTHORIZED_MESSAGE'));
      } else {
        alert.error(pageConfig.translate('ENV_INFO.UNKNOWN_ERROR_MESSAGE', { data: JSON.stringify(data) }));
      }
    } catch (error: any) {
      alert.error(pageConfig.translate('ENV_INFO.ERROR_MESSAGE', { error: error.message || error }));
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Page.Body>
      <H2>{pageConfig.label}</H2>
      <Box padding="lg">
        {isLoading && (
          <Flex justifyContent="center">
            <Spinner loading={isLoading} />
          </Flex>
        )}
        {!isLoading && (<FormWrapper>
            <Form
              validationSchema={yup.object().shape({
                'company-id-field': yup.string().required(pageConfig.translate('SEARCH.COMPANY_ID_REQUIRED')),
              })}
              onSubmit={(values) =>
                makeDevPortalAddFeaturesCall()
              }
            >
              <Form.Form name="project-number-form">
                <Form.Row>
                  <Form.Number
                    name="company-id-field"
                    label={pageConfig.translate('SEARCH.COMPANY_ID')}
                    value={companyId}
                    onChange={(e) => setCompanyId(e.parsedNumber ? e.parsedNumber.toString(10) : '')}
                    placeholder={pageConfig.translate('SEARCH.COMPANY_ID')}
                    data-qa="project-number-company-id-field"
                    required
                    colWidth={3}
                  />
                  <Form.Number
                    name="project-numbers-field"
                    label={pageConfig.translate('SEARCH.MAX_NUMBER_OF_ACTIVE_PROJECTS')}
                    value={numberOfProjects}
                    onChange={(e) => (setNumberOfProjects(e.parsedNumber ? e.parsedNumber.toString(10) : ''))}
                    placeholder={pageConfig.translate('SEARCH.MAX_NUMBER_OF_ACTIVE_PROJECTS')}
                    data-qa="project-numbers-field"
                    colStart={4}
                    colWidth={3}
                  />
                </Form.Row>
                <Button
                  type="submit"
                  disabled={!companyId}
                  data-qa="save-project-number-btn"
                >
                  {pageConfig.translate('SEARCH.APPLY')}
                </Button>
              </Form.Form>
            </Form>
          </FormWrapper>
        )}
      </Box>
    </Page.Body>
  );
};

