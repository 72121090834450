import React, { useEffect, useState } from 'react';
import { Form, Box, Button, Banner, InfoBanner } from '@procore/core-react';
import * as yup from 'yup';
import type { FormikProps } from 'formik';
import { urlIdParser } from 'utils/form';
import type { CustomReportTabsFormSearchProps } from './custom.reports.interface';
import { FormButtonWrapper, FormWrapper, StyledFormText } from 'styles';
import { useTranslation } from 'react-i18next';

interface CustomReportTabsFormProps {
  doSearch: (values: CustomReportTabsFormSearchProps) => void;
  isSearching: boolean;
}

export const CustomReportTabsForm = (props: CustomReportTabsFormProps) => {
  const { t } = useTranslation(['common', 'custom-report-tabs']);
  const [searchValues, setSearchValues] =
    useState<CustomReportTabsFormSearchProps>(
      {} as CustomReportTabsFormSearchProps,
    );

  useEffect(() => {
    if (props.doSearch && searchValues.companyId) {
      props.doSearch(searchValues);
    }
  }, [searchValues]);

  return (
    <React.Fragment>
      <Box marginBottom="md">
        <InfoBanner>
          <Banner.Content>
            <Banner.Body data-qa="banner-status-text">
              {t('INFO_MESSAGE', { ns: 'custom-report-tabs' })}
            </Banner.Body>
          </Banner.Content>
        </InfoBanner>
      </Box>
      <FormWrapper>
        <Form
          view="create"
          enableReinitialize={true}
          initialValues={searchValues}
          validationSchema={yup.object().shape({
            companyId: yup
              .number()
              .nullable()
              .required(t('REQUIRED', { ns: 'common' })),
          })}
          onSubmit={(
            values: CustomReportTabsFormSearchProps,
            { resetForm },
          ) => {
            setSearchValues(values);
          }}
        >
          {(formProps: FormikProps<CustomReportTabsFormSearchProps>) => {
            const { dirty, values, handleSubmit, setFieldValue } = formProps;
            return (
              <Form.Form
                data-qa="custom-report-tabs-form"
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleSubmit();
                  }
                }}
              >
                <Box>
                  <Form.Row>
                    <StyledFormText
                      name="companyId"
                      id="companyId"
                      type="number"
                      label={t('COMPANY_ID', { ns: 'common' })}
                      data-qa="company-field"
                      onPaste={(e) =>
                        urlIdParser(e, setFieldValue, 'companyId')
                      }
                      colStart={1}
                      colWidth={1}
                    />
                    <StyledFormText
                      name="reportId"
                      id="search"
                      type="number"
                      label={t('REPORT_ID', { ns: 'custom-report-tabs' })}
                      data-qa="report-field"
                      colStart={2}
                      colWidth={1}
                    />
                    <StyledFormText
                      name="projectId"
                      id="search"
                      type="number"
                      label={t('PROJECT_ID', { ns: 'common' })}
                      data-qa="project-field"
                      colStart={3}
                      colWidth={1}
                    />
                    <StyledFormText
                      name="userEmail"
                      id="search"
                      type="string"
                      label={t('USER_EMAIL', { ns: 'common' })}
                      data-qa="search-email-field"
                      colStart={4}
                      colWidth={2}
                    />
                    <StyledFormText
                      name="toolType"
                      id="search"
                      type="string"
                      label={t('TOOL_TYPE', { ns: 'custom-report-tabs' })}
                      data-qa="search-tool-type-field"
                      colStart={6}
                      colWidth={2}
                    />
                    <FormButtonWrapper>
                      <Button
                        type="submit"
                        variant="primary"
                        data-qa="search-btn"
                        disabled={!dirty || values.companyId === null}
                        loading={props.isSearching}
                      >
                        {t('SEARCH', { ns: 'common' })}
                      </Button>
                    </FormButtonWrapper>
                  </Form.Row>
                </Box>
              </Form.Form>
            );
          }}
        </Form>
      </FormWrapper>
    </React.Fragment>
  );
};
