import type { PageConfig } from 'pages/page-config.interfaces';

const ProjectPermissionTemplates: PageConfig = {
  label: 'TITLE',
  route: {
    page: 'project-permission-templates',
  },
  i18n: {
    namespace: 'contact',
    keyPrefix: 'PROJECT_PERMISSION_TEMPLATES',
  },
  permissions: {
    view: ['cs_view'],
  },
  endpoints: {
    view: [
      'companyDirectoryControllerGetProjectPermissionTemplates',
      'companyDirectoryControllerGetAssignablePermissionTemplates',
    ],
  },
  cards: {},
};

export default ProjectPermissionTemplates;
