import { AnalyticEvents } from 'pages/analytic-events.service';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const stripIdsRegex = new RegExp('(/\\d+)', 'g');

export const PageLoadReporter: React.FC = () => {
  const location = useLocation();

  const stripIdentifiers = (url: string) => {
    return url.replaceAll(stripIdsRegex, '/:id');
  };

  const reportPageLoad = (url: string) => {
    AnalyticEvents.postIpaEvent({
      event: 'page_load',
      props: {
        url: stripIdentifiers(url),
      },
    });
  };

  useEffect(() => {
    reportPageLoad(location.pathname);
  }, [location]);

  return <React.Fragment />;
};
