import {
  Box,
  Card,
  Flex,
  Spinner,
  Tabs,
  ToolHeader,
} from '@procore/core-react';
import { useContext, useEffect, useState } from 'react';
import { ContactContext } from 'contexts/ContactContext';
import { IpaAlertContext } from 'contexts/IpaAlertContext';
import { useTranslation } from 'react-i18next';
import React from 'react';
import InfoTab from './Info';
import ProjectsTab from './Projects';
import SettingsTab from './Settings';
import ChangeHistoryTab from './ChangeHistory';
import { StyledLink } from '../styles';

const ContactInfoPage = () => {
  const { t } = useTranslation(['contact']);
  const alert = useContext(IpaAlertContext);
  const [isInit, setIsInit] = useState<boolean>(false);
  const [isLoading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('info');
  const [cardLoading, setCardLoading] = useState<{ [key: string]: boolean }>(
    {},
  );
  const context = useContext(ContactContext);

  const tabs = [
    { id: 'info', name: t('CONTACT_INFO_PAGE.TITLE'), component: InfoTab },
    { id: 'projects', name: t('PROJECTS_PAGE.TITLE'), component: ProjectsTab },
    { id: 'settings', name: t('SETTINGS_PAGE.TITLE'), component: SettingsTab },
    {
      id: 'change-history',
      name: t('CHANGE_HISTORY_PAGE.TITLE'),
      component: ChangeHistoryTab,
    },
  ];

  const isTabActive = (tab: string) => {
    return tab === activeTab;
  };

  const changeCardLoading = (cardId: string, loading: boolean) => {
    setCardLoading({
      ...cardLoading,
      [cardId]: loading,
    });
  };

  const changeTab = (tab: string) => {
    if (tab !== activeTab) {
      window.history.pushState(null, '', `#${tab}`);
      setActiveTab(tab);
    }
  };

  useEffect(() => {
    if (window.location.hash !== '') {
      const hash = window.location.hash.substring(1);
      if (tabs.findIndex((t) => t.id === hash) !== -1) {
        changeTab(hash);
      }
    }
  }, []);

  useEffect(() => {
    setLoading(false);
    setIsInit(true);
  }, []);

  return (
    <Card>
      <Box padding="lg">
        <ToolHeader>
          <ToolHeader.Tabs>
            {tabs.map((tab, i) => (
              <Tabs.Tab
                active={isTabActive(tab.id)}
                onClick={() => changeTab(tab.id)}
                key={i}
                data-qa={`contact-info-${tab.id}-tab`}
              >
                <StyledLink
                  href={`/v2/company/${context.companyId}/contact/${context.contactId}/login/${context.loginId}/contact-info#${tab.id}`}
                  onClick={(e) => e.preventDefault()}
                  data-qa={`contact-info-${tab.id}-link`}
                >
                  <Tabs.Link>{tab.name}</Tabs.Link>
                </StyledLink>
              </Tabs.Tab>
            ))}
          </ToolHeader.Tabs>
        </ToolHeader>
        <br />

        <React.Fragment>
          {tabs
            .filter((tab) => isTabActive(tab.id))
            .map((tab, i) => (
              <React.Fragment key={i}>
                {(isLoading || cardLoading[tab.component.name]) && (
                  <Flex justifyContent="center">
                    <Spinner loading={isLoading} />
                  </Flex>
                )}

                {isInit && !isLoading && !cardLoading[tab.component.name] && (
                  <tab.component key={i} />
                )}
              </React.Fragment>
            ))}
        </React.Fragment>
      </Box>
    </Card>
  );
};
export default ContactInfoPage;
