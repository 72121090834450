import type { PageConfig } from 'pages/page-config.interfaces';

const OutboundEmailSettings: PageConfig = {
  label: 'TITLE',
  route: {
    page: 'outbound-emails-report',
  },
  i18n: {
    namespace: 'contact',
    keyPrefix: 'OUTBOUND_EMAILS_REPORT',
  },
  permissions: {
    edit: ['cs_view'],
  },
  cards: {},
};

export default OutboundEmailSettings;
