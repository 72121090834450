import React, { useContext } from 'react';
import { Breadcrumbs, Link, Page } from '@procore/core-react';
import { CompanyContext } from 'contexts/CompanyContext';
import { useTranslation } from 'react-i18next';

interface CompanyBreadcrumbsProps {
  pageLabel?: string;
}

const CompanyBreadcrumbs = (props: CompanyBreadcrumbsProps) => {
  const { t: tCommon } = useTranslation(['common']);
  const context = useContext(CompanyContext);

  return (
    <Page.Breadcrumbs>
      <Breadcrumbs>
        <Link href={`/v2/company/${context.companyId}`}>
          <Breadcrumbs.Crumb>{tCommon('COMPANY')}</Breadcrumbs.Crumb>
        </Link>
        {(context.contactId && context.loginId && (
          <Link
            href={`/v2/company/${context.companyId}/contact/${context.contactId}/login/${context.loginId}/contact-info`}
          >
            <Breadcrumbs.Crumb>
              {tCommon('CONTACT_INFORMATION')}
            </Breadcrumbs.Crumb>
          </Link>
        )) ||
          null}
        <Breadcrumbs.Crumb active>
          {props.pageLabel || context.pageConfig?.label || ''}
        </Breadcrumbs.Crumb>
      </Breadcrumbs>
    </Page.Breadcrumbs>
  );
};

export default CompanyBreadcrumbs;
