import React, {
  createContext,
  useEffect,
  useCallback,
  useContext,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, Outlet } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import { IpaAlertContext } from 'contexts/IpaAlertContext';
import { AppContext } from 'contexts/AppContext';
import { CompanyInfoApi } from '@procore/ipa-nt-api-client-ts';
import type { CompanyAccountStatusDto } from '@procore/ipa-nt-api-client-ts';
import { getApi } from 'utils/api';

export interface PaymentsContextProps {
  companyId?: number;
  companyStatus?: CompanyAccountStatusDto;
}

interface ContextProps extends ContextConsumerProps {
  children: JSX.Element;
}

interface ContextConsumerProps {
  children: any;
}

export const PaymentsContext = createContext({} as PaymentsContextProps);

export const PaymentsContextProvider = (props: ContextProps) => {
  const alert = useContext(IpaAlertContext);
  const { t } = useTranslation(['payments']);
  const { env } = useContext(AppContext);
  const { company_id, id } = useParams();
  const [companyStatus, setCompanyStatus] = useState<CompanyAccountStatusDto>();
  const companyId = +company_id || +id;
  const { authState } = useOktaAuth();

  const loadCompanyStatus = useCallback(async () => {
    alert.closeAlert();
    try {
      const api = getApi(CompanyInfoApi);
      const res = await api.companyCompanyInfoControllerGetCompanyAccountStatus(
        env,
        companyId,
      );
      if (res.data) {
        setCompanyStatus(res.data);
      }
    } catch (e: any) {
      alert.error(e);
      setCompanyStatus({
        id: Number(id),
        name: t('BUSINESS_ENTITY.UNKNOWN_NAME'),
        is_active: false,
        is_demo: false,
        enable_sandbox: false,
        account_deactivated: true,
        is_pin_required_for_jit: true,
        creation_date: 0,
        vendor_names: [],
      });
    }
  }, [companyId]);

  useEffect(() => {
    if (companyId && authState?.isAuthenticated) {
      loadCompanyStatus();
    }
  }, [companyId, authState]);
  return (
    <PaymentsContext.Provider value={{ companyId, companyStatus }}>
      {props.children}
    </PaymentsContext.Provider>
  );
};

export const PaymentContextLayout = () => {
  return (
    <PaymentsContextProvider>
      <Outlet />
    </PaymentsContextProvider>
  );
};

export default PaymentContextLayout;
