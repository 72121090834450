import type { SectionConfig } from 'pages/page-config.interfaces';
import CustomReportTabs from './CustomReportTabs/page.config';
import CustomReportTabsDetails from './CustomReportTabsDetails/page.config';

const reportsSection: SectionConfig = {
  id: 'Reports',
  permissions: {
    view: ['cs_view', 'company_tab_view'],
  },
  pages: {
    CustomReportTabs,
    CustomReportTabsDetails,
  },
};
export default reportsSection;
