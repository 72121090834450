import React, { useContext } from 'react';
import { EditableCard } from 'components/EditableCard';
import type { IEditableCard } from 'components/EditableCard';
import { ProjectScheduleContext } from '.';
import { Form, Box, H2, spacing } from '@procore/core-react';
import { ProjectContext } from 'contexts/ProjectContext';

export interface ProjectScheduleSettingsProps {
  id: string;
  save: IEditableCard['save'];
}

const GeneralScheduleSettingsCard = (props: ProjectScheduleSettingsProps) => {
  const context = useContext(ProjectContext);
  const config = context.pageConfig;
  const thirdparyIntegrationConfig = config.card('ThirdPartyIntegration');
  const uploadProjectScheduleFilesConfig = config.card(
    'UploadProjectScheduleFiles',
  );
  const projectTasksConfig = config.card('ProjectTasks');
  const projectScheduleSidebarConfig = config.card('ScheduleSidebar');

  const {
    dataSources: { scheduleSettings },
  } = useContext(ProjectScheduleContext);

  const thirdPartyIntegrationCard: IEditableCard = {
    id: props.id,
    i18n: thirdparyIntegrationConfig.translate,
    label: thirdparyIntegrationConfig.label,
    canEdit: context.hasPermission(config.permissions.edit),
    save: props.save,
    fields: [
      {
        source: scheduleSettings.id,
        value: scheduleSettings?.data.project_integration,
        name: 'project_integration',
        dataType: 'boolean',
        element: Form.Checkbox,
        ...thirdparyIntegrationConfig.fields.project_integration,
      },
    ],
  };

  return (
    <div style={{ marginTop: `${spacing.xl}px` }} key={props.id}>
      <EditableCard card={thirdPartyIntegrationCard} />
      <Form
        enableReinitialize={true}
        view="read"
        initialValues={{
          ...scheduleSettings?.data,
        }}
      >
        {() => {
          return (
            <Form.Form id="schedule-settings-form">
              <Box>
                <H2>{uploadProjectScheduleFilesConfig.label}</H2>
                <Form.Text
                  name="schedule_type"
                  label={
                    uploadProjectScheduleFilesConfig.fields.schedule_type.label
                  }
                  as={(data: any) => data.field.input.value?.label || '--'}
                  view="read"
                />
              </Box>
              <Box>
                <H2>{projectTasksConfig.label}</H2>
                <Form.Row>
                  <Form.Checkbox
                    name="display_task_names_with_full_outline_path"
                    label={
                      projectTasksConfig.fields
                        .display_task_names_with_full_outline_path.label
                    }
                    view="read"
                    colStart={1}
                    colWidth={6}
                  />
                  <Form.Checkbox
                    name="schedule_show_resources_on_calendar"
                    label={
                      projectTasksConfig.fields
                        .schedule_show_resources_on_calendar.label
                    }
                    view="read"
                    colStart={7}
                    colWidth={6}
                  />
                </Form.Row>
                <Form.Row>
                  <Form.Checkbox
                    name="schedule_allow_task_updates"
                    label={
                      projectTasksConfig.fields.schedule_allow_task_updates
                        .label
                    }
                    view="read"
                    colStart={1}
                    colWidth={6}
                  />
                  <Form.Checkbox
                    name="schedule_task_auto_formatting"
                    label={
                      projectTasksConfig.fields.schedule_task_auto_formatting
                        .label
                    }
                    view="read"
                    colStart={7}
                    colWidth={6}
                  />
                </Form.Row>
              </Box>
              <Box>
                <H2>{projectScheduleSidebarConfig.label}</H2>
                <Form.Row>
                  <Form.Checkbox
                    name="create_calendar_item_enabled"
                    label={
                      projectScheduleSidebarConfig.fields
                        .create_calendar_item_enabled.label
                    }
                    view="read"
                    colStart={1}
                    colWidth={6}
                  />
                  <Form.Checkbox
                    name="calendar_people_filters_enabled"
                    label={
                      projectScheduleSidebarConfig.fields
                        .calendar_people_filters_enabled.label
                    }
                    view="read"
                    colStart={7}
                    colWidth={6}
                  />
                </Form.Row>
              </Box>
            </Form.Form>
          );
        }}
      </Form>
    </div>
  );
};

export default GeneralScheduleSettingsCard;
