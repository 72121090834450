import React from 'react';
import { colors } from '@procore/core-react';
import Logo from 'images/procore-logo-white.svg';

interface ProcoreLogoProps {
  title: string;
}

export const ProcoreLogo = (props: ProcoreLogoProps) => {
  const logoStyle = { color: colors.white, fontWeight: 600, lineHeight: 1.5 };

  return (
    <span style={logoStyle}>
      <img src={Logo} alt='Procore' style={{ display: 'block' }} />{' '}
      {props.title}
    </span>
  );
};

export default ProcoreLogo;
