import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card,
  Flex,
  Spinner,
  Box,
  EmptyState,
  Typography,
  Form,
  Page,
} from '@procore/core-react';
import { getApi } from 'utils/api';
import { ProjectsApi } from '@procore/ipa-nt-api-client-ts';
import type { ProjectTaskItemsSettingsDto } from '@procore/ipa-nt-api-client-ts';
import { IpaAlertContext } from 'contexts/IpaAlertContext';
import { ProjectContext } from 'contexts/ProjectContext';
import { FieldWrapper } from 'styles';

const TaskItemsSettingsPage = () => {
  const alert = useContext(IpaAlertContext);
  const context = useContext(ProjectContext);
  const config = context.pageConfig.card('TaskItemsSettings');

  const t = config.translate;
  const { t: tCommon } = useTranslation(['common']);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isInit, setIsInit] = useState<boolean>(true);
  const [projectTaskItemsSettings, setProjectTaskItemsSettings] =
    useState<ProjectTaskItemsSettingsDto>({} as ProjectTaskItemsSettingsDto);

  const getProjectTaskItemsSettings = useCallback(async () => {
    setIsInit(false);
    setIsLoading(true);
    try {
      const api = getApi(ProjectsApi);
      const result = await api.projectControllerGetProjectTaskItemsSettings(
        context.env,
        context.companyId,
        context.projectId,
      );
      setProjectTaskItemsSettings(result.data);
    } catch (e: any) {
      alert.error(e);
    } finally {
      setIsLoading(false);
      setIsInit(true);
    }
  }, [context.projectId, context.companyId, context.env]);

  useEffect(() => {
    getProjectTaskItemsSettings();
  }, [getProjectTaskItemsSettings]);

  return (
    <React.Fragment>
      <Card>
        <Box padding="lg">
          {(!isInit || isLoading) && (
            <Box marginBottom="lg">
              <Card>
                <Box padding="lg">
                  {!isInit && !isLoading && (
                    <EmptyState>
                      <EmptyState.NoItems />
                      <EmptyState.Title>{tCommon('NO_DATA')}</EmptyState.Title>
                    </EmptyState>
                  )}
                  {isLoading && (
                    <Flex justifyContent="center">
                      <Spinner loading={isLoading} />
                    </Flex>
                  )}
                </Box>
              </Card>
            </Box>
          )}
          {isInit &&
            !isLoading &&
            Object.keys(projectTaskItemsSettings).length > 0 && (
              <Form
                initialValues={projectTaskItemsSettings}
                enableReinitialize={true}
                view="read"
              >
                <Form.Form>
                  <Page.Row>
                    <Page.Column>
                      <Form.Checkbox
                        name="private_by_default"
                        label={config.fields.private_by_default.label}
                      />
                    </Page.Column>
                    <Page.Column>
                      <FieldWrapper>
                        <Form.Text
                          name="days_due_default"
                          label={config.fields.days_due_default.label}
                        />{' '}
                        <Typography intent="body">
                          {t('DAYS_BY_DEFAULT')}
                        </Typography>
                      </FieldWrapper>
                    </Page.Column>
                  </Page.Row>
                </Form.Form>
              </Form>
            )}
        </Box>
      </Card>
    </React.Fragment>
  );
};

export default TaskItemsSettingsPage;
