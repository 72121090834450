import React, { useState, useEffect } from 'react';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  Form,
  Button,
  Box,
  Spinner,
  Page,
  H3,
} from '@procore/core-react';
import type { View } from '@procore/core-react/dist/Form/Form.types';
import { getChangedData, validateFormBeforeSubmit } from 'utils/form';
import { getSettingValue } from 'utils/utils';
import { IpaModalWrapper, ComparisonGridWrapper } from 'styles';
import type {
  DataEditModalProps,
  INoEditChunk,
} from './data.edit.modal.interface';

export const DataEditModal = (props: DataEditModalProps) => {
  const { t } = useTranslation('common');
  const [show, setShow] = useState<boolean>(false);
  const [data, setData] = useState<any>({});
  const [formView, setFormView] = useState<View>('read');
  const [modalStep, setModalStep] = useState<number>(1);
  const [dataLoaded, setDataLoaded] = useState<boolean>(false);
  const [changedData, setChangedData] = useState<any>([]);
  const [viewTitle, setViewTitle] = useState<string>(t('VIEW'));
  const [editTitle, setEditTitle] = useState<string>(t('EDIT'));
  const [editReason, setEditReason] = useState<string>(t('REASON_FOR_EDITING'));
  const [changesTitle, setChangesTitle] = useState<string>(t('CHANGES_MADE'));
  const [noEditChunks, setNoEditChunks] = useState<INoEditChunk>(
    props.noEditChunks,
  );

  const closeModal = () => {
    setData({});
    setModalStep(1);
    if (document.getElementById('data-edit-modal-form')) {
      const form = document.getElementById(
        'data-edit-modal-form',
      ) as HTMLFormElement;
      form.reset();
    }
    setDataLoaded(false);
    setChangedData([]);
    props.close();
  };

  const changeModalView = (n: number) => {
    setModalStep(n);
  };

  const formForward = (n: number, values: any) => {
    setChangedData(getChangedData(data, values));
    setModalStep(n);
  };

  const getSettingDisplayName = (name: string): string => {
    const setting = props.dataMap.find((s: any) => s.name === name);
    return setting ? setting.display_name : '';
  };

  const displayKeyValue = (key: string, data: any) => {
    return (
      `<strong>${getSettingDisplayName(key)}:</strong> ${getSettingValue(
        data[key],
      )}` + (noEditChunks?.[key] ? `-${noEditChunks[key]}` : '')
    );
  };

  useEffect(() => {
    if (Object.keys(props.data).length) {
      setDataLoaded(true);
      setData(props.data);
    }
  }, [props.data]);

  useEffect(() => {
    setShow(props.show);
    if (props.formView) {
      setFormView(props.formView);
    }
  }, [props.formView, props.show]);

  useEffect(() => {
    if (props.viewTitle && props.viewTitle !== '') {
      setViewTitle(props.viewTitle);
    }
    if (props.editTitle && props.editTitle !== '') {
      setEditTitle(props.editTitle);
    }
    if (props.editReason && props.editReason !== '') {
      setEditReason(props.editReason);
    }
    if (props.changesTitle && props.changesTitle !== '') {
      setChangesTitle(props.changesTitle);
    }
    if (props.noEditChunks) {
      setNoEditChunks(props.noEditChunks);
    }
  }, [
    props.editTitle,
    props.viewTitle,
    props.editReason,
    props.changesTitle,
    props.noEditChunks,
  ]);

  return (
    <Modal
      width={props.modalWidth ?? 'md'}
      open={show}
      onClickOverlay={closeModal}
    >
      <IpaModalWrapper>
        <Modal.Header onClose={closeModal}>
          {formView === 'update' ? editTitle : viewTitle}
        </Modal.Header>
        {!dataLoaded && <Spinner>{t('LOADING_DATA')}</Spinner>}
        {dataLoaded && (
          <Form
            view={formView}
            initialValues={{
              ...data,
              reason: '',
            }}
            validationSchema={yup.object().shape({
              reason: yup.string().nullable().required(t('REQUIRED')),
            })}
            onSubmit={(values) => {
              props.save(values, values.reason, changedData);
              closeModal();
            }}
          >
            {(formProps: any) => {
              const { dirty, values } = formProps;
              return (
                <Form.Form
                  id="data-edit-modal-form"
                  data-qa={'data-edit-modal-form'}
                >
                  {modalStep === 1 && (
                    <React.Fragment>
                      <Modal.Body>{props.children}</Modal.Body>
                      <Modal.Footer>
                        <Modal.FooterButtons>
                          {formView === 'read' && (
                            <Button variant="tertiary" onClick={closeModal}>
                              {t('CLOSE')}
                            </Button>
                          )}
                          {formView === 'update' && (
                            <React.Fragment>
                              <Button
                                variant="tertiary"
                                onClick={closeModal}
                                data-qa="cancel-btn"
                              >
                                {t('CANCEL')}
                              </Button>
                              <Button
                                variant="primary"
                                type="button"
                                data-qa="next-btn"
                                disabled={!dirty}
                                onClick={() =>
                                  validateFormBeforeSubmit(formProps, () =>
                                    formForward(2, values),
                                  )
                                }
                              >
                                {t('NEXT')}
                              </Button>
                            </React.Fragment>
                          )}
                        </Modal.FooterButtons>
                      </Modal.Footer>
                    </React.Fragment>
                  )}
                  {modalStep === 2 && (
                    <React.Fragment>
                      <Modal.Body>
                        <Modal.Section>
                          {props.modalCopy && (
                            <p
                              dangerouslySetInnerHTML={{
                                __html: props.modalCopy,
                              }}
                            ></p>
                          )}

                          <p>{changesTitle}</p>
                          <ComparisonGridWrapper>
                            <Page.Row>
                              <Page.Column className="ipa-col">
                                <Box>
                                  <H3>{t('CURRENT_VALUES')}</H3>
                                  <Box>
                                    <ul>
                                      {changedData?.map((key: string) => (
                                        <li
                                          key={key}
                                          dangerouslySetInnerHTML={{
                                            __html: displayKeyValue(key, data),
                                          }}
                                        />
                                      ))}
                                    </ul>
                                  </Box>
                                </Box>
                              </Page.Column>
                              <Page.Column className="ipa-col">
                                <Box>
                                  <H3>{t('NEW_VALUES')}</H3>
                                  <Box>
                                    <ul>
                                      {changedData?.map((key: string) => (
                                        <li
                                          key={key}
                                          dangerouslySetInnerHTML={{
                                            __html: displayKeyValue(
                                              key,
                                              values,
                                            ),
                                          }}
                                        />
                                      ))}
                                    </ul>
                                  </Box>
                                </Box>
                              </Page.Column>
                            </Page.Row>
                          </ComparisonGridWrapper>
                          <Box className="ipa-modal-desc form">
                            <p>{editReason}</p>
                          </Box>
                          <Form.TextArea
                            name="reason"
                            label={t('REASON')}
                            data-qa="data-edit-modal-reason"
                          />
                        </Modal.Section>
                      </Modal.Body>
                      <Modal.Footer>
                        <Modal.FooterButtons>
                          <Button
                            variant="tertiary"
                            onClick={() => changeModalView(1)}
                          >
                            {t('BACK')}
                          </Button>
                          <Button
                            variant="primary"
                            type="submit"
                            disabled={values.reason === ''}
                            data-qa="data-change-modal-save"
                          >
                            {t('SAVE')}
                          </Button>
                        </Modal.FooterButtons>
                      </Modal.Footer>
                    </React.Fragment>
                  )}
                </Form.Form>
              );
            }}
          </Form>
        )}
      </IpaModalWrapper>
    </Modal>
  );
};

export default DataEditModal;
