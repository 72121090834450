import React, { useState, useEffect } from 'react';
import { Folder, Plus, File, ArrowsCycleClockwise } from '@procore/core-icons';
import {
  TreeListWrapper,
  IconWrapper,
  Minus,
  Corner,
  InfiniteRotation,
  styles,
} from './styles';

interface TreeListProps {
  loadBranch(
    node: any,
    onlyOneOpen: boolean,
    index: number,
    treeIndex?: number,
  ): void;
  onlyOneOpen?: boolean;
  noDataMessage?: string;
  childrenNode?: string;
  data: any[];
  treeIndex?: number; // if multiple trees are on the same page
}

interface TreeNode {
  isLoaded: boolean;
  isOpen: boolean;
}

export const TreeList = (props: TreeListProps) => {
  const [data, setData] = useState<any[]>([]);
  const [childrenNode, setChildrenNode] = useState('nodes');
  const [onlyOneOpen, setOnlyOneOpen] = useState<boolean>(false);
  const [branchLoading, setBranchLoading] = useState(false);
  const [indexLoading, setIndexLoading] = useState<number | null>(null);
  const [noDataMessage, setNoDataMessage] = useState('No data available');
  const [treeIndex, setTreeIndex] = useState<number | null>(null);

  const toggleNode = (node: TreeNode, index: number) => {
    if (!node.isLoaded) {
      setBranchLoading(true);
      setIndexLoading(index);
    }
    if (typeof props.loadBranch === 'function') {
      props.loadBranch(node, onlyOneOpen, index, treeIndex);
    }
  };

  useEffect(() => {
    if (props.data.length > 0) {
      setData(props.data);
      if (branchLoading) {
        setBranchLoading(false);
        setIndexLoading(null);
      }
    }
    if (props.childrenNode) {
      setChildrenNode(props.childrenNode);
    }
    if (typeof props.onlyOneOpen !== 'undefined') {
      setOnlyOneOpen(props.onlyOneOpen);
    }
    if (props.noDataMessage && props.noDataMessage !== '') {
      setNoDataMessage(props.noDataMessage);
    }
    if (typeof props.treeIndex !== 'undefined') {
      setTreeIndex(props.treeIndex);
    }
  }, [props]);

  return (
    <TreeListWrapper>
      {data.length > 0 && (
        <ul>
          {data &&
            data.map((node, i) => (
              <li key={i}>
                {node.children_count > 0 ? (
                  <IconWrapper
                    data-qa="toggle-icon"
                    onClick={() => toggleNode(node, i)}
                  >
                    {!node.isOpen && indexLoading !== i && <Plus size="sm" />}
                    {(node.isOpen || (branchLoading && indexLoading === i)) && (
                      <Minus>
                        <span>-</span>
                      </Minus>
                    )}
                  </IconWrapper>
                ) : (
                  <IconWrapper className="empty" />
                )}
                {branchLoading && indexLoading === i ? (
                  <InfiniteRotation>
                    <ArrowsCycleClockwise />
                  </InfiniteRotation>
                ) : (
                  <Folder style={styles.folder} />
                )}

                <span style={styles.nodeName}>
                  {node.code} {node.name}
                </span>
                {node.isOpen && node[childrenNode] && (
                  <ul data-qa="expanded-branch">
                    {node[childrenNode].map((child: any, j: number) => (
                      <li key={j} style={styles.subList}>
                        <Corner style={styles.corner} />
                        <File style={styles.file} />
                        <span style={styles.subNodeName}>
                          {child.code} {child.name}
                        </span>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
        </ul>
      )}
      {data.length === 0 && <div>{noDataMessage}</div>}
    </TreeListWrapper>
  );
};

export default TreeList;
