import React, {useContext, useState} from 'react';
import { Page, Card, Button, Title, H1, InfoBanner, Banner, Box, Input, Select } from '@procore/core-react';
import { useTranslation } from "react-i18next";
import { DevPortalApi } from '@procore/ipa-nt-api-client-ts';
import { getApi } from 'utils/api';
import { PageConfig } from "pages/page-config.service";
import { IpaAlertContext } from "contexts/IpaAlertContext";

export const OauthSecretRotationsPage = () => {
  const { t: tCommon } = useTranslation(['common']);
  const pageConfig = PageConfig.get(
    tCommon,
    'DevPortal.OauthSecretRotations',
  );

  const [developerAppId, setDeveloperAppId] = useState('');
  const [selectedEnv, setSelectedEnv] = useState<string>('production');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const alert = useContext(IpaAlertContext);

  const envOptions = [
    { label: pageConfig.translate('ENV_INFO.PROD_ENV'), value: 'production' },
    { label: pageConfig.translate('ENV_INFO.SANDBOX_ENV'), value: 'sandbox' },
    { label: pageConfig.translate('ENV_INFO.MONTHLY_SANDBOX_ENV'), value: 'monthly_sandbox' }
  ];

  const handleSelect = (selection: any) => {
    setSelectedEnv(selection.item);
  };

  const handleSubmit = async () => {
    try {
      alert.closeAlert();
      setIsLoading(true);

      const api = getApi(DevPortalApi);
      const response: any = await api.devPortalControllerRotateSecret(developerAppId, selectedEnv);
      const data = response.data;
      const status = data.status || response.status;

      if (status === 201) {
        alert.success(pageConfig.translate('ENV_INFO.SUCCESS_MESSAGE'));
      } else if (status === 422) {
        alert.error(pageConfig.translate('ENV_INFO.UNPROCESSABLE_ENTITY', { error: JSON.stringify(data.error) }));
      } else if (status === 401) {
        alert.error(pageConfig.translate('ENV_INFO.UNAUTHORIZED_MESSAGE'));
      } else if (status === 404) {
        alert.error(pageConfig.translate('ENV_INFO.NOT_FOUND_MESSAGE'));
      } else {
        alert.error(
          pageConfig.translate('ENV_INFO.UNKNOWN_ERROR_MESSAGE', { status: status, data: JSON.stringify(data) })
        );
      }
    } catch (error: any) {
      alert.error(pageConfig.translate('ENV_INFO.ERROR_MESSAGE', { error: error.message || error }));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Page>
      <Page.Main>
        <Page.Header transparent={true}>
          <Page.Title>
            <Title>
              <Title.Text>
                <H1>{pageConfig.label}</H1>
              </Title.Text>
            </Title>
          </Page.Title>
        </Page.Header>
        <Page.Body>
          <Page.Row>
            <Page.Column>
              <Card>
                <InfoBanner>
                  <Banner.Content>
                    <Banner.Body data-qa="banner-status-text">
                      {pageConfig.translate('INFO_MESSAGE')}
                    </Banner.Body>
                  </Banner.Content>
                </InfoBanner>
              </Card>
              <Card>
                <Box margin="lg">
                  <Input
                    value={developerAppId}
                    onChange={(e) => setDeveloperAppId(e.target.value)}
                    placeholder={pageConfig.translate('ENV_INFO.APP_ID')}
                    data-qa="developer-app-id-field"
                    style={{
                      width: '30%',
                      marginBottom: '10px',
                    }}
                  />
                  <Select
                    label={selectedEnv}
                    onSelect={handleSelect}
                    placeholder={pageConfig.translate('ENV_INFO.SELECT_ENV')}
                    data-qa="env-select"
                    className="select"
                    style={{
                      width: '28%',
                      marginBottom: '5px',
                    }}
                  >
                    {envOptions.map((env) => (
                      <Select.Option
                        key={env.value}
                        value={env.value}
                        selected={env.value === selectedEnv}
                      >
                        {env.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Box>
                <Box margin="lg">
                  <Button
                    onClick={handleSubmit}
                    variant="primary"
                    data-qa="rotate-btn"
                    disabled={isLoading}
                    style={{
                      marginTop: '5px',
                      marginBottom: '10px',
                    }}
                  >
                    {pageConfig.translate('ENV_INFO.BUTTON_NAME')}
                  </Button>
                </Box>
              </Card>
            </Page.Column>
          </Page.Row>
        </Page.Body>
      </Page.Main>
    </Page>
  );
};

export default OauthSecretRotationsPage;
