import type { SectionConfig } from 'pages/page-config.interfaces';

const companyDirectorySection: SectionConfig = {
  id: 'Company Directory',
  i18n: {
    namespace: 'company',
  },
  label: 'COMPANY',
  permissions: {
    view: ['cs_view'],
    edit: ['add_user_to_directory'],
  },
  pages: {
    CompanyDirectory: {
      route: {
        path: '/v2/company-directory/:company_id?',
      },
      label: 'COMPANY_DIRECTORY_TITLE',
      endpoints: {
        view: [
          'companyDirectoryControllerGetCompanyDirectoryUser',
          'companyDirectoryControllerGetCompanyDirectory',
          'companyDirectoryControllerGetCompanyVendors',
          'companyDirectoryControllerGetPermissionTemplates',
        ],
        edit: ['companyDirectoryControllerUpdateCompanyDirectoryUser'],
      },
      cards: {
        Search: {
          label: 'COMPANY_DIRECTORY_TITLE',
          fields: {
            name: {
              label: 'NAME',
            },
            email_address: {
              label: 'EMAIL',
            },
          },
        },

        AddUser: {
          i18n: { namespace: 'company-directory' },
          label: 'ADD_USER_TITLE',
          fields: {
            firstName: {
              label: 'FIRST_NAME',
            },
            lastName: {
              label: 'LAST_NAME',
            },
            email: {
              label: 'EMAIL',
            },
            isEmployee: {
              label: 'IS_EMPLOYEE',
            },
            projectPermissionsTemplate: {
              label: 'PORJECT_PERMISSIONS_TEMPLATE',
            },
            companyPermissionsTemplate: {
              label: 'COMPANY_PERMISSIONS_TEMPLATE',
            },
          },
          endpoints: {
            edit: ['companyDirectoryControllerCreateCompanyDirectoryUser'],
          },
        },

        UserDetails: {
          i18n: {
            namespace: 'company-directory',
          },
          label: 'VIEW_USER_TITLE',
          fields: {
            firstName: {
              label: 'FIRST_NAME',
            },
            lastName: {
              label: 'LAST_NAME',
            },
            email: {
              label: 'EMAIL',
            },
            countryCode: {
              label: 'COUNTRY',
            },
            isEmployee: {
              label: 'IS_EMPLOYEE',
            },
            createNewProjects: {
              label: 'ALLOWED_CREATE_NEW_PROJECTS',
            },
            addToNewProjects: {
              label: 'ADD_TO_NEW_PROJECTS',
            },
            vendor: {
              label: 'COMPANY_NAME',
            },
          },
        },
      },
    },
  },
};

export default companyDirectorySection;
