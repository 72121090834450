import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { EditableCard } from 'components/EditableCard';
import type { IEditableCard } from 'components/EditableCard';
import {
  DateTimeProvider,
  Form,
  Grid,
  Link,
  Tooltip,
  Typography,
} from '@procore/core-react';
import { ExternalLink, Help } from '@procore/core-icons';
import { getBaseUrl } from 'utils/utils';
import { ProjectContext } from 'contexts/ProjectContext';
import { ipaAppStyles } from 'styles';
import type { ProjectInfoCardProps } from '../interfaces';
import { ProjectInfoContext } from '..';
import { PageConfig } from 'pages/page-config.service';
import type { CardConfigTranslated } from 'pages/page-config.interfaces';

export const ProjectInfoCard = (props: ProjectInfoCardProps) => {
  const context = useContext(ProjectContext);
  const { t } = useTranslation();
  const config = PageConfig.get(
    t,
    'Project.ProjectInfo.ProjectInformation',
  ) as CardConfigTranslated;
  const [editableInboundEmailAddress, setEditableInboundEmailAddress] =
    useState('');
  const [securityIdentifier, setSecurityIdentifier] = useState('');

  const {
    dataSources: { projectSettings },
  } = useContext(ProjectInfoContext);

  const data = projectSettings?.data;

  const change_reason_behaviour_options = [
    {
      id: '1',
      label: config.translate('CHANGE_REASON_BEHAVIOR_OPTIONS.0'),
      value: false,
    },
    {
      id: '2',
      label: config.translate('CHANGE_REASON_BEHAVIOR_OPTIONS.1'),
      value: true,
    },
  ];

  const change_reason_behavior_value = change_reason_behaviour_options.find(
    (opt) => opt.value === data?.advanced_settings?.enable_change_reason_select,
  );

  const checkSecurityIdentifier = (
    emailPrefix: string,
    createdDate: string,
  ) => {
    const temp = emailPrefix.split('-');
    if (
      temp[temp.length - 1].length === 10 &&
      new Date(createdDate) > new Date('2023-02-28 23:59:59')
    ) {
      setSecurityIdentifier(temp[temp.length - 1]);
      temp.pop();
      setEditableInboundEmailAddress(temp.join('-'));
    } else {
      setEditableInboundEmailAddress(emailPrefix);
    }
  };

  const card: IEditableCard = {
    id: props.id,
    i18n: config.translate,
    label: config.label,
    canEdit: context.hasPermission(config.permissions.edit),
    save: props.save,
    fields: [
      {
        source: projectSettings.id,
        value: data?.name,
        name: 'name',
        dataType: 'text',
        editable: false,
        elementFactory: (field) => (
          <React.Fragment>
            <Typography weight="bold">Name</Typography>
            <br />
            <Link
              href={
                getBaseUrl(context.env, 'procore', context.companyId) +
                `/${data?.id}/project/home`
              }
              target="_blank"
            >
              {data?.name} <ExternalLink size="sm" />
            </Link>
          </React.Fragment>
        ),
        element: <React.Fragment />,
        hideOnEdit: true,
        ...config.fields.name,
      },
      {
        source: projectSettings.id,
        value: data?.total_value,
        name: 'total_value',
        dataType: 'text',
        editable: false,
        element: Form.Text,
        hideOnEdit: true,
        ...config.fields.total_value,
      },
      {
        source: projectSettings.id,
        value: data?.start_date,
        name: 'start_date',
        dataType: 'text',
        editable: false,
        element: Form.DateSelect,
        hideOnEdit: true,
        ...config.fields.start_date,
      },
      {
        source: projectSettings.id,
        value: data?.actual_start_date,
        name: 'actual_start_date',
        dataType: 'text',
        editable: false,
        element: Form.DateSelect,
        hideOnEdit: true,
        ...config.fields.actual_start_date,
      },

      {
        source: projectSettings.id,
        value: data?.completion_date,
        name: 'completion_date',
        dataType: 'text',
        editable: false,
        element: Form.DateSelect,
        hideOnEdit: true,
        ...config.fields.completion_date,
      },
      {
        source: projectSettings.id,
        value: data?.projected_finish_date,
        name: 'projected_finish_date',
        dataType: 'text',
        editable: false,
        element: Form.DateSelect,
        hideOnEdit: true,
        ...config.fields.projected_finish_date,
      },
      {
        source: projectSettings.id,
        value: data?.project_stage?.selected_name,
        name: 'stage',
        dataType: 'text',
        editable: false,
        element: Form.Text,
        hideOnEdit: true,
        ...config.fields.stage,
      },
      {
        source: projectSettings.id,
        value: data?.project_type?.selected_name,
        name: 'project_type',
        dataType: 'text',
        editable: false,
        element: Form.Text,
        hideOnEdit: true,
        ...config.fields.project_type,
      },
      {
        source: projectSettings.id,
        value: data?.project_number,
        name: 'project_number',
        dataType: 'text',
        editable: false,
        element: Form.Text,
        hideOnEdit: true,
        ...config.fields.project_number,
      },
      {
        source: projectSettings.id,
        value: data?.square_feet,
        name: 'square_feet',
        dataType: 'text',
        editable: false,
        element: Form.Text,
        hideOnEdit: true,
        ...config.fields.square_feet,
      },
      {
        source: projectSettings.id,
        value: data?.description,
        name: 'description',
        dataType: 'text',
        editable: false,
        element: Form.Text,
        hideOnEdit: true,
        ...config.fields.description,
      },
      {
        source: projectSettings.id,
        value: data?.active,
        name: 'active',
        dataType: 'text',
        editable: false,
        element: Form.Checkbox,
        hideOnEdit: true,
        ...config.fields.active,
      },
      {
        source: projectSettings.id,
        value: `com-${data?.inbound_email_address}@${data?.inbound_email_server}`,
        name: 'inbound_email_address_complete',
        editable: false,
        dataType: 'text',
        element: Form.Text,
        hideOnEdit: true,
        formViews: ['read'],
        ...config.fields.inbound_email_address,
      },
      {
        source: projectSettings.id,
        value: data?.inbound_email_address,
        editableValue: editableInboundEmailAddress,
        noEditChunk: securityIdentifier || '',
        name: 'inbound_email_address',
        dataType: 'text',
        element: Form.Text,
        formViews: ['update'],
        ...config.fields.inbound_email_address,
      },
      {
        source: projectSettings.id,
        value: change_reason_behavior_value,
        name: 'enable_change_reason_select',
        elementFactory: (field) => (
          <Form.RadioButtons
            tooltip={config.translate('CHANGE_REASON_BEHAVIOR_TOOLTIP')}
            name={field.name}
            label={field.label}
            options={change_reason_behaviour_options}
          />
        ),
        transformValueAfter: (value) => {
          return value.value;
        },
        element: <React.Fragment />,
        ...config.fields.enable_change_reason_select,
      },
    ],
  };

  useEffect(() => {
    data?.inbound_email_address &&
      checkSecurityIdentifier(data?.inbound_email_address, data?.created_at);
  }, [data]);

  return (
    <DateTimeProvider timeZone="UTC">
      <EditableCard card={card} />
      <Grid>
        <hr />
        <Grid.Row>
          <Grid.Col colWidth={{ tabletSm: 4 }}>
            Logo:{' '}
            <Tooltip
              trigger="hover"
              placement="top"
              overlay="The project logo will display in the upper-left corner of the project navigation bar as well as in PDF exports, reports, and project emails."
            >
              <Help size="sm" style={ipaAppStyles.inlineTooltip} />
            </Tooltip>
          </Grid.Col>
          <Grid.Col colWidth={{ tabletSm: 8 }}>
            {data?.logo && (
              <img src={data.logo} style={{ maxWidth: '800px' }} />
            )}
          </Grid.Col>
        </Grid.Row>
        <hr />
        <Grid.Row>
          <Grid.Col colWidth={{ tabletSm: 4 }} className="name-col lower">
            Photo:{' '}
            <Tooltip
              overlay="The project photo will help identify your project on the Portfolio and Home pages."
              trigger="hover"
              placement="top"
            >
              <Help size="sm" style={ipaAppStyles.inlineTooltip} />
            </Tooltip>
          </Grid.Col>
          <Grid.Col colWidth={{ tabletSm: 8 }}>
            {data?.photo && (
              <img src={data.photo} style={{ maxWidth: '800px' }} />
            )}
          </Grid.Col>
        </Grid.Row>
      </Grid>
    </DateTimeProvider>
  );
};

export default ProjectInfoCard;
