import React, { createContext } from 'react';
import type {
  CompanyAccountStatusDto,
  ProjectSettingsDto,
} from '@procore/ipa-nt-api-client-ts';
import type { IDatasource } from 'components/EditableCard';
import type { PageConfigTranslated } from 'pages/page-config.interfaces';

export interface ProjectContextProps {
  env: string;
  companyId: number;
  projectId: number;
  contactId?: number;
  loginId?: number;
  dataSources: {
    companyStatus: IDatasource<CompanyAccountStatusDto>;
    projectSettings: IDatasource<ProjectSettingsDto>;
  };
  timeZone?: string;
  pageConfig: PageConfigTranslated;
  hasPermission: (permission: string[]) => boolean;
}

interface ContextProps extends ContextConsumerProps {
  children: JSX.Element;
}

interface ContextConsumerProps {
  children: any;
}

export const ProjectContext = createContext({} as ProjectContextProps);

export const ProjectContextProvider = (
  props: ProjectContextProps & ContextProps,
) => {
  return (
    <ProjectContext.Provider value={props}>
      {props.children}
    </ProjectContext.Provider>
  );
};
