import React, { useEffect, useState, useRef, useContext } from 'react';
import {
  Flex,
  FlexList,
  Link,
  Box,
  Popover,
  H2,
  Form,
  Button,
  Tooltip,
} from '@procore/core-react';
import * as yup from 'yup';
import sanitizeHtml from 'sanitize-html';
import { useTranslation } from 'react-i18next';
import { Home, CaretDown, Search, Help, Wrench } from '@procore/core-icons';
import MessageBanner from 'components/MessageBanner';
import ProcoreLogo from 'components/ProcoreLogo';
import UserMenu from 'components/UserMenu';
import { ProjectLookupTool } from './ProjectLookupTool';
import {
  HeaderWrapper,
  IconWrapper,
  CurrentLocation,
  Caret,
  IpaMenu,
  SupportDocsWrapper,
  NavSearch,
  SearchFieldWrapper,
  styles,
} from './styles';
import { useOktaAuth } from '@okta/okta-react';
import { AppContext } from 'contexts/AppContext';
import { IpaAlertContext } from 'contexts/IpaAlertContext';
import { ipaNavMap, getNavPath } from './ipaNavMap';
import type { IIpaNav, IIpaNavSubmenu } from './ipaNavMap';
import { can } from 'utils/permissions.helper';
import { ZONE_OPTIONS } from 'common/constants';
import { FeatureAndBugModal } from './featureAndBugModal';
import QuickHistory from 'components/QuickHistory';

interface AppHeaderProps {}

interface SearchForm {
  search: string;
}

interface FieldLabels {
  [index: string]: {
    title: string;
  };
}

export const AppHeader = (props: AppHeaderProps) => {
  const { authState } = useOktaAuth();
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [tab, setTab] = useState<IIpaNav>({} as IIpaNav);
  const [pageName, setPageName] = useState<string>('');
  const [nav, setNav] = useState<IIpaNav[]>([]);
  const [lastSearch, setLastSearch] = useState('');
  const [indexExist, setIndexExist] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const { t } = useTranslation('header');
  const { userConfig, userSettings, getLoginError, updateUserSettings } =
    useContext(AppContext);
  const alert = useContext(IpaAlertContext);
  const [featureAndBugModalOpen, setFeatureAndBugModalOpen] =
    useState<boolean>(false);
  const closeFeatureAndBugModal = () => setFeatureAndBugModalOpen(false);

  const SEARCH_FIELD_LABELS: FieldLabels = {
    US01: { title: 'SEARCH_FULL' },
    US02: { title: 'SEARCH_SHORT' },
  };

  const supportDocItems = [
    {
      title: 'SUPPORT_PROCORE_STATUS',
      url: 'https://status.procore.com/',
    },
    {
      title: 'SUPPORT_PROCORE SUPPORT',
      url: 'https://support.procore.com/',
    },
  ];

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      ref.current &&
      !ref.current.contains(event.target as Node) &&
      !document.querySelector('[data-qa="escalate-modal"]') &&
      !document.querySelector('[data-qa="confirm-modal"]')
    ) {
      setMenuOpen(false);
    }
  };

  const isSearchDisabled = (dirty: any, values: SearchForm) => {
    values.search = values.search.replace(/"/g, '');
    // Make sure neither value is empty and make sure at least one of the values has changed
    return !dirty || values.search === '' || values.search === lastSearch;
  };

  const doSearch = (values: SearchForm) => {
    values.search = values.search.replace(/"/g, '');
    let searchStr = values.search;
    setLastSearch(values.search);
    searchStr = sanitizeHtml(searchStr, {
      allowedTags: [],
      allowedAttributes: {},
    });

    localStorage.setItem(
      'ipa-nav-search',
      JSON.stringify({
        search: searchStr,
        exact: true,
      }),
    );

    window.location.href = getNavPath('NAV_GLOBAL_USER_SEARCH');
  };

  const supportDocsOverlay = (
    <Popover.Content
      style={styles.ipaReleasePopover}
      data-qa="support-documentation-overlay"
    >
      <SupportDocsWrapper>
        <H2>{t('SUPPORT_TITLE')}</H2>
        <ul>
          {supportDocItems.map((item, i) => (
            <li key={i}>
              <Link href={item.url} target="_blank">
                {t(item.title)}
              </Link>
            </li>
          ))}
        </ul>
      </SupportDocsWrapper>
    </Popover.Content>
  );

  const getShortPath = () => {
    const temp = window.location.pathname.split('/');
    if (temp[1] === 'v2' && temp[2]) {
      return '/' + temp[1] + '/' + temp[2];
    } else if (temp[1]) {
      return '/' + temp[1];
    } else {
      return '/';
    }
  };

  useEffect(() => {
    const setupPermissions = () => {
      setNav(
        ipaNavMap.map((item) => ({
          ...item,
          submenu: [
            ...item.submenu.filter((s) => {
              return can({ user: userConfig }, s.required_permissions);
            }),
          ],
          canView: can({ user: userConfig }, item.required_permissions),
        })),
      );
    };

    setupPermissions();
  }, [userConfig, userSettings]);

  const includesSpecificPath = (specificIndices: string[], path: string) =>
    specificIndices.map(
      (index) => path.indexOf(index) > 0 && setIndexExist(true),
    );

  useEffect(() => {
    const indices = ['contact-info'];

    includesSpecificPath(indices, window.location.pathname);
  }, []);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    const currentPath = window.location.pathname;
    const shortPath = getShortPath();
    let found: IIpaNavSubmenu | undefined = {} as IIpaNavSubmenu;
    ipaNavMap.every((nav) => {
      found = nav.submenu.find((sub) => {
        return sub.path === currentPath || sub.path === shortPath;
      });

      if (found) {
        setTab(nav);
        setPageName(found.name);
        return false;
      } else if (
        currentPath.includes('/custom-reports-tabs') &&
        nav.tab_name === 'company'
      ) {
        setTab(nav);
        setPageName(nav.submenu[1].name);
      } else if (
        currentPath.includes('contact-info') &&
        nav.tab_name === 'user_info'
      ) {
        setTab(nav);
        setPageName(nav.submenu[0].name);
        return false;
      } else if (
        currentPath.includes('customer-success') &&
        nav.tab_name === 'user_info'
      ) {
        setTab(nav);
        setPageName(nav.submenu[0].name);
        return false;
      } else if (
        currentPath.startsWith('/v2/company/') &&
        !indexExist &&
        nav.tab_name === 'company'
      ) {
        setTab(nav);
        setPageName(nav.submenu[0].name);
        return false;
      }
      return true;
    });
  }, [userConfig, window.location.pathname]);

  return (
    <HeaderWrapper id="header" data-qa="ipa-header" ref={ref}>
      <Flex>
        <Link href="/" style={styles.homeButton}>
          <Home />
        </Link>
        <Box style={styles.logoWrapper}>
          <ProcoreLogo title={t('APP_TITLE')} />
        </Box>
        <CurrentLocation onClick={toggleMenu} data-qa="ipa-nav-location">
          <div>
            <div className="tab" data-qa="ipa-nav-location-tab">
              {t(tab.name)}
            </div>
            <div className="page" data-qa="ipa-nav-location-page">
              {t(pageName)}
            </div>
          </div>
          <Caret animate={menuOpen}>
            <CaretDown />
          </Caret>
        </CurrentLocation>
        <FlexList space="md" style={styles.headerIcons}>
          {can({ user: userConfig }, 'cs_view') && (
            <NavSearch>
              <Form
                view="create"
                enableReinitialize={true}
                initialValues={{
                  search: '',
                  exact_search: true,
                }}
                validationSchema={yup.object().shape({
                  search: yup.string().nullable().required('Required'),
                })}
                onSubmit={(values) => doSearch(values)}
              >
                {(props: any) => {
                  const { dirty, values, handleSubmit } = props;
                  return (
                    <Form.Form
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' && values.search !== lastSearch) {
                          handleSubmit();
                        }
                      }}
                    >
                      <FlexList size="xs">
                        <SearchFieldWrapper>
                          <Form.Text
                            name="search"
                            id="nav-search"
                            type="text"
                            data-qa="nav-search-field"
                            className="search-field"
                            placeholder={t(
                              SEARCH_FIELD_LABELS[ZONE_OPTIONS[0].id].title,
                            )}
                          />
                        </SearchFieldWrapper>
                        <Button
                          icon={<Search />}
                          type="submit"
                          variant="primary"
                          data-qa="nav-search-btn"
                          className="ipa-row-button"
                          disabled={isSearchDisabled(dirty, values)}
                        ></Button>
                      </FlexList>
                    </Form.Form>
                  );
                }}
              </Form>
            </NavSearch>
          )}

          <ProjectLookupTool />
          <Tooltip
            overlay={t('FEATURE_AND_BUG_BUTTON_PLACEHOLDER')}
            trigger="hover"
          >
            <IconWrapper
              onClick={() => setFeatureAndBugModalOpen(true)}
              data-qa="ipa-nav-feature-and-bug"
            >
              <Wrench />
            </IconWrapper>
          </Tooltip>
          <Popover
            aria-labelledby="documentation"
            overlay={supportDocsOverlay}
            placement="bottom-left"
          >
            {() => (
              <IconWrapper data-qa="ipa-nav-support-documentation">
                <Help />
              </IconWrapper>
            )}
          </Popover>
          <QuickHistory />
          {authState && authState.isAuthenticated && userConfig.user && (
            <UserMenu
              email={userConfig.user.username ?? ''}
              // Authenticated config endpoint doesnt return name for JWT auth mode, so pull it from idToken
              name={
                (userConfig.user.name || authState.idToken?.claims?.name) ?? ''
              }
              roles={userConfig.user.roles.join(', ')}
              groups={userConfig.user.groups.join(', ')}
            />
          )}
        </FlexList>
      </Flex>
      <IpaMenu show={menuOpen}>
        <FlexList className="menu-grid" size="xl">
          {nav.map((item, i) => {
            if (item.canView && item.display) {
              return (
                <div key={i}>
                  <div className="tab">{t(item.name)}</div>
                  <ul>
                    {item.submenu.map((sub, j) => (
                      <li key={j}>
                        <Link href={getNavPath(sub.name)} data-qa={sub.qa}>
                          {t(sub.name)}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              );
            }
            return null;
          })}
        </FlexList>
      </IpaMenu>
      {getLoginError() && (
        <MessageBanner
          banner_message={t('LOGIN_ERROR')}
          banner_type="error"
          show_title={false}
        />
      )}
      <FeatureAndBugModal
        isOpen={featureAndBugModalOpen}
        modalTitle={t('FEATURE_AND_BUG_MODAL_TITLE')}
        closeModal={closeFeatureAndBugModal}
      />
    </HeaderWrapper>
  );
};

export default AppHeader;
