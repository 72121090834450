import React, { useState, useContext } from 'react';
import { Button, Title, H2, Page } from '@procore/core-react';
import { ipaAppStyles } from 'styles';
import ConfirmModal from 'components/ConfirmModal';
import { ProjectsApi } from '@procore/ipa-nt-api-client-ts';
import type { DeleteProjectBudgetRequestDto } from '@procore/ipa-nt-api-client-ts';
import { getApi } from 'utils/api';
import { IpaAlertContext } from 'contexts/IpaAlertContext';
import { ProjectContext } from 'contexts/ProjectContext';
import type { ProjectAdvancedSettingsProps } from '../interfaces';

export const ProjectBudgetSettings = (props: ProjectAdvancedSettingsProps) => {
  const alert = useContext(IpaAlertContext);
  const context = useContext(ProjectContext);
  const config = props.cardConfig;
  const t = config.translate;

  const [showConfirm, setShowConfirm] = useState(false);

  const confirmDeleteBudget = async (values: DeleteProjectBudgetRequestDto) => {
    try {
      const api = getApi(ProjectsApi);
      const res = await api.projectControllerDeleteProjectBudget(
        context.env,
        context.companyId,
        context.projectId,
        values,
      );
      if (res.data) {
        alert.success(t('SUCCESS'));
      }
    } catch (e: any) {
      alert.error(e);
    } finally {
      setShowConfirm(false);
    }
  };

  return (
    <React.Fragment>
      {context.hasPermission(config.permissions.edit) && (
        <React.Fragment>
          <Title style={ipaAppStyles.titleMargin}>
            <Title.Text>
              <H2>{config.label}</H2>
            </Title.Text>
          </Title>
          <Page.Row>
            <Button onClick={() => setShowConfirm(true)}>{t('DELETE')}</Button>
          </Page.Row>
          <ConfirmModal
            isOpen={showConfirm}
            closeModal={() => setShowConfirm(false)}
            modalTitle={t('DELETE')}
            modalCopy={t('MODAL')}
            verify={true}
            confirmModal={(reason) => confirmDeleteBudget(reason)}
            includeReason={true}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default ProjectBudgetSettings;
