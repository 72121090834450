// ***** PARAMETERS *****
// data: JSON data to display
// headers: array of header objects
// {
//    title: string (header title)
//    node: string (should match what's in JSON data)
//    is_sortable: boolean,
//    is_text: (renders a text cell if set to true),
//    is_currency: (renders string of numbers to currency format, eg 1025.9 => $1,205.90 )
//    is_boolean: (renders an icon if set to true),
//    is_time: (renders 'hh:mm:ss AM/PM timezone' if set to true),
//    is_datetime: (renders 'mm/dd/yyyy, h:mm:ss AM/PM timezone' if set to true)
//    is_time_remaining: (renders 'ss sec' or 'mm min ss sec' or 'hh hr mm min' if set to true)
//    is_link: (uses the 'url' parameter to display a link if set to true)
//    url: string (specifies URL path, used if 'is_link' is set to true)
//    col_hide_size: string (specifies window size when to hide this column, values are 'sm', 'md', 'lg', 'xl')
//    display_func: function (a function to render column cells)
//    column_width: number (sets percentage width, works only if 'fixed_table: true')
//    items: array of objects (this triggers grouping of rows)
// }
// page_size: number (page size for pagination)
// page_size_action: function (change page size function)
// default_sort_column: string (header node name to sort by)
// default_sort_direction: string ('asc' or 'desc)
// table_title: optional table title
// show_no_data_message: boolean (displays a message if there's no data)
// no_data_message: string (message to display if 'show_no_data_message' is set to true)
// hide_pagination: boolean (hides pagination if set to true)
// show_search: boolean (shows search field if set to true)
// search_placeholder: string (placeholder for the search field)
// search_columns: string[] (an array of node names)
// initial_search: string | undefined (default search value on init)
// show_page_size_select: boolean (displays a dropdown to select page sizes if set to true)
// horizontal_scroll: boolean (table becomes scrollable horizontally if set to true)
// hide_headers: boolean (header names will not be shown if set to true)
// fixed_table: boolean (if set to true, it makes table 'fixed', which allows to specify the width of each column)
// data_qa: data-qa attribute for testing purposes
//

import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  Pagination,
  Table,
  Box,
  Grid,
  Select,
  Typography,
  Tooltip,
  Search,
  H2,
  Flex,
} from '@procore/core-react';
import { Help, Check, Clear } from '@procore/core-icons';
import type { TableCellVariant } from '@procore/core-react/dist/Table/Table.types';
import { useTranslation } from 'react-i18next';
import CSS from 'csstype';
import { MessageBanner } from 'components/MessageBanner';
import { GroupRow } from './GroupRow';
import {
  TableWrapper,
  TooltipWrapper,
  PaginationWrapper,
  styles,
  StyledTableHeaderCell,
  StyledTableHeader,
} from './styles';
import type { TableHeader, TableProps, SelectType } from './table.interface';
import sanitizeHtml from 'sanitize-html';

export const TableBackendComponent = (props: TableProps) => {
  const { t } = useTranslation('common');
  const pageSizeOptions = [
    { value: 10, label: '10' },
    { value: 25, label: '25' },
    { value: 50, label: '50' },
    { value: 100, label: '100' },
  ];

  const getInitialPageSize = () => {
    const userSettings = localStorage.getItem('user-settings');
    if (userSettings !== null) {
      const settings = JSON.parse(userSettings).settings;
      return settings.default_page_size;
    } else {
      return pageSizeOptions[1].value;
    }
  };

  const [headers, setHeaders] = useState([]);
  const [currentPage, setCurrentPage] = useState(props.current_page);
  const [totalItems, setTotalItems] = useState(0);
  const [pageSize, setPageSize] = useState<number>(getInitialPageSize());
  const [allItems, setAllItems] = useState([]);
  const [displayedItems, setDisplayedItems] = useState([]);
  const [sortColumn, setSortColumn] = useState<string>('');
  const [sortDirection, setSortDirection] = useState<string>('asc');
  const [colorRows, setColorRows] = useState<boolean>(false);
  const [colorRowNode, setColorRowNode] = useState<string>('');
  const [colorRowRedValue, setColorRowRedValue] = useState('');
  const [tableTitle, setTableTitle] = useState<string>('');
  const [showNoDataMessage, setShowNoDataMessage] = useState<boolean>(false);
  const [noDataMessage, setNoDataMessage] = useState<string>(t('NO_DATA'));
  const [showPagination, setShowPagination] = useState(true);
  const [showPageSizeSelect, setShowPageSizeSelect] = useState(false);
  const [showSearch, setShowSearch] = useState<boolean>(false);
  const [horizontalScroll, setHorizontalScroll] = useState<boolean>(false);
  const [hideHeaders, setHideHeaders] = useState<boolean>(false);
  const [fixedTable, setFixedTable] = useState<boolean>(false);
  const [invalidData, setInvalidData] = useState(false);
  const [searchPlaceholder, setSearchPlaceholder] =
    useState<string>('Search...');
  const [missingData, setMissingData] = useState<string[]>([]);
  const [isInit, setIsInit] = useState<boolean>(false);

  const onSelect = ({ event, item, group }: SelectType) => {
    setPageSize(item);
    props.page_size_action?.(item);
  };

  const changePage = (page: number) => {
    if (props.paginator_selector) {
      props.paginator(page, pageSize, props.paginator_selector);
    } else {
      props.paginator(page, pageSize);
    }
  };

  const getTime = (str: string) => {
    if (typeof str === 'undefined' || !str) {
      return 'Invalid';
    }
    const t = new Date(str);
    const o = new Intl.DateTimeFormat('en-US', {
      timeStyle: 'long',
    });

    return o.format(t);
  };

  const getDate = (str: string) => {
    if (typeof str === 'undefined') {
      return 'Invalid';
    } else if (!str) {
      return '';
    }
    const t = new Date(str);
    const o = new Intl.DateTimeFormat('en-US');

    return o.format(t);
  };

  const getDatetime = (str: string) => {
    if (typeof str === 'undefined' || !str) {
      return '';
    }
    const t = new Date(str);
    const o = new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: 'numeric',
      minute: '2-digit',
      second: '2-digit',
      timeZoneName: 'short',
    });

    return o.format(t);
  };

  const getTimeRemaining = (end: string) => {
    const now = new Date().getTime();
    const endTime = new Date(end).getTime();
    const diff = Math.ceil((endTime - now) / 1000);
    let diffStr = '';

    if (diff <= 0) {
      return 'Demoting...';
    } else {
      const hours = Math.floor(diff / 3600);
      const mins = Math.floor((diff - hours * 3600) / 60);
      const secs = diff - hours * 3600 - mins * 60;
      if (hours > 0) {
        diffStr = hours + ' hr ' + mins + ' min';
      } else if (mins > 0) {
        diffStr = mins + ' min ' + secs + ' sec';
      } else {
        diffStr = secs + ' sec';
      }
      return diffStr;
    }
  };

  const getUrl = (url: string, node: string, obj: any) => {
    let isLink = false;
    if (typeof url !== 'undefined') {
      const matches = url.match(/[^{}]*(?=\})/gi);
      if (matches) {
        matches.forEach((v, i) => {
          if (v !== '' && obj[v]) {
            url = url.replace('{' + v + '}', obj[v]);
            isLink = true;
          }
        });
      }
    } else {
      url = obj[node];
      isLink = true;
    }

    if (isLink) {
      return (
        <Table.LinkCell href={sanitizeHtml(url)}>{obj[node]}</Table.LinkCell>
      );
    } else {
      return <Table.TextCell>{obj[node]}</Table.TextCell>;
    }
  };

  const getBooleanIcon = (val: boolean) =>
    val ? (
      <Check size="sm" style={styles.iconSuccess} />
    ) : (
      <Clear size="sm" style={styles.iconError} />
    );

  const headerClick = (header: TableHeader) => {
    if (!header.is_sortable) {
      return false;
    }
    if (sortColumn !== header.node) {
      changeSort(header.node, 'asc');
    } else {
      const direction = sortDirection === 'asc' ? 'desc' : 'asc';
      changeSort(header.node, direction);
    }
  };

  const getColumnType = (column: string) => {
    let columnType = 'text';

    headers.forEach((header: TableHeader) => {
      if (header.node === column) {
        if (header.is_time) {
          columnType = 'time';
        }
        if (header.is_date) {
          columnType = 'date';
        }
        if (header.is_datetime) {
          columnType = 'datetime';
        }
        if (header.is_time_remaining) {
          columnType = 'time_remaining';
        }
        // return true;
      }
    });

    return columnType;
  };

  const setupHeaders = useCallback(() => {
    const headerData =
      typeof props.headers === 'string'
        ? JSON.parse(props.headers)
        : props.headers;
    headerData.forEach((h: TableHeader) => {
      h.columnData = {};
      h.columnData.sortable = h.is_sortable ? true : false;

      // Only set sorting arrow to default on first pass. Otherwise use current sort value
      if (headers.length === 0 && h.node === props.default_sort_column) {
        h.columnData.variant = props.default_sort_direction
          ? props.default_sort_direction
          : sortDirection;
      } else if (h.node === sortColumn) {
        h.columnData.variant = sortDirection;
      }

      h.className = h.col_hide_size
        ? `ipa-table-hidden ${h.col_hide_size}:table-cell`
        : '';
    });
    setHeaders(headerData);
  }, [
    headers.length,
    props.default_sort_column,
    props.default_sort_direction,
    props.headers,
    sortColumn,
    sortDirection,
  ]);

  const updateHeaders = () => {
    headers.forEach((header: TableHeader) => {
      if (header.columnData && header.columnData.variant) {
        if (header.node !== sortColumn) {
          delete header.columnData.variant;
        } else {
          header.columnData.variant = sortDirection;
        }
      } else {
        if (header.node === sortColumn && header.columnData) {
          header.columnData.variant = sortDirection;
        }
      }
    });
    setHeaders(headers);
  };

  const changeSort = (column: string, direction: string) => {
    if (column !== '') {
      setSortDirection(direction);
      setSortColumn(column);
      updateHeaders();
      if (typeof props.sorter === 'function') {
        props.sorter(column, direction);
      } else {
        setupSort();
      }
    }
  };

  const sortTextColumn = () => {
    allItems.sort((a: any, b: any) => {
      const temp =
        typeof a[sortColumn] === 'string'
          ? a[sortColumn].toLowerCase()
          : a[sortColumn];
      const temp2 =
        typeof b[sortColumn] === 'string'
          ? b[sortColumn].toLowerCase()
          : b[sortColumn];
      if (sortDirection === 'asc') {
        return temp > temp2 ? 1 : -1;
      } else {
        return temp < temp2 ? 1 : -1;
      }
    });

    setDisplayedItems(
      allItems.slice((currentPage - 1) * pageSize, currentPage * pageSize),
    );
  };

  const sortTimeColumn = () => {
    if (sortDirection === 'asc') {
      allItems.sort((a, b) => {
        const timeOne = a[sortColumn] ? a[sortColumn] : 0;
        const timeTwo = b[sortColumn] ? b[sortColumn] : 0;
        return new Date(timeOne) > new Date(timeTwo) ? 1 : -1;
      });
    } else {
      allItems.sort((a, b) => {
        const timeOne = a[sortColumn] ? a[sortColumn] : 0;
        const timeTwo = b[sortColumn] ? b[sortColumn] : 0;
        return new Date(timeOne) < new Date(timeTwo) ? 1 : -1;
      });
    }
    setDisplayedItems(
      allItems.slice((currentPage - 1) * pageSize, currentPage * pageSize),
    );
  };

  const sortTimeRemainingColumn = () => {
    const now = new Date().getTime();

    if (sortDirection === 'asc') {
      allItems.sort((a, b) => {
        const diffA = new Date(a[sortColumn]).getTime() - now;
        const diffB = new Date(b[sortColumn]).getTime() - now;
        return diffA > diffB ? 1 : -1;
      });
    } else {
      allItems.sort((a, b) => {
        const diffA = new Date(a[sortColumn]).getTime() - now;
        const diffB = new Date(b[sortColumn]).getTime() - now;
        return diffA < diffB ? 1 : -1;
      });
    }
    setDisplayedItems(
      allItems.slice((currentPage - 1) * pageSize, currentPage * pageSize),
    );
  };

  const getRowColor = (obj: any) => {
    if (!colorRows) {
      return '';
    } else {
      if (obj[colorRowNode] && obj[colorRowNode] === colorRowRedValue) {
        return '';
      } else {
        return 'red-row';
      }
    }
  };

  const getText = (val: string) => {
    let value = null;
    try {
      value = JSON.parse(val);
    } catch (e) {
      value = val;
    }

    if (Array.isArray(value)) {
      return value.join(', ');
    } else if (value === null || value === undefined) {
      return '';
    } else if (typeof value === 'object' && Object.keys(value).length === 0) {
      return '';
    } else {
      if (typeof value.value !== 'undefined') {
        return value.value ? value.value.toString() : '0';
      } else if (
        typeof value.tool_name !== 'undefined' &&
        typeof value.is_active !== 'undefined'
      ) {
        return value.tool_name + ': ' + value.is_active.toString();
      } else if (
        typeof value.tool_name !== 'undefined' &&
        typeof value.is_selected !== 'undefined'
      ) {
        return value.tool_name + ': ' + value.is_selected.toString();
      } else {
        return typeof value === 'object'
          ? JSON.stringify(value)
          : value.toString();
      }
    }
  };

  const getCurrency = (val: number) => {
    let value = null;

    try {
      value = val.toString();
    } catch (e) {
      return 'ERROR';
    }

    value = value.replace(/(\d)(?=(\d{3})+(\.(\d){0,2})*$)/g, '$1,');

    if (value.indexOf('.') === -1) return value + '.00';

    var decimals = value.split('.')[1];

    return decimals.length < 2 ? value + '0' : value;
  };

  const getSearchResults = (value: string) => {
    if (typeof props.search_action === 'function') {
      props.search_action(value);
    }
  };

  const setupSort = () => {
    const columnType = getColumnType(sortColumn);
    if (columnType === 'text') {
      sortTextColumn();
    } else if (
      columnType === 'time' ||
      columnType === 'datetime' ||
      columnType === 'date'
    ) {
      sortTimeColumn();
    } else if (columnType === 'time_remaining') {
      sortTimeRemainingColumn();
    }
  };

  const dataCheck = (data: any, headers: TableHeader[]) => {
    let temp: string[] = [];
    const test = headers
      .filter((header) => header.node)
      .every((header) => {
        if (data[0] && data[0].items) {
          if (data[0].items[0] && header.node in data[0].items[0] === false) {
            temp.push(header.node);
          }
          return data[0].items[0] ? header.node in data[0].items[0] : true;
        } else {
          if (data[0] && header.node in data[0] === false) {
            temp.push(header.node);
          }
          return data[0] ? header.node in data[0] : true;
        }
      });
    setMissingData(temp);
    return test;
  };

  const toggleGroup = (index: number) => {
    let temp = [...allItems];
    temp.forEach((item: any, i) => {
      item.is_expanded = i === index ? !item.is_expanded : false;
    });
    setAllItems(temp);
  };

  const renderRow = (item: any, i: number, show: boolean) => (
    <Table.BodyRow
      key={i}
      className={getRowColor(item)}
      style={!show ? { display: 'none' } : {}}
    >
      {headers.map((header: TableHeader, j) => (
        <Table.BodyCell
          key={i + j}
          className={'ipa-table-cell ' + header.className}
        >
          {header.is_link &&
            header.url &&
            getUrl(header.url, header.node, item)}
          {header.display_func && (
            <Table.TextCell>
              {header.display_func(item, header.node)}
            </Table.TextCell>
          )}
          {header.is_time && (
            <Table.TextCell>{getTime(item[header.node])}</Table.TextCell>
          )}
          {header.is_date && (
            <Table.TextCell>{getDate(item[header.node])}</Table.TextCell>
          )}
          {header.is_datetime && (
            <Table.TextCell>{getDatetime(item[header.node])}</Table.TextCell>
          )}
          {header.is_time_remaining && (
            <Table.TextCell>
              {getTimeRemaining(item[header.node])}
            </Table.TextCell>
          )}
          {header.is_boolean && (
            <Table.TextCell>{getBooleanIcon(item[header.node])}</Table.TextCell>
          )}
          {header.is_text && (
            <Table.TextCell>{getText(item[header.node])}</Table.TextCell>
          )}
          {header.is_currency && (
            <Table.TextCell>{getCurrency(item[header.node])}</Table.TextCell>
          )}
        </Table.BodyCell>
      ))}
    </Table.BodyRow>
  );

  useEffect(() => {
    if (props.data) {
      if (props.color_rows && props.color_rows_red) {
        setColorRows(true);
        setColorRowNode(props.color_rows);
        setColorRowRedValue(props.color_rows_red);
      }
      if (props.show_no_data_message) {
        setShowNoDataMessage(true);
      }
      if (props.no_data_message) {
        setNoDataMessage(props.no_data_message);
      }
      if (props.hide_pagination) {
        setShowPagination(false);
      }
      if (props.show_search) {
        setShowSearch(true);
      }
      if (props.show_page_size_select) {
        setShowPageSizeSelect(props.show_page_size_select);
      }
      if (props.horizontal_scroll) {
        setHorizontalScroll(true);
      }
      if (props.hide_headers) {
        setHideHeaders(props.hide_headers);
      }
      if (props.fixed_table) {
        setFixedTable(props.fixed_table);
      }
      if (props.search_placeholder) {
        setSearchPlaceholder(props.search_placeholder);
      }
      setupHeaders();
    }
  }, [props, setupHeaders]);

  useEffect(() => {
    if (props.table_title) {
      setTableTitle(props.table_title);
    }
  }, [props.table_title]);

  useEffect(() => {
    if (props.data) {
      let temp: any = props.data;
      if (
        typeof props.headers === 'object' &&
        !dataCheck(props.data, props.headers)
      ) {
        setInvalidData(true);
        return;
      }
      if (props.data[0] && props.data[0].items) {
        temp = props.data.map((p, i) => {
          return { ...p, is_expanded: false, index: i };
        });
      }
      setTotalItems(props.data.length);
      setAllItems(temp);
      setCurrentPage(1);
      setIsInit(true);

      if (props.current_page) {
        setCurrentPage(props.current_page);
      }

      if (props.page_size && +props.page_size !== pageSize) {
        setPageSize(+props.page_size);
      } else {
        setDisplayedItems(temp.slice(0, pageSize));
      }
      if (props.default_sort_column && !isInit) {
        setSortColumn(props.default_sort_column);
      }
      if (props.default_sort_direction && !isInit) {
        setSortDirection(props.default_sort_direction);
      }
    }
  }, [props.data]);

  useEffect(() => {
    setDisplayedItems(allItems.slice(0, pageSize));
  }, [pageSize, allItems]);

  useEffect(() => {
    if (sortColumn !== '') {
      updateHeaders();
      setupSort();
    }
  }, [sortColumn, sortDirection]);

  return (
    <TableWrapper>
      <Box paddingTop="lg">
        {tableTitle !== '' && <H2>{tableTitle}</H2>}
        <Grid gutterY={'sm'}>
          <Grid.Row style={styles.row}>
            <Grid.Col colWidth={{ tabletSm: 12, tabletMd: 8, tabletLg: 8 }}>
              {totalItems > 0 && showPagination && (
                <PaginationWrapper>
                  <Pagination
                    data-qa="ipa-table-pagination"
                    activePage={currentPage}
                    items={props.data_total ? props.data_total : totalItems}
                    onSelectPage={(page) => {
                      changePage(page);
                    }}
                    perPage={pageSize}
                  />
                  {showPageSizeSelect && (
                    <div className="page-size">
                      <Typography intent="body">{t('PAGE_SIZE')}:</Typography>
                      <Select
                        label={pageSize}
                        onSelect={onSelect}
                        placeholder={t('SELECT_ITEM')}
                        className="select"
                      >
                        {pageSizeOptions.map((item) => (
                          <Select.Option
                            key={item.value}
                            value={item.value}
                            selected={item.value === pageSize}
                          >
                            {item.label}
                          </Select.Option>
                        ))}
                      </Select>
                      <TooltipWrapper>
                        <Tooltip
                          overlay={t('DEFAULT_PAGE_SIZE')}
                          trigger="hover"
                        >
                          <Help size="sm" />
                        </Tooltip>
                      </TooltipWrapper>
                    </div>
                  )}
                </PaginationWrapper>
              )}
            </Grid.Col>

            {showSearch && (
              <Grid.Col
                colWidth={{ tabletSm: 12, tabletMd: 4, tabletLg: 4 }}
                style={styles.search}
              >
                <Flex data-qa="ipa-table-search">
                  <Search
                    name="search"
                    data-qa="table-search"
                    placeholder={searchPlaceholder}
                    onSubmit={(value) => getSearchResults(value)}
                    onClear={() => getSearchResults('')}
                    style={styles.input}
                    value={props.initial_search}
                  />
                </Flex>
              </Grid.Col>
            )}
          </Grid.Row>
        </Grid>
        <Table.Container
          className={`ipa-table-wrapper ${
            horizontalScroll ? 'table-horizontal-scroll' : ''
          }`}
        >
          <Table
            {...(fixedTable ? { variant: 'fixed', width: '100%' } : {})}
            data-qa={props.data_qa ? props.data_qa : 'ipa-table'}
          >
            {!hideHeaders && (
              <StyledTableHeader $sticky={props.sticky_headers}>
                <Table.HeaderRow>
                  {headers.map((header: TableHeader, i: number) => {
                    const headerStyles: CSS.StandardProperties = {};
                    if (header.column_width && fixedTable) {
                      headerStyles.width = `${header.column_width}%`;
                    }
                    return (
                      <StyledTableHeaderCell
                        $sticky={props.sticky_headers}
                        snugfit={true}
                        className={header.className}
                        key={i}
                        style={headerStyles}
                        onClick={() => headerClick(header)}
                        sortable={header.is_sortable}
                        variant={
                          header.node === sortColumn
                            ? (sortDirection as TableCellVariant)
                            : ''
                        }
                      >
                        {header.title}
                      </StyledTableHeaderCell>
                    );
                  })}
                </Table.HeaderRow>
              </StyledTableHeader>
            )}
            {totalItems > 0 && (
              <Table.Body>
                {displayedItems.map((item: any, i: number) => {
                  if (item.items && Array.isArray(item.items)) {
                    return (
                      <React.Fragment key={i}>
                        <GroupRow
                          headers={headers}
                          item={item}
                          row_click={toggleGroup}
                        />
                        <React.Fragment>
                          {item.items.map((subitem: any, n: number) =>
                            renderRow(subitem, n, item.is_expanded),
                          )}
                        </React.Fragment>
                      </React.Fragment>
                    );
                  } else {
                    return renderRow(item, i, true);
                  }
                })}
              </Table.Body>
            )}
            {!totalItems && showNoDataMessage && !invalidData && (
              <Table.Body>
                <Table.BodyRow>
                  <Table.BodyCell
                    colSpan={headers.length}
                    className="ipa-table-cell"
                  >
                    <Table.TextCell>{noDataMessage}</Table.TextCell>
                  </Table.BodyCell>
                </Table.BodyRow>
              </Table.Body>
            )}
          </Table>
        </Table.Container>
        {invalidData && (
          <MessageBanner
            banner_type="error"
            banner_message={t('UNEXPECTED_DATA', {
              fields: missingData.join(', '),
            })}
          />
        )}
      </Box>
    </TableWrapper>
  );
};

export default TableBackendComponent;
