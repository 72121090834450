import type { PageConfig } from 'pages/page-config.interfaces';

const ProjectSettings: PageConfig = {
  label: 'TITLE',
  route: {
    page: 'project-settings',
  },
  i18n: {
    namespace: 'contact',
    keyPrefix: 'PROJECT_SETTINGS',
  },
  permissions: {
    view: ['cs_view'],
  },
  endpoints: {
    view: [
      'companyDirectoryControllerGetProjectPermissionTemplates',
      'companyDirectoryControllerGetAssignablePermissionTemplates',
    ],
  },
  cards: {
    ProjectPermissions: {
      i18n: {
        keyPrefix: 'PROJECT_SETTINGS.PROJECT_PERMISSIONS_CARD',
      },
      label: 'TITLE',
      fields: {},
    },
    DefaultDistribution: {
      i18n: {
        keyPrefix: 'PROJECT_SETTINGS.DEFAULT_DISTRIBUTION_CARD',
      },
      label: 'TITLE',
      fields: {},
    },
    ScheduleNotifications: {
      i18n: {
        keyPrefix: 'PROJECT_SETTINGS.SCHEDULE_NOTIFICATIONS_CARD',
      },
      label: 'TITLE',
      fields: {},
    },
  },
};

export default ProjectSettings;
