import type { SectionConfig } from 'pages/page-config.interfaces';
import IntegratorSandboxConfig from './IntegratorSandboxConfig/page.config';
import MonthlySandbox from './MonthlySandbox/page.config';
import OauthApplications from './OauthApplications/page.config';
import OauthSecretRotations from './OauthSecretRotations/page.config';

const DevPortal: SectionConfig = {
  id: 'Dev Portal',
  route: {
    path: '/v2/dev-portal',
  },
  i18n: {
    namespace: 'dev-portal',
  },
  permissions: {
    view: ['dev_portal'],
    edit: ['dev_portal'],
  },
  pages: {
    IntegratorSandboxConfig,
    MonthlySandbox,
    OauthApplications,
    OauthSecretRotations,
  },
};

export default DevPortal;
