import type { PageConfig } from 'pages/page-config.interfaces';

export default <PageConfig>{
  label: 'TITLE',
  i18n: {
    namespace: 'custom-report-tabs',
  },
  route: {
    v1_path: '/customer-success/custom-reports-tabs',
    path: '/v2/custom-reports-tabs',
  },
  permissions: {
    view: ['company_tab_view', 'cs_view'],
  },
  endpoints: {
    view: ['customReportTabsControllerGetCustomReports'],
  },
  cards: {},
};
