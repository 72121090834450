import type { PageConfig } from 'pages/page-config.interfaces';

const pageConfig: PageConfig = {
  label: 'TITLE',
  route: {
    page: 'list_generic_pdf_templates',
  },
  i18n: {
    namespace: 'super-area',
    keyPrefix: 'GLOBAL_PDF_TEMPLATES',
  },
  endpoints: {
    view: [],
  },
  cards: {},
};

export default pageConfig;
