import React, { useContext } from 'react';
import sanitizeHtml from 'sanitize-html';
import {
  Page,
  Card,
  Box,
  ToolHeader,
  Link,
  Breadcrumbs,
} from '@procore/core-react';
import { useParams } from 'react-router-dom';
import { PageConfig } from 'pages/page-config.service';
import type { PageConfigTranslated } from 'pages/page-config.interfaces';
import { TableComponent } from 'components/Table';
import type { TableHeader } from 'components/Table/table.interface';
import { useTranslation } from 'react-i18next';
import type { IInsightsReport } from './insights.interface';
import WorkflowUsageReportPage from './WorkflowUsageData';
import CompanyCustomToolReportPage from './CompanyCustomToolReport';
import ProjectCustomToolReportPage from './ProjectCustomToolReport';
import CompaniesInvalidSalesforceAccountPage from './CompaniesInvalidSalesforceAccount';
import CustomFieldsReportPage from './CustomFieldsReport';
import InsightsContextProvider, {
  InsightsContext,
} from 'contexts/InsightsContext';

const pages = [
  {
    id: 'workflow-data',
    configKey: 'Insights.WorkflowUsageReportPage',
    component: WorkflowUsageReportPage,
  },
  {
    id: 'company-custom-tool',
    configKey: 'Insights.CompanyCustomToolReportPage',
    component: CompanyCustomToolReportPage,
  },
  {
    id: 'project-custom-tool',
    configKey: 'Insights.ProjectCustomToolReportPage',
    component: ProjectCustomToolReportPage,
  },
  {
    id: 'companies-with-invalid-salesforce-account-ids',
    configKey: 'Insights.CompaniesInvalidSalesforceAccountPage',
    component: CompaniesInvalidSalesforceAccountPage,
  },
  {
    id: 'custom-fields-report',
    configKey: 'Insights.CustomFieldsReportPage',
    component: CustomFieldsReportPage,
  },
];

export const InsightsReportsPage = () => {
  const { t } = useTranslation(['insights']);
  const context = useContext(InsightsContext);
  const { page: _page } = useParams();

  let pageConfig = null;
  const page = pages.find((p) => p.id === _page) ?? null;

  if (page) {
    pageConfig = PageConfig.get(t, page.configKey) as PageConfigTranslated;
  }

  const displayReportUrl = (item: IInsightsReport) => (
    <Link href={sanitizeHtml(item.url)}>{item.report}</Link>
  );

  const tableHeaders: TableHeader[] = [
    {
      title: t('REPORT', { ns: 'common' }),
      node: 'report',
      is_sortable: false,
      column_width: 40,
      display_func: displayReportUrl,
    },
    {
      title: t('DESCRIPTION', { ns: 'common' }),
      node: 'description',
      is_text: true,
      is_sortable: false,
      column_width: 60,
    },
  ];

  const tableData: IInsightsReport[] = [
    {
      report: t('WORKFLOW_USAGE_REPORT.TITLE'),
      url: '/v2/insights/workflow-data',
      description: t('WORKFLOW_USAGE_REPORT.DESCRIPTION'),
    },
    {
      report: t('PROJECT_CUSTOM_TOOL_REPORT.TITLE'),
      url: '/v2/insights/project-custom-tool',
      description: t('PROJECT_CUSTOM_TOOL_REPORT.DESCRIPTION'),
    },
    {
      report: t('COMPANIES_INVALID_SALESFORCE_ID.TITLE'),
      url: '/v2/insights/companies-with-invalid-salesforce-account-ids',
      description: t('COMPANIES_INVALID_SALESFORCE_ID.DESCRIPTION'),
    },
    {
      report: t('CUSTOM_FIELDS_REPORT.TITLE'),
      url: '/v2/insights/custom-fields-report',
      description: t('CUSTOM_FIELDS_REPORT.DESCRIPTION'),
    },
    {
      report: t('COMPANY_CUSTOM_TOOL_REPORT.TITLE'),
      url: '/v2/insights/company-custom-tool',
      description: t('COMPANY_CUSTOM_TOOL_REPORT.DESCRIPTION'),
    },
  ];

  return (
    <Page>
      <Page.Main style={{ flex: 'auto' }}>
        <Page.Header>
          <ToolHeader>
            {page && pageConfig && (
              <Page.Breadcrumbs>
                <Breadcrumbs>
                  <Link href={`/v2/insights`}>
                    <Breadcrumbs.Crumb>
                      {t('INSIGHTS_REPORTS_TITLE')}
                    </Breadcrumbs.Crumb>
                  </Link>
                  <Breadcrumbs.Crumb active>
                    {pageConfig.label}
                  </Breadcrumbs.Crumb>
                </Breadcrumbs>
              </Page.Breadcrumbs>
            )}
            {!page && (
              <ToolHeader.Title data-qa={'insights-reports-title'}>
                {t('INSIGHTS_REPORTS_TITLE')}{' '}
              </ToolHeader.Title>
            )}
          </ToolHeader>
        </Page.Header>
        <Page.Body>
          {!page && (
            <Card>
              <Box padding="lg">
                <TableComponent
                  data={tableData}
                  headers={tableHeaders}
                  default_sort_column=""
                  default_sort_direction=""
                  hide_pagination={true}
                  fixed_table={true}
                />
              </Box>
            </Card>
          )}
          {page && (
            <InsightsContextProvider {...context}>
              <page.component />
            </InsightsContextProvider>
          )}
        </Page.Body>
      </Page.Main>
    </Page>
  );
};

export default InsightsReportsPage;
