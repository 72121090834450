import styled from 'styled-components';
import { spacing, colors } from '@procore/core-react';

export const ActionButtonsStyle = {
  position: 'relative',
  bottom: spacing.sm,
} as React.CSSProperties;

export const TooltipWrapper = styled.div`
  margin-left: ${spacing.xs}px;
  display: inline-block;
`;
