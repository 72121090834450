import React, { useEffect, useState } from 'react';
import { H2, H3, FlexList, Box } from '@procore/core-react';
import { ChevronDown } from '@procore/core-icons/dist';
import TableComponent from 'components/Table';
import { Caret } from 'components/AppHeader/styles';
import type { SubtypeType } from './FieldSet';
import type { ConfigurableFieldSetApiDto } from '@procore/ipa-nt-api-client-ts';
import type { PageConfigTranslated } from 'pages/page-config.interfaces';

type SubtypeProps = {
  subtype: SubtypeType;
  isOpen?: boolean;
  pageConfig: PageConfigTranslated;
};

export const Subtype = (props: SubtypeProps) => {
  const [isOpen, setIsOpen] = useState(props.isOpen);
  const pageConfig = props.pageConfig;

  const h2Style = {
    display: 'inline-block',
  };
  const subtypeStyle = {
    marginBottom: '20px',
  };

  const fieldsetTableHeaders = [
    {
      title: pageConfig.translate('NAME'),
      node: 'label',
      is_text: true,
      is_sortable: true,
    },
    {
      title: pageConfig.translate('VISIBLE'),
      node: 'visible',
      is_boolean: true,
      is_sortable: false,
    },
    {
      title: pageConfig.translate('REQUIRED'),
      node: 'required',
      is_boolean: true,
      is_sortable: false,
    },
  ];

  const displayFieldset = (fieldset: ConfigurableFieldSetApiDto[]) => {
    return fieldset.map((f) => {
      return (
        <div key={f.id} style={subtypeStyle}>
          <H3>{f.name}</H3>
          <TableComponent
            data_qa={`fieldset-${f.id}`}
            data={f.fields}
            headers={fieldsetTableHeaders}
            show_no_data_message={true}
            no_data_message={pageConfig.translate('NO_FIELDSETS')}
            show_page_size_select={true}
            page_size={10}
            default_sort_column="label"
            default_sort_direction="asc"
          />
        </div>
      );
    });
  };

  useEffect(() => {
    setIsOpen(props.isOpen || props.subtype.name === '');
  }, [props]);

  return (
    <Box marginTop="lg">
      {props.subtype.name !== '' && (
        <FlexList>
          <H2 style={h2Style}>{props.subtype.name}</H2>
          <Caret
            animate={isOpen}
            onClick={() => setIsOpen(!isOpen)}
            data-qa="expand-subtype"
          >
            <ChevronDown size="lg" />
          </Caret>
        </FlexList>
      )}
      <div style={isOpen ? { display: 'block' } : { display: 'none' }}>
        {props.subtype.fieldsets.map((fieldset) => {
          return displayFieldset(fieldset);
        })}
      </div>
    </Box>
  );
};

export default Subtype;
