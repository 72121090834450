import React, { useState, createContext, useContext } from 'react';
import { AxiosError } from 'axios';
import type { ToastVariant } from '@procore/core-react/dist/Toast/Toast.types';
import { AlertComponent } from 'components/Alert';
import { getErrorMessage } from 'utils/api';
import { I18nContext } from 'react-i18next';

export type AlertVariant = 'none' | ToastVariant;

export interface IpaAlertContextProps {
  alertType: AlertVariant;
  message: string;
  timeout: number;
  keepVisible?: boolean;
  success(message: string, keepVisible?: boolean): void;
  error(message: string | string[] | AxiosError | AxiosError[]): void;
  closeAlert(): void;
}

interface ContextProps {
  logErrors?: boolean;
  children: JSX.Element;
}

interface ContextConsumerProps {
  children: any;
}

export const IpaAlertContext = createContext({} as IpaAlertContextProps);

export const IpaAlertContextProvider = (props: ContextProps) => {
  const { i18n } = useContext(I18nContext);
  const [alertType, setAlertType] = useState<AlertVariant>('none');
  const [message, setMessage] = useState<string>('');
  const [keepVisible, setKeepVisible] = useState<boolean>(false);
  const timeout: number = 5; // 5 seconds

  return (
    <IpaAlertContext.Provider
      value={{
        alertType: alertType,
        message: message,
        timeout: timeout,
        keepVisible: keepVisible,
        success: (message: string, keepVisible?: boolean) => {
          setMessage(message);
          setAlertType('success');
          if (typeof keepVisible !== 'undefined') {
            setKeepVisible(keepVisible);
          } else {
            setKeepVisible(false);
          }
        },
        error: (error: string | string[] | AxiosError | AxiosError[]) => {
          const defaultError = i18n.t('THERE_WAS_ERROR', { ns: 'common' });
          let message;
          if (Array.isArray(error)) {
            const errorsArray: string[] = [];
            error.forEach((err) => {
              if (err instanceof AxiosError) {
                errorsArray.push(getErrorMessage(err) || defaultError);
              } else {
                errorsArray.push(err);
              }
            });
            message = errorsArray.join('<br />');
          } else {
            if (error instanceof AxiosError) {
              message = getErrorMessage(error) || defaultError;
            } else {
              message = error;
            }
          }
          props.logErrors && console.error('Alert (error):', message);
          setMessage(message);
          setAlertType('error');
        },
        closeAlert: () => {
          setAlertType('none');
        },
      }}
    >
      <>
        {props.children}
        <AlertComponent />
      </>
    </IpaAlertContext.Provider>
  );
};

export const IpaAlertContextConsumer = (props: ContextConsumerProps) => {
  return <IpaAlertContext.Consumer>{props.children}</IpaAlertContext.Consumer>;
};

export default IpaAlertContextProvider;
