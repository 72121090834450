import type { PageConfig } from 'pages/page-config.interfaces';

const pageConfig: PageConfig = {
  label: 'PROJECT_CONFIG_HISTORY',
  route: {
    page: 'project-config-history',
    v1_path: '/change-history/company/:company_id/project/:project_id',
  },
  endpoints: {
    view: ['projectControllerGetProjectCommitmentSettings'],
  },
  cards: {
    ProjectConfigurationChangeHistory: {
      label: 'PROJECT_CONFIG_HISTORY',
      fields: {},
    },
  },
};

export default pageConfig;
