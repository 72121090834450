import React, { useState, useEffect, useContext } from 'react';
import sanitizeHtml from 'sanitize-html';
import { Page, ToolHeader, Button, Card, Box } from '@procore/core-react';
import { Plus } from '@procore/core-icons';
import { TableComponent } from 'components/Table';
import { GlobalAnnouncementModal } from './GlobalAnnouncementModal';
import { GlobalAnnouncementsApi } from '@procore/ipa-nt-api-client-ts';
import type { GlobalAnnouncementDto } from '@procore/ipa-nt-api-client-ts';
import { getApi } from 'utils/api';
import type { TableHeader } from 'components/TableBackend/table.interface';
import { actions } from './GlobalAnnouncementHelpers';
import type { IGlobalAnnouncement } from './global.announcement.interface';
import { useTranslation } from 'react-i18next';
import { IpaAlertContext } from 'contexts/IpaAlertContext';

export const GlobalAnnouncementAdminPage = () => {
  const { t } = useTranslation('global-announcements');
  const { t: tCommon } = useTranslation('common');
  const alert = useContext(IpaAlertContext);
  const [announcements, setAnnouncements] = useState<GlobalAnnouncementDto[]>(
    [],
  );
  const [allowNewActiveAnnouncement, setAllowNewActiveAnnouncement] =
    useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedAnnouncementEntry, setSelectedAnnouncementEntry] =
    useState<IGlobalAnnouncement>({} as IGlobalAnnouncement);
  const [modalAction, setModalAction] = useState('');

  const actionCell = (item: GlobalAnnouncementDto) => {
    return (
      <Button size="sm" onClick={() => editEntry(item)}>
        {tCommon('EDIT')}
      </Button>
    );
  };

  const displayMessage = (item: GlobalAnnouncementDto) => {
    let message = item.message.replace(/(<([^>]+)>)/gi, '');
    message = message.length > 20 ? message.substring(0, 50) + '...' : message;
    return <>{message}</>;
  };

  const tableHeaders: TableHeader[] = [
    {
      title: tCommon('MESSAGE'),
      node: 'message',
      is_sortable: false,
      display_func: displayMessage,
      column_width: 25,
    },
    {
      title: t('POSTED_BY'),
      node: 'insertedBy',
      is_sortable: true,
      is_text: true,
      column_width: 20,
    },
    {
      title: t('CREATED_DATE'),
      node: 'insertedAt',
      is_sortable: true,
      is_datetime: true,
      column_width: 25,
    },
    {
      title: t('TYPE'),
      node: 'messageType',
      is_sortable: true,
      is_text: true,
      column_width: 10,
    },
    {
      title: t('ACTIVE'),
      node: 'isActive',
      is_sortable: true,
      is_boolean: true,
      column_width: 10,
    },
    { title: '', node: '', display_func: actionCell, column_width: 10 },
  ];

  const closeModal = () => {
    setShowModal(false);
  };

  const confirmModal = async (values: IGlobalAnnouncement) => {
    try {
      let result;
      let message = sanitizeHtml(values.message as string, {
        allowedTags: ['b', 'strong', 'i', 'em', 'u'],
        allowedAttributes: {},
      });
      if (modalAction === actions.CREATE) {
        result = await getApi(
          GlobalAnnouncementsApi,
        ).globalAnnouncementControllerCreateAnnouncement({
          message: message,
          isActive: values.isActive,
          messageType: values.messageType?.id as string,
        });
        alert.success(t('ANNOUNCEMENT_SUCCESS'));
      } else {
        result = await getApi(
          GlobalAnnouncementsApi,
        ).globalAnnouncementControllerUpdateAnnouncement(values.id, {
          message: message,
          isActive: values.isActive,
          messageType: values.messageType?.id as string,
        });
        alert.success(t('ANNOUNCEMENT_UPDATE'));
      }
      setAnnouncements(result.data);
      checkActiveAnnouncement(result.data);
    } catch (e: any) {
      alert.error(e);
    } finally {
      closeModal();
    }
  };

  const createEntry = () => {
    setModalAction(actions.CREATE);
    setSelectedAnnouncementEntry({
      id: null,
      message: '',
      messageType: null,
      insertedAt: new Date(),
      insertedBy: '',
      updatedBy: '',
      isActive: false,
    });
    setShowModal(true);
  };

  const editEntry = (entry: GlobalAnnouncementDto) => {
    setModalAction(actions.UPDATE);
    setSelectedAnnouncementEntry({
      id: entry.id,
      message: entry.message,
      messageType: {
        id: entry.messageType,
        label: getMessageLabel(entry.messageType),
      },
      insertedAt: new Date(entry.insertedAt as string),
      insertedBy: entry.insertedBy,
      updatedBy: entry.updatedBy,
      isActive: entry.isActive,
    });
    setShowModal(true);
  };

  // get message label from translation file
  const getMessageLabel = (message: string) => {
    if (message === 'Warning') {
      return tCommon('WARNING');
    }

    return tCommon('INFO');
  };

  const getAnnouncements = async () => {
    alert.closeAlert();
    try {
      const result = await getApi(
        GlobalAnnouncementsApi,
      ).globalAnnouncementControllerGetAnnouncements();
      setAnnouncements(result.data);
      checkActiveAnnouncement(result.data);
    } catch (e: any) {
      alert.error(e);
    }
  };

  const checkActiveAnnouncement = (data: GlobalAnnouncementDto[]) => {
    const activeExist = data.filter((item) => item.isActive).length > 0;
    setAllowNewActiveAnnouncement(!activeExist);
  };

  useEffect(() => {
    getAnnouncements();
  }, []);

  return (
    <Page>
      <Page.Main style={{ flex: 'auto' }}>
        <Page.Header>
          <ToolHeader>
            <ToolHeader.Title>{t('TITLE')}</ToolHeader.Title>
            <ToolHeader.Actions>
              <Box paddingBottom="md">
                <Button
                  icon={<Plus />}
                  data-qa="create-btn"
                  onClick={createEntry}
                />
              </Box>
            </ToolHeader.Actions>
          </ToolHeader>
        </Page.Header>
        <Page.Body>
          <Card>
            <Box padding="lg">
              <TableComponent
                headers={tableHeaders}
                data={announcements}
                page_size={20}
                default_sort_column="inserted_at"
                default_sort_direction="desc"
                show_no_data_message={true}
                fixed_table={true}
              />
            </Box>
          </Card>
        </Page.Body>
      </Page.Main>
      <GlobalAnnouncementModal
        show={showModal}
        closeModal={closeModal}
        confirmModal={confirmModal}
        entry={selectedAnnouncementEntry}
        action={modalAction}
        allowNewActiveAnnouncement={allowNewActiveAnnouncement}
      />
    </Page>
  );
};

export default GlobalAnnouncementAdminPage;
