import React, { useState, useCallback, useEffect, useContext } from 'react';
import { Button, Link, Tooltip } from '@procore/core-react';
import { useTranslation } from 'react-i18next';
import JitEscalateModal from 'components/JitEscalateModal';
import type { JitEscalateFormValues } from 'components/JitEscalateModal';
import { JitSessionList } from './JitSessionList';
import { ExternalLink, Help } from '@procore/core-icons';
import { getBaseUrl } from 'utils/utils';
import { getApi } from 'utils/api';
import { JITApi } from '@procore/ipa-nt-api-client-ts';
import type { JitCompanySettingsDto } from '@procore/ipa-nt-api-client-ts';
import { IpaAlertContext } from 'contexts/IpaAlertContext';
import { AnalyticEvents } from '../analytic-events.service';

interface JitEnvironmentProps {
  env: string;
  companyId?: string;
  accessLevel?: string;
  reason?: string;
  redirectUrl?: string;
  escalation_length?: string;
  pdmSupport?: string;
  setSuccessMessage: (message: string) => void;
  setErrorMessage: (message: string) => void;
}

export const JitEnvironment = (props: JitEnvironmentProps) => {
  const { t } = useTranslation(['jit']);
  const [loading, setLoading] = useState<boolean>(false);
  const alert = useContext(IpaAlertContext);
  const [latestEscalation, setLatestEscalation] = useState<Date>();
  const [escalateModalOpen, setEscalateModalOpen] = useState<boolean>(false);
  const [selectedCompany, setSelectedCompany] =
    useState<JitCompanySettingsDto>();

  const escalate = useCallback(async (values: JitEscalateFormValues) => {
    try {
      setLoading(true);

      const result = await getApi(JITApi).jitControllerCreateSession({
        env: props.env,
        companyId: Number(values.company_id),
        accessLevel: values.access_level!.id as any,
        category: values.category!.id,
        reason: values.detail,
        impersonateEmail: values.email,
        pin: values.pin,
        salesForceCaseNumber: values.salesforce_case_number,
        escalationLength: Number(values.escalation_length.id),
        projectId: Number(values.project_id),
        pdmSupport: values.pdm_support,
        redirectUrl: props.redirectUrl,
        escalationConfirmationCheck: values.sa_escalation_confirmation_check,
      });
      setLoading(false);

      if (result.data) {
        setLatestEscalation(new Date());
        props.setSuccessMessage(
          t('ESCALATE_SUCCESS', {
            accessLevel: result.data.accessLevel,
            companyName: result.data.companyName,
          }),
        );
        if (values.open_new_window && !props.redirectUrl) {
          if (values.project_id === '') {
            window.open(
              `${getBaseUrl(props.env, 'procore', +values.company_id)}/${
                values.company_id
              }/company/home/list`,
              '_blank',
            );
          } else {
            window.open(
              `${getBaseUrl(props.env, 'procore', +values.company_id)}/${
                values.project_id
              }/project/home`,
              '_blank',
            );
          }
        }
        //redirect if any url is provided
        if (props.redirectUrl) {
          window.open(
            `${getBaseUrl(props.env, 'procore', +values.company_id)}/${
              props.redirectUrl
            }`,
            '_blank',
          );
        }
      }
    } catch (error: any) {
      const msg = t('ESCALATE_FAILED', {
        companyId: values.company_id,
        reason: error.response?.data?.message || error.message,
      });
      props.setErrorMessage && props.setErrorMessage(msg);
      return null;
    } finally {
      setLoading(false);
      setEscalateModalOpen(false);
      await AnalyticEvents.postProcoreEvent({
        key: 'ipa.ipa_jit.escalate.escalated',
        eventProps: {
          user_company_id: values.company_id,
          env: props.env,
        },
        env: props.env,
      });
    }
  }, []);

  const openEscalateModal = useCallback(() => {
    setEscalateModalOpen(true);
  }, []);

  const closeEscalateModal = useCallback(() => {
    setLatestEscalation(new Date());
    setEscalateModalOpen(false);
  }, []);

  const closeEscalateModalOnEsc = useCallback((event) => {
    if (event.key === 'Escape') {
      closeEscalateModal();
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', closeEscalateModalOnEsc, false);
    return () => {
      document.removeEventListener('keydown', closeEscalateModalOnEsc, false);
    };
  }, [closeEscalateModalOnEsc]);

  useEffect(() => {
    if (
      props.env &&
      props.companyId &&
      props.accessLevel &&
      props.redirectUrl
    ) {
      const api = getApi(JITApi);
      api
        .jitControllerGetCompanySettings(+props.companyId, props.env)
        .then((response) => {
          setSelectedCompany(response.data);
          setEscalateModalOpen(true);
        })
        .catch((error) => {
          setSelectedCompany(undefined);
          alert.error(t('COMPANY_NOT_FOUND', { ns: 'jit' }));
        });
    } else {
      setSelectedCompany(undefined);
    }
  }, []);

  const envDescriptionKey = `ENV_DESCRIPTION_${props.env}`;
  const envDescription =
    t(envDescriptionKey) !== envDescriptionKey
      ? t(envDescriptionKey)
      : undefined;

  return (
    <div>
      <h4>
        <Link
          href={`${getBaseUrl(props.env, 'login')}/staff`}
          target={'_blank'}
        >
          {props.env} <ExternalLink size="sm" />
        </Link>
        &nbsp;&nbsp;
        {envDescription && (
          <Tooltip overlay={envDescription} trigger="hover">
            <Help size="sm" />
          </Tooltip>
        )}
        &nbsp;&nbsp;
        <Button
          size="sm"
          onClick={openEscalateModal}
          data-qa={`${props.env}-escalate-btn`}
        >
          {t('ESCALATE')}
        </Button>
      </h4>
      <JitSessionList
        env={props.env}
        latestEscalation={latestEscalation}
        setSuccessMessage={props.setSuccessMessage}
        setErrorMessage={props.setErrorMessage}
      />
      <JitEscalateModal
        env={props.env}
        isOpen={escalateModalOpen}
        closeModal={closeEscalateModal}
        re_escalate={false}
        customer_success={false}
        confirmModal={escalate}
        canSelectEscalationLength={true}
        selected_company={selectedCompany}
        selected_access_level={
          props.accessLevel
            ? {
                id: props.accessLevel,
                label: props.accessLevel,
                disabled: false,
              }
            : undefined
        }
        selected_reason={props.reason}
        escalation_length={props.escalation_length}
        pdmSupport={props.pdmSupport === 'true'}
        // Showing the strategic account checkbox if redirectUrl is provided (eg Polaris Enhanced Reporting)
        isStrategicAccount={props.redirectUrl !== undefined}
      />
    </div>
  );
};
