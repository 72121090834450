import React from 'react';
import { useNavigate } from 'react-router-dom';
import AppContainer from './components/AppContainer';

import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import { IpaRoutes } from './routes';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';

const getOktaAuth = (): OktaAuth | undefined => {
  if (window.hasOwnProperty('config')) {
    const config = (window as any).config;
    if (config.auth.mode === 'jwt' && config.auth.jwt) {
      const jwtConfig = config.auth.jwt;
      return new OktaAuth({
        clientId: jwtConfig.client_id,
        issuer: jwtConfig.issuer,
        redirectUri: `${window.location.origin}/oauth2/callback`,
        scopes: jwtConfig.scopes,
        pkce: true,
        tokenManager: {
          autoRenew: true,
        },
        services: {
          autoRenew: true,
          autoRemove: true,
        },
      });
    } else if (config.auth.mode === 'elixir') {
      return new OktaAuth({
        clientId: 'elixir',
        issuer: 'https://procore.okta.com',
        transformAuthState: async (oktaAuth: unknown, authState: any) => {
          authState.isAuthenticated = true;
          authState.user = {};
          return authState;
        },
      });
    } else {
      console.log(`Unknown auth mode: ${config.auth.mode}`);
    }
  } else {
    console.log('Missing app configuration (window.config)');
  }
};

const Main = () => {
  const navigate = useNavigate();
  const restoreOriginalUri = (_oktaAuth: any, originalUri: string) => {
    navigate(toRelativeUrl(originalUri ?? '/', window.location.origin));
  };

  const oktaAuth = getOktaAuth();
  if (oktaAuth) {
    return (
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
        <I18nextProvider i18n={i18n}>
          <AppContainer>
            <IpaRoutes />
          </AppContainer>
        </I18nextProvider>
      </Security>
    );
  } else {
    return <div>Error: Missing auth configuration</div>;
  }
};

export default Main;
