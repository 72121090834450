import React, { useContext } from 'react';
import { Form } from '@procore/core-react';
import { EditableCard } from 'components/EditableCard';
import type { IEditableCard } from 'components/EditableCard';
import type { ProjectAdvancedSettingsProps } from '../interfaces';
import { ProjectInfoContext } from '..';
import { ProjectContext } from 'contexts/ProjectContext';

export const ProjectDrawingLogSettings = (
  props: ProjectAdvancedSettingsProps,
) => {
  const context = useContext(ProjectContext);
  const config = props.cardConfig;

  const {
    dataSources: { drawingLogSettings },
  } = useContext(ProjectInfoContext);

  const data = drawingLogSettings?.data;

  const card: IEditableCard = {
    id: props.id,
    i18n: config.translate,
    label: config.label,
    canEdit: context.hasPermission(config.permissions.edit),
    save: props.save,
    fields: [
      {
        source: drawingLogSettings.id,
        value: data?.enable_rfi_real_time_as_builts,
        name: 'enable_rfi_real_time_as_builts',
        dataType: 'boolean',
        element: Form.Checkbox,
        ...config.fields.enable_rfi_real_time_as_builts,
      },
    ],
  };

  return <EditableCard card={card} />;
};
