import React, { useState, useEffect } from 'react';
import * as yup from 'yup';
import type { FormikProps } from 'formik';
import { useTranslation, Trans } from 'react-i18next';
import {
  Modal,
  Form,
  Button,
  Box,
  Grid,
  ZIndexProvider,
  Typography,
} from '@procore/core-react';
import { SU_REASON_CATEGORIES } from 'common/constants';
import { ipaAppStyles } from 'styles';
import type { PageConfigTranslated } from 'pages/page-config.interfaces';

type SelectOption = {
  id: number | string;
  label: string;
};

type ReasonCategory = {
  id: string;
  name: string;
  salesforceCaseNumberRequired: boolean;
};

export interface SUPromoteFormValues {
  env: string;
  category: ReasonCategory | null;
  reasonDetails: string;
  hoursRequested: SelectOption;
  salesforceCaseNumber?: string;
}

interface SUPromoteModalProps {
  config: PageConfigTranslated;
  env: string | undefined;
  email: string | undefined;
  show: boolean;

  closeModal(): void;

  confirmModal(values: SUPromoteFormValues): void;
}

export const SuperUserPromoteModal = (props: SUPromoteModalProps) => {
  const { t } = useTranslation(['super-user']);
  const { config, confirmModal } = props;
  const [suCategory, setSuCategory] = useState<any[]>([]);
  const [env, setEnv] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const [detailLength, setDetailLength] = useState<number>(0);
  const [showModal, setShowModal] = useState<boolean>(false);

  let requestedHoursOptions: SelectOption[] = [];
  for (let i = 1; i <= 10; i++) {
    requestedHoursOptions.push({
      id: i,
      label: i.toString(),
    });
  }

  const closeModal = () => {
    props.closeModal();
  };

  useEffect(() => {
    setShowModal(props.show);
    setSuCategory(
      SU_REASON_CATEGORIES.map((category) => ({
        id: category.id,
        label: t(category.key, { ns: 'common' }),
        salesforceCaseNumberRequired: category.salesforceCaseNumberRequired,
      })),
    );
    if (props.env) {
      setEnv(props.env);
    }

    return () => {
      setEnv('');
      setLoading(false);
    };
  }, [props]);

  return (
    <ZIndexProvider>
      <Modal
        open={showModal}
        onClickOverlay={closeModal}
        width="md"
        data-qa="su-promote-modal"
      >
        <Modal.Header onClose={closeModal}>
          {config.translate('PROMOTE_MODAL_TITLE')}
        </Modal.Header>

        <Form
          view="create"
          initialValues={{
            env: env,
            category: null,
            hoursRequested: requestedHoursOptions[0],
            reasonDetails: '',
          }}
          validationSchema={yup.object().shape({
            hoursRequested: yup
              .object()
              .required(config.translate('REQUIRED', { ns: 'common' })),
            reasonDetails: yup
              .string()
              .required(config.translate('REQUIRED', { ns: 'common' }))
              .max(500, config.translate('CHARACTER_LIMIT')),
            category: yup
              .object()
              .shape({ id: yup.string(), label: yup.string() })
              .nullable()
              .required(config.translate('REQUIRED', { ns: 'common' })),
            salesforceCaseNumber: yup.string().when('category', {
              is: (category: ReasonCategory) => {
                return category.salesforceCaseNumberRequired;
              },
              then: yup
                .string()
                .required(config.translate('SALESFORCE_CASE_NUMBER_REQUIRED')),
            }),
          })}
          onSubmit={(values: SUPromoteFormValues) => {
            if (typeof confirmModal === 'function') {
              confirmModal({ ...values, env: env });
              setLoading(true);
            }
          }}
        >
          {(form: FormikProps<SUPromoteFormValues>) => (
            <Form.Form style={ipaAppStyles.ipaModalForm}>
              <Modal.Body data-qa="su-modal-body" className="ipa-modal-body">
                {config.translate('PROMOTE_SUBTITLE', {
                  email: props.email,
                  env: props.env,
                })}
                <Form.Row>
                  <Form.Select
                    onSearch={false}
                    name="hoursRequested"
                    id="hoursRequested"
                    label={config.translate('LABEL_HOURS_REQUESTED')}
                    options={requestedHoursOptions}
                    data-qa="hours-requested-select"
                  />
                </Form.Row>
                <Form.Row>
                  <Form.Select
                    data-qa="su-category-select"
                    onSearch={false}
                    name="category"
                    id="category"
                    label={config.translate('LABEL_CATEGORY')}
                    options={suCategory}
                    placeholder={config.translate('PLACEHOLDER_CATEGORY')}
                  />
                </Form.Row>
                {form.values?.category?.salesforceCaseNumberRequired && (
                  <Form.Row>
                    <Form.Field
                      data-qa="salesforce-case-number"
                      name="salesforceCaseNumber"
                      label={config.translate('LABEL_SALESFORCE_CASE_NUMBER')}
                      id="salesforceCaseNumber"
                    />
                  </Form.Row>
                )}
                <Grid.Row>
                  <Grid.Col>
                    <Typography>
                      <Trans>{config.translate('WARNING_TEXT')}</Trans>
                    </Typography>
                    <Typography>
                      <Trans>{config.translate('NEED_HELP')}</Trans>
                    </Typography>
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Col>
                    <Form.TextArea
                      data-qa="reason-details"
                      name="reasonDetails"
                      label={config.translate('LABEL_DETAIL')}
                      id="reasonDetails"
                      onChange={(e) => setDetailLength(e.target.value.length)}
                    />
                    <Box textAlign="right">
                      {config.translate('CHARACTER_COUNT', {
                        count: detailLength,
                        limit: 500,
                      })}
                    </Box>
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Col colWidth={{ tabletSm: 12 }}></Grid.Col>
                </Grid.Row>
              </Modal.Body>

              <Modal.Footer>
                <Modal.FooterButtons>
                  <Button
                    variant="secondary"
                    onClick={closeModal}
                    data-qa="cancel-btn"
                  >
                    {config.translate('CANCEL', { ns: 'common' })}
                  </Button>
                  <Button type="submit" loading={loading} data-qa="promote-btn">
                    {config.translate('PROMOTE')}
                  </Button>
                </Modal.FooterButtons>
              </Modal.Footer>
            </Form.Form>
          )}
        </Form>
      </Modal>
    </ZIndexProvider>
  );
};

export default SuperUserPromoteModal;
