import React, { useEffect, useContext } from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import type { BugsnagErrorBoundary } from '@bugsnag/plugin-react';
import { AppContext } from 'contexts/AppContext';
import { MessageBanner } from 'components/MessageBanner';

interface AppBugsnagProps {
  children?: React.ReactNode;
}

export const AppBugsnag = (props: AppBugsnagProps) => {
  const { userConfig } = useContext(AppContext);
  let ErrorBoundary: BugsnagErrorBoundary | undefined;
  const ErrorView = () => (
    <MessageBanner
      banner_message={`There's an error`}
      banner_type="error"
      banner_sticky={false}
      show_title={false}
    />
  );

  useEffect(() => {
    if (userConfig.bugsnag?.api_key && !Bugsnag.isStarted()) {
      Bugsnag.start({
        apiKey: userConfig.bugsnag.api_key,
        releaseStage: userConfig.bugsnag.release_stage,
        user: userConfig.user
          ? {
              id: userConfig.user?.username,
              name: userConfig.user?.name,
            }
          : undefined,
        plugins: [new BugsnagPluginReact()],
        onError: errorHandler,
      });

      ErrorBoundary = Bugsnag.getPlugin('react')?.createErrorBoundary(React);
    }
  }, [userConfig]);

  return ErrorBoundary ? (
    <ErrorBoundary FallbackComponent={ErrorView}>
      {props.children}
    </ErrorBoundary>
  ) : (
    <React.Fragment>{props.children}</React.Fragment>
  );
};

export const errorHandler = (event: any) => {
  const errorClass = event.errors[0].errorClass || undefined;
  const handledErrorClasses = ['ChunkLoadError'];
  if (handledErrorClasses.includes(errorClass)) {
    console.log(`Marking unhandled error ${errorClass} as handled`);
    event.unhandled = false;
  }
};

export default AppBugsnag;
