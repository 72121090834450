import type { PageConfig } from 'pages/page-config.interfaces';

const pageConfig: PageConfig = {
  label: 'RFI_SETTINGS',
  route: {
    page: 'rfi-settings',
    v1_path: '/rfi-settings/company/:company_id/project/:project_id',
  },
  endpoints: {
    view: [
      'projectControllerGetProjectRfiSettings',
      'projectControllerGetProjectRfis',
    ],
  },
  cards: {
    RfiSettings: {
      i18n: {
        keyPrefix: 'PROJECT_RFI_SETTINGS',
      },
      label: 'TITLE',
      fields: {
        default_rfi_manager_name: {
          label: 'RFI_MANAGER',
        },
        rfi_due_days_default: {
          label: 'ANSWERS_NEW_RFI_QUESTIONS_DUE',
        },
        rfi_only_show_official_responses: {
          label: 'ENABLE_EMAIL_REMINDERS',
        },
        rfi_overdue_emails_enabled: {
          label: 'ONLY_SHOW_OFFICIAL_RESPONSES',
        },
      },
    },
    ProjectRfis: {
      i18n: {
        keyPrefix: 'PROJECT_RFI_SETTINGS.PROJECT_RFIS',
      },
      label: 'TABLE_TITLE',
      fields: {
        subject: {
          label: 'SUBJECT',
        },
        status: {
          label: 'STATUS',
        },
        responsible_contractor: {
          label: 'RESPONSIBLE_CONTRACTOR',
        },
        received_from: {
          label: 'RECEIVED_FROM',
        },
        date_initiated: {
          label: 'DATE_INITIATED',
        },
        rfi_manager: {
          label: 'RFI_MANAGER',
        },
        assignees: {
          label: 'ASSIGNEES',
        },
        ball_in_court: {
          label: 'BALL_IN_COURT',
        },
      },
    },
    RfiEmails: {
      i18n: {
        keyPrefix: 'PROJECT_RFI_SETTINGS.RFI_EMAILS',
      },
      label: 'TABLE_TITLE',
      fields: {
        email_event: {
          label: 'EMAIL_EVENT',
        },
        enable_email: {
          label: 'ENABLE_EMAIL',
        },
        creator: {
          label: 'CREATOR',
        },
        manager: {
          label: 'MANAGER',
        },
        assignee: {
          label: 'ASSIGNEE',
        },
        distribution_group: {
          label: 'DISTRIBUTION_GROUP',
        },
      },
    },
  },
};

export default pageConfig;
