import React, { memo } from 'react';
import type { FC } from 'react';
import { useDrag } from 'react-dnd';
import type { IWidgetSelection } from './interfaces';
import { DraggableCard, DraggableModuleLabel } from './styles';

export const DraggableModule: FC<IWidgetSelection> = memo(
  function DraggableModule(props) {
    const [{ opacity }, drag] = useDrag(
      () => ({
        type: 'module',
        item: { ...props },
        collect: (monitor) => ({
          opacity: monitor.isDragging() ? 0.4 : 1,
        }),
      }),
      [props],
    );
    const { isUsed, description } = props;

    return (
      <DraggableCard
        ref={!isUsed ? drag : null}
        style={{ opacity }}
        className={isUsed ? 'used' : ''}
      >
        <props.icon size="lg" />
        <br />
        <DraggableModuleLabel intent="small">
          {description}
        </DraggableModuleLabel>
      </DraggableCard>
    );
  },
);

export default DraggableModule;
