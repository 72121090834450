import type { PageConfig } from 'pages/page-config.interfaces';

export default <PageConfig>{
  label: 'BUSINESS_ENTITY.CHANGE_HISTORY',
  endpoints: {
    view: [],
    edit: [],
  },
  cards: {
    PaymentsServiceChangeHistory: {
      i18n: {
        keyPrefix:
          'BUSINESS_ENTITY.CHANGE_HISTORY_TAB.PAYMENTS_SERVICE_CHANGE_HISTORY_CARD',
      },
      label: 'TITLE',
      fields: {},
    },
    IpaPaymentsChangeHistory: {
      i18n: {
        keyPrefix:
          'BUSINESS_ENTITY.CHANGE_HISTORY_TAB.IPA_PAYMENTS_CHANGE_HISTORY_CARD',
      },
      label: 'TITLE',
      fields: {},
    },
  },
};
