import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card,
  Flex,
  Spinner,
  Box,
  EmptyState,
  Grid,
  colors,
  Form,
} from '@procore/core-react';
import styled from 'styled-components';
import { getApi } from 'utils/api';
import { ProjectsApi } from '@procore/ipa-nt-api-client-ts';
import type {
  ProjectLocationDto,
  ProjectLocationsConfigurationDto,
} from '@procore/ipa-nt-api-client-ts';
import { IpaAlertContext } from 'contexts/IpaAlertContext';
import { ProjectContext } from 'contexts/ProjectContext';
import { StyledGridColumn } from 'styles';
import { AxiosError } from 'axios';
import { EditableCard } from 'components/EditableCard';
import type { IEditableCard } from 'components/EditableCard';

const LocationsPage = () => {
  const alert = useContext(IpaAlertContext);
  const context = useContext(ProjectContext);
  const config = context.pageConfig.card('LocationsConfiguration');
  const t = config.translate;

  const { t: tCommon } = useTranslation(['common']);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isInit, setIsInit] = useState<boolean>(true);
  const [locationTiers, setLocationTiers] = useState<number>(1);
  const [projectLocations, setProjectLocations] = useState<ProjectLocation[]>(
    [],
  );
  const [projectLocationsConfiguration, setProjectLocationsConfiguration] =
    useState<ProjectLocationsConfigurationDto>();

  interface ProjectLocation extends ProjectLocationDto {
    children?: ProjectLocation[];
  }

  const card: IEditableCard = {
    id: 'locations-configuration',
    i18n: t,
    label: '',
    canEdit: false,
    save: () => {},
    fields: [
      {
        source: 'locations-configuration',
        value: projectLocationsConfiguration?.disable_dynamic_location_creation,
        name: 'disable_dynamic_location_creation',
        element: Form.Checkbox,
        ...config.fields.disable_dynamic_location_creation,
      },
      {
        source: 'locations-configuration',
        value: projectLocationsConfiguration?.include_location_code,
        name: 'include_location_code',
        element: Form.Checkbox,
        ...config.fields.include_location_code,
      },
    ],
  };

  const RowWrapper = styled.div`
    border-bottom: 1px solid ${colors.gray85};
    padding: 10px 0;
  `;

  const RowWrapperInner = styled(RowWrapper)`
    &:last-child {
      border: none;
    }
  `;

  function getLocationString(location: ProjectLocation) {
    return `${
      projectLocationsConfiguration?.include_location_code && location.code
        ? `(${location.code})`
        : ''
    } ${location.node_name}`;
  }

  const GridHeader = () => {
    let cols = [];
    for (let i = 1; i <= locationTiers; i++) {
      cols.push(
        <StyledGridColumn colWidth={4} key={i} data-qa="locations-tier">
          <strong>{tCommon('TIER', { number: i })}</strong>
        </StyledGridColumn>,
      );
    }
    return <Grid.Row>{cols}</Grid.Row>;
  };

  const getProjectLocationsConfiguration = async () => {
    setProjectLocationsConfiguration(undefined);
    try {
      const api = getApi(ProjectsApi);
      const res = await api.projectControllerGetProjectLocationsConfiguration(
        context.env,
        context.companyId,
        context.projectId,
      );
      if (res.data) {
        setProjectLocationsConfiguration(res.data);
      }
    } catch (e: any) {
      return e;
    }
  };

  const getProjectLocations = async () => {
    try {
      const api = getApi(ProjectsApi);
      const result = await api.projectControllerGetProjectLocations(
        context.env,
        context.companyId,
        context.projectId,
      );

      let levels = 0;
      let temp: ProjectLocation[] = result.data.map(
        (location: ProjectLocationDto) => {
          const len = location.name.match(/[>]/g)
            ? location.name.match(/[>]/g)!.length
            : 0;
          if (len > levels) {
            levels = len;
          }
          return { ...location, children: [] };
        },
      );
      temp = temp
        .map((location: ProjectLocation) => {
          temp.map((l) => {
            if (l.parent_id === location.id) {
              location.children!.push(l);
            }
          });
          return { ...location };
        })
        .filter((location) => !location.parent_id);

      setLocationTiers(levels + 1);
      setProjectLocations(temp);
    } catch (e: any) {
      return e;
    }
  };

  const initPage = useCallback(async () => {
    setIsLoading(true);
    setIsInit(false);

    const errors: AxiosError[] = [];
    const promises = await Promise.all([
      getProjectLocations(),
      getProjectLocationsConfiguration(),
    ]);

    promises.forEach((promise) => {
      if (promise instanceof AxiosError) {
        errors.push(promise);
      }
    });
    if (errors.length > 0) {
      alert.error(errors);
    }
    setIsLoading(false);
    setIsInit(true);
  }, []);

  useEffect(() => {
    initPage();
  }, [context.projectId, context.companyId, context.env]);

  return (
    <React.Fragment>
      <Box marginBottom="lg">
        <Card>
          <Box padding="lg">
            {(!isInit || isLoading) && (
              <Box marginBottom="lg">
                <Card>
                  <Box padding="lg">
                    {!isInit && !isLoading && (
                      <EmptyState>
                        <EmptyState.NoItems />
                        <EmptyState.Title>
                          {tCommon('NO_DATA')}
                        </EmptyState.Title>
                      </EmptyState>
                    )}
                    {isLoading && (
                      <Flex justifyContent="center">
                        <Spinner loading={isLoading} />
                      </Flex>
                    )}
                  </Box>
                </Card>
              </Box>
            )}
            {isInit && !isLoading && projectLocations.length > 0 && (
              <Grid gutterX="sm">
                <GridHeader />
                {projectLocations.map((location, i) => (
                  <RowWrapper key={i}>
                    <Grid.Row>
                      <StyledGridColumn colWidth={{ tabletSm: 4 }}>
                        {getLocationString(location)}
                      </StyledGridColumn>
                      {location.children!.length > 0 && (
                        <StyledGridColumn colWidth={{ tabletSm: 8 }}>
                          {location.children!.map((tier2, j) => (
                            <RowWrapperInner key={j}>
                              <Grid.Row>
                                <StyledGridColumn colWidth={{ tabletSm: 6 }}>
                                  {getLocationString(tier2)}
                                </StyledGridColumn>
                                {tier2.children!.length > 0 && (
                                  <StyledGridColumn colWidth={{ tabletSm: 6 }}>
                                    {tier2.children!.map((tier3, k) => (
                                      <Grid.Row key={k}>
                                        <StyledGridColumn
                                          colWidth={{ tabletSm: 6 }}
                                        >
                                          {getLocationString(tier3)}
                                        </StyledGridColumn>
                                      </Grid.Row>
                                    ))}
                                  </StyledGridColumn>
                                )}
                              </Grid.Row>
                            </RowWrapperInner>
                          ))}
                        </StyledGridColumn>
                      )}
                    </Grid.Row>
                  </RowWrapper>
                ))}
              </Grid>
            )}
            {!isLoading && !projectLocations.length && (
              <p>{t('NO_LOCATIONS')}</p>
            )}
          </Box>
        </Card>
      </Box>
      <Card>
        <Box padding="lg">
          <EditableCard card={card} />
        </Box>
      </Card>
    </React.Fragment>
  );
};
export default LocationsPage;
