// ***** PARAMETERS *****
// show: boolean (displays the modal if set to true)
// modalTitle: string
// modalContent: JSX
// modalWidth: ModalWidth (optional modal width)
// id: string (optional modal id)
// closeModal: function (closes the modal)
// icon: string (optional icon url)
//

import React, { useState, useEffect } from 'react';
import { Modal, Button } from '@procore/core-react';
import { Info } from '@procore/core-icons';
import type { ModalWidth } from '@procore/core-react/dist/Modal/Modal.types.js';
import { styles } from './styles';
import { useTranslation } from 'react-i18next';
import { IpaModalWrapper } from 'styles';

interface DataDisplayModalComponentProps {
  show: boolean | ((prevState: boolean) => boolean);
  modalTitle: string;
  modalContent: JSX.Element;
  modalWidth?: ModalWidth;
  id?: string;
  closeModal(): void;
  icon?: string;
}

export const DataDisplayModalComponent = (
  props: DataDisplayModalComponentProps,
) => {
  const { t } = useTranslation('common');
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalContent, setModalContent] = useState((<></>) as JSX.Element);
  const [modalId, setModalId] = useState('details-modal');

  useEffect(() => {
    setShowModal(props.show);
    setModalTitle(props.modalTitle);
    setModalContent(props.modalContent);
    if (props.id) {
      setModalId(props.id);
    }
  }, [props.id, props.modalContent, props.modalTitle, props.show]);

  return (
    <Modal
      open={showModal}
      id={modalId}
      data-qa={modalId}
      width={props.modalWidth ?? 'md'}
    >
      <IpaModalWrapper>
        <Modal.Header onClose={props.closeModal}>
          {!props.icon ? (
            <Info size="md" style={styles.icon} />
          ) : (
            <img alt="" src={props.icon} width="40" style={styles.icon} />
          )}
          <span data-qa="modal-title">{modalTitle}</span>
        </Modal.Header>
        <Modal.Body>{modalContent}</Modal.Body>
        <Modal.Footer>
          <Modal.FooterButtons>
            <Button
              variant="primary"
              onClick={props.closeModal}
              data-qa="ok-button"
            >
              {t('OK')}
            </Button>
          </Modal.FooterButtons>
        </Modal.Footer>
      </IpaModalWrapper>
    </Modal>
  );
};

export default DataDisplayModalComponent;
