// ***** PARAMETERS *****
// banner_message: text that shows up in the banner (string)
// banner_type: 'success' or 'error'
// banner_sticky: determines whether the banner will be fixed below the top nav (true or false)
// show_title: boolean (true by default)
// closeBanner: function that closes the banner (the close button won't show up if this function is not provided)
// is_global_announcement: replaces closeBanner method with own method to store dismissed announcements in LocalStorage (true or false)
// announcement_id: number (id of a global announcement if is_global_announcement == true)
// banner_title: optional title
//

import React, { useEffect, useState, useRef } from 'react';
import {
  ErrorBanner,
  InfoBanner,
  AttentionBanner,
  Banner,
} from '@procore/core-react';
import type { BannerVariant } from '@procore/core-react/dist/Banner/Banner.types';

interface MessageBannerProps {
  banner_message: string;
  closeBanner?: () => void;
  is_global_announcement?: boolean;
  announcement_id?: number;
  banner_type?: BannerVariant;
  banner_sticky?: boolean;
  show_title?: boolean;
  banner_title?: string;
}

export const MessageBanner = (props: MessageBannerProps) => {
  const [bannerMessage, setBannerMessage] = useState('');
  const [bannerType, setBannerType] = useState<BannerVariant>('info');
  const [showTitle, setShowTitle] = useState(true);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isSticky, setIsSticky] = useState(true);
  const [navHeight, setNavHeight] = useState(0);
  const [snapPosition, setSnapPosition] = useState(0);
  const [dismissedBanners, setDismissedBanners] = useState(
    JSON.parse(localStorage.getItem('ipa-dismissed-announcements') ?? '[]') ||
      [],
  );
  const bannerRef = useRef<HTMLDivElement>(null);

  const closeBanner = () => {
    if (props.closeBanner) {
      props.closeBanner();
    } else if (props.is_global_announcement && props.announcement_id) {
      setDismissedBanners([...dismissedBanners, props.announcement_id]);
      localStorage.setItem(
        'ipa-dismissed-announcements',
        JSON.stringify([...dismissedBanners, props.announcement_id]),
      );
    }
  };

  const onScroll = () => {
    setScrollPosition(window.scrollY);
  };

  const isFixedPos = () => {
    return scrollPosition > snapPosition && isSticky ? true : false;
  };

  const DismissButton = () => {
    return props.closeBanner || props.is_global_announcement ? (
      <Banner.Dismiss onClick={() => closeBanner()} />
    ) : (
      <span></span>
    );
  };

  useEffect(() => {
    if (props.banner_message) {
      setBannerMessage(props.banner_message);
    }
    if (props.banner_type) {
      setBannerType(props.banner_type);
    }
    if (typeof props.banner_sticky !== 'undefined') {
      setIsSticky(props.banner_sticky);
    }
    if (typeof props.show_title !== 'undefined') {
      setShowTitle(props.show_title);
    }
    setScrollPosition(window.scrollY);
  }, [props]);

  useEffect(() => {
    const header = document.getElementById('header');
    if (header !== null && bannerRef.current) {
      setNavHeight(header.offsetHeight);
      setSnapPosition(
        bannerRef.current.getBoundingClientRect().top - header.offsetHeight,
      );
    }

    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  const BannerContent = () => {
    return (
      <Banner.Content>
        {showTitle && (
          <Banner.Title>{props.banner_title || bannerType}</Banner.Title>
        )}
        <Banner.Body data-qa="banner-status-text">{bannerMessage}</Banner.Body>
      </Banner.Content>
    );
  };

  return (
    <div
      style={{ top: navHeight }}
      className={`${isFixedPos() ? 'ipa-message-banner-fixed' : ''}`}
      ref={bannerRef}
    >
      {(!props.is_global_announcement ||
        (props.is_global_announcement &&
          dismissedBanners.indexOf(props.announcement_id) === -1)) && (
        <div
          className={`${isFixedPos() ? '' : 'ipa-message-banner-container'}`}
          data-qa="global-announcement"
        >
          {bannerType === 'info' && (
            <InfoBanner>
              <BannerContent />
              <DismissButton />
            </InfoBanner>
          )}
          {bannerType === 'error' && (
            <ErrorBanner>
              <BannerContent />
              <DismissButton />
            </ErrorBanner>
          )}
          {bannerType === 'attention' && (
            <AttentionBanner>
              <BannerContent />
              <DismissButton />
            </AttentionBanner>
          )}
        </div>
      )}
    </div>
  );
};

export default MessageBanner;
