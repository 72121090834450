import React, { useState } from 'react';
import { Button, FlexList, Form, spacing } from '@procore/core-react';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { FormWrapper } from 'styles';
import { ENVIRONMENT_OPTIONS, DAY_OPTIONS } from 'common/constants';
import type { ISelectOption } from 'interfaces/common.interface';
import type { ISearchForm } from './su.sync.interface';
import EnvironmentSwitcher from 'components/EnvironmentSwitcher';

export interface SuSyncReportProps {
  getReport: (env: string) => void;
}
export const SuSyncReportForm = (props: SuSyncReportProps) => {
  const { t } = useTranslation(['su-sync']);
  const [lastSearchDays, setLastSearchDays] = useState<ISelectOption | null>(
    null,
  );
  const [env, setEnv] = useState<string>('PROD');

  const getReport = () => {
    props.getReport(env);
  };

  const isSearchDisabled = (dirty: boolean, values: ISearchForm) => {
    return values.days === null || values.days === lastSearchDays;
  };

  const changeEnvironment = (newEnv: string) => {
    setEnv(newEnv);
  };

  return (
    <FormWrapper>
      <Form
        view="create"
        enableReinitialize={true}
        initialValues={{
          env: ENVIRONMENT_OPTIONS[0],
          days: DAY_OPTIONS[0],
        }}
        validationSchema={yup.object().shape({
          days: yup.object().required('Required'),
        })}
        onSubmit={(values) => getReport()}
      >
        {(props: any) => {
          const { dirty, values } = props;
          return (
            <Form.Form>
              <Form.Row>
                <FlexList>
                  <EnvironmentSwitcher
                    initialValue={env}
                    onChange={changeEnvironment}
                  />
                  <Button
                    type="submit"
                    variant="primary"
                    data-qa="syn-report-btn"
                    disabled={isSearchDisabled(dirty, values)}
                    style={{ marginLeft: spacing.xl }}
                  >
                    {t('GET_REPORT')}
                  </Button>
                </FlexList>
              </Form.Row>
            </Form.Form>
          );
        }}
      </Form>
    </FormWrapper>
  );
};

export default SuSyncReportForm;
