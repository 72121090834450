import React, { useContext } from 'react';
import { EditableCard } from 'components/EditableCard';
import type { IEditableCard } from 'components/EditableCard';
import { Form } from '@procore/core-react';
import { EmailValueWrapper } from 'styles';
import { ProjectInfoContext } from '..';
import type { ProjectAdvancedSettingsProps } from '../interfaces';
import { ProjectContext } from 'contexts/ProjectContext';

export const ProjectInboundPhotosEmailSettings = (
  props: ProjectAdvancedSettingsProps,
) => {
  const context = useContext(ProjectContext);
  const config = props.cardConfig;

  const {
    dataSources: { projectSettings },
  } = useContext(ProjectInfoContext);

  const data = projectSettings.data?.photo_import_config?.email;
  const emailServer = projectSettings.data?.inbound_email_server;

  const card: IEditableCard = {
    id: props.id,
    i18n: config.translate,
    label: config.label,
    canEdit: context.hasPermission(config.permissions.edit),
    save: props.save,
    fields: [
      {
        source: projectSettings.id,
        value: data?.email_upload_address,
        name: 'email_upload_address',
        dataType: 'text',
        label: '',
        elementFactory: () => (
          <EmailValueWrapper>
            <div>{config.translate('COMPLETE_ADDRESS')}</div>
            <span>
              {data?.email_upload_address}@{emailServer}
            </span>
          </EmailValueWrapper>
        ),
        element: <React.Fragment />,
        editable: false,
        hideOnEdit: true,
      },
      {
        source: projectSettings.id,
        value: data?.email_upload_address,
        name: 'email_upload_address',
        dataType: 'text',
        element: Form.Text,
        ...config.fields.email_upload_address,
      },
    ],
  };

  return <EditableCard card={card} />;
};

export default ProjectInboundPhotosEmailSettings;
