import React, { useCallback, useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card,
  Flex,
  Spinner,
  Box,
  EmptyState,
  Button,
  Pill,
} from '@procore/core-react';
import TableBackendComponent from 'components/TableBackend';
import type { TableHeader } from 'components/TableBackend/table.interface';
import { AppContext } from 'contexts/AppContext';
import { useNavigate } from 'react-router-dom';
import { getApi } from 'utils/api';
import { PaymentsServiceBusinessEntitiesApi } from '@procore/ipa-nt-api-client-ts';
import type { BusinessEntityDto } from '@procore/ipa-nt-api-client-ts';
import { IpaAlertContext } from 'contexts/IpaAlertContext';
import {
  formatEntityType,
  getRouteForEnv,
} from 'pages/Payments/payments.helper';

const BusinessEntitiesTab = () => {
  const { userSettings, env } = useContext(AppContext);
  const alert = useContext(IpaAlertContext);
  const navigate = useNavigate();
  const { t } = useTranslation(['payments']);
  const { t: tCommon } = useTranslation(['common']);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isInit, setIsInit] = useState<boolean>(true);
  const [total, setTotal] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(
    userSettings.default_page_size as number | 25,
  );
  const [searchStr, setSearchStr] = useState<string>('');
  const [businessEntities, setBusinessEntities] = useState<any[]>([]);

  const viewCell = (item: BusinessEntityDto) => {
    return (
      <span>
        <Button
          data-qa={`view-business-entity-btn`}
          variant="secondary"
          size="sm"
          onClick={() =>
            navigate(getRouteForEnv(env, `/business-entity/${item.externalId}`))
          }
        >
          {tCommon('VIEW')}
        </Button>
      </span>
    );
  };

  const statusCell = (item: BusinessEntityDto) => {
    return (
      <Pill color={item.active ? 'green' : 'gray'}>
        {item.active ? 'ACTIVE' : 'INACTIVE'}
      </Pill>
    );
  };

  const tableHeaders: TableHeader[] = [
    {
      title: '',
      node: '',
      display_func: viewCell,
      column_width: 10,
    },
    {
      title: t('BUSINESS_ENTITIES_TAB.COMPANY_NAME'),
      node: 'entityName',
      is_text: true,
      is_sortable: false,
      column_width: 30,
    },
    {
      title: t('BUSINESS_ENTITIES_TAB.COMPANY_ID'),
      node: 'externalId',
      is_text: true,
      is_sortable: false,
      column_width: 20,
    },
    {
      title: t('BUSINESS_ENTITIES_TAB.ENTITY_TYPE'),
      node: 'capabilities',
      display_func: function (item, node) {
        return formatEntityType(item[node]);
      },
      is_sortable: false,
      column_width: 20,
    },
    {
      title: t('BUSINESS_ENTITIES_TAB.ENTITY_STATUS'),
      node: 'active',
      is_sortable: false,
      display_func: statusCell,
      column_width: 20,
    },
  ];

  const changePage = (page: number, _pageSize: number) => {
    setCurrentPage(page);
  };

  const search = (searchStr: string) => {
    setSearchStr(searchStr);
  };

  const getBusinessEntities = useCallback(async () => {
    alert.closeAlert();
    setLoading(true);
    try {
      const api: PaymentsServiceBusinessEntitiesApi = getApi(
        PaymentsServiceBusinessEntitiesApi,
      );
      const res = await api.businessEntityControllerGetBusinessEntities(
        currentPage,
        perPage,
        searchStr,
        env,
      );
      if (res.data) {
        const entities = res.data.items.map((item: BusinessEntityDto) => {
          return {
            ...item,
            entityName:
              item.entityName ??
              t('BUSINESS_ENTITIES_TAB.NO_ENTITY_NAME_FOUND', {
                companyId: item.externalId,
              }),
          };
        });
        setBusinessEntities(entities);
        setTotal(res.data.total);
      }
    } catch (e: any) {
      alert.error(e);
    } finally {
      setLoading(false);
    }
  }, [env, currentPage, perPage, searchStr]);

  useEffect(() => {
    getBusinessEntities();
  }, [getBusinessEntities]);

  return (
    <Card>
      <Box padding="lg">
        {!isInit && !isLoading && (
          <EmptyState>
            <EmptyState.NoItems />
            <EmptyState.Title>{tCommon('NO_DATA')}</EmptyState.Title>
          </EmptyState>
        )}
        {isLoading && (
          <Flex justifyContent="center">
            <Spinner loading={isLoading} />
          </Flex>
        )}

        {isInit && !isLoading && (
          <TableBackendComponent
            data-qa={'business-entities-table'}
            data={businessEntities}
            data_total={total}
            current_page={currentPage}
            default_sort_column=""
            default_sort_direction="asc"
            headers={tableHeaders}
            paginator={changePage}
            sorter={() => {}}
            no_data_message={tCommon('NO_DATA')}
            show_no_data_message={true}
            show_search={true}
            search_placeholder={tCommon('SEARCH')}
            search_action={search}
          />
        )}
      </Box>
    </Card>
  );
};

export default BusinessEntitiesTab;
