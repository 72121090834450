import React, { useState, useEffect } from 'react';
import * as yup from 'yup';
import type { FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import { Modal, Button, Form, Checkbox, Grid } from '@procore/core-react';
import { IpaModalWrapper } from 'styles';
import type { ConfirmModalForm } from './interface';

interface ConfirmModalProps {
  isOpen: boolean;
  modalTitle: string;
  modalCopy: string;
  includeReason?: boolean;
  reasonCopy?: string;
  initialValues?: object;
  closeModal(): void;
  confirmModal(values: any): void;
  children?: React.ReactNode;
  validationSchema?: any | (() => any);
  verify?: boolean;
  id?: string;
}

export const ConfirmModal = (props: ConfirmModalProps) => {
  const { t } = useTranslation('common');
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalTitle, setModalTitle] = useState<string>('');
  const [modalCopy, setModalCopy] = useState<string>('');
  const [includeReason, setIncludeReason] = useState<boolean>(false);
  const [givenInitialValues, setGivenInitialValues] = useState<{
    [key: string]: any;
  }>({});
  const [verifyCheck, setVerifyCheck] = useState<boolean>(false);

  const closeModal = () => {
    props.closeModal();
  };

  useEffect(() => {
    setShowModal(props.isOpen);
    setModalTitle(props.modalTitle);
    setModalCopy(props.modalCopy);
    setVerifyCheck(false);
    if (props.initialValues) {
      setGivenInitialValues(props.initialValues);
    }
    if (props.includeReason) {
      setIncludeReason(props.includeReason);
    }
  }, [props.isOpen]);

  return (
    <Modal
      width="md"
      open={showModal}
      onClickOverlay={closeModal}
      data-qa={props.id ?? 'confirm-modal'}
    >
      <IpaModalWrapper>
        <Modal.Header onClose={closeModal}>{modalTitle}</Modal.Header>
        <Form
          view="create"
          initialValues={{
            reason: '',
            ...givenInitialValues,
          }}
          validationSchema={yup.object().shape({
            reason: includeReason
              ? yup.string().nullable().required(t('REQUIRED'))
              : yup.string().nullable(),
            ...props.validationSchema,
          })}
          onSubmit={(values) => {
            props.confirmModal(values);
            closeModal();
          }}
        >
          {(formProps: FormikProps<ConfirmModalForm>) => {
            const { values, dirty } = formProps;
            return (
              <Form.Form className="ipa-modal-form">
                <Modal.Body>
                  <Modal.Section>
                    {modalCopy && (
                      <Grid>
                        <Grid.Row>
                          <Grid.Col colWidth={1}>
                            {props.verify && (
                              <Checkbox
                                style={{ marginTop: '0.5rem' }}
                                data-qa="verify-checkbox"
                                onClick={() => setVerifyCheck(!verifyCheck)}
                              />
                            )}
                          </Grid.Col>
                          <Grid.Col colWidth={10}>
                            <span
                              dangerouslySetInnerHTML={{ __html: modalCopy }}
                            ></span>
                          </Grid.Col>
                        </Grid.Row>
                      </Grid>
                    )}
                    {props.children}
                    {includeReason && (
                      <React.Fragment>
                        <p>{props.reasonCopy || t('CONFIRM_REASON')}</p>
                        <Form.TextArea
                          name="reason"
                          label={t('REASON')}
                          data-qa="data-change-modal-reason"
                        />
                      </React.Fragment>
                    )}
                  </Modal.Section>
                </Modal.Body>
                <Modal.Footer>
                  <Modal.FooterButtons>
                    <Button
                      variant="secondary"
                      data-qa="cancel-btn"
                      onClick={props.closeModal}
                    >
                      {t('CANCEL')}
                    </Button>
                    <Button
                      type="submit"
                      variant="primary"
                      data-qa="confirm-btn"
                      disabled={
                        ((!dirty || !values.reason) && props.includeReason) ||
                        (props.verify && !verifyCheck)
                      }
                    >
                      {t('CONFIRM')}
                    </Button>
                  </Modal.FooterButtons>
                </Modal.Footer>
              </Form.Form>
            );
          }}
        </Form>
      </IpaModalWrapper>
    </Modal>
  );
};

export default ConfirmModal;
