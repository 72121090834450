import type { PageConfig } from 'pages/page-config.interfaces';

export default <PageConfig>{
  route: {
    path: '/v2/global-announcement-admin',
  },
  i18n: {
    namespace: 'global-announcements',
  },
  label: 'TITLE',
  endpoints: {
    view: ['globalAnnouncementControllerGetAnnouncements'],
    edit: [
      'globalAnnouncementControllerCreateAnnouncement',
      'globalAnnouncementControllerUpdateAnnouncement',
    ],
  },
  cards: {},
};
