import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { EditableCard } from 'components/EditableCard';
import type { IEditableCard } from 'components/EditableCard';
import { Box, Card, Form } from '@procore/core-react';
import { DisbursementGeneralTabContext } from '.';
import {
  disbursementStatusPills,
  getTranslatedDisbursementStatus,
} from 'pages/Payments/payments.helper';

export interface GeneralInformationCardProps {
  id: string;
  canEdit: boolean;
  isCardLoading: boolean;
}

export const GeneralInformationCard = (props: GeneralInformationCardProps) => {
  const { t } = useTranslation(['payments'], {
    keyPrefix: 'DISBURSEMENT.GENERAL_TAB.GENERAL_INFORMATION_CARD',
  });
  const { t: tStatus } = useTranslation(['payments'], { keyPrefix: 'STATUS' });

  const {
    dataSources: { generalInformation },
  } = useContext(DisbursementGeneralTabContext);

  const card: IEditableCard = {
    id: props.id,
    i18n: t,
    label: t('GENERAL'),
    canEdit: props.canEdit,
    save: () => {},
    fieldsPerRow: 3,
    fields: [
      {
        source: generalInformation.id,
        value: generalInformation.data?.displayAmount,
        name: 'amount',
        element: Form.Text,
        editable: false,
        label: t('AMOUNT'),
      },
      {
        source: generalInformation.id,
        value: generalInformation.data?.id,
        name: 'id',
        element: Form.Text,
        editable: false,
        label: t('REFERENCE_ID'),
      },
      {
        source: generalInformation.id,
        value: generalInformation.data?.sendingExternalAccountDisplay,
        name: 'sendingExternalAccountDisplay',
        element: Form.Text,
        editable: false,
        label: t('BANK_ACCOUNT'),
      },
      {
        source: generalInformation.id,
        value: generalInformation.data?.status
          ? getTranslatedDisbursementStatus(
              generalInformation.data?.status,
              tStatus,
            )
          : '',
        name: 'status',
        editable: false,
        label: t('DISBURSEMENT_STATUS'),
        element: <React.Fragment />,
        elementFactory: (field) => (
          <Form.PillSelect
            name={field.name}
            label={t('DISBURSEMENT_STATUS')}
            options={disbursementStatusPills.map((pill) => {
              return { ...pill, label: tStatus(pill.id) };
            })}
            disabled
          />
        ),
      },
      {
        source: generalInformation.id,
        value: generalInformation.data?.paymentsUserName,
        name: 'paymentsUserName',
        element: Form.Text,
        editable: false,
        label: t('CREATED_BY'),
      },
      {
        source: generalInformation.id,
        value: generalInformation.data?.createdAt,
        name: 'createdAt',
        element: Form.DateSelect,
        editable: false,
        label: t('CREATED_ON'),
      },
    ],
  };

  return (
    <Box marginBottom="lg">
      <Card>
        <Box padding="lg">
          <EditableCard card={card} />
        </Box>
      </Card>
    </Box>
  );
};

export default GeneralInformationCard;
