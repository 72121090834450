import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Card, H2, Link } from '@procore/core-react';
import TableComponent from 'components/Table';
import type { TableHeader } from 'components/TableBackend/table.interface';
import { DisbursementGeneralTabContext } from '.';
import type { PayoutDto } from '@procore/ipa-nt-api-client-ts';
import {
  getPayoutStatus,
  formatCurrency,
  getRouteForEnv,
} from 'pages/Payments/payments.helper';
import { AppContext } from 'contexts/AppContext';

export interface PayoutsCardProps {
  id: string;
}

export const PayoutsCard = (props: PayoutsCardProps) => {
  const { env } = useContext(AppContext);
  const { t } = useTranslation(['payments'], {
    keyPrefix: 'DISBURSEMENT.GENERAL_TAB.PAYOUTS_CARD',
  });
  const { t: tStatus } = useTranslation(['payments'], {
    keyPrefix: 'STATUS',
  });

  const {
    dataSources: { payouts },
  } = useContext(DisbursementGeneralTabContext);

  const tableHeaders: TableHeader[] = [
    {
      title: t('CREATED_ON'),
      node: 'createdAt',
      is_datetime: true,
      is_sortable: true,
    },
    {
      title: t('INVOICE_ID'),
      node: 'invoiceId',
      is_sortable: true,
      is_text: true,
    },
    {
      title: t('CHECK_NUMBER'),
      node: 'checkNumber',
      is_sortable: true,
      is_text: true,
    },
    {
      title: t('AMOUNT'),
      node: 'amount',
      is_sortable: true,
      display_func: (item) => {
        return (
          <span>{formatCurrency(item.currencyCode, item.amount / 100)}</span>
        );
      },
    },
    {
      title: t('STATUS'),
      node: 'status',
      is_sortable: true,
      display_func: (item: PayoutDto) => getPayoutStatus(item.status, tStatus),
    },
    {
      title: t('RECEIVING_COMPANY'),
      node: 'metadata',
      is_sortable: true,
      display_func: (item, node) => {
        return (
          <Link
            href={`${getRouteForEnv(
              env,
              `/business-entity/${item.receivingPaymentsCompanyExternalId}?tab=received-payouts`,
            )}`}
          >
            {item[node]
              ? item[node].receiving_payments_company_name
              : item.receivingPaymentsCompanyExternalId}
          </Link>
        );
      },
    },
    {
      title: t('RECEIVING_BANK_ACCOUNT'),
      node: 'receivingExternalAccountLastFour',
      is_sortable: true,
      display_func: (item, node) => {
        return (
          <span>{`${item.receivingExternalAccountNickname} (...${item[node]})`}</span>
        );
      },
    },
  ];

  return (
    <Box marginBottom="lg">
      <Card>
        <Box padding="lg">
          <H2>{t('TITLE')}</H2>
          <TableComponent
            data={payouts.data}
            headers={tableHeaders}
            default_sort_column="createdAt"
            default_sort_direction="asc"
            show_no_data_message={true}
            no_data_message={t('NO_PAYOUTS')}
          />
        </Box>
      </Card>
    </Box>
  );
};

export default PayoutsCard;
