import type { PageConfig } from 'pages/page-config.interfaces';

const pageConfig: PageConfig = {
  label: 'SUBMITTAL_WORKFLOW_TEMPLATES',
  route: {
    page: 'submittal-workflow-templates',
    v1_path:
      '/submittal-workflow-templates/company/:company_id/project/:project_id',
  },
  endpoints: {
    view: ['projectControllerGetProjectSubmittalWorkflowTemplates'],
  },
  cards: {
    SubmittalWorkflowTemplates: {
      label: 'SUBMITTAL_WORKFLOW_TEMPLATES',
      fields: {
        step: {
          label: 'SUBMITTAL_WORKFLOW_TEMPLATES_PAGE.TABLE_HEADERS.STEP',
        },
        approvers: {
          label: 'NAME',
        },
        approver_type: {
          label: 'SUBMITTAL_WORKFLOW_TEMPLATES_PAGE.TABLE_HEADERS.ROLE',
        },
        days_to_respond: {
          label:
            'SUBMITTAL_WORKFLOW_TEMPLATES_PAGE.TABLE_HEADERS.DAYS_TO_SUBMIT',
        },
      },
    },
  },
};

export default pageConfig;
