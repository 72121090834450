import React, { createContext } from 'react';
import type { IDatasource } from 'components/EditableCard';
import type { PageConfigTranslated } from 'pages/page-config.interfaces';
import type {
  BusinessEntityDto,
  CompanyAccountStatusDto,
} from '@procore/ipa-nt-api-client-ts';

export interface PaymentsBusinessEntityProps {
  env: string;
  companyId: string;
  dataSources: {
    companyStatus: IDatasource<CompanyAccountStatusDto>;
    generalInformation: IDatasource<BusinessEntityDto>;
  };
  pageConfig: PageConfigTranslated;
  hasPermission: (permission: string[]) => boolean;
}

interface ContextProps extends ContextConsumerProps {
  children: JSX.Element;
}

interface ContextConsumerProps {
  children: any;
}

export const PaymentsBusinessEntityContext = createContext(
  {} as PaymentsBusinessEntityProps,
);

export const PaymentsBusinessEntityContextProvider = (
  props: PaymentsBusinessEntityProps & ContextProps,
) => {
  return (
    <PaymentsBusinessEntityContext.Provider value={props}>
      {props.children}
    </PaymentsBusinessEntityContext.Provider>
  );
};
