import styled from 'styled-components';
import { colors } from '@procore/core-react';

export const FooterWrapper = styled.footer`
  height: 100px;
  background: ${colors.white};
  box-shadow: 0px -12px 24px -6px rgba(23, 26, 28, 0.24);
  padding: 14px 24px;
  box-sizing: border-box;
  @media only screen and (min-width: 992px) {
    height: 64px;
  }
  z-index: 3;
`;

export const styles = {
  buildStyle: {
    fontSize: '12px',
    lineHeight: '36px',
  },
  linkStyle: {
    color: `${colors.gray15}`,
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '36px',
  },
};
