import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DefaultStagesCard from './DefaultStages';
import ProjectsCard from './Projects';
import { AppContext } from 'contexts/AppContext';
import { IpaAlertContext } from 'contexts/IpaAlertContext';
import { ProjectManagementContext } from '../index';

const ProjectListPage = () => {
  const { t } = useTranslation(['project-management']);
  const { userConfig } = useContext(AppContext);
  const alert = useContext(IpaAlertContext);
  const { companyId, env } = useContext(ProjectManagementContext);

  return (
    <React.Fragment>
      <ProjectsCard />
      <br />
      <DefaultStagesCard />
    </React.Fragment>
  );
};
export default ProjectListPage;
