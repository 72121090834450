import React from 'react';
import { HomepageContextProvider } from './HomepageContext';
import Homepage from './Homepage';

const HomeV2 = () => {
  return (
    <HomepageContextProvider>
      <Homepage />
    </HomepageContextProvider>
  );
};

export default HomeV2;
