import React, { useState, useEffect, useCallback } from 'react';
import { Page, Tabs, ToolHeader, Card, Box } from '@procore/core-react';
import { useTranslation } from 'react-i18next';
import { IntegratorSandboxNumberPage } from './ActiveProjectNumbersTab';
import { IntegratorSandboxEntitlementsPage } from './EntitlementsTab';
import type { ISettingsTabs } from './usersettings.interface';

export const IntegratorSandboxConfigPage = () => {
  const { t } = useTranslation('dev-portal');
  const [tabs] = useState<ISettingsTabs[]>([
    {
      id: 'entitlements',
      name: 'INTEGRATOR_SANDBOX_CONFIG.TAB_TITLE',
      tag: 'Entitlements-tab',
      data: [],
    },
    {
      id: 'active_projects_number',
      name: 'INTEGRATOR_SANDBOX_NUMBER.TAB_TITLE',
      tag: 'Project-numbers-tab',
      data: [],
    },
  ]);
  const [activeTab, setActiveTab] = useState<string>(tabs[0].id);

  const isTabActive = (tab: string) => {
    return tab === activeTab;
  };

  const changeTab = useCallback((tab: string) => {
    window.location.hash = tab;
    setActiveTab(tab);
  }, []);

  useEffect(() => {
    if (window.location.hash !== '') {
      const hash = window.location.hash.substring(1);
      if (tabs.findIndex((t) => t.id === hash) !== -1) {
        changeTab(hash);
      }
    }
  }, [tabs, changeTab]);

  return (
    <Page>
      <Page.Main style={{ flex: 'auto' }}>
        <Page.Header>
          <ToolHeader>
            <ToolHeader.Title>{t('HEADER_TITLE')}</ToolHeader.Title>
            <ToolHeader.Tabs>
              {tabs.map((tab, i) => (
                <Tabs.Tab
                  active={isTabActive(tab.id)}
                  onClick={() => changeTab(tab.id)}
                  data-qa={tab.tag}
                  key={tab.id}
                >
                  <Tabs.Link>{t(tab.name)}</Tabs.Link>
                </Tabs.Tab>
              ))}
            </ToolHeader.Tabs>
          </ToolHeader>
        </Page.Header>
        <Page.Body>
          <Card>
            <Box padding="lg">
              {activeTab === 'entitlements' && (
                <IntegratorSandboxEntitlementsPage
                  initialFieldsVisibility={[]}
                />
              )}
              {activeTab === 'active_projects_number' && (
                <IntegratorSandboxNumberPage />
              )}
            </Box>
          </Card>
        </Page.Body>
      </Page.Main>
    </Page>
  );
};

export default IntegratorSandboxConfigPage;
