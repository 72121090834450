import React, { useContext, useEffect, useState } from 'react';
import { Box, Card, Form, Spinner, Typography } from '@procore/core-react';
import { useTranslation } from 'react-i18next';
import { EditableCard } from 'components/EditableCard';
import type { IEditableCard } from 'components/EditableCard';
import { PageConfig } from 'pages/page-config.service';
import type { CustomReportDetailsDto } from '@procore/ipa-nt-api-client-ts';
import { AppContext } from 'contexts/AppContext';
import CompanyLink from 'components/CompanyLink';

export interface IEditableCardProps {
  id: string;
  reportDetails: CustomReportDetailsDto;
}

export const ReportDetailsCard = (props: IEditableCardProps) => {
  const { t } = useTranslation('custom-report-tabs-details');
  const context = useContext(AppContext);
  const config = PageConfig.getCard(
    t,
    'Reports',
    'CustomReportTabsDetails',
    'ReportDetails',
  );
  const [reportDetails, setReportDetails] =
    useState<CustomReportDetailsDto>(null);

  const card: IEditableCard = {
    id: props.id,
    i18n: config.translate,
    label: config.translate('REPORT_INFORMATION'),
    canEdit: false,
    save: undefined,
    fields: [
      {
        source: reportDetails?.id.toString(),
        value: reportDetails?.reportName,
        name: 'reportName',
        dataType: 'text',
        editable: false,
        elementFactory: (field) => (
          <React.Fragment>
            <Typography weight="bold">
              {config.translate('REPORT_INFO.NAME_ID')}
            </Typography>
            <br />
            <CompanyLink
              companyId={reportDetails.company.id}
              env={context.env}
              companyName={reportDetails.company.name}
              hideLabel={true}
              customText={`${reportDetails.reportName} (${reportDetails.id})`}
              url={
                reportDetails.project
                  ? `/${reportDetails.project.id}/project/custom_reports/${reportDetails.id}`
                  : `/${reportDetails.company.id}/company/custom_reports/${reportDetails.id}`
              }
              data-qa="input-reportName"
            />
          </React.Fragment>
        ),
        element: <React.Fragment />,
        hideOnEdit: true,
        ...config.fields.report_name,
      },
      {
        source: reportDetails?.id.toString(),
        value: reportDetails?.company?.name,
        name: 'companyName',
        element: Form.Text,
        ...config.fields.company,
      },
      {
        source: reportDetails?.id.toString(),
        value: reportDetails?.project?.name,
        name: 'projectName',
        element: Form.Text,
        ...config.fields.project,
      },
      {
        source: reportDetails?.id.toString(),
        value: reportDetails?.createdBy?.login,
        name: 'createdBy',
        element: Form.Text,
        ...config.fields.creator,
      },
      {
        source: reportDetails?.id.toString(),
        value: reportDetails?.createdAt,
        name: 'createdAt',
        element: Form.Text,
        ...config.fields.created_at,
      },
      {
        source: reportDetails?.id.toString(),
        value: reportDetails?.tabs ? reportDetails.tabs.join(', ') : '',
        name: 'tabs',
        element: Form.Text,
        ...config.fields.tabs,
      },
    ],
  };

  useEffect(() => {
    if (props.reportDetails) {
      setReportDetails(props.reportDetails);
    }
  }, [props.reportDetails]);

  return (
    <Card>
      <Box padding="lg">
        {reportDetails !== null ? <EditableCard card={card} /> : <Spinner />}
      </Box>
    </Card>
  );
};

export default ReportDetailsCard;
