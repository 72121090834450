import type { SectionConfig } from 'pages/page-config.interfaces';
import CommitmentSettings from './CommitmentSettings/page.config';
import ConfigurationChangeHistory from './ConfigurationChangeHistory/page.config';
import CostCodes from './CostCodes/page.config';
import DailyLogSettings from './DailyLogSettings/page.config';
import Locations from './Locations/page.config';
import MeetingSettings from './MeetingSettings/page.config';
import PrimeContractSettings from './PrimeContractSettings/page.config';
import ProjectInfo from './ProjectInfo/ProjectInfo/page.config';
import ProjectAdvanced from './ProjectInfo/ProjectAdvancedTab/page.config';
import ProjectLocation from './ProjectInfo/ProjectLocation/page.config';
import RfiSettings from './RfiSettings/page.config';
import ScheduleSettings from './ScheduleSettings/page.config';
import SubmittalResponses from './SubmittalResponses/page.config';
import SubmittalSettings from './SubmittalSettings/page.config';
import SubmittalWorkflowTemplates from './SubmittalWorkflowTemplates/page.config';
import TaskItemsSettings from './TaskItemsSettings/page.config';

const projectSectionConfig: SectionConfig = {
  id: 'Project',
  label: 'PROJECT',
  route: {
    path: '/v2/company/:company_id/project/:project_id/:page',
    v1_path: '/customer-success/project-management',
  },
  i18n: {
    namespace: 'project',
  },
  permissions: {
    view: ['company_tab_view'],
    edit: ['cs_edit', 'company_tab_edit_project_settings'],
  },
  endpoints: {
    view: ['companyCompanyInfoControllerGetCompanyAccountStatus'],
  },
  pages: {
    CommitmentSettings,
    ConfigurationChangeHistory,
    CostCodes,
    DailyLogSettings,
    Locations,
    MeetingSettings,
    PrimeContractSettings,
    ProjectInfo,
    ProjectAdvanced,
    ProjectLocation,
    RfiSettings,
    ScheduleSettings,
    SubmittalResponses,
    SubmittalSettings,
    SubmittalWorkflowTemplates,
    TaskItemsSettings,
  },
};

export default projectSectionConfig;
