import React, { createContext, useState } from 'react';
import type {
  CompanyAccountStatusDto,
  CompanyUserContactDto,
  ProjectInfo,
} from '@procore/ipa-nt-api-client-ts';
import type { IDatasource } from 'components/EditableCard';
import type { PageConfigTranslated } from 'pages/page-config.interfaces';

export interface ContactContextProps {
  env: string;
  companyId: number;
  contactId: number;
  loginId: number;
  projectId?: number;
  dataSources: {
    contact: IDatasource<CompanyUserContactDto>;
    companyStatus: IDatasource<CompanyAccountStatusDto>;
    projectInfo: IDatasource<ProjectInfo>;
  };
  pageConfig: PageConfigTranslated;
  hasPermission: (permission: string[]) => boolean;
  loadContactInfo: () => void;
  updatePowerUser: (v: boolean) => void;
  powerUser: boolean;
  contactEmail?: string;
}

interface ContextProps extends ContextConsumerProps {
  children: JSX.Element;
}

interface ContextConsumerProps {
  children: any;
}

export const ContactContext = createContext({} as ContactContextProps);

export const ContactContextProvider = (
  props: ContactContextProps & ContextProps,
) => {
  const [powerUser, setPowerUser] = useState<boolean>(false);

  const updatePowerUser = (v: boolean) => {
    setPowerUser(v);
  };

  return (
    <ContactContext.Provider value={{ ...props, powerUser, updatePowerUser }}>
      {props.children}
    </ContactContext.Provider>
  );
};
