import type { SectionConfig } from 'pages/page-config.interfaces';
import Dashboard from './Dashboard/page.config';
import JitAdmin from './JitAdmin/page.config';
import JitSync from './JitSync/page.config';
import JitAuditLog from './JitAuditLog/page.config';
import OktaUsers from './OktaUsers/page.config';
import OktaGroups from './OktaGroups/page.config';
import SuPromotionReport from './SuPromotionReport/page.config';
import GlobalAnnouncement from './GlobalAnnouncement/page.config';
import SystemSettings from './SystemSettings/page.config';
import SuSync from './SuSync/page.config';
import AuditLogs from './AuditLogs/page.config';

const adminSection: SectionConfig = {
  id: 'Admin',
  i18n: {
    namespace: 'admin',
  },
  permissions: {
    view: ['view_admin'],
    edit: ['view_admin'],
  },
  pages: {
    AuditLogs,
    Dashboard,
    JitAdmin,
    JitSync,
    JitAuditLog,
    OktaUsers,
    OktaGroups,
    SuPromotionReport,
    GlobalAnnouncement,
    SuSync,
    SystemSettings,
  },
};
export default adminSection;
