import React from 'react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { EditableCard } from 'components/EditableCard';
import type { IEditableCard } from 'components/EditableCard';
import { Form } from '@procore/core-react';
import { ContactContext } from 'contexts/ContactContext';
import type { IEditableCardProps } from 'pages/Contact/interfaces';

export const PersonalInfoCard = (props: IEditableCardProps) => {
  const context = useContext(ContactContext);
  const config = context.pageConfig.card('PersonalInfo');
  const contact = context.dataSources?.contact;
  const { t } = useTranslation(['contact'], {
    keyPrefix: 'CONTACT_INFO_PAGE.PERSONAL_INFO_CARD',
  });

  const card: IEditableCard = {
    id: props.id,
    i18n: config.translate,
    label: config.label,
    canEdit: false,
    save: props.save,
    fields: [
      {
        source: contact.id,
        value: contact.data?.first_name,
        name: 'first_name',
        dataType: 'text',
        element: Form.Text,
        ...config.fields.first_name,
      },
      {
        source: contact.id,
        value: contact.data?.last_name,
        name: 'last_name',
        dataType: 'text',
        element: Form.Text,
        ...config.fields.last_name,
      },
      {
        source: contact.id,
        value: contact.data?.email,
        name: 'email',
        dataType: 'text',
        element: Form.Text,
        ...config.fields.email,
        tooltip:
          contact.data?.email !== contact.data?.login_email
            ? t('EMAIL_TOOLTIP')
            : '',
      },
      {
        source: contact.id,
        value: contact.data?.login_email,
        name: 'login',
        dataType: 'text',
        element: Form.Text,
        ...config.fields.login,
      },
      {
        source: contact.id,
        value: contact.data?.vendor_name,
        name: 'vendor_name',
        dataType: 'text',
        element: Form.Text,
        ...config.fields.vendor_name,
      },
      {
        source: contact.id,
        value: contact.data?.last_login_at,
        name: 'last_login_at',
        dataType: 'text',
        element: Form.Text,
        ...config.fields.last_login_at,
      },
    ],
  };

  return <EditableCard card={card} isCollapsible={true} />;
};

export default PersonalInfoCard;
