export const getDisplayList = (data: any) => {
  if (!data) return '';

  return data
    .reduce((map: any, g: any) => {
      if (g.name) {
        map.push(g.name);
      } else if (g.label) {
        map.push(g.label);
      }
      return map;
    }, [])
    .join(', ');
};
