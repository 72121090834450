import type { PageConfig } from 'pages/page-config.interfaces';

const NextGenSettingsPage: PageConfig = {
  label: 'TAB_NEXTGEN-SETTINGS',
  route: {
    page: 'nextgen-settings',
    v1_path: '#next_gen_settings',
  },
  i18n: {
    namespace: 'company',
  },
  permissions: {
    edit: ['company_tab_edit'],
  },
  cards: {
    NextGenSettings: {
      i18n: {
        keyPrefix: 'NEXT_GEN_SETTINGS',
      },
      label: 'TITLE',
      fields: {
        conversion_started: {
          label: 'CONVERSION_STARTED',
        },
        conversion_complete: {
          label: 'CONVERSION_COMPLETE',
        },
        migrate_all_projects: {
          label: 'MIGRATE_ALL_PROJECTS',
        },
      },
      endpoints: {
        view: ['companyNextGenSettingsControllerGetCompanyNextGenSettings'],
        edit: ['companyNextGenSettingsControllerUpdateNextGenSettings'],
      },
    },
  },
};

export default NextGenSettingsPage;
