import type { PageConfig } from 'pages/page-config.interfaces';

const pageConfig: PageConfig = {
  label: 'SUBMITTAL_RESPONSES',
  route: {
    page: 'submittal-responses',
    v1_path: '/submittal-responses/company/:company_id/project/:project_id',
  },
  endpoints: {
    view: ['projectControllerGetProjectSubmittalSettings'],
  },
  cards: {
    SubmittalResponses: {
      i18n: {
        keyPrefix: 'PROJECT_SUBMITTAL_RESPONSES',
      },
      label: 'TITLE',
      fields: {
        approved: {
          label: 'APPROVED',
        },
        approved_as_noted: {
          label: 'APPROVED_AS_NOTED',
        },
        for_record_only: {
          label: 'FOR_RECORD_ONLY',
        },
        forwarded_for_review: {
          label: 'FORWARDED_FOR_REVIEW',
        },
        pending: {
          label: 'PENDING',
        },
        rejected: {
          label: 'REJECTED',
        },
        revise_and_resubmit: {
          label: 'REVISE_AND_RESUBMIT',
        },
        submitted: {
          label: 'SUBMITTED',
        },
        void: {
          label: 'VOID',
        },
      },
    },
  },
};

export default pageConfig;
