import { ExternalLink, ClipboardCheck, Duplicate } from '@procore/core-icons';
import { Flex, Link } from '@procore/core-react';
import React, { useContext, useState } from 'react';
import { getBaseUrl } from 'utils/utils';
import { useTranslation } from 'react-i18next';
import { getApi } from 'utils/api';
import { CompaniesApi } from '@procore/ipa-nt-api-client-ts';
import { IpaAlertContext } from 'contexts/IpaAlertContext';

interface CompanyLinkProps {
  companyId: number;
  env: string;
  companyName: string;
  url?: string; // override the URL to use
  hideLabel?: boolean;
  'data-qa'?: string;
  customText?: string;
}

export const CompanyLink = (props: CompanyLinkProps) => {
  const { t } = useTranslation(['company']);
  const alert = useContext(IpaAlertContext);
  const [copied, setCopied] = useState(false);

  const getCompanyUrl = async () => {
    if (props.url) {
      if (props.url.startsWith('http')) {
        return props.url;
      } else {
        return getBaseUrl(props.env, 'procore', props.companyId) + props.url;
      }
    }

    const api = getApi(CompaniesApi);

    try {
      const res = await api.companyControllerGetCompanyProcoreAppUrl(
        props.companyId,
        props.env,
      );

      if (!res.data?.procore_app_url) {
        alert.error('Could not get Procore App URL for company');
      }

      return res.data.procore_app_url;
    } catch (e: any) {
      alert.error(e);
    }
  };

  const openLink = async () => {
    const url = await getCompanyUrl();
    window.open(url, '_blank');
  };

  const copyLink = async () => {
    const url = await getCompanyUrl();
    navigator.clipboard.writeText(url);
    setCopied(true);
    alert.success(t('LINK_COPIED', { ns: 'common' }));
  };

  return (
    <Flex>
      {!props.hideLabel && (
        <strong>{`${t('COMPANY', {
          ns: 'common',
        })}: `}</strong>
      )}
      <Link
        data-qa={props['data-qa'] ?? `company-link-${props.companyId}`}
        onClick={openLink}
      >
        {props.customText ? props.customText : props.companyName}{' '}
        <ExternalLink size="sm" />
      </Link>
      <Link
        data-qa={props['data-qa'] ?? `company-link-copy-${props.companyId}`}
        onClick={copyLink}
      >
        {copied ? (
          <ClipboardCheck size="sm" color="green" />
        ) : (
          <Duplicate size="sm" />
        )}
      </Link>
    </Flex>
  );
};

export default CompanyLink;
