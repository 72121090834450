import { useTranslation } from 'react-i18next';
import {
  Card,
  Flex,
  Spinner,
  Box,
  EmptyState,
  Form,
} from '@procore/core-react';
import { getApi } from 'utils/api';
import React, { useState, useEffect, useCallback, useContext } from 'react';
import { ProjectContext } from 'contexts/ProjectContext';
import { IpaAlertContext } from 'contexts/IpaAlertContext';
import { ProjectsApi } from '@procore/ipa-nt-api-client-ts';
import type { ProjectMeetingSettingsDto } from '@procore/ipa-nt-api-client-ts';
import { EditableCard } from 'components/EditableCard';
import type { IEditableCard } from 'components/EditableCard';

const MeetingSettingsPage = () => {
  const context = useContext(ProjectContext);
  const config = context.pageConfig.card('MeetingSettings');
  const t = config.translate;

  const { t: tCommon } = useTranslation(['common']);
  const alert = useContext(IpaAlertContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isInit, setIsInit] = useState<boolean>(true);
  const [meetingSettings, setMeetingSettings] =
    useState<ProjectMeetingSettingsDto>();

  const getMeetingSettings = useCallback(async () => {
    setIsInit(false);
    setIsLoading(true);

    try {
      const api = getApi(ProjectsApi);
      const result = await api.projectControllerGetMeetingSettings(
        context.env,
        context.companyId,
        context.projectId,
      );

      setMeetingSettings(result.data);
    } catch (e: any) {
      alert.error(e);
    } finally {
      setIsLoading(false);
      setIsInit(true);
    }
  }, [context.projectId, context.companyId, context.env]);

  useEffect(() => {
    getMeetingSettings();
  }, []);

  const sourceId = 'meetingSettings';
  const card: IEditableCard = {
    id: 'meeting-settings',
    i18n: t,
    label: '',
    canEdit: false,
    save: () => {},
    fields: [
      {
        source: sourceId,
        value: meetingSettings?.default_meeting_format,
        name: 'default_meeting_format',
        element: Form.Text,
        ...config.fields.default_meeting_format,
      },
      {
        source: sourceId,
        value: meetingSettings?.number_of_previous_meeting_minutes_shown_on_pdf,
        name: 'number_of_previous_meeting_minutes_shown_on_pdf',
        element: Form.Text,
        ...config.fields.number_of_previous_meeting_minutes_shown_on_pdf,
      },
      {
        source: sourceId,
        value: meetingSettings?.show_historical_meeting_comments,
        name: 'show_historical_meeting_comments',
        element: Form.Text,
        ...config.fields.show_historical_meeting_comments,
      },
      {
        source: sourceId,
        value: meetingSettings?.meeting_topics_have_descriptions,
        name: 'meeting_topics_have_descriptions',
        element: Form.Checkbox,
        ...config.fields.meeting_topics_have_descriptions,
      },
      {
        source: sourceId,
        value: meetingSettings?.use_meeting_wide_numbering,
        name: 'use_meeting_wide_numbering',
        element: Form.Checkbox,
        ...config.fields.use_meeting_wide_numbering,
      },
    ],
  };

  return (
    <React.Fragment>
      <Card>
        <Box padding="lg">
          {(!isInit || isLoading) && (
            <Box marginBottom="lg">
              <Card>
                <Box padding="lg">
                  {!isInit && !isLoading && (
                    <EmptyState>
                      <EmptyState.NoItems />
                      <EmptyState.Title>{tCommon('NO_DATA')}</EmptyState.Title>
                    </EmptyState>
                  )}
                  {isLoading && (
                    <Flex justifyContent="center">
                      <Spinner loading={isLoading} />
                    </Flex>
                  )}
                </Box>
              </Card>
            </Box>
          )}
          {isInit && !isLoading && meetingSettings && (
            <EditableCard card={card} />
          )}
        </Box>
      </Card>
    </React.Fragment>
  );
};
export default MeetingSettingsPage;
