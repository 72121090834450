import React from 'react';
import { Form, Page } from '@procore/core-react';
import { useTranslation } from 'react-i18next';
import type { IErpSettingsFormProps } from './erp.settings.interface';

export const ErpSettingsForm = (props: IErpSettingsFormProps) => {
  const { t } = useTranslation('company');

  return (
    <Page.Row>
      <Page.Column>
        <Form.Checkbox
          label={t('ERP_SETTINGS.CAN_PUSH_TO_ACCOUNTING')}
          name="can_push_to_accounting"
          data-qa={'can-push-to-accounting-checkbox'}
          onClick={(e) => {
            props.setFieldValue(
              'subscribed_to_erp_digest_emails',
              e.currentTarget.checked,
            );
            props.setFieldValue(
              'can_push_to_accounting',
              e.currentTarget.checked,
            );
          }}
        />
      </Page.Column>
      <Page.Column>
        <Form.Checkbox
          label={t('ERP_SETTINGS.SUBSCRIBE_TO_ERP_DIGEST_EMAILS')}
          name="subscribed_to_erp_digest_emails"
          data-qa={'subscribe-to-erp-digest-emails-checkbox'}
        />
      </Page.Column>
    </Page.Row>
  );
};

export default ErpSettingsForm;
