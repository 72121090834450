import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card,
  Flex,
  Spinner,
  Box,
  Form,
  EmptyState,
} from '@procore/core-react';
import { AppContext } from 'contexts/AppContext';
import { IpaAlertContext } from 'contexts/IpaAlertContext';
import { ProjectContext } from 'contexts/ProjectContext';
import { getApi } from 'utils/api';
import { ProjectsApi } from '@procore/ipa-nt-api-client-ts';
import type { ProjectPrimeContractSettingsDto } from '@procore/ipa-nt-api-client-ts';
import { EditableCard } from 'components/EditableCard';
import type { IEditableCard } from 'components/EditableCard';

const PrimeContractSettingsPage = () => {
  const { userConfig } = useContext(AppContext);
  const alert = useContext(IpaAlertContext);
  const context = useContext(ProjectContext);
  const config = context.pageConfig.card('PrimeContractSettings');
  const t = config.translate;
  const { t: tCommon } = useTranslation(['common']);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isInit, setIsInit] = useState<boolean>(true);
  const [projectPrimeContractSettings, setProjectPrimeContractSettings] =
    useState<ProjectPrimeContractSettingsDto>(
      {} as ProjectPrimeContractSettingsDto,
    );

  const saveChanges = async (
    card: IEditableCard,
    values: any,
    reason?: string,
    changedKeys?: string[],
  ) => {
    alert.closeAlert();
    setIsLoading(true);
    try {
      const api = getApi(ProjectsApi);
      const result =
        await api.projectControllerUpdateProjectPrimeContractSettings(
          context.env,
          context.companyId,
          context.projectId,
          {
            update: values.primeContractSettings,
            reason: reason ?? '',
          },
        );
      if (result.data) {
        setProjectPrimeContractSettings(result.data);
        alert.success(t('UPDATE_SUCCESS'));
      }
    } catch (e: any) {
      alert.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const sourceId = 'primeContractSettings';
  const card: IEditableCard = {
    id: 'prime-contract-settings',
    i18n: t,
    label: '',
    canEdit: context.hasPermission(config.permissions.edit),
    save: saveChanges,
    fields: [
      {
        source: sourceId,
        value:
          projectPrimeContractSettings?.number_of_prime_contract_change_order_tiers,
        name: 'number_of_prime_contract_change_order_tiers',
        element: Form.Text,
        ...config.fields.number_of_prime_contract_change_order_tiers,
      },
      {
        source: sourceId,
        value: projectPrimeContractSettings?.allow_standard_to_create_prime_pco,
        name: 'allow_standard_to_create_prime_pco',
        element: Form.Checkbox,
        ...config.fields.allow_standard_to_create_prime_pco,
      },
      {
        source: sourceId,
        value: projectPrimeContractSettings?.enable_prime_editable_sov,
        name: 'enable_prime_editable_sov',
        element: Form.Checkbox,
        ...config.fields.enable_prime_editable_sov,
      },
      {
        source: sourceId,
        value: projectPrimeContractSettings?.change_order_statuses_cascade,
        name: 'change_order_statuses_cascade',
        element: Form.Checkbox,
        ...config.fields.change_order_statuses_cascade,
      },
    ],
  };

  const getPrimeContractSettings = useCallback(async () => {
    setIsInit(false);
    setIsLoading(true);
    try {
      const api = getApi(ProjectsApi);
      const result = await api.projectControllerGetProjectPrimeContractSettings(
        context.env,
        context.companyId,
        context.projectId,
      );
      setProjectPrimeContractSettings(result.data);
    } catch (e: any) {
      alert.error(e);
    } finally {
      setIsLoading(false);
      setIsInit(true);
    }
  }, [context.projectId, context.companyId, context.env]);

  useEffect(() => {
    getPrimeContractSettings();
  }, [getPrimeContractSettings]);

  return (
    <React.Fragment>
      <Card>
        <Box padding="lg">
          {(!isInit || isLoading) && (
            <Box marginBottom="lg">
              <Card>
                <Box padding="lg">
                  {!isInit && !isLoading && (
                    <EmptyState>
                      <EmptyState.NoItems />
                      <EmptyState.Title>{tCommon('NO_DATA')}</EmptyState.Title>
                    </EmptyState>
                  )}
                  {isLoading && (
                    <Flex justifyContent="center">
                      <Spinner loading={isLoading} />
                    </Flex>
                  )}
                </Box>
              </Card>
            </Box>
          )}
          {isInit && !isLoading && projectPrimeContractSettings && (
            <EditableCard card={card} modalWidth="lg" />
          )}
        </Box>
      </Card>
    </React.Fragment>
  );
};

export default PrimeContractSettingsPage;
