import type { PageConfig } from 'pages/page-config.interfaces';

const pageConfig: PageConfig = {
  label: 'PROJECT_INFORMATION',
  route: {
    page: 'project-info',
    tab: 'info',
    v1_path: '/project-info/company/:company_id/project/:project_id#info',
  },
  endpoints: {
    view: [
      'projectControllerGetProjectSettings',
      'projectControllerGetProjectDrawingLogSettings',
    ],
    edit: ['projectControllerUpdateProjectSettings'],
  },
  cards: {
    ProjectInformation: {
      i18n: {
        keyPrefix: 'INFO_TAB.INFO_CARD',
      },
      label: 'TITLE',
      fields: {
        name: {
          label: 'NAME',
        },
        total_value: {
          label: 'TOTAL_VALUE',
        },
        start_date: {
          label: 'START_DATE',
          tooltip: 'START_DATE_TOOLTIP',
        },
        actual_start_date: {
          label: 'ACTUAL_START_DATE',
          tooltip: 'ACTUAL_START_DATE_TOOLTIP',
        },
        completion_date: {
          label: 'COMPLETION_DATE',
          tooltip: 'COMPLETION_DATE_TOOLTIP',
        },
        projected_finish_date: {
          label: 'PROJECTED_FINISH_DATE',
          tooltip: 'PROJECTED_FINISH_DATE_TOOLTIP',
        },
        stage: {
          label: 'PROJECT_STAGE',
        },
        project_type: {
          label: 'PROJECT_TYPE',
        },
        project_number: {
          label: 'PROJECT_NUMBER',
        },
        square_feet: {
          label: 'SQUARE_FEET',
        },
        description: {
          label: 'DESCRIPTION',
        },
        active: {
          label: 'ACTIVE',
        },
        inbound_email_address: {
          label: 'INBOUND_EMAIL_ADDRESS',
        },
        enable_change_reason_select: {
          label: 'CHANGE_REASON_BEHAVIOR',
        },
      },
    },
  },
};

export default pageConfig;
