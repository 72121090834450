import React, { useContext } from 'react';
import { EditableCard } from 'components/EditableCard';
import type { IEditableCard } from 'components/EditableCard';
import { Box, Card, Flex, Form, Spinner } from '@procore/core-react';
import { PaymentsBusinessEntityContext } from 'contexts/PaymentsBusinessEntityContext';
import { PaymentsBusinessEntityGeneralTabContext } from '.';
import type { ISelectOption } from 'interfaces/common.interface';

export interface FeePaySettingsCardProps {
  id: string;
  isCardLoading: boolean;
  save: IEditableCard['save'];
}

export const FeePaySettingsCard = (props: FeePaySettingsCardProps) => {
  const context = useContext(PaymentsBusinessEntityContext);
  const config = context.pageConfig.card('FeePaySettings');
  const t = config.translate;

  const {
    dataSources: { generalInformation },
  } = useContext(PaymentsBusinessEntityGeneralTabContext);

  const feeStatusOptions: ISelectOption[] = [
    { id: 'feeDisabled', label: t('DISABLE') },
    { id: 'feeEnabled', label: t('ENABLE') },
  ];

  const feePayTypeOptions: ISelectOption[] = [
    { id: 'SC_PAYS', label: t('GC_PAYS_DISABLED') },
    { id: 'GC_PAYS', label: t('GC_PAYS_ENABLED') },
  ];

  const card: IEditableCard = {
    id: props.id,
    i18n: t,
    label: config.translate('FEE_PAY_SETTINGS'),
    canEdit: context.hasPermission(config.permissions.edit ?? []),
    save: props.save,
    fields: [
      {
        source: generalInformation.id,
        value: generalInformation.data?.applyFeesOnAgreements
          ? feeStatusOptions[1]
          : feeStatusOptions[0],
        name: 'applyFeesOnAgreements',
        element: <React.Fragment />,
        elementFactory: (field) => (
          <Form.Select
            name={field.name}
            label={field.label}
            options={feeStatusOptions}
            data-qa="input-fee-pay-status"
          />
        ),
        editable: true,
        ...config.fields.applyFeesOnAgreements,
      },
      {
        source: generalInformation.id,
        value: generalInformation.data?.payorPaysFeesFeatureEnabled
          ? feePayTypeOptions[1]
          : feePayTypeOptions[0],
        name: 'payorPaysFeesFeatureEnabled',
        element: <React.Fragment />,
        elementFactory: (field) => (
          <Form.Select
            name={field.name}
            label={field.label}
            options={feePayTypeOptions}
            data-qa="input-fee-pay-type"
          />
        ),
        editable: true,
        hideOnRead: !generalInformation.data?.applyFeesOnAgreements,
        ...config.fields.payorPaysFeesFeatureEnabled,
      },
    ],
  };

  return (
    <Box marginBottom="lg">
      <Card>
        {props.isCardLoading ? (
          <Flex justifyContent="center" padding="lg">
            <Spinner loading={props.isCardLoading} />
          </Flex>
        ) : (
          <Box padding="lg">
            <EditableCard card={card} />
          </Box>
        )}
      </Card>
    </Box>
  );
};

export default FeePaySettingsCard;
