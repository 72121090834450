import type { PageConfig } from 'pages/page-config.interfaces';

const AppInstallationsPage: PageConfig = {
  label: 'TAB_APP-INSTALLATIONS',
  route: {
    page: 'app-installations',
    v1_path: '#app_installations',
  },
  i18n: {
    namespace: 'company',
  },
  permissions: {
    // read only page
  },
  endpoints: {
    view: ['companyAppInstallationsControllerGetCompanyAppInstallations'],
  },
  cards: {
    AppInstallations: {
      i18n: {
        keyPrefix: 'APP_INSTALLATIONS',
      },
      label: 'TITLE',
      fields: {
        publisher: {
          label: 'PUBLISHER',
        },
        installer: {
          label: 'INSTALLED_BY',
        },
        installed_at: {
          label: 'INSTALLED_ON',
        },
        uninstaller: {
          label: 'UNINSTALLED_BY',
        },
        uninstalled_at: {
          label: 'UNINSTALLED_ON',
        },
      },
    },
  },
};

export default AppInstallationsPage;
