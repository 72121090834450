import type { PageConfig } from 'pages/page-config.interfaces';

const pageConfig: PageConfig = {
  label: 'EXPENSE_ALLOCATIONS',
  route: {
    page: 'expense-allocations',
    v1_path: '/expense-allocation/company/:company_id',
  },
  endpoints: {
    view: ['companyControllerSearchProjects'],
  },
  cards: {
    ExpenseAllocations: {
      i18n: {
        keyPrefix: 'EXPENSE_ALLOCATIONS_TABLE',
      },
      label: 'TITLE',
      fields: {
        project_name: {
          label: 'PROJECTS',
        },
        original_contract_value: {
          label: 'ESTIMATED_CONTRACT_VALUE',
        },
        value: {
          label: 'VALUE',
        },
        estimated_start_date: {
          label: 'ESTIMATED_START_DATE',
        },
        estimated_end_date: {
          label: 'ESTIMATED_END_DATE',
        },
        billing_period_original_value: {
          label: 'CONTRACT_BILLING_ORIGINAL_VALUE',
        },
        billing_period_remaining_value: {
          label: 'CONTRACT_BILLING_REMAINING_VALUE',
        },
        billing_period_start_date: {
          label: 'CONTRACT_BILLING_START_DATE',
        },
        billing_period_end_date: {
          label: 'CONTRACT_BILLING_END_DATE',
        },
      },
    },
  },
};

export default pageConfig;
