import React, { useContext } from 'react';
import { Card, Box, Select } from '@procore/core-react';
import { ZONE_OPTIONS } from 'common/constants';
import { InsightsContext } from 'contexts/InsightsContext';

const ZoneSelector = () => {
  const { zone, updateZone } = useContext(InsightsContext);

  return (
    <Box marginTop="lg">
      <Card>
        <Box padding="lg">
          <Select
            label={zone.label}
            onSelect={(e) => {
              updateZone(e.item);
            }}
          >
            {ZONE_OPTIONS.map((item) => (
              <Select.Option
                key={item.id}
                value={item}
                selected={item.id === zone.id}
              >
                {item.label}
              </Select.Option>
            ))}
          </Select>
        </Box>
      </Card>
    </Box>
  );
};

export default ZoneSelector;
