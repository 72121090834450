import React from 'react';
import { getNavPath } from 'components/AppHeader/ipaNavMap';
import { AppContext } from 'contexts/AppContext';
import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { can, type IAuthzReq } from 'utils/permissions.helper';

const permissionToHomepage = [
  ['jit_escalate', getNavPath('NAV_JIT')],
  ['su', getNavPath('NAV_SU')],
  ['payments_prod_access', getNavPath('NAV_PAYMENTS_OPERATIONS')],
  [
    'payments_operations_int_sbx',
    getNavPath('NAV_PAYMENTS_OPERATIONS_INT_SBX'),
  ],
  ['company_tab_view', getNavPath('NAV_COMPANY_INFO')],
  ['cs_view', getNavPath('NAV_GLOBAL_USER_SEARCH')],
  ['view_admin', getNavPath('NAV_DASHBOARD')],
];

export const HomepageRedirect = () => {
  const { userConfig } = useContext(AppContext);
  const authRequest: IAuthzReq = {
    user: userConfig,
  };

  if (userConfig.maintenanceMode) {
    return <Navigate replace to={'/under-maintenance'} />;
  }

  const redirectTo = permissionToHomepage.find((homepage) =>
    can(authRequest, homepage[0]),
  );

  if (redirectTo) {
    return <Navigate replace to={redirectTo[1]} />;
  } else {
    console.log('Unable to determine home page redirect');
    return <Navigate replace to={'/403'} />;
  }
};
