import { IpaAlertContext } from 'contexts/IpaAlertContext';
import React, { createContext, useContext, useState } from 'react';
import type { IDatasource } from 'components/EditableCard';
import { getApi } from 'utils/api';
import { ContactContext } from 'contexts/ContactContext';
import { Box, Card } from '@procore/core-react';
import { CompanyContactsApi } from '@procore/ipa-nt-api-client-ts';
import type { CompanyUserContactProjectsDto } from '@procore/ipa-nt-api-client-ts';
import { ProjectList } from './ProjectList';
import MessageBanner from 'components/MessageBanner';

interface ProjectsTabContextProps {
  dataSources: {
    projectsBelongingTo: IDatasource<CompanyUserContactProjectsDto>;
    projectsNotBelongingTo: IDatasource<CompanyUserContactProjectsDto>;
  };
}

export const ProjectsTabContext = createContext({} as ProjectsTabContextProps);

export interface ProjectSearchProps {
  page: number;
  perPage: number;
  query: string;
}

export interface ProjectCardProps {
  id: string;
  search: (options: ProjectSearchProps) => Promise<void>;
}

const ProjectsTab = () => {
  const alert = useContext(IpaAlertContext);
  const context = useContext(ContactContext);
  const config = context.pageConfig.card('CompanyToolPermissions');
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isInit, setIsInit] = useState<boolean>(true);
  const [projectsBelongingTo, setProjectsBelongingTo] =
    useState<CompanyUserContactProjectsDto>();
  const [projectsNotBelongingTo, setProjectsNotBelongingTo] =
    useState<CompanyUserContactProjectsDto>();
  const contact = context.dataSources.contact?.data;

  const dataSources: ProjectsTabContextProps['dataSources'] = {
    projectsBelongingTo: {
      id: 'projectsBelongingTo',
      data: projectsBelongingTo!,
    },
    projectsNotBelongingTo: {
      id: 'projectsNotBelongingTo',
      data: projectsNotBelongingTo!,
    },
  };

  const getContactProjects = async (
    currentProjects: boolean,
    search: ProjectSearchProps,
  ): Promise<CompanyUserContactProjectsDto> => {
    const api = getApi(CompanyContactsApi);
    const res = await api.companyContactsControllerGetContactProjects(
      context.env,
      context.companyId,
      context.contactId,
      currentProjects,
      search.page,
      search.perPage,
      search.query,
    );

    return res.data;
  };

  const getProjectsNotBelongingTo = async (search: ProjectSearchProps) => {
    try {
      const data = await getContactProjects(false, search);
      if (data) {
        setProjectsNotBelongingTo(data);
      }
    } catch (e: any) {
      alert.error(e);
    } finally {
      setLoading(false);
      setIsInit(true);
    }
  };

  const getProjectsBelongingTo = async (search: ProjectSearchProps) => {
    try {
      const data = await getContactProjects(true, search);
      if (data) {
        setProjectsBelongingTo(data);
      }
    } catch (e: any) {
      alert.error(e);
    } finally {
      setLoading(false);
      setIsInit(true);
    }
  };

  const cards = [
    {
      id: 'ProjectsBelongingTo',
      search: getProjectsBelongingTo,
    },
    {
      id: 'ProjectsNotBelongingTo',
      search: getProjectsNotBelongingTo,
    },
  ];

  return (
    <ProjectsTabContext.Provider value={{ dataSources }}>
      {cards.map((card, idx) => (
        <Box marginBottom="lg" key={idx} data-qa={`card-${card.id}`}>
          {isInit && !isLoading && (
            <Card>
              <Box>
                {context.powerUser && (
                  <Box marginBottom="md">
                    <MessageBanner
                      banner_type="info"
                      banner_title={config.translate('POWER_USER_TITLE', {
                        NAME: `${contact?.first_name} ${contact?.last_name}`,
                      })}
                      banner_message={config.translate('POWER_USER_MESSAGE')}
                    />
                  </Box>
                )}
                <ProjectList
                  key={`card-${idx}`}
                  id={card.id}
                  search={card.search}
                />
              </Box>
            </Card>
          )}
        </Box>
      ))}
    </ProjectsTabContext.Provider>
  );
};
export default ProjectsTab;
