import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Card,
  Flex,
  Form,
  H2,
  Page,
  Spinner,
  Title,
} from '@procore/core-react';
import * as yup from 'yup';
import type { TableHeader } from 'components/TableBackend/table.interface';
import { PaymentsBusinessEntityContext } from 'contexts/PaymentsBusinessEntityContext';
import { PaymentsBusinessEntityGeneralTabContext } from '.';
import TableBackendComponent from 'components/TableBackend';
import ConfirmModal from 'components/ConfirmModal';
import type { FundingAccountDto } from '@procore/ipa-nt-api-client-ts';
import { Pencil } from '@procore/core-icons';
import DataEditModal from 'components/DataEditModal';

export interface IAddFundingAccount {
  vendorAccountId: string;
  reason: string;
}

export interface DemandDepositAccountsCardProps {
  id: string;
  isCardLoading: boolean;
  pageSize: number;
  addFundingAccount: (values: IAddFundingAccount) => void;
  editFundingAccount: (id: string, values: IAddFundingAccount) => void;
}

export const DemandDepositAccountsCard = (
  props: DemandDepositAccountsCardProps,
) => {
  const context = useContext(PaymentsBusinessEntityContext);
  const config = context.pageConfig.card('DemandDepositAccounts');
  const t = config.translate;
  const { t: tCommon } = useTranslation(['common']);
  const [showAddAccountModal, setShowAddAccountModal] = useState(false);
  const [showEditAccountModal, setShowEditAccountModal] = useState(false);
  const [selectedFundingAccount, setSelectedFundingAccount] =
    useState<FundingAccountDto>({} as FundingAccountDto);

  const {
    dataSources: { fundingAccounts },
  } = useContext(PaymentsBusinessEntityGeneralTabContext);

  const accountSettingsMap = [
    {
      display_name: config.translate('NICKNAME'),
      name: 'entityName',
    },
    {
      display_name: config.translate('ACCOUNT_NUMBER'),
      name: 'lastFour',
    },
    {
      display_name: config.translate('FUNDING_ID'),
      name: 'vendorAccountId',
    },
  ];

  const tableHeaders: TableHeader[] = [
    {
      title: t('PARTY_NAME'),
      node: 'entityName',
      column_width: 45,
      display_func: (item: FundingAccountDto) => {
        return <div>{`${item.entityName} (...${item.lastFour})`}</div>;
      },
    },
    {
      title: t('FUNDING_ID'),
      node: 'vendorAccountId',
      is_text: true,
      column_width: 50,
    },
    {
      title: '',
      node: '',
      column_width: 5,
      display_func: (item: FundingAccountDto) => {
        return (
          <Button
            variant="secondary"
            icon={<Pencil />}
            size="sm"
            disabled={!context.hasPermission(config.permissions.edit ?? [])}
            data-qa={`edit-account-${item.id}`}
            onClick={() => {
              setSelectedFundingAccount(item);
              setShowEditAccountModal(true);
            }}
          />
        );
      },
    },
  ];

  return (
    <Box marginBottom="lg">
      <Card>
        {props.isCardLoading ? (
          <Flex justifyContent="center" padding="lg">
            <Spinner loading={props.isCardLoading} />
          </Flex>
        ) : (
          <Box padding="lg">
            {fundingAccounts.data && (
              <React.Fragment>
                <Title>
                  <Title.Text>
                    <H2>{t('TITLE')}</H2>
                  </Title.Text>
                  <Title.Actions>
                    {context.hasPermission(config.permissions?.edit ?? []) && (
                      <Button
                        variant="primary"
                        onClick={() => setShowAddAccountModal(true)}
                        data-qa={'add-account-btn'}
                      >
                        {t('ADD_ACCOUNT')}
                      </Button>
                    )}
                  </Title.Actions>
                </Title>

                <TableBackendComponent
                  data={fundingAccounts.data.items}
                  data_total={fundingAccounts.data.total}
                  current_page={fundingAccounts.data.page}
                  page_size={props.pageSize}
                  headers={tableHeaders}
                  paginator={() => {}}
                  sorter={() => {}}
                  no_data_message={tCommon('NO_DATA')}
                  show_no_data_message={true}
                  default_sort_column=""
                  default_sort_direction=""
                  fixed_table={true}
                />
              </React.Fragment>
            )}
          </Box>
        )}
        <ConfirmModal
          isOpen={showAddAccountModal}
          modalTitle={t('ADD_ACCOUNT_MODAL_TITLE')}
          modalCopy={t('ADD_ACCOUNT_MODAL_COPY')}
          closeModal={() => setShowAddAccountModal(false)}
          confirmModal={(values: IAddFundingAccount) =>
            props.addFundingAccount(values)
          }
          includeReason={true}
          validationSchema={{
            vendorAccountId: yup.string().required(t('REQUIRED')),
          }}
        >
          <Form.Text
            name="vendorAccountId"
            label={t('FUNDING_ID')}
            data-qa="funding-id-input"
            required
          />
        </ConfirmModal>
        <DataEditModal
          data={selectedFundingAccount}
          show={showEditAccountModal}
          formView={'update'}
          dataMap={accountSettingsMap}
          close={() => setShowEditAccountModal(false)}
          save={(values) => {
            props.editFundingAccount(selectedFundingAccount?.id, values);
          }}
          editReason={tCommon('PROVIDE_REASON')}
          changesTitle={t('EDIT_ACCOUNT_CHANGES_TITLE', {
            entityName: selectedFundingAccount?.entityName,
            lastFour: selectedFundingAccount?.lastFour,
          })}
          editTitle={t('EDIT_ACCOUNT_TITLE')}
        >
          <Page.Grid>
            <Page.Row>
              <Page.Column colWidth={{ tabletMd: 5, tabletSm: 11 }}>
                <Form.Text name="entityName" label={t('NICKNAME')} disabled />
              </Page.Column>
              <Page.Column colWidth={{ tabletMd: 5, tabletSm: 11 }}>
                <Form.Text
                  name="lastFour"
                  label={t('ACCOUNT_NUMBER')}
                  disabled
                />
              </Page.Column>
              <Page.Column colWidth={{ tabletSm: 11 }}>
                <Form.Text
                  name="vendorAccountId"
                  label={t('FUNDING_ID')}
                  data-qa="funding-id-input"
                />
              </Page.Column>
            </Page.Row>
          </Page.Grid>
        </DataEditModal>
      </Card>
    </Box>
  );
};
export default DemandDepositAccountsCard;
