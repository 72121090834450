import type { PageConfig } from 'pages/page-config.interfaces';

const pageConfig: PageConfig = {
  label: 'TITLE',
  route: {
    page: 'drawing_imports',
  },
  i18n: {
    namespace: 'super-area',
    keyPrefix: 'DRAWING_IMPORTS',
  },
  endpoints: {
    view: [],
  },
  cards: {},
};

export default pageConfig;
