import React, { useContext } from 'react';
import { Form } from '@procore/core-react';
import { ContactContext } from 'contexts/ContactContext';
import { EditableCard } from 'components/EditableCard';
import type { IEditableCard } from 'components/EditableCard';
import type { ProjectSettingsProps } from './index';

const DefaultEmailNotificationsCard = (props: ProjectSettingsProps) => {
  const { pageConfig } = useContext(ContactContext);
  const config = pageConfig.card(props.id);

  const card: IEditableCard = {
    id: props.id,
    i18n: config.translate,
    label: config.label,
    canEdit: false,
    save: () => {},
    fieldsPerRow: 2,
    fields: Array.isArray(props.data)
      ? props.data?.map((item, idx) => {
          return {
            source: props.id,
            value: item.checked,
            name: item.name,
            label: item.name,
            dataType: 'string',
            element: Form.Checkbox,
            editable: false,
          };
        })
      : [],
  };

  return <EditableCard card={card} modalWidth="lg" />;
};

export default DefaultEmailNotificationsCard;
