import styled from 'styled-components';

export const ModalWrapper = styled.div`
  width: 500px;
`;

export const styles = {
  icon: {
    marginRight: '10px',
  },
};
