import React, { useState, useEffect, useRef, useContext } from 'react';
import { Card, Search, Grid, Link, Box } from '@procore/core-react';
import { CaretDown, ExternalLink } from '@procore/core-icons';
import { NavButton, Caret, Lookup, Info } from './styles';
import { useTranslation } from 'react-i18next';
import { CompanyInfoApi, ProjectsApi } from '@procore/ipa-nt-api-client-ts';
import { getApi } from 'utils/api';
import { getCompanyZone, getZoneConfig } from 'utils/utils';
import { AppContext } from 'contexts/AppContext';
import JitWidget from 'components/JitWidget';
import { getBaseUrl } from 'utils/utils';

interface ICompanyInfo {
  id: number;
  name: string;
  is_pin_required_for_jit?: boolean;
}

interface IProjectInfo {
  id: number;
  name: string;
  company: ICompanyInfo;
}

export const ProjectLookupTool = () => {
  const [panelOpen, setPanelOpen] = useState(false);
  const [companyInfo, setCompanyInfo] = useState<ICompanyInfo>(
    {} as ICompanyInfo,
  );
  const [projectInfo, setProjectInfo] = useState<IProjectInfo>(
    {} as IProjectInfo,
  );
  const [lookupError, setLookupError] = useState<string>('');
  const [lookupUrl, setLookupUrl] = useState<string>('');
  const ref = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const { env } = useContext(AppContext);

  const toggleMenu = () => {
    setPanelOpen(!panelOpen);
  };
  const { t } = useTranslation('header');

  const lookupProjectById = async (projectId: number) => {
    /*
      Use us01/us02 zone company ids based on project id's zone numerical range
      In the future we may need to query all zones to find which zone a project is in
    */
    const companyId =
      getZoneConfig(getCompanyZone(+projectId))?.company_id || +projectId;
    const response = await getApi(ProjectsApi).projectControllerGetProjectInfo(
      'PROD',
      companyId,
      +projectId,
    );

    return response.data;
  };

  const getCompanyStatus = async (companyId: number) => {
    const response = await getApi(
      CompanyInfoApi,
    ).companyCompanyInfoControllerGetCompanyAccountStatus('PROD', companyId);
    return response.data;
  };

  const processUrl = async (value: string) => {
    setLookupError('');
    const regex1 = /companies\/(\d+)\/projects\/(\d+)/;
    const regex2 = /(\d+)\/(project|company)/;
    const regex3 = /(\d+)/;
    if (regex1.test(value)) {
      const matches = value.match(regex1);
      try {
        if (matches) {
          const companyId = +matches[1];
          const projectId = +matches[2];
          const project = await lookupProjectById(projectId);
          const company = await getCompanyStatus(companyId);
          setCompanyInfo(company);
          setProjectInfo(project);
          setLookupUrl(value);
        }
      } catch (error: any) {
        showError(error.message);
      }
    } else if (regex2.test(value)) {
      const matches = value.match(regex2);
      try {
        if (matches) {
          const entityId = +matches[1];
          if (matches[2] === 'company') {
            const company = await getCompanyStatus(+entityId);

            setProjectInfo({} as IProjectInfo);
            setCompanyInfo(company);
          } else if (matches[2] === 'project') {
            const project = await lookupProjectById(entityId);
            const company = await getCompanyStatus(project.company.id);
            setCompanyInfo(company);
            setProjectInfo(project);
            setLookupUrl(value);
          }
        }
      } catch (error: any) {
        showError(error.message);
      }
    } else if (regex3.test(value)) {
      const matches = value.match(regex3);
      try {
        if (matches) {
          const projectId = +matches[1];
          const project = await lookupProjectById(projectId);
          const company = await getCompanyStatus(project.company.id);
          setCompanyInfo(company);
          setProjectInfo(project);
          setLookupUrl(value);
        }
      } catch (error: any) {
        showError(error.message);
      }
    } else {
      showError(t('PROJECT_LOOKUP_ERROR'));
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      ref.current &&
      !ref.current.contains(event.target as Node) &&
      !document.querySelector('[data-qa="escalate-modal"]') &&
      !document.querySelector('[data-qa="confirm-modal"]')
    ) {
      setPanelOpen(false);
    }
  };

  const showError = (errorMessage: string) => {
    setProjectInfo({} as IProjectInfo);
    setCompanyInfo({} as ICompanyInfo);
    setLookupError(errorMessage);
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
  }, []);

  useEffect(() => {
    if (!panelOpen) {
      setLookupError('');
    } else if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [panelOpen]);

  const ProjectInfo = () => (
    <Info>
      <Grid>
        <Grid.Row>
          <Grid.Col colWidth={4}>
            <strong>{t('PROJECT_LOOKUP_PROJECT_ID')}:</strong>
          </Grid.Col>
          <Grid.Col colWidth={8} data-qa="project-lookup-project-id">
            {projectInfo.id}
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col colWidth={4}>
            <strong>{t('PROJECT_LOOKUP_PROJECT_NAME')}:</strong>
          </Grid.Col>
          <Grid.Col colWidth={8} data-qa="project-lookup-project-name">
            <Link
              href={`/v2/company/${projectInfo.company.id}/project/${projectInfo.id}/project-info`}
              target="_blank"
            >
              {projectInfo.name} <ExternalLink size="sm" />
            </Link>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col colWidth={4}>
            <strong>{t('PROJECT_LOOKUP_COMPANY_ID')}:</strong>
          </Grid.Col>
          <Grid.Col colWidth={8}>{projectInfo.company.id}</Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col colWidth={4}>
            <strong>{t('PROJECT_LOOKUP_COMPANY_NAME')}:</strong>
          </Grid.Col>
          <Grid.Col colWidth={8}>
            <Link
              href={`/v2/company/${projectInfo.company.id}`}
              target="_blank"
            >
              {projectInfo.company.name} <ExternalLink size="sm" />
            </Link>
          </Grid.Col>
        </Grid.Row>
      </Grid>
    </Info>
  );

  const CompanyInfo = () => (
    <Info>
      <Grid>
        <Grid.Row>
          <Grid.Col colWidth={4}>
            <strong>{t('PROJECT_LOOKUP_COMPANY_ID')}:</strong>
          </Grid.Col>
          <Grid.Col colWidth={8}>{companyInfo.id}</Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col colWidth={4}>
            <strong>{t('PROJECT_LOOKUP_COMPANY_NAME')}:</strong>
          </Grid.Col>
          <Grid.Col colWidth={8}>
            <Link href={`/v2/company/${companyInfo.id}`} target="_blank">
              {companyInfo.name} <ExternalLink size="sm" />
            </Link>
          </Grid.Col>
        </Grid.Row>
      </Grid>
    </Info>
  );

  return (
    <div style={{ position: 'relative' }} ref={ref}>
      <NavButton onClick={toggleMenu} data-qa="ipa-nav-project-lookup">
        <span>{t('PROJECT_LOOKUP')}</span>
        <Caret animate={panelOpen}>
          <CaretDown />
        </Caret>
      </NavButton>
      <Lookup show={panelOpen}>
        <Card shadowStrength={2} className="lookup-wrapper">
          <Search
            placeholder={t('PROJECT_LOOKUP_PLACEHOLDER')}
            onSubmit={processUrl}
            autoFocus={true}
            onFocus={(e) => e.target.select()}
            ref={inputRef}
          />
          {lookupError !== '' && (
            <p className="error" data-qa="lookup-error">
              {lookupError}
            </p>
          )}
          {!!projectInfo?.id && <ProjectInfo />}
          {!!companyInfo?.id && !projectInfo.id && <CompanyInfo />}
          {!!companyInfo?.id && (
            <Box marginTop="md">
              <JitWidget
                env={env}
                companyId={companyInfo.id}
                companyName={companyInfo.name}
                isPinRequired={companyInfo.is_pin_required_for_jit}
                redirectUrl={lookupUrl}
              />
            </Box>
          )}
        </Card>
      </Lookup>
    </div>
  );
};

export default ProjectLookupTool;
