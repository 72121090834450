import React, { useState, createContext } from 'react';
import type { ISelectOption } from 'interfaces/common.interface';
import { ZONE_OPTIONS } from 'common/constants';

interface ContextProps extends ContextConsumerProps {
  children: JSX.Element;
}

interface ContextConsumerProps {
  children: any;
}

export interface InsightsContextProps {
  zone: ISelectOption;
  updateZone: (zone: ISelectOption) => void;
}

export const InsightsContext = createContext({} as InsightsContextProps);

export const InsightsContextProvider = (props: ContextProps) => {
  const [zone, setZone] = useState<ISelectOption>(ZONE_OPTIONS[0]);

  const updateZone = (zone: ISelectOption) => {
    setZone(zone);
  };

  return (
    <InsightsContext.Provider value={{ zone, updateZone }}>
      {props.children}
    </InsightsContext.Provider>
  );
};

export default InsightsContextProvider;
