import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

interface CountdownProps {
  time_left: number; // seconds
  expired_msg: string;
}

export const Countdown = (props: CountdownProps) => {
  const { t } = useTranslation(['countdown']);
  const [seconds, setSeconds] = useState<number>(0);
  const [expiredMsg, setExpiredMsg] = useState<string>(t('EXPIRED'));
  const [countdownDisplay, setCountdownDisplay] = useState<string>(
    t('CALCULATING'),
  );

  const calculateTimeLeft = () => {
    if (seconds > 0) {
      const mins = Math.floor(seconds / 60);
      const secs = seconds - mins * 60;
      setCountdownDisplay(() => {
        let str = '';
        if (mins) {
          str += t('COUNTDOWN_MINUTES', { value: mins }) + ', ';
        }
        str += t('COUNTDOWN_SECONDS', { value: secs });
        return str;
      });
    } else {
      setCountdownDisplay(expiredMsg);
    }
  };

  useEffect(() => {
    if (props.time_left) {
      setSeconds(props.time_left);
    }
    if (props.expired_msg && props.expired_msg !== '') {
      setExpiredMsg(props.expired_msg);
    }
  }, [props]);

  useEffect(() => {
    const timer =
      seconds >= 0 &&
      setTimeout(() => {
        setSeconds((seconds) => seconds - 1);
        calculateTimeLeft();
      }, 1000);
    return () => {
      timer && clearTimeout(timer);
    };
  }, [seconds]);

  return <span>{countdownDisplay}</span>;
};

export default Countdown;
