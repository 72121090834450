import type { PageConfig } from 'pages/page-config.interfaces';

const pageConfig: PageConfig = {
  label: 'TITLE',
  route: {
    page: 'daily-log-settings',
    v1_path: '/daily-log-settings/company/:company_id/project/:project_id',
  },
  i18n: {
    keyPrefix: 'PROJECT_DAILY_LOG_SETTINGS',
  },
  endpoints: {
    view: ['projectControllerGetProjectDailyLogSettings'],
  },
  cards: {
    DailyLogSettings: {
      label: 'TITLE',
      fields: {},
    },
  },
};

export default pageConfig;
