import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AppContext } from 'contexts/AppContext';
import { can } from 'utils/permissions.helper';
import { HomepageContext } from './HomepageContext';
import DraggableModule from './DraggableModule';

const WidgetSelection = () => {
  const { t } = useTranslation('homepage', { keyPrefix: 'MODULES' });
  const { userConfig } = useContext(AppContext);
  const { widgets, usedWidgets } = useContext(HomepageContext);

  const isWidgetUsed = (name: string) => {
    return usedWidgets.indexOf(name) > -1;
  };

  return (
    <React.Fragment>
      {widgets.map((w) => {
        return (
          can({ user: userConfig }, w.permissions) && (
            <DraggableModule
              icon={w.icon}
              id={w.id}
              name={w.name}
              description={t(w.description)}
              isUsed={isWidgetUsed(w.id)}
              key={w.id}
            />
          )
        );
      })}
    </React.Fragment>
  );
};

export default WidgetSelection;
