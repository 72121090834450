import React, { useContext, useEffect, useState } from 'react';
import { Panel, Menu, Tooltip, Button, Page, Link } from '@procore/core-react';
import { useTranslation } from 'react-i18next';
import { ArrowLeft } from '@procore/core-icons';
import { AsideWrapper } from './styles';
import { ContactContext } from 'contexts/ContactContext';
import { getContextQueryParams } from './urlGenerator';

export const AsidePanel = () => {
  const { t } = useTranslation(['contact'], {
    keyPrefix: 'SIDE_PANEL',
  });
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [menuSearchTerm, setMenuSearchTerm] = useState<string>('');

  const context = useContext(ContactContext);

  useEffect(() => {
    setMenuSearchTerm('');
  }, [context]);

  const contextQueryParams = getContextQueryParams(context);

  const pageMenu = [
    {
      url: `/v2/company/${context.companyId}/project-management?${contextQueryParams}`,
      title: t('PROJECT_MANAGEMENT_LINK'),
      qa: 'project-management',
    },
    {
      url: `/v2/company/${context.companyId}/?${contextQueryParams}`,
      title: t('COMPANY_SETTINGS_LINK'),
      qa: 'company-settings',
    },
    {
      url: `/v2/company/${context.companyId}/sso-settings?${contextQueryParams}`,
      title: t('COMPANY_SSO_SETTINGS_LINK'),
      qa: 'company-sso-settings',
    },
    {
      url: `/v2/company/${context.companyId}/change-history?${contextQueryParams}`,
      title: t('CHANGE_HISTORY_LINK'),
      qa: 'change-history',
    },
    {
      url: `/v2/company/${context.companyId}/pdf-template-config?${contextQueryParams}`,
      title: t('PDF_TEMPLATE_LINK'),
      qa: 'pdf-templates',
    },
    {
      url: `/v2/company/${context.companyId}/contact/${context.contactId}/login/${context.loginId}/cost-codes?env=${context.env}`,
      title: t('COMPANY_COST_CODES_LINK'),
      qa: 'company-cost-codes',
    },
    {
      url: `/v2/company/${context.companyId}/field-sets?${contextQueryParams}`,
      title: t('FIELDSETS_LINK'),
      qa: 'fieldsets',
    },
    {
      url: `/v2/company/${context.companyId}/contact/${context.contactId}/login/${context.loginId}/project-permission-templates?env=${context.env}`,
      title: t('PROJECT_PERMISSION_TEMPLATES_LINK'),
      qa: 'project-permission-templates',
    },
    {
      url: `/v2/company/${context.companyId}/contact/${context.contactId}/login/${context.loginId}/outbound-emails-report?env=${context.env}`,
      title: t('OUTBOUND_EMAILS_REPORT_LINK'),
      qa: 'outbound-emails-report',
    },
    {
      url: `/v2/company/${context.companyId}/correspondence-types?${contextQueryParams}`,
      title: t('CORRESPONDENCE_TYPES_LINK'),
      qa: 'correspondence-types',
    },
    context.hasPermission(['cs_view']) && {
      url: `/v2/company-directory/${context.companyId}?${contextQueryParams}`,
      title: t('COMPANY_DIRECTORY_LINK'),
      qa: 'company-directory',
    },
  ].filter((opt) => {
    return opt.title.toLowerCase().includes(menuSearchTerm.toLowerCase());
  });

  return (
    <AsideWrapper className={sidebarOpen ? '' : 'closed'}>
      <Page.Aside open={true}>
        {!sidebarOpen && (
          <Tooltip overlay={t('OPEN_SIDEBAR_TOOLTIP')} trigger="hover">
            <Button
              variant="tertiary"
              icon={<ArrowLeft />}
              onClick={() => setSidebarOpen(true)}
              className="open"
              data-qa="open-sidebar"
            ></Button>
          </Tooltip>
        )}
        <Panel>
          <Panel.Header
            onClose={() => setSidebarOpen(false)}
            data-qa="panel-header"
          >
            <Panel.Title>{t('MORE_SETTINGS')}</Panel.Title>
          </Panel.Header>
          {context.companyId && (
            <Panel.Body>
              <Menu onSearch={(e) => setMenuSearchTerm(e.target.value ?? '')}>
                <Menu.Search value={menuSearchTerm} />
                <Menu.Options>
                  {pageMenu.map((item, idx) => (
                    <Menu.Item
                      key={idx}
                      autoFocus={false}
                      nonce={idx.toString()}
                    >
                      <Link href={item.url} data-qa={item.qa}>
                        {item.title}
                      </Link>
                    </Menu.Item>
                  ))}
                </Menu.Options>
              </Menu>
            </Panel.Body>
          )}
        </Panel>
      </Page.Aside>
    </AsideWrapper>
  );
};

export default AsidePanel;
