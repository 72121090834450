import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { getApi } from 'utils/api';
import { JITApi } from '@procore/ipa-nt-api-client-ts';
import type { JitSessionDto } from '@procore/ipa-nt-api-client-ts';
import ConfirmModal from 'components/ConfirmModal';

interface JitDeescalateModalProps {
  session: JitSessionDto | null;
  isOpen: boolean;
  closeModal(): void;
  setParentErrorMessage?: (message: string) => void;
  doneCallback?: (success: boolean, session: JitSessionDto) => void;
}

export const JitDeescalateModal = (props: JitDeescalateModalProps) => {
  const { t } = useTranslation(['jit']);

  const deescalateConfirm = useCallback(async () => {
    if (!props.session) {
      return;
    }

    try {
      await getApi(JITApi).jitControllerTerminateSession(props.session.id);
      props.doneCallback && props.doneCallback(true, props.session);
    } catch (error: any) {
      const msg = t('DEESCALATE_ERROR', {
        ns: 'jit',
        reason: error.response?.data?.message || error.message,
      });
      props.setParentErrorMessage && props.setParentErrorMessage(msg);
      props.doneCallback && props.doneCallback(false, props.session);
      return null;
    }
  }, [props.session]);

  const questionVars = {
    env: props.session?.env,
    userName: props.session?.userEmail,
    companyName: props.session?.companyName,
    accessLevel: props.session?.accessLevel,
  };

  return (
    <div>
      <ConfirmModal
        isOpen={props.isOpen}
        modalTitle={t('CONFIRM_TITLE', { ns: 'jit' })}
        modalCopy={t('DEESCALATE_QUESTION', {
          ns: 'jit',
          ...questionVars,
        })}
        closeModal={props.closeModal}
        confirmModal={deescalateConfirm}
      />
    </div>
  );
};
