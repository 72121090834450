import type { PageConfig } from 'pages/page-config.interfaces';

const globalDirectorySearchPage: PageConfig = {
  label: 'GDS_PAGE_TITLE',
  route: {
    path: '/user',
  },
  i18n: {
    namespace: 'customer-success',
  },
  permissions: {
    view: ['cs_view'],
  },
  endpoints: {
    view: ['searchControllerDoGlobalDirectoryUserSearch'],
  },
  cards: {},
};

export default globalDirectorySearchPage;
