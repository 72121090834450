import React, { useState, useContext, useEffect } from 'react';
import { H1, FlexList, Box, Card, Spinner } from '@procore/core-react';
import { useTranslation } from 'react-i18next';
import { AppContext } from 'contexts/AppContext';
import { InsightsContext } from 'contexts/InsightsContext';
import { IpaAlertContext } from 'contexts/IpaAlertContext';
import ZoneSelector from '../ZoneSelector';
import { InsightsApi } from '@procore/ipa-nt-api-client-ts';
import type { CompaninesInvalidSalesforceAccountDto } from '@procore/ipa-nt-api-client-ts';
import { getApi } from 'utils/api';
import { TableBackendComponent } from 'components/TableBackend';
import type { TableHeader } from 'components/TableBackend/table.interface';

const CompaniesInvalidSalesforceAccountPage = () => {
  const { t } = useTranslation(['insights'], {
    keyPrefix: 'COMPANIES_INVALID_SALESFORCE_ID',
  });
  const { t: tCommon } = useTranslation(['common']);
  const { userSettings, env } = useContext(AppContext);
  const alert = useContext(IpaAlertContext);
  const { zone } = useContext(InsightsContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isInit, setIsInit] = useState<boolean>(false);
  const [
    companiesInvalidSalesforceAccount,
    setCompaniesInvalidSalesforceAccount,
  ] = useState<CompaninesInvalidSalesforceAccountDto[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [perPage] = useState<number>(
    userSettings.default_page_size as number | 25,
  );
  const [total, setTotal] = useState<number>(0);
  const [prevZone, setPrevZone] = useState<string>(zone.label);

  const tableHeaders: TableHeader[] = [
    {
      title: tCommon('NAME'),
      node: 'name',
      is_text: true,
    },
    {
      title: t('SALESFORCE_ACCOUNT_ID'),
      node: 'deprecated_sales_force_account_id',
      is_text: true,
    },
  ];

  const changePage = (page: number) => {
    setCurrentPage(page);
  };

  const getCompaniesInvalidSalesforceAccount = async (
    zone: string,
    currentPage: number,
    perPage: number,
  ) => {
    try {
      const api = getApi(InsightsApi);
      const result =
        await api.insightsControllerGetCompaniesInvalidSalesforceAccount(
          env,
          zone,
          currentPage,
          perPage,
        );
      return result.data;
    } catch (e: any) {
      alert.error(e);
    }
  };

  useEffect(() => {
    if (zone) {
      if (!isInit) {
        setIsLoading(true);
      }
      if (zone.label !== prevZone) {
        setPrevZone(zone.label);
        setIsInit(false);
        setCurrentPage(1);
        setIsLoading(true);
      }

      const nextPage = async () => {
        const data = await getCompaniesInvalidSalesforceAccount(
          zone.label,
          currentPage,
          perPage,
        );

        if (data?.reports) {
          setCompaniesInvalidSalesforceAccount(data.reports);
          setTotal(data.total);
          setIsLoading(false);
          setIsInit(true);
        }
      };
      nextPage();
    }
  }, [zone, currentPage, perPage]);

  return (
    <React.Fragment>
      <FlexList>
        <H1>{t('TITLE')}</H1>
      </FlexList>
      <ZoneSelector />
      <Box marginTop="lg">
        <Card>
          <Box padding="lg">
            {isLoading && (
              <Box>
                <FlexList justifyContent="center">
                  <Spinner loading={isLoading} />
                </FlexList>
              </Box>
            )}
            {isInit && !isLoading && (
              <TableBackendComponent
                headers={tableHeaders}
                data={companiesInvalidSalesforceAccount}
                data_total={total}
                current_page={currentPage}
                paginator={changePage}
                sorter={() => {}}
                default_sort_column=""
                default_sort_direction=""
                show_no_data_message={true}
                no_data_message={tCommon('NO_DATA')}
              />
            )}
          </Box>
        </Card>
      </Box>
    </React.Fragment>
  );
};

export default CompaniesInvalidSalesforceAccountPage;
