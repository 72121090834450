import React, { useContext } from 'react';
import { EditableCard } from 'components/EditableCard';
import type { IEditableCard } from 'components/EditableCard';
import { Box, Card, Flex, Form, Spinner } from '@procore/core-react';
import { PaymentsBusinessEntityContext } from 'contexts/PaymentsBusinessEntityContext';
import { PaymentsBusinessEntityGeneralTabContext } from '.';
import type { ISelectOption } from 'interfaces/common.interface';
import type { PillOption } from '@procore/core-react/dist/PillSelect/PillSelect.types';

export interface GeneralInformationCardProps {
  id: string;
  isCardLoading: boolean;
  save: IEditableCard['save'];
}

export const GeneralInformationCard = (props: GeneralInformationCardProps) => {
  const context = useContext(PaymentsBusinessEntityContext);
  const config = context.pageConfig.card('GeneralInformation');
  const t = config.translate;

  const entityTypeOptions: ISelectOption[] = [
    { id: 'send_payments', label: t('SEND') },
    { id: 'receive_payments', label: t('RECEIVE') },
  ];

  const entityStatusOptions: PillOption[] = [
    { id: 'active', label: t('ACTIVE'), color: 'green' },
    { id: 'inactive', label: t('INACTIVE'), color: 'gray' },
  ];

  const mtlStatusOptions: ISelectOption[] = [
    { id: 'enabled', label: t('ENABLED') },
    { id: 'disabled', label: t('DISABLED') },
  ];

  const {
    dataSources: { generalInformation },
  } = useContext(PaymentsBusinessEntityGeneralTabContext);

  const card: IEditableCard = {
    id: props.id,
    i18n: t,
    label: config.translate('GENERAL'),
    canEdit: context.hasPermission(config.permissions.edit ?? []),
    save: props.save,
    fields: [
      {
        source: generalInformation.id,
        value: generalInformation.data?.entityName,
        name: 'entityName',
        element: Form.Text,
        editable: false,
        ...config.fields.entityName,
      },
      {
        source: generalInformation.id,
        value: generalInformation.data?.externalId,
        name: 'externalId',
        element: Form.Text,
        editable: false,
        ...config.fields.externalId,
      },
      {
        source: generalInformation.id,
        // convert the value to the corresponding radio button option
        value: entityTypeOptions.find(
          ({ id }) => id === generalInformation.data?.capabilities,
        ),
        name: 'capabilities',
        element: <React.Fragment />,
        editable: false,
        elementFactory: (field) => (
          <Form.RadioButtons
            name={field.name}
            label={field.label}
            options={entityTypeOptions}
            disabled
          />
        ),
        ...config.fields.capabilities,
      },
      {
        source: generalInformation.id,
        // convert the value to the corresponding pill option
        value: generalInformation.data?.active
          ? entityStatusOptions.find(({ id }) => id === 'active')
          : entityStatusOptions.find(({ id }) => id === 'inactive'),
        name: 'active',
        element: <React.Fragment />,
        elementFactory: (field) => (
          <Form.PillSelect
            name={field.name}
            label={field.label}
            options={entityStatusOptions}
            data-qa="input-entityStatus"
          />
        ),
        ...config.fields.active,
      },
      {
        source: generalInformation.id,
        // convert the value to the corresponding option
        value: generalInformation.data?.mtlEnabled
          ? mtlStatusOptions.find(({ id }) => id === 'enabled')
          : mtlStatusOptions.find(({ id }) => id === 'disabled'),
        name: 'mtlEnabled',
        element: <React.Fragment />,
        elementFactory: (field) => (
          <Form.Select
            name={field.name}
            label={field.label}
            options={mtlStatusOptions}
            data-qa="input-mtlStatus"
          />
        ),
        ...config.fields.mtlEnabled,
      },
    ],
  };

  return (
    <Box marginBottom="lg">
      <Card>
        {props.isCardLoading ? (
          <Flex justifyContent="center" padding="lg">
            <Spinner loading={props.isCardLoading} />
          </Flex>
        ) : (
          <Box padding="lg">
            <EditableCard card={card} />
          </Box>
        )}
      </Card>
    </Box>
  );
};

export default GeneralInformationCard;
