import type { PageConfig } from 'pages/page-config.interfaces';

const pageConfig: PageConfig = {
  label: 'TITLE',
  i18n: {
    keyPrefix: 'PROJECT_TASK_ITEMS_SETTINGS',
  },
  route: {
    page: 'task-items-settings',
    v1_path: '/task-items-settings/company/:company_id/project/:project_id',
  },
  endpoints: {
    view: ['projectControllerGetProjectTaskItemsSettings'],
  },
  cards: {
    TaskItemsSettings: {
      label: 'TITLE',
      fields: {
        private_by_default: {
          label: 'PRIVATE_BY_DEFAULT',
        },
        days_due_default: {
          label: 'TASK_ITEMS_DUE',
        },
      },
    },
  },
};

export default pageConfig;
