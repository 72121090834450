import type { PageConfig } from 'pages/page-config.interfaces';

export default <PageConfig>{
  label: 'PENDING_PAYMENTS_ADMINS',
  endpoints: {
    view: ['payment_operations'],
    edit: ['approve_payments_admins'],
  },
  cards: {},
};
