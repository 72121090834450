import type { PageConfig } from 'pages/page-config.interfaces';

const IntegratorSandboxConfig: PageConfig = {
  route: {
    path: '/integrator-sandbox-config',
  },
  label: 'TITLE',
  i18n: {
    keyPrefix: 'INTEGRATOR_SANDBOX_NUMBER',
  },
  cards: {},
};

export default IntegratorSandboxConfig;
