import { colors, spacing } from '@procore/core-react';

export const styles = {
  warningIcon: {
    verticalAlign: 'top',
    color: colors.yellow40,
    marginLeft: '1px',
  },
  warningBackground: {
    width: '18px',
    height: '20px',
    background: colors.yellow90,
    border: `1.5px solid ${colors.yellow60}`,
    borderRadius: spacing.xl,
    marginRight: spacing.xxs,
  },
  cardBanner: {
    marginBottom: spacing.xl,
  },
};
