import React, { useState, useContext } from 'react';
import {
  Box,
  Button, Flex,
  Form,
  H2,
  Page, Spinner,
} from '@procore/core-react';
import { IpaAlertContext } from 'contexts/IpaAlertContext';
import { PageConfig } from 'pages/page-config.service';
import { useTranslation } from 'react-i18next';
import { entitlementsData } from './constants';
import {
  getDevPortalAddFeaturesCallResult,
  onChangeMultiSelect,
} from './utils/utils';
import type { EntitlementsType } from './utils/utils';


interface Props {
  initialFieldsVisibility: string[];
}

interface BasicFeature {
  feature_id: string;
}

interface ExtendedFeature extends BasicFeature {
  value?: number | boolean | string;
}

export type Feature = BasicFeature | ExtendedFeature;

type FormValues = {
  'company-id-field': string;
  'feature-ids': BasicFeature[];
  'enable-private-correspondence-types-custom-tools': boolean;
  'correspondence-types-limit': number;
  'exclude-bidding-stage-projects-checkbox': boolean;
  'contract-volume-field': number;
  'max-active-projects-field': number;
  'contract-volume-currency-field': string;
};

export const IntegratorSandboxEntitlementsPage: React.FC<Props> = ({
                                                                     initialFieldsVisibility,
                                                                   }) => {
  const { t: tCommon } = useTranslation(['common']);
  const alert = useContext(IpaAlertContext);
  const pageConfig = PageConfig.get(
    tCommon,
    'DevPortal.IntegratorSandboxConfig',
  );
  const [optionalFieldsVisibility, setOptionalFieldsVisibility] = useState<
    string[]
  >(initialFieldsVisibility);
  const [companyId, setCompanyId] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [value, setValue] = useState<EntitlementsType[]>([]);

  const makeDevPortalAddFeaturesCall = async (values: FormValues) => {
    alert.closeAlert();
    setIsLoading(true);

    const features: Feature[] = value.map((item) => ({
      feature_id: item.label,
    }));
    features.forEach((feature, index) => {
      switch (feature.feature_id) {
        case 'procore_enable_private_correspondence_types_and_custom_tools':
          features[index] = {
            ...feature,
            value:
              !!values['enable-private-correspondence-types-custom-tools'],
          };
          break;
        case 'procore_correspondence_types_limit':
          features[index] = {
            ...feature,
            value: values['correspondence-types-limit'],
          };
          break;
        case 'procore_exclude_bidding_stage_projects':
          features[index] = {
            ...feature,
            value: !!values['exclude-bidding-stage-projects-checkbox'],
          };
          break;
        case 'procore_max_active_projects':
          features[index] = {
            ...feature,
            value: values['max-active-projects-field'],
          };
          break;
        case 'procore_annual_contract_volume_currency':
          features[index] = {
            ...feature,
            value: values['contract-volume-currency-field'],
          };
          break;
        case 'procore_annual_contract_volume':
          features[index] = {
            ...feature,
            value: values['contract-volume-field'],
          };
          break;
      }
    });

    try {
      const { status, data } = await getDevPortalAddFeaturesCallResult(
        features,
        companyId,
      );

      if (status === 200) {
        alert.success(`${pageConfig.translate('ENV_INFO.SUCCESS_MESSAGE')} ${JSON.stringify(data)}`);
      } else if (status === 401) {
        alert.error(pageConfig.translate('ENV_INFO.UNAUTHORIZED_MESSAGE'));
      } else {
        alert.error(
          pageConfig.translate('ENV_INFO.UNKNOWN_ERROR_MESSAGE', {data: JSON.stringify(data)}),
        );
      }
    } catch (error: any) {
      alert.error(
        pageConfig.translate('ENV_INFO.ERROR_MESSAGE', {
          error: error.message || error,
        }),
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Page.Body>
      <H2>{pageConfig.translate('TITLE_ENTITLEMENTS')}</H2>
      {isLoading && (
        <Flex justifyContent="center">
          <Spinner loading={isLoading}/>
        </Flex>
      )}
      {!isLoading && (<Box margin="sm">
          <Form
            onSubmit={(values) =>
              makeDevPortalAddFeaturesCall(values)
            }
          >
            <Form.Form name="entitlements-from">
              <Form.Row>
                <Form.Number
                  label={pageConfig.translate('SEARCH.COMPANY_ID')}
                  name="company-id-field"
                  placeholder={pageConfig.translate('SEARCH.COMPANY_ID')}
                  data-qa="company-id-field"
                  value={companyId}
                  onChange={(e) => setCompanyId(e.parsedNumber.toString(10))}
                  required
                  colWidth={3}
                />
              </Form.Row>
              <Form.Row>
                <Form.MultiSelect
                  label={pageConfig.translate('SEARCH.ENTITLEMENTS')}
                  block
                  onChange={(features: EntitlementsType[]) => {
                    onChangeMultiSelect({
                      features,
                      setValue,
                      setOptionalFieldsVisibility,
                    });
                  }}
                  options={entitlementsData}
                  name="feature-ids"
                  data-qa="feature-ids"
                  value={value}
                  colWidth={6}
                />
              </Form.Row>
              {optionalFieldsVisibility.includes(
                'procore_annual_contract_volume',
              ) && (
                <Form.Row>
                  <Form.Number
                    placeholder={pageConfig.translate('CASE_ONE')}
                    label={pageConfig.translate('CASE_ONE')}
                    data-qa="contract-volume-field"
                    name="contract-volume-field"
                    colWidth={6}
                  />
                </Form.Row>
              )}
              {optionalFieldsVisibility.includes(
                'procore_annual_contract_volume_currency',
              ) && (
                <Form.Row>
                  <Form.Text
                    placeholder={pageConfig.translate('CASE_TWO')}
                    label={pageConfig.translate('CASE_TWO')}
                    data-qa="contract-volume-currency-field"
                    name="contract-volume-currency-field"
                    colWidth={6}
                  />
                </Form.Row>
              )}
              {optionalFieldsVisibility.includes(
                'procore_max_active_projects',
              ) && (
                <Form.Row>
                  <Form.Number
                    placeholder={pageConfig.translate('CASE_THREE')}
                    label={pageConfig.translate('CASE_THREE')}
                    data-qa="max-active-projects-field"
                    name="max-active-projects-field"
                    colWidth={6}
                  />
                </Form.Row>
              )}
              {optionalFieldsVisibility.includes(
                'procore_correspondence_types_limit',
              ) && (
                <Form.Row>
                  <Form.Number
                    placeholder={pageConfig.translate('CASE_FIVE')}
                    label={pageConfig.translate('CASE_FIVE')}
                    data-qa="correspondence-types-limit"
                    name="correspondence-types-limit"
                    colWidth={6}
                  />
                </Form.Row>
              )}
              {optionalFieldsVisibility.includes(
                'procore_exclude_bidding_stage_projects',
              ) && (
                <Form.Row>
                  <Form.Checkbox
                    label={pageConfig.translate('CASE_FOUR')}
                    data-qa="exclude-bidding-stage-projects-checkbox"
                    name="exclude-bidding-stage-projects-checkbox"
                  />
                </Form.Row>
              )}
              {optionalFieldsVisibility.includes(
                'procore_enable_private_correspondence_types_and_custom_tools',
              ) && (
                <Form.Row>
                  <Form.Checkbox
                    label={pageConfig.translate('CASE_SIX')}
                    data-qa="enable-private-correspondence-types-custom-tools"
                    name="enable-private-correspondence-types-custom-tools"
                  />
                </Form.Row>
              )}
              <Button type="submit" disabled={!companyId} data-qa="save-btn">
                Save
              </Button>
            </Form.Form>
          </Form>
        </Box>
      )}
    </Page.Body>
  );
};

export default IntegratorSandboxEntitlementsPage;
