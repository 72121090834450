import type { PageConfig } from 'pages/page-config.interfaces';

const ErpSettingsPage: PageConfig = {
  label: 'TAB_ERP-SETTINGS',
  route: {
    page: 'erp-settings',
    v1_path: '#erp_settings',
  },
  i18n: {
    namespace: 'company',
  },
  endpoints: {
    view: [
      'companyContactsControllerGetContact',
      'companyContactsControllerListContacts',
    ],
  },
  cards: {
    ErpSettings: {
      i18n: {
        keyPrefix: 'ERP_SETTINGS',
      },
      label: 'ERP_SETTINGS_TITLE',
      permissions: {
        edit: ['company_tab_edit'],
      },
      fields: {
        can_push_to_accounting: {
          label: 'CAN_PUSH_TO_ACCOUNTING',
        },
        subscribed_to_erp_digest_emails: {
          label: 'SUBSCRIBE_TO_ERP_DIGEST_EMAILS',
        },
      },
    },
  },
};

export default ErpSettingsPage;
