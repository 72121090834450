import type { ProjectManagementContextProps } from './interfaces';

type UrlContext = Pick<
  ProjectManagementContextProps,
  'companyId' | 'env' | 'contactId' | 'loginId'
>;

export const generateUrl = (page: string, context: UrlContext) => {
  let url = `/v2/company/${context.companyId}/project-management/${page}`;
  let params = [`env=${context.env}`];
  if (context.contactId) {
    params.push(`contactId=${context.contactId}`);
  }
  if (context.loginId) {
    params.push(`loginId=${context.loginId}`);
  }
  return `${url}?${params.join('&')}`;
};
