import type { PageConfig } from 'pages/page-config.interfaces';

const SsoSettingsPage: PageConfig = {
  label: 'TAB_SSO-SETTINGS',
  route: {
    page: 'sso-settings',
    v1_path: '#sso_settings',
  },
  i18n: {
    namespace: 'company',
  },
  cards: {
    SsoSettings: {
      i18n: {
        keyPrefix: 'SSO_SETTINGS',
      },
      permissions: {
        edit: ['sso_settings_edit'],
      },
      label: 'TITLE',
      fields: {
        sso_enabled: {
          label: 'SSO_ENABLED',
        },
        sso_use_unique_entity_id: {
          label: 'SSO_USE_UNIQUE_ENTITY_ID',
        },
        sso_issuer_url: {
          label: 'SSO_ISSUER_URL',
        },
        sso_target_url: {
          label: 'SSO_TARGET_URL',
        },
        sso_domain: {
          label: 'SSO_DOMAIN',
        },
        sso_x509_cert: {
          label: 'SSO_X509_CERT',
        },
      },
      endpoints: {
        view: ['companySsoSettingsControllerGetCompanySsoSettings'],
        edit: ['companySsoSettingsControllerUpdateSsoSettings'],
      },
    },
  },
};

export default SsoSettingsPage;
