import type { SectionConfig } from 'pages/page-config.interfaces';
import CompanySearch from './CompanySearch/page.config';
import GlobalDirectorySearch from './CustomerSuccess/page.config';

const searchSection: SectionConfig = {
  id: 'Search',
  route: {
    path: '/search',
  },
  i18n: {
    namespace: 'company',
  },
  label: 'Search',
  pages: {
    CompanySearch,
    GlobalDirectorySearch,
  },
};
export default searchSection;
