import React, { useState, useEffect } from 'react';
import { Table } from '@procore/core-react';
import type { TableHeader } from './table.interface';

interface Item {
  is_expanded: boolean;
  index: number;
}

interface GroupRowProps {
  headers: TableHeader[];
  item: Item;
  row_click: (num: number) => void;
}

export const GroupRow = (props: GroupRowProps) => {
  const [headers, setHeaders] = useState<TableHeader[]>([]);

  const caratStyle = {
    marginRight: '10px',
  };
  const groupRowStyle = {
    cursor: 'pointer',
  };

  useEffect(() => {
    if (props.headers) {
      setHeaders(props.headers);
    }
  }, [props]);

  return (
    <React.Fragment>
      <Table.BodyRow
        style={groupRowStyle}
        onClick={() => props.row_click(props.item.index)}
      >
        {headers.map((header, j) => {
          if (props.item[header.node as keyof Item]) {
            return (
              <Table.BodyCell key={j}>
                <Table.TextCell>
                  {!j ? (
                    <Table.Carat
                      style={caratStyle}
                      expanded={props.item.is_expanded}
                      onClick={() => false}
                    />
                  ) : (
                    ''
                  )}
                  <strong>{props.item[header.node as keyof Item]}</strong>
                </Table.TextCell>
              </Table.BodyCell>
            );
          } else {
            return <Table.BodyCell key={j}></Table.BodyCell>;
          }
        })}
      </Table.BodyRow>
    </React.Fragment>
  );
};

export default GroupRow;
