import styled from 'styled-components';
import { colors } from '@procore/core-react';

export const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  line-height: 1.5;
  position: static !important;
  box-sizing: border-box;
  background: ${colors.gray96};
`;

export const PageWrapper = styled.div`
  flex-grow: 1;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0;
  padding-right: 0;
  width: 100%;
`;
