// ***** PARAMETERS *****
// permissions: array of objects
//

import React, { useState, useEffect } from 'react';
import { colors } from '@procore/core-react';
import { TableComponent } from 'components/Table';
import type { TableHeader } from 'components/TableBackend/table.interface';
import { Check, Ban } from '@procore/core-icons/dist';
import type { ProjectUserPermissionsRowsDto } from '@procore/ipa-nt-api-client-ts';
import { useTranslation } from 'react-i18next';

interface PermissionsGridProps {
  permissions: ProjectUserPermissionsRowsDto[];
}

type Item = {
  label: string;
};

type SubItem = {
  is_expanded?: boolean;
  items?: Item[];
};

interface PermissionsGridItem extends ProjectUserPermissionsRowsDto {
  id?: number;
  subItems?: SubItem;
  permissions?: string;
}

export const PermissionsGrid = (props: PermissionsGridProps) => {
  const { t } = useTranslation('contact', {
    keyPrefix: 'PROJECT_SETTINGS.PROJECT_PERMISSIONS_CARD',
  });
  const [permissionsList, setPermissionsList] = useState([]);

  const redIcon = {
    color: colors.red50,
  };
  const greenIcon = {
    color: colors.green40,
  };
  const displayPermission = (
    item: PermissionsGridItem,
    node: string,
    column: string,
  ) => {
    return item.user_access_level.name === column ? (
      <Check size="sm" style={greenIcon} />
    ) : (
      <Ban size="sm" style={redIcon} />
    );
  };

  const permissionsHeaders: TableHeader[] = [
    {
      title: '',
      node: 'label',
      is_text: true,
      is_sortable: false,
    },
    {
      title: t('NONE'),
      node: 'permissions',
      display_func: (item, node) => {
        return displayPermission(item, node, 'None');
      },
    },
    {
      title: t('READ_ONLY'),
      node: 'permissions',
      display_func: (item, node) => {
        return displayPermission(item, node, 'Read Only');
      },
    },
    {
      title: t('STANDARD'),
      node: 'permissions',
      display_func: (item, node) => {
        return displayPermission(item, node, 'Standard');
      },
    },
    {
      title: t('ADMIN'),
      node: 'permissions',
      display_func: (item, node) => {
        return displayPermission(item, node, 'Admin');
      },
    },
  ];

  const setExpansion = (flag: boolean, rowId: number) => {
    const temp = [...permissionsList];
    temp.forEach((p) => {
      if (p.id === rowId) {
        p.subItems.is_expanded = flag;
      } else {
        p.subItems.is_expanded = false;
      }
    });
    setPermissionsList(temp);
  };

  useEffect(() => {
    if (props.permissions?.length > 0) {
      const filteredPermissions = props.permissions.filter((p) => {
        if (
          !p.label.toLowerCase().includes('generic') &&
          !p.name.toLowerCase().includes('generic')
        ) {
          return p;
        } else {
          return null;
        }
      });

      const perms: PermissionsGridItem[] = filteredPermissions.map(
        (p: PermissionsGridItem) => {
          switch (p.user_access_level.id) {
            case 1:
              p.permissions = 'None';
              break;
            case 2:
              p.permissions = 'Read Only';
              break;
            case 3:
              p.permissions = 'Standard';
              break;
            case 4:
              p.permissions = 'Admin';
          }
          p.id = p.domain_id;
          p.subItems = { items: [], is_expanded: false };
          if (p.granted_actions.length > 0) {
            p.granted_actions.forEach((ga, idx) => {
              p.subItems.items.push({
                label: ga.label,
              });
            });
          }

          return p;
        },
      );
      setPermissionsList(perms);
    }
  }, [props.permissions]);

  return (
    <TableComponent
      data-qa="permissions-grid"
      headers={permissionsHeaders}
      data={permissionsList}
      show_no_data_message={true}
      no_data_message={t('NO_DATA')}
      hide_pagination={true}
      show_page_size_select={true}
      page_size={50}
      default_sort_column="label"
      default_sort_direction="asc"
      show_search={true}
      setExpansion={setExpansion}
      search_columns={['label', 'permissions']}
    />
  );
};

export default PermissionsGrid;
