import type { SectionConfig } from 'pages/page-config.interfaces';

export default <SectionConfig>{
  id: 'ERP Deactivations',
  route: {
    path: '/v2/company/:company_id/erp-deactivations',
  },
  i18n: {
    namespace: 'erp-deactivations',
  },
  pages: {
    ErpDeactivations: {
      i18n: {
        namespace: 'erp-deactivations',
      },
      label: 'ERP_DEACTIVATIONS_TITLE',
      permissions: {
        view: ['erp_deactivations'],
        edit: ['erp_deactivations'],
      },
      endpoints: {
        view: [
          'companyControllerGetCompanyErpConnections',
          'companyCompanyInfoControllerGetCompanyAccountStatus',
          'erpToolsControllerGetErpDeactivations',
        ],
        edit: [
          'erpToolsControllerRetryErpDeactivation',
          'erpToolsControllerCreateErpDeactivation',
        ],
      },
      cards: {
        AllDeactivations: {
          label: 'DEACTIVATIONS_TABLE_TITLE',
          fields: {
            integration_name: {
              label: 'INTEGRATION',
            },
            deactivation_job_id: {
              label: 'DEACTIVATION_JOB_ID',
            },
            created_by: {
              label: 'CREATED_BY',
            },
            created_at: {
              label: 'CREATED_AT',
            },
            retry_count: {
              label: 'RETRY_COUNT',
            },
            status: {
              label: 'STATUS',
            },
          },
        },
      },
    },
  },
};
