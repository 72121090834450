import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Spinner, H2, Box, Card } from '@procore/core-react';
import { AppContext } from 'contexts/AppContext';
import { IpaAlertContext } from 'contexts/IpaAlertContext';
import TableBackendComponent from 'components/TableBackend';
import type { TableHeader } from 'components/TableBackend/table.interface';
import type { DisbursementTabProps } from '../interfaces';
import { getApi } from 'utils/api';
import { PaymentsServiceBusinessEntitiesApi } from '@procore/ipa-nt-api-client-ts';
import type { DisbursementChangeHistoryDto } from '@procore/ipa-nt-api-client-ts';

const ChangeHistoryTab = (props: DisbursementTabProps) => {
  const { t: tCommon } = useTranslation(['common']);
  const { t } = useTranslation(['payments'], {
    keyPrefix: 'DISBURSEMENT.CHANGE_HISTORY_TAB.CHANGE_HISTORY_CARD',
  });
  const { userSettings, env } = useContext(AppContext);
  const alert = useContext(IpaAlertContext);
  const [total, setTotal] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(
    userSettings.default_page_size as number | 25,
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isInit, setIsInit] = useState<boolean>(false);
  const [disbursementChangeHistory, setDisbursementChangeHistory] = useState<
    DisbursementChangeHistoryDto[]
  >([]);

  const tableHeaders: TableHeader[] = [
    {
      title: t('DATE'),
      node: 'createdAt',
      is_datetime: true,
      is_sortable: true,
    },
    {
      title: t('ACTION_BY'),
      node: 'actionBy',
      is_sortable: true,
      is_text: true,
    },
    {
      title: t('CHANGED'),
      node: 'changed',
      is_sortable: true,
      is_text: true,
    },
    {
      title: t('FROM'),
      node: 'oldValue',
      is_sortable: true,
      is_text: true,
    },
    {
      title: t('TO'),
      node: 'newValue',
      is_sortable: true,
      is_text: true,
    },
  ];

  const getDisbursementChangeHistory = async () => {
    alert.closeAlert();
    setIsLoading(true);
    try {
      const api: PaymentsServiceBusinessEntitiesApi = getApi(
        PaymentsServiceBusinessEntitiesApi,
      );
      const result =
        await api.businessEntityControllerGetDisbursementChangeHistory(
          env,
          props.companyId,
          props.disbursementId,
          currentPage,
          perPage,
        );
      return result.data;
    } catch (e: any) {
      alert.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const changePage = (page: number, _pageSize: number) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    if (props.companyId && props.disbursementId) {
      if (!isInit) {
        setIsLoading(true);
      }

      const nextPage = async () => {
        const res = await getDisbursementChangeHistory();

        if (res?.items) {
          setDisbursementChangeHistory(res.items);
          setTotal(res.total);
          setIsLoading(false);
          setIsInit(true);
        }
      };
      nextPage();
    }
  }, [props.companyId, props.disbursementId, currentPage, perPage]);

  return isLoading ? (
    <Flex justifyContent="center">
      <Spinner loading={isLoading} />
    </Flex>
  ) : (
    <Box marginBottom="lg">
      <Card>
        <Box padding="lg">
          <H2>{t('TITLE')}</H2>
          <TableBackendComponent
            data={disbursementChangeHistory}
            data_total={total}
            current_page={currentPage}
            default_sort_column=""
            default_sort_direction="asc"
            headers={tableHeaders}
            paginator={changePage}
            sorter={() => {}}
            no_data_message={tCommon('NO_DATA')}
            show_no_data_message={true}
          />
        </Box>
      </Card>
    </Box>
  );
};

export default ChangeHistoryTab;
