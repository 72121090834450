import type { PageConfig } from 'pages/page-config.interfaces';

const EventsPage: PageConfig = {
  label: 'TAB_EVENTS',
  route: {
    page: 'events',
    v1_path: '#events',
  },
  i18n: {
    namespace: 'company',
  },
  cards: {
    EventHistory: {
      label: 'EVENT_HISTORY',
      fields: {
        email: {
          label: 'EMAIL',
        },
        accountAttribute: {
          label: 'EVENTS.ACCOUNT_ATTRIBUTE',
        },
        from: {
          label: 'FROM',
        },
        to: {
          label: 'TO',
        },
        reason: {
          label: 'REASON',
        },
      },
      endpoints: {
        view: ['companyEventsControllerGet'],
      },
    },
  },
};

export default EventsPage;
