import React, { useState, useEffect, createContext, useCallback } from 'react';
import axios from 'axios';
import type { AxiosResponse } from 'axios';
import { useOktaAuth } from '@okta/okta-react';
import type { IAuthenticatedConfig } from 'interfaces/common.interface';
import type { IPermissionsRole } from 'pages/Permissions/permissions.interface';
import { UserSettingsApi } from '@procore/ipa-nt-api-client-ts';
import type {
  PublicConfigDto,
  UserSettingDto,
} from '@procore/ipa-nt-api-client-ts';
import { getApi } from 'utils/api';
import { loadFrame } from '@okta/okta-auth-js';

export interface AppContextProps {
  publicConfig: PublicConfigDto;
  userConfig: IAuthenticatedConfig;
  userSettings: UserSettingDto;
  updateUserSettings(settings: UserSettingDto): void;
  reloadUserConfig(): Promise<void>;
  checkUserAction(action: string): boolean;
  getLoginError(): boolean;
  refreshToken(target?: Window): Promise<boolean>;
  sessionRefreshCheck(expiresAt: number): void;
  env: string;
}

interface ContextProps {
  children: JSX.Element;
}

interface ContextConsumerProps {
  children: any;
}

export const AppContext = createContext({} as AppContextProps);

export const AppContextProvider = (props: ContextProps) => {
  const publicConfig: PublicConfigDto = (window as any).config;
  const [userSettings, setUserSettings] = useState<UserSettingDto>(
    {} as UserSettingDto,
  );
  const { authState } = useOktaAuth();
  const [userConfig, setUserConfig] = useState<IAuthenticatedConfig>(
    {} as IAuthenticatedConfig,
  );
  const [userActions, setUserActions] = useState<string[]>([]);
  const [loginError, setLoginError] = useState<boolean>(false);
  const params = new URL(document.location.toString()).searchParams;
  const env = window.location.href.includes('payments-integrator-sandbox')
    ? 'INTEGRATOR_SANDBOX'
    : params.get('env') ?? 'PROD';

  let sessionTokenRefreshTimer: NodeJS.Timer;
  let refreshTokenFailures: number = 0;
  let refreshTokenInFlight: boolean = false;

  const setSessionTokenRefreshTimer = (expiresAt: number) => {
    clearInterval(sessionTokenRefreshTimer);

    sessionTokenRefreshTimer = setInterval(
      () => sessionRefreshCheck(expiresAt),
      3000,
    );
  };

  const updateUserSettings = (settings: UserSettingDto) => {
    setUserSettings(settings);
    localStorage.setItem(
      'user-settings',
      JSON.stringify({ settings: settings }),
    );
  };

  const getLoginError = () => {
    return loginError;
  };

  const fetchUserConfig = useCallback(
    async (refresh: boolean) => {
      try {
        if (authState && (refresh || !userConfig?.user)) {
          const res: AxiosResponse = await axios.get(
            `/v1.0/config/authenticated`,
          );
          const roles: AxiosResponse = await axios.get(
            `/v1.0/permissions/roles`,
          );
          const systemSettings: AxiosResponse = await axios.get(
            `/v1.0/system-settings`,
          );
          setUserActions(res.data.user.actions);
          setSessionTokenRefreshTimer(res.data.user.expiresAt);
          setUserConfig({
            ...res.data,
            bugsnag: res.data.bugsnag,
            permissionActions: res.data.user.actions,
            maintenanceMode: systemSettings.data.maintenance_mode,
          });
        }
      } catch (error: any) {
        if (publicConfig?.auth?.mode === 'elixir') {
          redirectToLogin();
        } else {
          setLoginError(true);
        }
      }
    },
    [authState],
  );

  const reloadUserConfig = async () => {
    return await fetchUserConfig(true);
  };

  useEffect(() => {
    const fetchUserSettings = async () => {
      const api = await getApi(UserSettingsApi);
      try {
        if (authState) {
          const settings = await api.settingsControllerGet();
          setUserSettings(settings.data);
          localStorage.setItem(
            'user-settings',
            JSON.stringify({ settings: settings.data }),
          );
        }
      } catch (error: any) {
        if (error.response?.status === 404 && authState) {
          // user has no settings yet, so we create a default settings for them
          const settings = await api.settingsControllerCreateDefaultSettings();
          setUserSettings(settings.data);
        } else {
          console.log(error);
        }
      }
    };

    if (localStorage.getItem('user-settings')) {
      setUserSettings(
        JSON.parse(
          localStorage.getItem('user-settings') ||
            JSON.stringify({ settings: {} }),
        ).settings,
      );
    } else if (authState && authState.isAuthenticated) {
      fetchUserSettings();
    }

    if (authState && authState.isAuthenticated) {
      fetchUserConfig(false);
    }

    return () => {
      clearInterval(sessionTokenRefreshTimer);
    };
  }, [authState, fetchUserConfig]);

  const sessionRefreshCheck = (expiresAt: number) => {
    if (publicConfig?.auth?.mode !== 'elixir') {
      return;
    }

    const currentTime = Math.round(+Date.now() / 1000);
    const isExpired = expiresAt < currentTime;

    if (isExpired) {
      if (refreshTokenFailures > 10) {
        console.log(
          'Token refresh failed too many times, redirecting to login',
        );
        redirectToLogin();
      }

      if (refreshTokenInFlight === false) {
        console.log('Token expired');

        refreshTokenInFlight = true;
        refreshToken()
          .then(() => fetchUserConfig(true))
          .then(() => {
            refreshTokenInFlight = false;
            refreshTokenFailures = 0;
            console.log('Token Refreshed');
          })
          .catch(() => {
            refreshTokenInFlight = false;
            refreshTokenFailures += 1;
            console.error('Token Refresh Failed');
          })
          .finally(() => {
            console.error('Done!');
          });
      } else {
        console.log('Token refresh already in flight, skipping...');
      }
    }
  };

  const refreshToken = async (target?: Window): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      const _target = target || window;
      if (publicConfig?.auth?.mode === 'elixir') {
        const state = encodeURIComponent(
          '/v1.0/config/authenticated?r=' + Math.random(),
        );

        const listener = async (event: MessageEvent) => {
          _target.removeEventListener('message', listener);

          if (event?.data?.error == 'login_required') {
            console.error(
              'Token refresh failed, Okta requires user to login again',
              event.data,
            );
            redirectToLogin(_target);
            reject(false);
          } else if (event?.data?.code) {
            try {
              const res: AxiosResponse = await axios.get(
                `/auth/okta/callback?code=${event.data.code}&state=${state}`,
              );
              if (res.status === 200) {
                fetchUserConfig(true);
                resolve(true);
              } else {
                console.error(
                  `Token failed to refresh, authenticated endpoint returned ${res.status}`,
                );
                reject(false);
              }
            } catch (error: any) {
              console.error(
                `Error refreshing token: ${error?.response?.data?.message}`,
              );
              reject(false);
            }
          } else {
            console.error(
              'Token refresh failed, event did not contain code',
              event,
            );
            reject(false);
          }
        };
        _target.addEventListener('message', listener);

        loadFrame(
          `/auth/okta?state=${state}&prompt=none&response_mode=okta_post_message`,
        );
      } else {
        reject(new Error('Token refresh for auth mode not supported'));
      }
    });
  };

  const redirectToLogin = (target?: Window) => {
    // Redirect to the Elixir hosted okta auth endpoint with the current URL as the state
    const _target = target || window;
    const currentUrl = _target.location.href.replace(
      _target.location.origin,
      '',
    );
    _target.location.href = `/auth/okta?state=${encodeURIComponent(
      currentUrl,
    )}`;
  };

  const checkUserAction = (action: string) => {
    return userActions.includes(action);
  };

  return (
    <AppContext.Provider
      value={{
        publicConfig,
        userSettings,
        updateUserSettings,
        userConfig,
        reloadUserConfig,
        checkUserAction,
        getLoginError,
        refreshToken,
        sessionRefreshCheck,
        env,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export const AppContextConsumer = (props: ContextConsumerProps) => {
  return <AppContext.Consumer>{props.children}</AppContext.Consumer>;
};

export default AppContextProvider;
