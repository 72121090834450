export default {
  id: 'Permissions',
  route: {
    path: '/v2/permissions',
  },
  i18n: {
    namespace: 'permissions',
  },
  permissions: {
    view: ['view_admin'],
    edit: ['view_admin'],
  },
  pages: {
    Access: {
      route: {
        tab: 'access',
      },
      label: 'ACCESS',
      cards: {},
    },
    Actions: {
      route: {
        tab: 'actions',
      },
      label: 'ACTIONS',
      cards: {},
    },
    Resources: {
      route: {
        tab: 'resources',
      },
      label: 'RESOURCES',
      cards: {},
    },
    Roles: {
      route: {
        tab: 'roles',
      },
      label: 'ROLES',
      cards: {},
    },
    Groups: {
      route: {
        tab: 'groups',
      },
      label: 'GROUPS',
      cards: {},
    },
    Users: {
      route: {
        tab: 'users',
      },
      label: 'USERS',
      cards: {},
    },
    Tools: {
      route: {
        tab: 'tools',
      },
      label: 'TOOLS',
      cards: {},
    },
  },
};
