import type { EntitlementsType } from '../utils/utils';

export const entitlementsData: EntitlementsType[] = [
  { id: '1', label: 'procore_company_admin' },
  { id: '2', label: 'procore_company_app_management' },
  { id: '3', label: 'procore_company_concierge' },
  { id: '4', label: 'procore_company_cost_catalog' },
  { id: '5', label: 'procore_company_directory' },
  { id: '6', label: 'procore_company_documents' },
  { id: '7', label: 'procore_company_equipment_register' },
  { id: '8', label: 'procore_company_erp_integrations' },
  { id: '9', label: 'procore_company_home' },
  { id: '10', label: 'procore_company_payments' },
  { id: '11', label: 'procore_company_payments_with_payee_fee' },
  { id: '12', label: 'procore_company_permissions' },
  { id: '13', label: 'procore_company_planroom' },
  { id: '14', label: 'procore_company_prequalification' },
  { id: '15', label: 'procore_company_procore_analytics' },
  { id: '16', label: 'procore_company_programs' },
  { id: '17', label: 'procore_company_reports' },
  { id: '18', label: 'procore_company_timecard' },
  { id: '19', label: 'procore_company_timesheets' },
  { id: '20', label: 'procore_company_workflows' },
  { id: '21', label: 'procore_company_equipment' },
  { id: '22', label: 'procore_company_prequalification_portal' },
  { id: '23', label: 'procore_company_inspections' },
  { id: '24', label: 'procore_company_schedule' },
  { id: '25', label: 'procore_company_workforce_planning' },
  { id: '26', label: 'procore_company_bid_board' },
  { id: '27', label: 'procore_company_document_service' },
  { id: '28', label: 'procore_company_account_onboarding' },
  { id: '29', label: 'procore_project_admin' },
  { id: '30', label: 'procore_project_directory' },
  { id: '31', label: 'procore_project_documents' },
  { id: '32', label: 'procore_project_estimating' },
  { id: '33', label: 'procore_project_home' },
  { id: '34', label: 'procore_project_reports' },
  { id: '35', label: 'procore_project_tasks' },
  { id: '36', label: 'procore_project_bidding' },
  { id: '37', label: 'procore_project_assemble' },
  { id: '38', label: 'procore_project_coordination_issues' },
  { id: '39', label: 'procore_project_daily_log' },
  { id: '40', label: 'procore_project_drawings' },
  { id: '41', label: 'procore_project_emails' },
  { id: '42', label: 'procore_project_action_plans' },
  { id: '43', label: 'procore_project_forms' },
  { id: '44', label: 'procore_project_incidents' },
  { id: '45', label: 'procore_project_inspections' },
  { id: '46', label: 'procore_project_instructions' },
  { id: '47', label: 'procore_project_meetings' },
  { id: '48', label: 'procore_project_models' },
  { id: '49', label: 'procore_project_observations' },
  { id: '50', label: 'procore_project_photos' },
  { id: '51', label: 'procore_project_punch_list' },
  { id: '52', label: 'procore_project_rfi' },
  { id: '53', label: 'procore_project_schedule' },
  { id: '54', label: 'procore_project_specifications' },
  { id: '55', label: 'procore_project_submittals' },
  { id: '56', label: 'procore_project_transmittals' },
  { id: '57', label: 'procore_project_budget' },
  { id: '58', label: 'procore_project_change_events' },
  { id: '59', label: 'procore_project_change_orders' },
  { id: '60', label: 'procore_project_commitments' },
  { id: '61', label: 'procore_project_direct_costs' },
  { id: '62', label: 'procore_project_invoicing' },
  { id: '63', label: 'procore_project_prime_contract' },
  { id: '64', label: 'procore_project_crews' },
  { id: '65', label: 'procore_project_equipment' },
  { id: '66', label: 'procore_project_equipment_register' },
  { id: '67', label: 'procore_project_T&M_tickets' },
  { id: '68', label: 'procore_project_timesheets' },
  { id: '69', label: 'procore_project_document_service' },
  { id: '70', label: 'procore_project_connection_hub' },
  {
    id: '71',
    label: 'procore_enable_private_correspondence_types_and_custom_tools',
  },
  { id: '72', label: 'procore_correspondence_types_limit' },
  { id: '73', label: 'procore_exclude_bidding_stage_projects' },
  { id: '74', label: 'procore_max_active_projects' },
  { id: '75', label: 'procore_annual_contract_volume_currency' },
  { id: '76', label: 'procore_annual_contract_volume' },
];
