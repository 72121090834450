import styled, { keyframes } from 'styled-components';
import { colors, spacing } from '@procore/core-react';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const TreeListWrapper = styled.div`
  ul {
    list-style: none;
    padding-left: ${spacing.sm}px;
  }
`;

export const IconWrapper = styled.div`
  border: 1px solid ${colors.gray15};
  width: 18px;
  height: 18px;
  display: inline-block;
  cursor: pointer;
  margin-right: 8px;

  svg {
    vertical-align: top;
  }

  &.empty {
    border: 1px solid ${colors.white};
    cursor: default;
  }
`;

export const Minus = styled.div`
  width: 16px;
  height: 16px;
  text-align: center;

  span {
    position: relative;
    top: -5px;
  }
`;

export const Corner = styled.div`
  display: inline-block;
  width: 12px;
  height: 12px;
  border-left: 1px solid ${colors.gray50};
  border-bottom: 1px solid ${colors.gray50};
  position: relative;
  top: 2px;
`;

export const InfiniteRotation = styled.div`
  vertical-align: baseline;
  margin-right: 8px;
  animation: ${rotate} 1s linear infinite;
  display: inline-block;
  position: relative;
  top: -3px;
`;

export const styles = {
  folder: {
    verticalAlign: 'baseline',
    marginRight: '8px',
  },
  file: {
    marginRight: '8px',
  },
  nodeName: {
    display: 'inline-block',
    position: 'relative' as 'relative',
    top: '-5px',
  },
  subNodeName: {
    display: 'inline-block',
    position: 'relative' as 'relative',
    top: '2px',
  },
  subList: {
    paddingLeft: '28px',
  },
  corner: {
    position: 'relative' as 'relative',
    top: `-${spacing.xs}px`,
  },
};
