import React, { useContext } from 'react';
import type { ProjectAdvancedSettingsProps } from '../interfaces';
import { EditableCard } from 'components/EditableCard';
import type { IEditableCard } from 'components/EditableCard';
import { Form } from '@procore/core-react';
import { getDisplayList } from 'pages/Permissions/helpers';
import { ProjectInfoContext } from '..';
import { ProjectContext } from 'contexts/ProjectContext';

export const ProjectAdvancedCard = (props: ProjectAdvancedSettingsProps) => {
  const context = useContext(ProjectContext);
  const config = props.cardConfig;

  const {
    dataSources: { projectSettings },
  } = useContext(ProjectInfoContext);
  const data = projectSettings?.data?.advanced_settings;

  const card: IEditableCard = {
    id: props.id,
    i18n: config.translate,
    label: config.label,
    canEdit: context.hasPermission(config.permissions.edit),
    save: props.save,
    fields: [
      {
        source: projectSettings?.id,
        value: data?.office.selected_name,
        name: 'office',
        dataType: 'text',
        editable: false,
        element: Form.Text,
        hideOnEdit: true,
        ...config.fields.office,
      },
      {
        source: projectSettings?.id,
        value: getDisplayList(data?.departments.departments),
        name: 'departments',
        dataType: 'text',
        editable: false,
        element: Form.Text,
        hideOnEdit: true,
        ...config.fields.departments,
      },
      {
        source: projectSettings?.id,
        value: data?.program.selected_name,
        name: 'program',
        dataType: 'text',
        editable: false,
        element: Form.Text,
        hideOnEdit: true,
        ...config.fields.program,
      },
      {
        source: projectSettings?.id,
        value: data?.flag.selected_name,
        name: 'flag',
        dataType: 'text',
        editable: false,
        element: Form.Text,
        hideOnEdit: true,
        ...config.fields.flag,
      },
      {
        source: projectSettings?.id,
        value: data?.region.selected_name,
        name: 'region',
        dataType: 'text',
        editable: false,
        element: Form.Text,
        hideOnEdit: true,
        ...config.fields.region,
      },
      {
        source: projectSettings?.id,
        value: data?.bid_type.selected_name,
        name: 'bid_type',
        dataType: 'text',
        editable: false,
        element: Form.Text,
        hideOnEdit: true,
        ...config.fields.bid_type,
      },
      {
        source: projectSettings?.id,
        value: data?.owner_type.selected_name,
        name: 'owner_type',
        dataType: 'text',
        editable: false,
        element: Form.Text,
        hideOnEdit: true,
        ...config.fields.owner_type,
      },
      {
        source: projectSettings?.id,
        value: data?.parent_project.selected_name,
        name: 'parent_project',
        dataType: 'text',
        editable: false,
        element: Form.Text,
        hideOnEdit: true,
        ...config.fields.parent_project,
      },
      {
        source: projectSettings?.id,
        value: data?.warranty_start_date
          ? new Date(`${data.warranty_start_date}T00:00:00`)
          : data?.warranty_start_date,
        name: 'warranty_start_date',
        dataType: 'text',
        editable: false,
        element: Form.DateSelect,
        hideOnEdit: true,
        ...config.fields.warranty_start_date,
      },
      {
        source: projectSettings?.id,
        value: data?.warranty_end_date
          ? new Date(`${data.warranty_end_date}T00:00:00`)
          : data?.warranty_end_date,
        name: 'warranty_end_date',
        dataType: 'text',
        editable: false,
        element: Form.DateSelect,
        hideOnEdit: true,
        ...config.fields.warranty_end_date,
      },
      {
        source: projectSettings?.id,
        value: data?.quickbooks_customer,
        name: 'quickbooks_customer',
        dataType: 'text',
        editable: false,
        element: Form.Text,
        hideOnEdit: true,
        ...config.fields.quickbooks_customer,
      },
      {
        source: projectSettings?.id,
        value: data?.locale.selected_name,
        name: 'locale',
        editable: false,
        dataType: 'text',
        element: Form.Text,
        hideOnEdit: true,
        ...config.fields.locale,
      },

      {
        source: projectSettings?.id,
        value: data?.prevent_overbilling_on_this_project,
        name: 'prevent_overbilling_on_this_project',
        dataType: 'boolean',
        element: Form.Checkbox,
        hideOnEdit: true,
        ...config.fields.prevent_overbilling_on_this_project,
      },

      {
        source: projectSettings?.id,
        value: data?.change_event_noncommitted_enabled,
        name: 'change_event_noncommitted_enabled',
        dataType: 'boolean',
        element: Form.Checkbox,
        hideOnEdit: true,
        ...config.fields.change_event_noncommitted_enabled,
      },

      {
        source: projectSettings?.id,
        value: data?.enable_sub_jobs,
        name: 'enable_sub_jobs',
        dataType: 'boolean',
        element: Form.Checkbox,
        hideOnEdit: true,
        ...config.fields.enable_sub_jobs,
      },

      {
        source: projectSettings?.id,
        value: data?.is_demo,
        name: 'is_demo',
        dataType: 'boolean',
        element: Form.Checkbox,
        editable: !data?.enable_project_as_template,
        ...config.fields.is_demo,
        tooltip: data?.enable_project_as_template
          ? config.translate('TEST_PROJ_TOOLTIP_DISABLED')
          : config.translate('TEST_PROJ_TOOLTIP_ENABLED'),
      },
      {
        source: projectSettings?.id,
        value: data?.enable_change_order_associations,
        name: 'enable_change_order_associations',
        dataType: 'boolean',
        element: Form.Checkbox,
        hideOnEdit: true,
        ...config.fields.enable_change_order_associations,
      },
    ],
  };

  return <EditableCard card={card} />;
};

export default ProjectAdvancedCard;
