import React, { useContext, useEffect, useState } from 'react';
import { ContactContext } from 'contexts/ContactContext';
import {
  Form,
  Box,
  Title,
  H2,
  ErrorBanner,
  Banner,
  Button,
} from '@procore/core-react';
import { ChevronDown, ChevronRight } from '@procore/core-icons';
import { useTranslation } from 'react-i18next';
import type { IEditableCardProps } from 'pages/Contact/interfaces';
import { InfoTabContext } from './index';
import { ipaAppStyles } from 'styles';

export const ErpIntegrations = (props: IEditableCardProps) => {
  const { pageConfig } = useContext(ContactContext);
  const {
    dataSources: { erpConnections },
  } = useContext(InfoTabContext);
  const [erpServiceAccount, setErpServiceAccount] = useState<string>('test');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const config = pageConfig.card('ErpIntegrations');
  const { t } = useTranslation('common');
  const [collapsed, setCollapsed] = useState<boolean>(false);

  useEffect(() => {
    setErpServiceAccount(erpConnections?.data?.service_account_user?.login);
  }, [erpConnections]);

  return (
    <Box>
      <Title style={ipaAppStyles.titleMargin}>
        <Title.Assets>
          <Button
            variant="tertiary"
            onClick={() => setCollapsed(!collapsed)}
            data-qa="erp-integrations-collapse-btn"
          >
            {!collapsed ? <ChevronDown /> : <ChevronRight />}
          </Button>
        </Title.Assets>
        <Title.Text>
          <H2 data-qa={'erp-integrations-title'}>
            {config.translate('TITLE')}
          </H2>
        </Title.Text>
      </Title>
      {!isLoading && !props.error && !collapsed && (
        <Form
          view="read"
          enableReinitialize={true}
          initialValues={{ service_account: erpServiceAccount }}
        >
          <Form.Form>
            <Form.Text
              name="service_account"
              label={config.translate('SERVICE_ACCOUNT')}
              data-qa="service-account-text"
            />
          </Form.Form>
        </Form>
      )}
      {!isLoading && props.error && !collapsed && (
        <ErrorBanner>
          <Banner.Content>
            <Banner.Title>{t('ERROR')}</Banner.Title>
            <Banner.Body>{config.translate('INTEGRATION_ERROR')}</Banner.Body>
          </Banner.Content>
        </ErrorBanner>
      )}
    </Box>
  );
};

export default ErpIntegrations;
