import React, { useState, useContext } from 'react';
import { ExternalLink, ClipboardCheck, Duplicate } from '@procore/core-icons';
import { Link, FlexList } from '@procore/core-react';
import { getBaseUrl } from 'utils/utils';
import { useTranslation } from 'react-i18next';
import { IpaAlertContext } from 'contexts/IpaAlertContext';

interface ProjectLinkProps {
  companyId: number;
  projectId: number;
  env: string;
  projectName: string;
  'data-qa'?: string;
}

const ProjectLink = (props: ProjectLinkProps) => {
  const { t } = useTranslation(['common']);
  const alert = useContext(IpaAlertContext);
  const [copied, setCopied] = useState(false);

  const openLink = async () => {
    const url =
      getBaseUrl(props.env, 'procore', props.companyId) +
      `/${props.projectId}/project/home`;
    window.open(url, '_blank');
  };

  const copyLink = async () => {
    try {
      const url =
        getBaseUrl(props.env, 'procore', props.companyId) +
        `/${props.projectId}/project/home`;
      await navigator.clipboard.writeText(url);
      setCopied(true);
      alert.success(t('PROJECT_LINK_COPIED', { ns: 'common' }));
    } catch (e: any) {
      alert.error(e);
    }
  };

  return (
    <FlexList space="xs">
      <strong>{`${t('PROJECT')}:`}</strong>
      <Link data-qa={`project-link-${props.projectId}`} onClick={openLink}>
        {props.projectName} <ExternalLink size="sm" />
      </Link>
      <Link
        data-qa={props['data-qa'] ?? `project-link-copy-${props.projectId}`}
        onClick={copyLink}
      >
        {copied ? (
          <ClipboardCheck size="sm" color="green" />
        ) : (
          <Duplicate size="sm" />
        )}
      </Link>
    </FlexList>
  );
};

export default ProjectLink;
