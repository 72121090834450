import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Card, Flex, Spinner, Box, EmptyState } from '@procore/core-react';
import { Check, Ban } from '@procore/core-icons';
import { useTranslation } from 'react-i18next';
import { getApi } from 'utils/api';
import { ProjectsApi } from '@procore/ipa-nt-api-client-ts';
import type { RestV10InternalSuperIpaProjectsProjectIdDailyLogUsagePermissionsGet200ResponseLoginInformationsInnerApiDto } from '@procore/ipa-nt-api-client-ts';
import { AppContext } from 'contexts/AppContext';
import { IpaAlertContext } from 'contexts/IpaAlertContext';
import { ProjectContext } from 'contexts/ProjectContext';
import TableBackendComponent from 'components/TableBackend';
import type { TableHeader } from 'components/Table/table.interface';
import { ipaAppStyles } from 'styles';

interface LoginInfo
  extends RestV10InternalSuperIpaProjectsProjectIdDailyLogUsagePermissionsGet200ResponseLoginInformationsInnerApiDto {}

const DailyLogSettingsPage = () => {
  const alert = useContext(IpaAlertContext);
  const context = useContext(ProjectContext);
  const config = context.pageConfig.card('DailyLogSettings');
  const t = config.translate;

  const { t: tCommon } = useTranslation(['common']);
  const { userSettings } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isInit, setIsInit] = useState<boolean>(true);
  const [dailyLogSettings, setDailyLogSettings] = useState<LoginInfo[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(
    userSettings.default_page_size as number | 25,
  );

  const displayPermission = (item: any, node: string, column: string) => {
    return item[node] === column ? (
      <Check size="sm" style={ipaAppStyles.success} />
    ) : (
      <Ban size="sm" style={ipaAppStyles.failure} />
    );
  };

  const tableHeaders: TableHeader[] = [
    {
      title: tCommon('NAME'),
      node: 'name',
      is_text: true,
      is_sortable: false,
      column_width: 20,
    },
    {
      title: tCommon('COMPANY'),
      node: 'company',
      is_text: true,
      is_sortable: false,
      column_width: 20,
    },
    {
      title: tCommon('NONE'),
      node: 'permissions',
      display_func: (item, node) => {
        return displayPermission(item, node, 'None');
      },
      column_width: 15,
    },
    {
      title: t('READ_ONLY'),
      node: 'permissions',
      display_func: (item, node) => {
        return displayPermission(item, node, 'Read Only');
      },
      column_width: 15,
    },
    {
      title: t('STANDARD'),
      node: 'permissions',
      display_func: (item, node) => {
        return displayPermission(item, node, 'Standard');
      },
      column_width: 15,
    },
    {
      title: t('ADMIN'),
      node: 'permissions',
      display_func: (item, node) => {
        return displayPermission(item, node, 'Admin');
      },
      column_width: 15,
    },
  ];

  const changePage = (page: number, pageSize: number) => {
    setCurrentPage(page);
  };

  const getDailyLogSettings = useCallback(async () => {
    if (!dailyLogSettings) {
      // only for first page
      setIsInit(false);
      setIsLoading(true);
    }
    try {
      const api = getApi(ProjectsApi);
      const result = await api.projectControllerGetProjectDailyLogSettings(
        context.env,
        context.companyId,
        context.projectId,
        currentPage,
        perPage,
      );
      setDailyLogSettings(result.data.login_informations);
      setTotal(result.data.total);
    } catch (e: any) {
      alert.error(e);
    } finally {
      setIsLoading(false);
      setIsInit(true);
    }
  }, [context.projectId, context.companyId, context.env, currentPage]);

  useEffect(() => {
    getDailyLogSettings();
  }, [getDailyLogSettings]);

  return (
    <React.Fragment>
      <Card>
        <Box padding="lg">
          {(!isInit || isLoading) && (
            <Box>
              {!isInit && !isLoading && (
                <EmptyState>
                  <EmptyState.NoItems />
                  <EmptyState.Title>{tCommon('NO_DATA')}</EmptyState.Title>
                </EmptyState>
              )}
              {isLoading && (
                <Flex justifyContent="center">
                  <Spinner loading={isLoading} />
                </Flex>
              )}
            </Box>
          )}
          {isInit && !isLoading && (
            <TableBackendComponent
              data={dailyLogSettings}
              data_total={total}
              current_page={currentPage}
              default_sort_column="name"
              default_sort_direction="asc"
              headers={tableHeaders}
              paginator={changePage}
              sorter={() => {}}
              fixed_table={true}
            />
          )}
        </Box>
      </Card>
    </React.Fragment>
  );
};

export default DailyLogSettingsPage;
