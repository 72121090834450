import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, FlexList, H2, Button, Typography } from '@procore/core-react';
import { ContactContext } from 'contexts/ContactContext';
import { IpaAlertContext } from 'contexts/IpaAlertContext';
import { getApi } from 'utils/api';
import { CompanyDirectoryApi } from '@procore/ipa-nt-api-client-ts';
import type {
  ProjectUserPermissionsDto,
  ProjectUserPermissionsRowsDto,
  ProjectUserPermissionsTemplateDto,
  AssignablePermissionTemplatesDto,
} from '@procore/ipa-nt-api-client-ts';
import type { ProjectSettingsProps } from './index';
import { AssignableTemplatesModal } from 'pages/Contact/ProjectPermissionTemplates/AssignableTemplatesModal';
import { PermissionsGrid } from 'components/PermissionsGrid';

const ProjectPermissionsTemplatesCard = (props: ProjectSettingsProps) => {
  const { pageConfig, env, companyId } = useContext(ContactContext);
  const { t } = useTranslation('common');
  const alert = useContext(IpaAlertContext);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [template, setTemplate] = useState<ProjectUserPermissionsTemplateDto>();
  const [projectPermissions, setProjectPermissions] = useState<
    ProjectUserPermissionsRowsDto[]
  >([]);
  const [assignableTemplates, setAssignableTemplates] = useState<
    AssignablePermissionTemplatesDto[] | null
  >(null);
  const [isLoadingAssignableTemplates, setIsLoadingAssignableTemplates] =
    useState<boolean>(false);
  const config = pageConfig.card(props.id);

  const getAssignableTemplates = async () => {
    try {
      const api = getApi(CompanyDirectoryApi);
      const result =
        await api.companyDirectoryControllerGetAssignablePermissionTemplates(
          env,
          companyId,
          template?.id,
        );
      return result.data;
    } catch (e: any) {
      alert.error(e);
    }
  };

  useEffect(() => {
    if (props.data) {
      const data = props.data as ProjectUserPermissionsDto;
      setTemplate(data.permission_template);
      setProjectPermissions(data.rows);
    }
  }, [props.data]);

  return (
    <React.Fragment>
      <Flex>
        <H2>{config.label}</H2>
        <Button
          variant="secondary"
          data-qa="refresh-data-btn"
          style={{ marginLeft: 'auto' }}
          onClick={() => {
            props.reloadData();
          }}
        >
          {config.translate('REFRESH')}
        </Button>
      </Flex>
      <FlexList gap="xl">
        <Typography typeof="body">
          <strong>{config.translate('PERMISSIONS_TEMPLATE')}</strong>
          <div>{template?.name || t('N/A')}</div>
        </Typography>
        {template && (
          <Button
            data-qa="view-assignable-templates-btn"
            loading={isLoadingAssignableTemplates}
            size="sm"
            onClick={async () => {
              setIsLoadingAssignableTemplates(true);
              const temp = await getAssignableTemplates();
              setAssignableTemplates(temp);
              setShowModal(true);
              setIsLoadingAssignableTemplates(false);
            }}
          >
            {config.translate('VIEW_ASSIGNABLE_TEMPLATES')}
          </Button>
        )}
      </FlexList>
      <PermissionsGrid permissions={projectPermissions} />
      <AssignableTemplatesModal
        show={showModal}
        template={template}
        assignableTemplates={assignableTemplates}
        closeModal={() => setShowModal(false)}
      />
    </React.Fragment>
  );
};

export default ProjectPermissionsTemplatesCard;
