import React, { useContext } from 'react';
import { ProjectContext } from 'contexts/ProjectContext';
import type { IEditableCard } from 'components/EditableCard';
import { Box, H2 } from '@procore/core-react';
import TableComponent from 'components/Table';
import { ProjectScheduleContext } from '.';

export interface ProjectSchedulePermissionsProps {
  id: string;
  save: IEditableCard['save'];
}

const PermissionsTab = (props: ProjectSchedulePermissionsProps) => {
  const context = useContext(ProjectContext);
  const config = context.pageConfig.card('Permissions');

  const {
    dataSources: { scheduleSettings },
  } = useContext(ProjectScheduleContext);

  const t = config.translate;

  const permissionHeaders = [
    {
      title: t('TABLE_HEADERS.NAME'),
      node: 'name',
      is_text: true,
      is_sortable: true,
    },
    {
      title: t('TABLE_HEADERS.COMPANY'),
      node: 'vendor_name',
      is_text: true,
      is_sortable: true,
    },
    {
      title: t('TABLE_HEADERS.PERMISSION_LEVEL'),
      node: 'permission',
      is_text: true,
      is_sortable: true,
    },
    {
      title: t('TABLE_HEADERS.RESOURCE'),
      node: 'resource',
      is_text: true,
      is_sortable: true,
    },
    {
      title: t('TABLE_HEADERS.WEEKLY_PROJECT_EMAIL'),
      node: 'weekly_project_schedule',
      is_boolean: true,
      is_sortable: false,
    },
    {
      title: t('TABLE_HEADERS.WEEKLY_RESOURCE_EMAIL'),
      node: 'weekly_resource_schedule',
      is_boolean: true,
      is_sortable: false,
    },
    {
      title: t('TABLE_HEADERS.WEEKLY_LOOKAHEAD_EMAIL'),
      node: 'weekly_schedule_lookahead',
      is_boolean: true,
      is_sortable: false,
    },
  ];

  return (
    <Box padding="xl">
      <H2>{t('PERMISSIONS')}</H2>
      <TableComponent
        data={scheduleSettings?.data.permission_table ?? []}
        headers={permissionHeaders}
        default_sort_column="name"
        default_sort_direction="asc"
        show_no_data_message={true}
        show_search={true}
        search_columns={['name']}
        show_page_size_select={true}
      />
    </Box>
  );
};

export default PermissionsTab;
