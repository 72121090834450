/**
 * This class is responsible for managing the map of items that the user has recently visited.
 * The map is stored in browser local storage.
 */

export interface QuickHistoryKey {
  type: string;
  companyId: string;
  projectId?: string;
  contactId?: string;
}

export interface QuickHistoryItem extends QuickHistoryKey {
  title: string;
  lastVisitedAt: number; // timestamp
}

export class QuickHistoryState {
  MAX_ITEMS = 10;
  private history: Map<string, QuickHistoryItem>;

  constructor() {
    this.history = new Map();
    this.load();
    window.addEventListener('storage', () => this.load());
  }

  private load(): void {
    const history = localStorage.getItem('quickHistory');
    if (history) {
      this.history = new Map(JSON.parse(history));
    }
  }

  private save(): void {
    localStorage.setItem(
      'quickHistory',
      JSON.stringify(Array.from(this.history.entries())),
    );
    window.dispatchEvent(new Event('storage'));
  }

  public add(key: QuickHistoryKey, title: string): void {
    const strKey = this.getStrKey(key);
    const item: QuickHistoryItem = {
      ...key,
      title,
      lastVisitedAt: +Date.now(),
    };
    this.history.set(strKey, item);
    this.trimOldest();
    this.save();
  }

  public has(key: QuickHistoryKey): boolean {
    const strKey = this.getStrKey(key);
    return this.history.has(strKey);
  }

  public bump(item: QuickHistoryKey): void {
    const key = this.getStrKey(item);
    const existingItem = this.history.get(key);
    if (existingItem) {
      existingItem.lastVisitedAt = +Date.now();
      this.history.delete(key);
      this.history.set(key, existingItem);
      this.save();
    }
  }

  private trimOldest(): void {
    if (this.history.size > this.MAX_ITEMS) {
      const oldestItem = this.getItems().shift();
      this.history.delete(this.getStrKey(oldestItem));
      this.save();
    }
  }

  public getItems(): QuickHistoryItem[] {
    return Array.from(this.history.values()).sort(
      (a, b) => b.lastVisitedAt - a.lastVisitedAt,
    );
  }

  private getStrKey(item: QuickHistoryKey): string {
    return `${item.type}-${item.companyId}-${item.projectId || ''}-${
      item.contactId || ''
    }`;
  }
}
