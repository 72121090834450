import React from 'react';
import { AppContextProvider } from 'contexts/AppContext';
import { AppHeader } from 'components/AppHeader';
import { AppFooter } from 'components/AppFooter';
import { AppWrapper, PageWrapper } from './styles';
import { AxiosConfigurator } from 'components/AxiosConfigurator';
import { AppBugsnag } from 'components/AppBugsnag';
import { IpaAlertContextProvider } from 'contexts/IpaAlertContext';
import GlobalAnnouncementComponent from 'components/GlobalAnnouncement';
import { useOktaAuth } from '@okta/okta-react';
import { PageLoadReporter } from 'components/PageLoadReporter';
import { QuickHistoryWatcher } from 'components/QuickHistory/QuickHistoryWatcher';

interface AppContainerProps {
  children?: React.ReactNode;
}

export const AppContainer = (props: AppContainerProps) => {
  const { authState } = useOktaAuth();
  const isAuthenticated = authState && authState.isAuthenticated;

  return (
    <AppContextProvider>
      <IpaAlertContextProvider>
        <AppBugsnag>
          <AppWrapper>
            <AxiosConfigurator />
            <PageLoadReporter />
            <QuickHistoryWatcher />
            {isAuthenticated && <AppHeader />}
            {isAuthenticated && <GlobalAnnouncementComponent />}
            <PageWrapper>{props.children}</PageWrapper>
            {isAuthenticated && <AppFooter build_number={'ee5b89f'} />}
          </AppWrapper>
        </AppBugsnag>
      </IpaAlertContextProvider>
    </AppContextProvider>
  );
};

export default AppContainer;
