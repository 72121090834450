import type { SectionConfig } from 'pages/page-config.interfaces';

export default <SectionConfig>{
  id: 'User History',
  route: {
    path: '/v2/events',
  },
  i18n: {
    namespace: 'user-history',
  },
  permissions: {
    view: ['view_admin'],
    edit: ['view_admin'],
  },
  pages: {
    UserHistory: {
      label: 'USER_HISTORY_HEADER',
      endpoints: {
        view: [],
      },
      cards: {},
    },

    UserHistoryDetails: {
      route: {
        path: '/users', // ?email=...
      },
      i18n: {
        namespace: 'user-history',
      },
      label: 'USER_HISTORY',
      endpoints: {
        view: [],
      },
      cards: {
        OktaChangeHistory: {
          route: {
            tab: 'okta-change-history',
          },
          label: 'OKTA_CHANGE_HISTORY_HEADER',
          fields: {},
        },
        SuPromotionHistory: {
          route: {
            tab: 'su-promotion-history',
          },
          label: 'SU_PROMOTION_HISTORY_HEADER',
          fields: {},
        },
      },
    },
  },
};
