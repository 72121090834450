import type { PageConfig } from 'pages/page-config.interfaces';

const ToolsPage: PageConfig = {
  label: 'TAB_TOOLS',
  route: {
    page: 'tools',
    v1_path: '#tools',
  },
  i18n: {
    namespace: 'company',
  },
  endpoints: {
    view: ['toolEntitlementControllerGetEntitlements'],
  },
  cards: {
    CompanyTools: {
      i18n: {
        keyPrefix: 'ENTITLEMENTS.COMPANY',
      },
      label: 'TITLE',
      permissions: {
        edit: ['edit_company_and_project_tools'],
      },
      fields: {
        // Tools/entitlements are dynamic
      },
      endpoints: {
        edit: ['toolEntitlementControllerUpdateEntitlements'],
      },
    },
    CustomCompanyTools: {
      i18n: {
        keyPrefix: 'TOOLS.CUSTOM_COMPANY',
      },
      label: 'TITLE',
      permissions: {
        edit: ['company_tab_edit', 'add_custom_tool'],
      },
      fields: {
        // Tools/entitlements are dynamic
      },
      endpoints: {
        edit: ['companyToolsControllerUpdateCompanyTools'],
        add: ['companyToolsControllerAddCustomCompanyTool'],
      },
    },
    ProjectTools: {
      i18n: {
        keyPrefix: 'ENTITLEMENTS.PROJECT',
      },
      label: 'TITLE',
      permissions: {
        edit: ['edit_company_and_project_tools'],
      },
      fields: {
        // Tools/entitlements are dynamic
      },
      endpoints: {
        edit: ['toolEntitlementControllerUpdateEntitlements'],
      },
    },
    CustomCompanyProjectTools: {
      i18n: {
        keyPrefix: 'TOOLS.CUSTOM_PROJECT',
      },
      label: 'TITLE',
      permissions: {
        edit: ['company_tab_edit', 'add_custom_tool'],
      },
      fields: {
        // Tools/entitlements are dynamic
      },
      endpoints: {
        edit: ['companyProjectToolsControllerUpdateCompanyProjectTools'],
        add: ['companyProjectToolsControllerAddCustomCompanyProjectTool'],
      },
    },
    ProductSubscriptions: {
      // i18n: {
      //   keyPrefix: 'ENTITLEMENTS.PRODUCT_SUBSCRIPTIONS',
      // },
      // Some fields come from common, so keyPrefix causes an issue
      label: 'ENTITLEMENTS.PRODUCT_SUBSCRIPTIONS.TITLE',
      permissions: {
        edit: ['company_tab_edit'],
      },
      fields: {
        product_name: {
          label: 'ENTITLEMENTS.PRODUCT_SUBSCRIPTIONS.PRODUCT_NAME',
        },
        product_subscription_id: {
          label: 'ENTITLEMENTS.PRODUCT_SUBSCRIPTIONS.PRODUCT_SUBSCRIPTION_ID',
        },
        record_status: {
          label: 'ENTITLEMENTS.PRODUCT_SUBSCRIPTIONS.RECORD_STATUS',
        },
        start_date: {
          label: 'START_DATE',
        },
        end_date: {
          label: 'END_DATE',
        },
      },
      endpoints: {
        // missing updateProductSubscriptions?
        view: ['toolEntitlementControllerGetProductSubscriptions'],
      },
    },
    // ProductSubscriptionDeactivation: {
    //   label: 'TITLE',
    //   i18n: {},
    //   fields: {},
    // },
  },
};

export default ToolsPage;
