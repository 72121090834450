import React, { useEffect, useState } from 'react';
import { GlobalAnnouncementsApi } from '@procore/ipa-nt-api-client-ts';
import type { GlobalAnnouncementDto } from '@procore/ipa-nt-api-client-ts';
import { getApi } from 'utils/api';
import MessageBanner from 'components/MessageBanner';

export const GlobalAnnouncementComponent = () => {
  const [announcement, setAnnouncement] =
    useState<GlobalAnnouncementDto | null>(null);
  useEffect(() => {
    const getActiveAccouncement = async () => {
      const api = getApi(GlobalAnnouncementsApi);
      try {
        const res =
          await api.globalAnnouncementControllerGetActiveAnnouncement();

        if (res.data.hasAnnouncement) {
          setAnnouncement(res.data.announcement);
        }
      } catch (error: any) {}
    };

    getActiveAccouncement();
  }, []);

  return (
    <React.Fragment>
      {announcement && (
        <MessageBanner
          banner_message={announcement.message}
          is_global_announcement={true}
          announcement_id={announcement.id}
          banner_type={
            announcement.messageType === 'Warning' ? 'error' : 'info'
          }
          banner_sticky={false}
          show_title={false}
        />
      )}
    </React.Fragment>
  );
};

export default GlobalAnnouncementComponent;
