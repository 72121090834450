import type { SectionConfig } from 'pages/page-config.interfaces';

import BusinessEntitiesList from './BusinessEntity/page.config';
import PayoutFailures from './PaymentsTabs/payoutFailures/page.config';
import PendingPaymentsAdmins from './PaymentsTabs/pendingPaymentsAdmins/page.config';
import BusinessEntityChangeHistory from './BusinessEntity/BusinessEntityTabs/ChangeHistoryTab/page.config';
import BusinessEntityGeneral from './BusinessEntity/BusinessEntityTabs/GeneralTab/page.config';
import BusinessEntityReceivedPayouts from './BusinessEntity/BusinessEntityTabs/ReceivedPayoutsTab/page.config';
import BusinessEntitySentDisbursements from './BusinessEntity/BusinessEntityTabs/SentDisbursementsTab/page.config';

export default {
  id: 'Payments',
  route: {
    path: '/v2/payments',
  },
  i18n: {
    namespace: 'payments',
  },
  permissions: {
    view: ['payments_operations'],
    edit: ['payments_operations'],
    create_business_entity: ['create_business_entity'],
    approve_payments_admins: ['approve_payments_admins'],
    edit_vendor_connections: ['edit_vendor_connections'],
  },
  pages: {
    BusinessEntitiesList,
    PayoutFailures,
    PendingPaymentsAdmins,
    BusinessEntityChangeHistory,
    BusinessEntityGeneral,
    BusinessEntityReceivedPayouts,
    BusinessEntitySentDisbursements,
  },
} as SectionConfig;
