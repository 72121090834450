import React, { useCallback, useEffect, useState, useContext } from 'react';

import {
  Page,
  ToolHeader,
  Card,
  Flex,
  Spinner,
  Box,
  EmptyState,
  FlexList,
  Breadcrumbs,
  Link,
  Grid,
} from '@procore/core-react';
import { useTranslation } from 'react-i18next';
import { IpaAlertContext } from 'contexts/IpaAlertContext';
import { getApi } from 'utils/api';
import { CompanyInfoApi, ProjectsApi } from '@procore/ipa-nt-api-client-ts';
import type {
  CompanyAccountStatusDto,
  ProjectSettingsDto,
} from '@procore/ipa-nt-api-client-ts';
import {
  Navigate,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import CompanyPills from 'components/CompanyPills';
import EnvironmentSwitcher from 'components/EnvironmentSwitcher';
import { generateUrl } from './urlGenerator';
import { generateUrl as generateProjectManagementUrl } from '../ProjectManagement/urlGenerator';
import JitWidget from 'components/JitWidget';
import LocationsPage from './Locations';
import MeetingSettingsPage from './MeetingSettings';
import ProjectInfoPage from './ProjectInfo';
import CommitmentSettingsPage from './CommitmentSettings';
import SubmittalResponsesPage from './SubmittalResponses';
import SubmittalSettingsPage from './SubmittalSettings';
import RfiSettingsPage from './RfiSettings';
import ProjectSelectorComponent from './ProjectSelector';
import { ActionButtonsStyle } from './style';
import ProjectToolsDropdown from 'components/ProjectToolsDropdown';
import { AppContext } from 'contexts/AppContext';
import CompanyLink from 'components/CompanyLink';
import ProjectSchedulePage from './ScheduleSettings';
import DailyLogSettingsPage from './DailyLogSettings';
import ProjectConfigurationChangeHistoryPage from './ConfigurationChangeHistory';
import TaskItemsSettingsPage from './TaskItemsSettings';
import CostCodesPage from './CostCodes';
import PrimeContractSettingsPage from './PrimeContractSettings';
import SubmittalWorkflowTemplatesPage from './SubmittalWorkflowTemplates';
import { can } from 'utils/permissions.helper';
import { PageConfig } from 'pages/page-config.service';
import type { PageConfigTranslated } from 'pages/page-config.interfaces';
import { ProjectContextProvider } from 'contexts/ProjectContext';
import type { ProjectContextProps } from 'contexts/ProjectContext';
import ContextInfoPopup from 'components/ContextInfoPopup';

export const ProjectPage = () => {
  const { t } = useTranslation(['project']);
  const { t: tCommon } = useTranslation(['common']);
  const { company_id: id, project_id: projectId, page: _page } = useParams();
  const page = _page ?? '';
  const navigate = useNavigate();
  const alert = useContext(IpaAlertContext);
  const { userSettings, userConfig } = useContext(AppContext);

  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isInit, setIsInit] = useState<boolean>(false);
  const [companyStatus, setCompanyStatus] = useState<CompanyAccountStatusDto>();
  const [projectSettings, setProjectSettings] = useState<ProjectSettingsDto>();
  const [projectTimezone, setProjectTimezone] = useState<string>(null);

  const pages: { [key: string]: any } = {
    'project-info': {
      label: t('PROJECT_INFORMATION'),
      configKey: 'Project.ProjectInfo',
      component: ProjectInfoPage,
    },
    locations: {
      label: t('LOCATIONS'),
      configKey: 'Project.Locations',
      component: LocationsPage,
    },
    'meeting-settings': {
      label: t('MEETING_SETTINGS'),
      configKey: 'Project.MeetingSettings',
      component: MeetingSettingsPage,
    },
    'rfi-settings': {
      label: t('RFI_SETTINGS'),
      configKey: 'Project.RfiSettings',
      component: RfiSettingsPage,
    },
    'schedule-settings': {
      label: t('SCHEDULE_SETTINGS'),
      configKey: 'Project.ScheduleSettings',
      component: ProjectSchedulePage,
    },
    'submittal-responses': {
      label: t('SUBMITTAL_RESPONSES'),
      configKey: 'Project.SubmittalResponses',
      component: SubmittalResponsesPage,
    },
    'submittal-settings': {
      label: t('SUBMITTAL_SETTINGS'),
      configKey: 'Project.SubmittalSettings',
      component: SubmittalSettingsPage,
    },
    'submittal-workflow-templates': {
      label: t('SUBMITTAL_WORKFLOW_TEMPLATES'),
      configKey: 'Project.SubmittalWorkflowTemplates',
      component: SubmittalWorkflowTemplatesPage,
    },
    'task-items-settings': {
      label: t('TASK_ITEMS_SETTINGS'),
      configKey: 'Project.TaskItemsSettings',
      component: TaskItemsSettingsPage,
    },
    'daily-log-settings': {
      label: t('DAILY_LOG_SETTINGS'),
      configKey: 'Project.DailyLogSettings',
      component: DailyLogSettingsPage,
    },
    'cost-codes': {
      label: t('COST_CODES'),
      configKey: 'Project.CostCodes',
      component: CostCodesPage,
    },
    'prime-contract-settings': {
      label: t('PRIME_CONTRACT_SETTINGS'),
      configKey: 'Project.PrimeContractSettings',
      component: PrimeContractSettingsPage,
    },
    'commitment-settings': {
      label: t('COMMITMENT_SETTINGS'),
      configKey: 'Project.CommitmentSettings',
      component: CommitmentSettingsPage,
    },
    'project-config-history': {
      label: t('PROJECT_CONFIG_HISTORY'),
      configKey: 'Project.ConfigurationChangeHistory',
      component: ProjectConfigurationChangeHistoryPage,
    },
  };

  const currentPage = pages[page];
  const pageConfig = PageConfig.get(t, currentPage.configKey);

  const context: ProjectContextProps = {
    env: searchParams.get('env') ?? 'PROD',
    contactId: Number(searchParams.get('contactId')) || undefined,
    loginId: Number(searchParams.get('loginId')) || undefined,
    companyId: Number(id),
    projectId: Number(projectId),
    dataSources: {
      companyStatus: {
        id: 'companyStatus',
        data: companyStatus!,
      },
      projectSettings: {
        id: 'projectSettinga',
        data: projectSettings!,
      },
    },
    timeZone: projectTimezone!,
    hasPermission: (actions: string[]) => {
      return can({ user: userConfig, subject: companyStatus }, actions, {
        allowDemoAccess: true,
      });
    },
    pageConfig: pageConfig as PageConfigTranslated,
  };

  const loadCompanyStatus = useCallback(async () => {
    alert.closeAlert();
    setCompanyStatus(undefined);
    setIsInit(false);
    try {
      setLoading(true);

      const api = getApi(CompanyInfoApi);
      const res = await api.companyCompanyInfoControllerGetCompanyAccountStatus(
        context.env,
        context.companyId,
      );
      if (res.data) {
        setCompanyStatus(res.data);
      }
      setIsInit(true);
    } catch (e: any) {
      alert.error(e);
    } finally {
      setLoading(false);
    }
  }, [id]);

  const loadProjectSettings = useCallback(async () => {
    setProjectSettings(undefined);
    alert.closeAlert();
    setIsInit(false);
    setLoading(true);
    try {
      const api = getApi(ProjectsApi);
      const res = await api.projectControllerGetProjectSettings(
        context.env,
        context.companyId,
        context.projectId,
      );
      if (res.data) {
        setProjectSettings(res.data);
        setProjectTimezone(
          res.data.location_settings?.time_zone.split(') ')[1],
        );
      }
      setIsInit(true);
    } catch (e: any) {
      alert.error(e);
    } finally {
      setLoading(false);
    }
  }, [id, projectId]);

  useEffect(() => {
    loadCompanyStatus();
    loadProjectSettings();
  }, [id]);

  const changeEnvironment = (newEnv: string) => {
    navigate(
      generateUrl(page, {
        ...context,
        env: newEnv,
      }),
    );
  };

  if (!pages[page]) {
    return <Navigate replace to={'/404'} />;
  }

  const pageConfigV1Path =
    pageConfig.route.v1_path
      ?.replace(':company_id', id)
      .replace(':project_id', projectId) ?? '';

  return (
    <ProjectContextProvider {...context}>
      <Page>
        <Page.Main style={{ flex: 'auto' }}>
          <Page.Header>
            <Page.Breadcrumbs>
              <Breadcrumbs>
                <Link href={`/v2/company/${id}`}>
                  <Breadcrumbs.Crumb>{tCommon('COMPANY')}</Breadcrumbs.Crumb>
                </Link>
                {context.contactId && context.loginId && (
                  <Link
                    href={`/v2/company/${id}/contact/${context.contactId}/login/${context.loginId}/contact-info`}
                  >
                    <Breadcrumbs.Crumb>
                      {tCommon('CONTACT_INFORMATION')}
                    </Breadcrumbs.Crumb>
                  </Link>
                )}
                <Link href={generateProjectManagementUrl('', context)}>
                  <Breadcrumbs.Crumb>
                    {t('PROJECT_MANAGEMENT')}
                  </Breadcrumbs.Crumb>
                </Link>
                <Breadcrumbs.Crumb active>
                  {currentPage.label}
                </Breadcrumbs.Crumb>
              </Breadcrumbs>
            </Page.Breadcrumbs>
            <ToolHeader>
              <ToolHeader.Title>{currentPage.label} </ToolHeader.Title>

              <ToolHeader.Actions style={ActionButtonsStyle}>
                <FlexList size="xs">
                  <EnvironmentSwitcher
                    initialValue={context.env}
                    onChange={changeEnvironment}
                  />
                  <ProjectToolsDropdown {...context} />
                </FlexList>
              </ToolHeader.Actions>
            </ToolHeader>
          </Page.Header>
          <Page.Body>
            <Page.Row>
              <Page.Column>
                <Box paddingBottom="lg" paddingTop="lg">
                  {companyStatus && (
                    <Grid>
                      <Grid.Row>
                        <Grid.Col
                          colWidth={{ tabletSm: 9, tabletMd: 9, tabletLg: 9 }}
                        >
                          {id && <ProjectSelectorComponent page={page} />}
                        </Grid.Col>
                        {can({ user: userConfig }, 'jit_escalate') && (
                          <Grid.Col>
                            <FlexList size="sm" justifyContent="flex-end">
                              <JitWidget
                                env={context.env}
                                companyId={context.companyId}
                                companyName={companyStatus.name}
                                isPinRequired={
                                  companyStatus.is_pin_required_for_jit
                                }
                              />
                            </FlexList>
                          </Grid.Col>
                        )}
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Col>
                          <FlexList size="sm" marginTop="lg">
                            {/* {id && <ProjectSelectorComponent page={page} />} */}
                            <Box data-qa="company-name-div">
                              <CompanyLink
                                companyId={companyStatus.id}
                                companyName={companyStatus.name}
                                env={context.env}
                              />
                              <CompanyPills
                                companyId={context.companyId}
                                env={context.env}
                              />
                            </Box>
                            <Box>
                              <ContextInfoPopup />
                            </Box>
                          </FlexList>
                        </Grid.Col>
                      </Grid.Row>
                    </Grid>
                  )}
                </Box>

                {isLoading && (
                  <Flex justifyContent="center">
                    <Spinner loading={isLoading} />
                  </Flex>
                )}
                {isInit && !isLoading && !companyStatus && (
                  <Card>
                    <Box padding="lg" marginBottom="lg">
                      <EmptyState>
                        <EmptyState.NoItems />
                        <EmptyState.Title>{t('EMPTY_STATE')}</EmptyState.Title>
                      </EmptyState>
                    </Box>
                  </Card>
                )}

                {isInit &&
                  !isLoading &&
                  companyStatus &&
                  React.createElement(pages[page].component)}
              </Page.Column>
            </Page.Row>
          </Page.Body>
        </Page.Main>
      </Page>
    </ProjectContextProvider>
  );
};

export default ProjectPage;
