import React, { useState, useEffect } from 'react';
import * as yup from 'yup';
import type { FormikProps } from 'formik';
import { Modal, Form, Button } from '@procore/core-react';
import type { ISelectOption } from 'interfaces/common.interface';
import type { CompanyCostCodesDto } from '@procore/ipa-nt-api-client-ts';
import { useTranslation } from 'react-i18next';
import { PageConfig } from 'pages/page-config.service';
import type { CardConfigTranslated } from 'pages/page-config.interfaces';

export interface ImportForm {
  standard_cost_code_list_id: ISelectOption;
  file: Blob;
}

interface ImportCompanyCostCodesModalProps {
  show: boolean;
  cost_codes_list: CompanyCostCodesDto[];
  closeModal: () => void;
  importFile: (values: any) => void;
}

const ImportCompanyCostCodesModal = (
  props: ImportCompanyCostCodesModalProps,
) => {
  const { t } = useTranslation();
  const { t: tCommon } = useTranslation(['common']);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [costCodeIds, setCostCodeIds] = useState<ISelectOption[]>([]);
  const config = PageConfig.get(
    t,
    'Contact.CompanyCostCodes',
  ) as CardConfigTranslated;

  useEffect(() => {
    setShowModal(props.show);
    if (props.cost_codes_list) {
      setCostCodeIds(
        props.cost_codes_list.map((c: CompanyCostCodesDto) => {
          return { id: +c.id, label: c.name };
        }),
      );
    }
  }, [props]);

  return (
    <Modal open={showModal} onClickOverlay={props.closeModal} width="md">
      <Form
        view="create"
        enableReinitialize={true}
        initialValues={{ file: '', standard_cost_code_list_id: null }}
        validationSchema={yup.object().shape({
          file: yup.string().required(tCommon('REQUIRED')),
          standard_cost_code_list_id: yup
            .object()
            .nullable()
            .required(tCommon('REQUIRED')),
        })}
        onSubmit={(values) => props.importFile(values)}
      >
        {(formProps: FormikProps<ImportForm>) => {
          const { values, setFieldValue } = formProps;
          return (
            <Form.Form>
              <Modal.Header data-qa="modal-header" onClose={props.closeModal}>
                {config.translate('IMPORT_MODAL_TITLE')}
              </Modal.Header>
              <Modal.Body>
                <Form.Row>
                  <Form.Select
                    name="standard_cost_code_list_id"
                    data-qa="standard-cost-code-list-id"
                    label={config.translate('STANDARD_COST_CODE_LIST_ID')}
                    options={costCodeIds}
                    onSearch={false}
                  />
                </Form.Row>
                <input
                  name="file"
                  type="file"
                  data-qa="file-input"
                  onChange={(e) => {
                    setFieldValue(
                      'file',
                      e.currentTarget.files ? e.currentTarget.files[0] : '',
                    );
                  }}
                />
              </Modal.Body>
              <Modal.Footer>
                <Modal.FooterButtons>
                  <Button
                    variant="tertiary"
                    onClick={props.closeModal}
                    data-qa="close-import-modal-btn"
                  >
                    {tCommon('CANCEL')}
                  </Button>
                  <Button
                    variant="primary"
                    type="submit"
                    disabled={
                      !values.file || !values.standard_cost_code_list_id
                    }
                    data-qa="import-btn"
                  >
                    {tCommon('IMPORT')}
                  </Button>
                </Modal.FooterButtons>
              </Modal.Footer>
            </Form.Form>
          );
        }}
      </Form>
    </Modal>
  );
};

export default ImportCompanyCostCodesModal;
