import React, { useCallback, useEffect, useState, useContext } from 'react';
import {
  Page,
  ToolHeader,
  Flex,
  Spinner,
  Link,
  Breadcrumbs,
  Tabs,
  Pill,
} from '@procore/core-react';
import { useTranslation } from 'react-i18next';
import { IpaAlertContext } from 'contexts/IpaAlertContext';
import { BetaBanner } from 'components/BetaBanner';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { AppContext } from 'contexts/AppContext';
import GeneralTab from './BusinessEntityTabs/GeneralTab';
import SentDisbursementsTab from './BusinessEntityTabs/SentDisbursementsTab';
import ReceivedPayoutsTab from './BusinessEntityTabs/ReceivedPayoutsTab';
import ChangeHistoryTab from './BusinessEntityTabs/ChangeHistoryTab';
import {
  CompanyInfoApi,
  PaymentsServiceBusinessEntitiesApi,
} from '@procore/ipa-nt-api-client-ts';
import type {
  BusinessEntityDto,
  CompanyAccountStatusDto,
} from '@procore/ipa-nt-api-client-ts';
import { getApi } from 'utils/api';
import { PageConfig } from 'pages/page-config.service';
import type { PageConfigTranslated } from 'pages/page-config.interfaces';
import { can } from 'utils/permissions.helper';
import { PaymentsBusinessEntityContextProvider } from 'contexts/PaymentsBusinessEntityContext';
import { getRouteForEnv } from '../payments.helper';
import { AxiosError } from 'axios';

interface IPaymentsTab {
  id: string;
  label: string;
  configKey: string;
  component: React.ComponentType; // or replace with the specific component type if known
}

export const PaymentsBusinessEntityPage = () => {
  const { userConfig, env } = useContext(AppContext);
  const alert = useContext(IpaAlertContext);
  const { t } = useTranslation(['payments']);
  const navigate = useNavigate();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const [companyStatus, setCompanyStatus] = useState<CompanyAccountStatusDto>();
  const [isLoading, setLoading] = useState<boolean>(true);
  const [generalInformation, setGeneralInformation] =
    useState<BusinessEntityDto>();
  const [tabs, setTabs] = useState<IPaymentsTab[]>([]);

  const activeTab = searchParams.get('tab') ?? 'general';

  const pageConfig = PageConfig.get(
    t,
    tabs.find((tab) => tab.id === activeTab)?.configKey ??
      'Payments.BusinessEntityGeneral',
  ) as PageConfigTranslated;

  const dataSources = {
    companyStatus: {
      id: 'companyStatus',
      data: companyStatus!,
    },
    generalInformation: {
      id: 'generalInformation',
      data: generalInformation,
    },
  };

  const changeTab = (newTab: string) => {
    navigate(
      getRouteForEnv(
        env,
        `/business-entity/${companyStatus?.id}?tab=${newTab}`,
      ),
    );
  };

  const hasPermission = (actions: string[]) => {
    return can({ user: userConfig, subject: companyStatus }, actions, {
      allowDemoAccess: false,
    });
  };

  const getGeneralInformation = useCallback(async () => {
    try {
      const api: PaymentsServiceBusinessEntitiesApi = getApi(
        PaymentsServiceBusinessEntitiesApi,
      );

      const response = await api.businessEntityControllerGetBusinessEntity(
        env,
        Number(id),
      );

      // const response = {
      //   data: {
      //     externalId: '39',
      //     externalSource: 'PROCORE',
      //     capabilities: 'send_payments',
      //     active: true,
      //     workflowsEnabled: true,
      //     payOnNewProjectsEnabled: false,
      //     mtlEnabled: false,
      //     payorPaysFeesFeatureEnabled: false,
      //     applyFeesOnAgreements: false,
      //     entityName: 'Ford Industries',
      //     workflowEnabled: false,
      //   },
      // };

      setGeneralInformation(response.data);

      if (response.data) {
        const { capabilities } = response.data;

        const tempTabs: IPaymentsTab[] = [
          {
            id: 'general',
            label: t('BUSINESS_ENTITY.GENERAL'),
            configKey: 'Payments.BusinessEntityGeneral',
            component: GeneralTab,
          },
          {
            id: 'change-history',
            label: t('BUSINESS_ENTITY.CHANGE_HISTORY'),
            configKey: 'Payments.BusinessEntityChangeHistory',
            component: ChangeHistoryTab,
          },
        ];

        if (capabilities === 'send_payments') {
          tempTabs.splice(1, 0, {
            id: 'sent-disbursements',
            label: t('BUSINESS_ENTITY.SENT_DISBURSEMENTS'),
            configKey: 'Payments.BusinessEntitySentDisbursements',
            component: SentDisbursementsTab,
          });
        } else if (capabilities === 'receive_payments') {
          tempTabs.splice(1, 0, {
            id: 'received-payouts',
            label: t('BUSINESS_ENTITY.RECEIVED_PAYOUTS'),
            configKey: 'Payments.BusinessEntityReceivedPayouts',
            component: ReceivedPayoutsTab,
          });
        }

        setTabs(tempTabs);
      }
    } catch (e: any) {
      return e;
    }
  }, [env, id]);

  const loadCompanyStatus = useCallback(async () => {
    try {
      const api = getApi(CompanyInfoApi);
      const res = await api.companyCompanyInfoControllerGetCompanyAccountStatus(
        env,
        Number(id),
      );
      if (res.data) {
        setCompanyStatus(res.data);
      }
    } catch (e: any) {
      // If the company is not found, set the company status to a default value so the the error message is displayed
      setCompanyStatus({
        id: Number(id),
        name: t('BUSINESS_ENTITY.UNKNOWN_NAME'),
        is_active: false,
        is_demo: false,
        enable_sandbox: false,
        account_deactivated: true,
        is_pin_required_for_jit: true,
        creation_date: 0,
        vendor_names: [],
      });
    }
  }, [env, id, t]);

  const initPage = useCallback(async () => {
    setLoading(true);
    const errors: AxiosError[] = [];
    const promises = await Promise.all([
      getGeneralInformation(),
      loadCompanyStatus(),
    ]);
    promises.forEach((promise) => {
      if (promise instanceof AxiosError) {
        errors.push(promise);
      }
    });
    if (errors.length > 0) {
      alert.error(errors);
    }
    setLoading(false);
  }, [getGeneralInformation, loadCompanyStatus]);

  useEffect(() => {
    if (!companyStatus) {
      initPage();
    }
  }, [companyStatus, id, initPage]);

  return (
    <PaymentsBusinessEntityContextProvider
      env={env}
      companyId={id || ''}
      dataSources={dataSources}
      pageConfig={pageConfig}
      hasPermission={hasPermission}
    >
      <Page>
        {isLoading ? (
          <Flex justifyContent="center">
            <Spinner loading={isLoading} />
          </Flex>
        ) : (
          <Page.Main style={{ flex: 'auto' }}>
            <Page.Header>
              <Page.Breadcrumbs>
                <Breadcrumbs>
                  <Link href={`${getRouteForEnv(env, '')}`}>
                    <Breadcrumbs.Crumb>{t('PAYMENTS_TITLE')}</Breadcrumbs.Crumb>
                  </Link>
                  <Breadcrumbs.Crumb
                    active
                  >{`${companyStatus?.name} (${companyStatus?.id})`}</Breadcrumbs.Crumb>
                </Breadcrumbs>
              </Page.Breadcrumbs>
              <ToolHeader>
                <ToolHeader.Title>
                  {`${companyStatus?.name} (${companyStatus?.id}) `}
                  {env === 'INTEGRATOR_SANDBOX' && (
                    <Pill color="red">{t('INTEGRATOR_SANDBOX')}</Pill>
                  )}
                </ToolHeader.Title>
              </ToolHeader>
              <Page.Tabs>
                <Tabs>
                  {tabs.map((tab, i) => (
                    <Tabs.Tab
                      key={i}
                      data-qa={`tab-${tab.id}`}
                      active={activeTab === tab.id}
                      onClick={() => changeTab(tab.id)}
                    >
                      <Tabs.Link>{tab.label}</Tabs.Link>
                    </Tabs.Tab>
                  ))}
                </Tabs>
              </Page.Tabs>
            </Page.Header>
            <Page.Body>
              <Page.Row>
                <Page.Column>
                  {tabs.map(
                    (tab, idx) =>
                      activeTab === tab.id &&
                      React.createElement(tab.component, {
                        key: idx,
                        // env: env,
                      }),
                  )}
                </Page.Column>
              </Page.Row>
            </Page.Body>
          </Page.Main>
        )}
      </Page>
    </PaymentsBusinessEntityContextProvider>
  );
};

export default PaymentsBusinessEntityPage;
