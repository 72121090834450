// ***** PARAMETERS *****
// email: string
// name: string
// roles: string
// groups: string
//

import React, { useState, useEffect } from 'react';
import { Popover, Avatar, Link, FlexList } from '@procore/core-react';
import { UserMenuContent, styles } from './styles';
import { useTranslation } from 'react-i18next';
import { getNavPath } from 'components/AppHeader/ipaNavMap';

export interface UserMenuProps {
  name?: string;
  email: string;
  roles: string;
  groups: string;
}

export const UserMenu = (props: UserMenuProps) => {
  const [initials, setInitials] = useState<string>('');
  const { t } = useTranslation('header');

  useEffect(() => {
    setInitials(() =>
      props.name
        ? props.name
            .split(' ')
            .map((s) => s[0])
            .join('')
        : '',
    );
  }, [props]);

  const overlay = (
    <Popover.Content style={styles.popover} data-qa="user-overlay">
      <UserMenuContent>
        <ul>
          <li>
            <strong>{t('USER_MENU_USER')}:</strong> {props.email}
          </li>
          <li>
            <strong>{t('USER_MENU_ROLES')}:</strong> {props.roles}
          </li>
          <li>
            <strong>{t('USER_MENU_GROUPS')}:</strong> {props.groups}
          </li>
          <li>
            <Link href={getNavPath('USER_MY_ACTIVITY')}>
              {t('USER_MY_ACTIVITY')}
            </Link>
          </li>
        </ul>
        <FlexList justifyContent="space-between" style={styles.settingsRow}>
          <Link
            href={getNavPath('USER_SETTINGS')}
            // </Box>href='/settings'
            // onClick={() =>
            //   props.amplitude_tracking('ipa.ipa_navigation.settings.clicked')
            // }
          >
            {t('USER_SETTINGS')}
          </Link>
          <Link
            href="/logout"
            // onClick={() =>
            //   props.amplitude_tracking('ipa.ipa_navigation.logout.clicked')
            // }
          >
            {t('USER_LOGOUT')}
          </Link>
        </FlexList>
      </UserMenuContent>
    </Popover.Content>
  );

  return (
    <Popover
      aria-labelledby="initials"
      overlay={overlay}
      placement="bottom-left"
    >
      {() => (
        <Avatar data-qa="ipa-nav-user" size="lg" role="button">
          <Avatar.Label>{initials}</Avatar.Label>
        </Avatar>
      )}
    </Popover>
  );
};

export default UserMenu;
