import type { PageConfig } from 'pages/page-config.interfaces';

const pageConfig: PageConfig = {
  label: 'INFO_TAB.PROJECT_LOCATION',
  route: {
    page: 'project-info',
    tab: 'location',
    v1_path: '/project-info/company/:company_id/project/:project_id#location',
  },
  endpoints: {
    view: ['projectControllerGetProjectSettings'],
  },
  cards: {
    ProjectLocation: {
      i18n: {
        keyPrefix: 'INFO_TAB.PROJECT_LOCATION_TAB',
      },
      label: 'TITLE',
      fields: {
        country: {
          label: 'COUNTRY',
        },
        time_zone: {
          label: 'TIMEZONE',
        },
        address: {
          label: 'ADDRESS',
          tooltip: 'ADDRESS_TOOLTIP',
        },
        city: {
          label: 'CITY',
        },
        state: {
          label: 'STATE',
        },
        zip: {
          label: 'ZIP',
        },
        county: {
          label: 'COUNTY',
        },
        phone: {
          label: 'PHONE',
        },
        location: {
          label: 'LOCATION',
          tooltip: 'LOCATION_TOOLTIP',
        },
        location_latitude: {
          label: 'LATTITUDE',
        },
        location_longitude: {
          label: 'LONGITUDE',
        },
        fax: {
          label: 'FAX',
        },
      },
    },
  },
};

export default pageConfig;
