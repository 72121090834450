export interface IAuthzSubject {
  id: number;
  is_demo: boolean;
}

export interface IAuthzReq {
  user: {
    permissionActions: string[];
  };
  subject?: IAuthzSubject;
}

export interface IAuthzOptions {
  allowDemoAccess?: boolean;
}

export const can = (
  request: IAuthzReq,
  actions: string | string[] | undefined,
  options?: IAuthzOptions,
): boolean => {
  if (!actions) return false;
  const _actions = Array.isArray(actions) ? actions : [actions];

  const result = _actions.some((action) => {
    if (!request.user.permissionActions) {
      return false;
    } else if (request.user.permissionActions.includes(action)) {
      return true;
    } else if (
      options?.allowDemoAccess &&
      request.subject &&
      request.subject.is_demo
    ) {
      return request.user.permissionActions.includes('demo_access');
    } else {
      return false;
    }
  });

  return result;
};
