import React from 'react';
import GeneralScheduleSettingsCard from './GeneralScheduleSettingsCard';
import ProjectScheduleEmailCard from './ProjectScheduleEmailCard';
import ResourceScheduleEmailCard from './ResourceScheduleEmailCard';
import LookaheadScheduleEmailCard from './LookaheadScheduleEmailCard';
import type { IEditableCard } from 'components/EditableCard';

export interface ProjectScheduleSettingsProps {
  id: string;
  save: IEditableCard['save'];
  saveEmailSettings?: IEditableCard['save'];
}

const SettingsTab = (props: ProjectScheduleSettingsProps) => {
  return (
    <>
      <GeneralScheduleSettingsCard id={props.id} save={props.save} />
      <ProjectScheduleEmailCard id={props.id} save={props.saveEmailSettings} />
      <ResourceScheduleEmailCard id={props.id} save={props.saveEmailSettings} />
      <LookaheadScheduleEmailCard
        id={props.id}
        save={props.saveEmailSettings}
      />
    </>
  );
};

export default SettingsTab;
