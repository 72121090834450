import type { PageConfig } from 'pages/page-config.interfaces';

export default {
  label: 'TITLE',
  route: {
    v1_path:
      '/customer-success/custom-reports-tabs/company/:company_id/details/:report_id',
    path: '/v2/company/:company_id/custom-reports-tabs/:report_id',
  },
  i18n: {
    namespace: 'custom-report-tabs-details',
  },
  permissions: {
    view: ['company_tab_view', 'cs_view'],
  },
  cards: {
    ReportDetails: {
      i18n: {
        namespace: 'custom-report-tabs-details',
      },
      endpoints: {
        view: ['customReportTabsControllerGetReportDetails'],
      },
      label: 'REPORT_INFORMATION',
      fields: {
        report_name: {
          label: 'REPORT_INFO.NAME_ID',
        },
        company: {
          label: 'REPORT_INFO.COMPANY',
        },
        project: {
          label: 'REPORT_INFO.PROJECT',
        },
        creator: {
          label: 'REPORT_INFO.CREATOR',
        },
        created_at: {
          label: 'REPORT_INFO.CREATED_AT',
        },
        tabs: {
          label: 'REPORT_INFO.TABS',
        },
      },
    },
    ReportPermissions: {
      i18n: {
        namespace: 'custom-report-tabs-details',
        keyPrefix: 'REPORT_PERMISSIONS',
      },
      label: 'REPORT_PERMISSIONS',
      endpoints: {
        view: ['customReportTabsControllerGetReportDetails'],
      },
      fields: {},
    },
  },
} as PageConfig;
