import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Spinner } from '@procore/core-react';
import { AppContext } from 'contexts/AppContext';
import { useNavigate } from 'react-router-dom';
import PaymentsServiceChangeHistoryCard from './PaymentsServiceChangeHistoryCard';
import IpaPaymentsChangeHistoryCard from './IpaPaymentsChangeHistoryCard';

const ChangeHistoryTab = () => {
  const { t } = useTranslation(['payments']);
  const { t: tCommon } = useTranslation(['common']);
  const { userSettings } = useContext(AppContext);
  const navigate = useNavigate();

  const [isLoading, setLoading] = useState<boolean>(false);
  const [isInit, setIsInit] = useState<boolean>(true);

  return isLoading ? (
    <Flex justifyContent="center">
      <Spinner loading={isLoading} />
    </Flex>
  ) : (
    <React.Fragment>
      <PaymentsServiceChangeHistoryCard id="changeHistory" />
      <IpaPaymentsChangeHistoryCard id="ipaChangeHistory" />
    </React.Fragment>
  );
};

export default ChangeHistoryTab;
