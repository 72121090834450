import type { PageConfig } from 'pages/page-config.interfaces';

const page: PageConfig = {
  route: {
    page: 'conversations',
  },
  label: 'TITLE',
  i18n: {
    namespace: 'company',
    keyPrefix: 'CONVERSATIONS',
  },
  endpoints: {
    view: [],
  },
  cards: {
    Access: {
      i18n: {
        keyPrefix: 'CONVERSATIONS',
      },
      fields: {
        conversations_switch: {
          label: 'SWITCH_LABEL',
          tooltip: 'SWITCH_TOOLTIP',
        },
        selected_projects: {
          label: 'SELECTED_PROJECTS_LABEL',
        },
        projects: {
          label: 'PROJECTS',
        },
      },
      permissions: {
        edit: ['company_tab_edit'],
      },
    },
    Features: {
      i18n: {
        keyPrefix: 'CONVERSATIONS',
      },
      fields: {
        edit_delete: {
          label: 'EDIT_DELETE_LABEL',
          tooltip: 'EDIT_DELETE_TOOLTIP',
        },
        direct_messages: {
          label: 'DIRECT_MESSAGES_LABEL',
          tooltip: 'DIRECT_MESSAGES_TOOLTIP',
        },
        item_conversations: {
          label: 'ITEM_CONVERSATIONS_LABEL',
          tooltip: 'ITEM_CONVERSATIONS_TOOLTIP',
        },
      },
      permissions: {
        edit: ['company_tab_edit'],
      },
    },
  },
};

export default page;
