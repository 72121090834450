import styled from 'styled-components';
import { colors, spacing } from '@procore/core-react';

export const SearchWrapper = styled.div`
  position: relative;
  button {
    position: absolute;
    top: 25px;
    right: 1px;
    height: 34px;
  }
`;

export const SelectedCompany = styled.div`
  padding-top: ${spacing.xl}px;
  display: flex;

  .greenIcon {
    color: ${colors.green50};
    display: inline;
    margin-right: 5px;
  }

  .redIcon {
    color: ${colors.red50};
    display: inline;
    margin-right: 5px;
  }
`;

export const FormDescription = styled.div`
  margin-bottom: 20px;
  span {
    color: ${colors.red50};
  }
  ul {
    list-style-type: '- ';
    li {
      margin: 5px 0 5px 20px;
      padding-left: 5px;
    }
  }
`;

export const styles = {
  companyName: {
    display: 'inline-block',
    marginTop: '25px',
  },
  greenCheckMark: {
    color: colors.green40,
    marginRight: '5px',
  },
  superuserWarning: {
    marginBottom: spacing.lg,
  },
  jitMfaNotice: {
    color: 'red',
  },
};
