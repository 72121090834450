import type { SectionConfig } from 'pages/page-config.interfaces';

const sectionConfig: SectionConfig = {
  id: 'SuperUser',
  i18n: {
    namespace: 'super-user',
  },
  route: {
    path: '/v2/super-user',
    v1_path: '/su',
  },
  permissions: {
    view: ['su'],
    edit: ['su'],
  },
  endpoints: {
    view: ['superUserControllerGetSessionsForEmail'],
    edit: [
      'superUserControllerPromote',
      'superUserControllerDemote',
      'superUserControllerExtend',
    ],
  },
  pages: {
    SuperUser: {
      i18n: {
        namespace: 'super-user',
      },
      label: 'SU_HOME_TITLE',
      permissions: {
        view: ['su'],
        edit: ['su'],
      },
      cards: {},
    },
  },
};

export default sectionConfig;
