import React, { useContext, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Box, H2, Title, Button } from '@procore/core-react';
import { ChevronDown, ChevronRight } from '@procore/core-icons';
import { ContactContext } from 'contexts/ContactContext';
import type { IEditableCardProps } from 'pages/Contact/interfaces';
import TableComponent from 'components/Table';
import type { TableHeader } from 'components/Table/table.interface';
import { Check, Ban } from '@procore/core-icons';
import type { CompanyUserToolPermissionsDto } from '@procore/ipa-nt-api-client-ts';
import { InfoTabContext } from './index';
import { ipaAppStyles } from 'styles';
import MessageBanner from 'components/MessageBanner';

export const CompanyToolPermissionsCard = (props: IEditableCardProps) => {
  const { t: tCommon } = useTranslation(['common']);
  const { pageConfig, powerUser } = useContext(ContactContext);
  const config = pageConfig.card('CompanyToolPermissions');
  const [filteredToolPermissions, setFilteredToolPermissions] =
    useState<CompanyUserToolPermissionsDto['rows']>(null);
  const {
    dataSources: { contact },
  } = useContext(ContactContext);
  const {
    dataSources: { companyUserToolPermissions },
  } = useContext(InfoTabContext);
  const [contactData, setContactData] = useState(null);
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const contactName = contactData
    ? `${contactData?.first_name} ${contactData?.last_name}`
    : '';
  const permissions = companyUserToolPermissions.data;
  if (permissions && !permissions.permission_template) {
    permissions.permission_template = { name: tCommon('N/A') };
  }

  const displayPermission = useCallback((item, column) => {
    return item.user_access_level.name === column ? (
      <Check size="sm" style={ipaAppStyles.success} />
    ) : (
      <Ban size="sm" style={ipaAppStyles.failure} />
    );
  }, []);

  const permissionsHeaders: TableHeader[] = [
    {
      title: '',
      node: 'label',
      is_text: true,
      is_sortable: false,
      column_width: 4,
    },
    {
      title: 'None',
      node: 'user_access_level',
      display_func: (item) => {
        return displayPermission(item, 'None');
      },
    },
    {
      title: 'Read Only',
      node: 'user_access_level',
      display_func: (item) => {
        return displayPermission(item, 'Read Only');
      },
    },
    {
      title: 'Standard',
      node: 'user_access_level',
      display_func: (item) => {
        return displayPermission(item, 'Standard');
      },
    },
    {
      title: 'Admin',
      node: 'user_access_level',
      display_func: (item) => {
        return displayPermission(item, 'Admin');
      },
    },
  ];

  useEffect(() => {
    const ftp = companyUserToolPermissions.data?.rows.filter((p) => {
      if (
        !p.label.toLowerCase().includes('generic') &&
        !p.name.toLowerCase().includes('generic')
      ) {
        return p;
      }
    });
    setFilteredToolPermissions(ftp);
  }, [companyUserToolPermissions.data?.rows]);

  useEffect(() => {
    if (contact) setContactData(contact.data);
  }, [contact]);

  return (
    <Box>
      {powerUser && (
        <Box marginBottom="md">
          <MessageBanner
            banner_type="info"
            banner_title={config.translate('POWER_USER_TITLE', {
              NAME: `${contactName}`,
            })}
            banner_message={config.translate('POWER_USER_MESSAGE')}
          />
        </Box>
      )}
      <Title style={ipaAppStyles.titleMargin}>
        <Title.Assets>
          <Button variant="tertiary" onClick={() => setCollapsed(!collapsed)}>
            {!collapsed ? <ChevronDown /> : <ChevronRight />}
          </Button>
        </Title.Assets>
        <Title.Text>
          <H2 data-qa={'erp-integrations-title'}>
            {config.translate('TITLE', {
              NAME: `${contactName}`,
            })}
          </H2>
        </Title.Text>
      </Title>
      {!collapsed && (
        <Box>
          <Form view="read" initialValues={permissions}>
            <Form.Form>
              <Form.Text
                name="permission_template.name"
                label={config.translate('PERMISSIONS_TEMPLATE')}
                data-qa="permissions-template"
              />
            </Form.Form>
          </Form>
          <TableComponent
            headers={permissionsHeaders}
            data={filteredToolPermissions || []}
            show_search={true}
            search_columns={['label']}
            default_sort_column="label"
            default_sort_direction="asc"
            show_no_data_message={true}
            no_data_message={tCommon('NO_RESULTS')}
            hide_pagination={true}
            sticky_headers={true}
          />
        </Box>
      )}
    </Box>
  );
};

export default CompanyToolPermissionsCard;
