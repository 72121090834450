import type { PageConfig } from 'pages/page-config.interfaces';

export default <PageConfig>{
  label: 'OKTA_GROUPS_TITLE',
  route: {
    path: '/v2/okta-groups',
  },
  endpoints: {
    view: [
      'oktaControllerGetOktaGroups',
      'oktaControllerGetOktaGroupUsers',
      'oktaControllerExportUserProfiles',
      'oktaControllerExportUsersInGroups',
      'oktaControllerExportUsersEffectiveOktaGroups',
      'oktaControllerExportGroupUsers',
    ],
  },
  cards: {
    UserOktaProfile: {
      label: 'USER_OKTA_PROFILE_TITLE',
      fields: {},
      endpoints: {
        view: ['oktaControllerGetOktaUserProfile'],
      },
    },
  },
};
