import type { SectionConfig } from 'pages/page-config.interfaces';
import AccessControl from './AccessControl/page.config';
import Advanced from './Advanced/page.config';
import ChangeHistory from './ChangeHistory/page.config';
import CompanyInfo from './CompanyInfo/page.config';
import Settings from './Settings/page.config';
import AppInstallations from './AppInstallations/page.config';
import ErpSettings from './ErpSettings/page.config';
import Events from './Events/page.config';
import NextGenSettings from './NextGenSettings/page.config';
import PdfTemplateConfig from './PdfTemplateConfig/page.config';
import SsoSettings from './SsoSettings/page.config';
import Tools from './Tools/page.config';
import OutboundEmailsReport from 'pages/Contact/OutboundEmailsReport/page.config';
import Conversations from './Conversations/page.config';

export default {
  id: 'Company',
  label: 'COMPANY',
  route: {
    path: '/v2/company/:company_id/:page?',
    v1_path: '/company/:company_id',
  },
  i18n: {
    namespace: 'common',
  },
  permissions: {
    view: ['company_tab_view'],
    edit: ['company_tab_edit'],
  },
  pages: {
    AccessControl,
    AppInstallations,
    Advanced,
    ChangeHistory,
    CompanyInfo,
    ErpSettings,
    Events,
    NextGenSettings,
    PdfTemplateConfig,
    Settings,
    SsoSettings,
    Tools,
    OutboundEmailsReport,
    Conversations,
  },
} as SectionConfig;
