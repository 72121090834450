import React, { useContext, useEffect, useState } from 'react';
import { ProjectsTabContext } from '.';
import type { ProjectCardProps } from '.';
import { AppContext } from 'contexts/AppContext';
import { ContactContext } from 'contexts/ContactContext';
import { Box, Title, H2 } from '@procore/core-react';
import TableBackendComponent from 'components/TableBackend';
import ProjectToolsDropdown from 'components/ProjectToolsDropdown';
import { PageConfig } from 'pages/page-config.service';
import type { CardConfigTranslated } from 'pages/page-config.interfaces';
import { useTranslation } from 'react-i18next';
import { ipaAppStyles } from 'styles';

export const ProjectList = (props: ProjectCardProps) => {
  const { t } = useTranslation('contact');
  const { userSettings } = useContext(AppContext);
  const context = useContext(ContactContext);
  const projectsTabContext = useContext(ProjectsTabContext);

  const config = PageConfig.get(
    t,
    'Contact.Projects.' + props.id,
  ) as CardConfigTranslated;

  const [searchStr, setSearchStr] = useState<string>('');
  const [perPage, setPerPage] = useState<number>(
    userSettings.default_page_size as number | 25,
  );
  const [currentPage, setCurrentPage] = useState<number>(1);

  const changePageSize = (pageSize: number) => {
    setCurrentPage(1);
    setPerPage(pageSize);
  };

  const changePage = (page: number, pageSize: number) => {
    setCurrentPage(page);
  };

  const searchProjects = (value: string) => {
    setCurrentPage(1);
    setSearchStr(value);
  };

  useEffect(() => {
    props.search({
      page: currentPage,
      perPage,
      query: searchStr,
    });
  }, [currentPage, perPage, searchStr]);

  const projectLink = `/v2/company/${context.companyId}/contact/${context.contactId}/login/${context.loginId}/project-settings?project_id={project_id}`;

  const projectTableHeaders = [
    {
      title: t('PROJECTS_PAGE.PROJECT_NUMBER'),
      node: 'project_number',
      is_sortable: false,
      is_link: true,
      url: projectLink,
    },
    {
      title: t('PROJECTS_PAGE.PROJECT_ID'),
      node: 'project_id',
      is_sortable: false,
      is_link: true,
      url: projectLink,
    },
    {
      title: t('PROJECTS_PAGE.PROJECT_NAME'),
      node: 'project_name',
      is_sortable: false,
      is_link: true,
      url: projectLink,
    },
    {
      title: t('PROJECTS_PAGE.CURRENT_PERMISSION_TEMPLATE'),
      node: 'current_permission_template',
      is_text: true,
      is_sortable: false,
    },
    {
      title: t('PROJECTS_PAGE.HAS_TEAM_MEMBERS'),
      node: 'has_team_members',
      is_boolean: true,
      is_sortable: false,
    },
    {
      title: t('PROJECTS_PAGE.TOOL_SETTINGS'),
      node: '',
      is_sortable: false,
      display_func: (project: any) => (
        <ProjectToolsDropdown
          {...{ ...context, projectId: project.project_id }}
        />
      ),
    },
  ];

  const contact = context.dataSources.contact?.data;
  const projectList =
    props.id === 'ProjectsNotBelongingTo'
      ? projectsTabContext.dataSources.projectsNotBelongingTo?.data
      : projectsTabContext.dataSources.projectsBelongingTo?.data;

  if (!contact) {
    return null;
  }

  return (
    <Box padding="lg">
      <Title style={ipaAppStyles.titleMargin}>
        <Title.Text>
          <H2 data-qa={`${props.id}-title`}>
            {config.translate('TITLE', {
              NAME: `${contact.first_name ?? ''} ${contact.last_name ?? ''}`,
            })}
          </H2>
        </Title.Text>
      </Title>
      <TableBackendComponent
        data={projectList?.projects ?? []}
        data_total={projectList?.total ?? 0}
        page_size={perPage}
        show_no_data_message={true}
        no_data_message="No projects"
        show_page_size_select={true}
        default_sort_column=""
        default_sort_direction={'asc'}
        paginator={changePage}
        headers={projectTableHeaders}
        sorter={() => {}}
        show_search={true}
        search_action={(v) => searchProjects(v)}
        search_placeholder={t('PROJECTS_PAGE.TABLE_SEARCH_PLACEHOLDER')}
        page_size_action={changePageSize}
        initial_search={searchStr}
        current_page={currentPage}
      />
    </Box>
  );
};
