import React, { useState } from 'react';
import { Box, Button, Card, Form, H2, Title } from '@procore/core-react';
import { ChevronDown, ChevronRight } from '@procore/core-icons';
import { FormWrapper, ipaAppStyles } from 'styles';
import type { FormikProps } from 'formik';
import type { IErpSettings } from './erp.settings.interface';
import ErpSettingsForm from './ErpSettingsForm';
import { useTranslation } from 'react-i18next';
import { ErpEditSettingsModal } from './ErpEditSettingsModal';

export interface ErpSettingsCardProps {
  email: string;
  canEdit: boolean;
  erpSettings: IErpSettings;
  saveErpSettings(values: IErpSettings): void;
  isCollapsible?: boolean;
}

export const ErpSettingsCard = (props: ErpSettingsCardProps) => {
  const { t } = useTranslation(['company']);
  const [showModal, setShowModal] = React.useState(false);
  const [collapsed, setCollapsed] = useState<boolean>(false);

  return (
    <React.Fragment>
      <Card>
        <Box padding="lg" marginBottom="lg">
          <Title
            style={ipaAppStyles.titleMargin}
            data-qa={'erp-settings-header'}
          >
            {props.isCollapsible && (
              <Title.Assets>
                <Button
                  variant="tertiary"
                  onClick={() => setCollapsed(!collapsed)}
                >
                  {!collapsed ? <ChevronDown /> : <ChevronRight />}
                </Button>
              </Title.Assets>
            )}
            <Title.Text>
              <H2>
                {t('ERP_SETTINGS.ERP_SETTINGS_TITLE', { email: props.email })}
              </H2>
            </Title.Text>
            <Title.Actions>
              {props.canEdit && !showModal && (
                <Button
                  variant="secondary"
                  onClick={() => setShowModal(true)}
                  data-qa={'erp-settings-edit-button'}
                >
                  {t('EDIT', { ns: 'common' })}
                </Button>
              )}
            </Title.Actions>
          </Title>
          {props.erpSettings && !collapsed && (
            <FormWrapper>
              <Form
                view="read"
                enableReinitialize={true}
                initialValues={{
                  subscribed_to_erp_digest_emails:
                    props.erpSettings.subscribed_to_erp_digest_emails,
                  can_push_to_accounting:
                    props.erpSettings.can_push_to_accounting,
                }}
              >
                {(formProps: FormikProps<IErpSettings>) => {
                  const { setFieldValue } = formProps;
                  return (
                    <Form.Form>
                      <ErpSettingsForm
                        data={props.erpSettings}
                        setFieldValue={setFieldValue}
                      />
                    </Form.Form>
                  );
                }}
              </Form>
            </FormWrapper>
          )}
        </Box>
      </Card>
      {props.erpSettings && (
        <ErpEditSettingsModal
          edit={props.saveErpSettings}
          data={props.erpSettings}
          isOpen={showModal}
          closeModal={() => setShowModal(false)}
        />
      )}
    </React.Fragment>
  );
};
