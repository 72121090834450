import React, { useState, useContext, useEffect } from 'react';
import { H1, FlexList, Box, Card, Spinner, H2 } from '@procore/core-react';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { AppContext } from 'contexts/AppContext';
import { InsightsContext } from 'contexts/InsightsContext';
import { IpaAlertContext } from 'contexts/IpaAlertContext';
import ZoneSelector from '../ZoneSelector';
import { InsightsApi } from '@procore/ipa-nt-api-client-ts';
import type {
  CustomFieldsReportDto,
  InsightsCustomFieldsReportDto,
} from '@procore/ipa-nt-api-client-ts';
import { getApi } from 'utils/api';
import { TableBackendComponent } from 'components/TableBackend';
import type { TableHeader } from 'components/TableBackend/table.interface';

interface CustomFieldsCard {
  id: string;
  title: string;
  isLoading: boolean;
  page: number;
  total: number;
  data: CustomFieldsReportDto[];
}

const CustomFieldsReportPage = () => {
  const { t } = useTranslation(['insights'], {
    keyPrefix: 'CUSTOM_FIELDS_REPORT',
  });
  const { t: tCommon } = useTranslation(['common']);
  const { userSettings, env } = useContext(AppContext);
  const alert = useContext(IpaAlertContext);
  const { zone } = useContext(InsightsContext);
  const [perPage] = useState<number>(userSettings.default_page_size | 25);
  const [prevZone, setPrevZone] = useState<string>(zone.label);
  const [cards, setCards] = useState<CustomFieldsCard[]>([
    {
      id: 'prime_contract',
      title: t('PRIME_CONTRACT'),
      isLoading: true,
      page: 1,
      total: 0,
      data: [],
    },
    {
      id: 'submittal_log',
      title: t('SUBMITTAL_LOG'),
      isLoading: true,
      page: 1,
      total: 0,
      data: [],
    },
    {
      id: 'commitments',
      title: t('COMMITMENTS'),
      isLoading: true,
      page: 1,
      total: 0,
      data: [],
    },
    {
      id: 'rfi',
      title: t('RFI'),
      isLoading: true,
      page: 1,
      total: 0,
      data: [],
    },
    {
      id: 'transmittals',
      title: t('TRANSMITTALS'),
      isLoading: true,
      page: 1,
      total: 0,
      data: [],
    },
  ]);

  const tableHeaders: TableHeader[] = [
    {
      title: t('COMPANY_PROJECT'),
      node: 'provider',
      is_text: true,
    },
    { title: t('FIELD_TITLE'), node: 'field_title', is_text: true },
    {
      title: t('FIELD_TYPE'),
      node: 'field_type',
      is_text: true,
    },
  ];

  const resetCards = () => {
    const temp = [...cards];
    temp.forEach((card: CustomFieldsCard) => {
      card.isLoading = true;
      card.page = 1;
      card.total = 0;
      card.data = [];
      return card;
    });
    setCards(temp);
  };

  const updateCard = (cardId: string, data: InsightsCustomFieldsReportDto) => {
    const temp = [...cards];
    temp.forEach((card: CustomFieldsCard) => {
      if (card.id === cardId) {
        card.data = data.custom_fields;
        card.total = data.total;
        card.page = +data.page;
        card.isLoading = false;
      }
      return card;
    });
    setCards(temp);
  };

  const changePage = async (
    page: number,
    _pageSize: number,
    domain: string,
  ) => {
    const card = getCard(domain);
    const res = await getCustomFieldsReport(zone.label, {
      ...card,
      page: page,
    });
    updateCard(res.id, res.response);
  };

  const getCard = (domain: string) => {
    return cards.find((card: CustomFieldsCard) => card.id === domain);
  };

  const getCustomFieldsReport = async (
    zone: string,
    card: CustomFieldsCard,
  ) => {
    try {
      const api = getApi(InsightsApi);
      const result = await api.insightsControllerGetCustomFieldsReport(
        env,
        zone,
        card.id,
        card.page,
        perPage,
      );

      return { response: result.data, id: card.id };
    } catch (e: any) {
      alert.error(e);
    }
  };

  useEffect(() => {
    if (zone) {
      if (zone.label !== prevZone) {
        resetCards();
        setPrevZone(zone.label);
      }
      const errors: AxiosError[] = [];
      const getData = async () => {
        const promises = await Promise.all(
          cards.map((card: any) => getCustomFieldsReport(zone.label, card)),
        );

        promises.forEach((promise: any) => {
          if (promise instanceof AxiosError) {
            errors.push(promise);
          } else {
            updateCard(promise.id, promise.response);
          }
        });
        if (errors.length > 0) {
          alert.error(errors);
        }
      };

      getData();
    }
  }, [zone]);

  return (
    <React.Fragment>
      <FlexList>
        <H1>{t('TITLE')}</H1>
      </FlexList>
      <ZoneSelector />
      {cards.map((card: any) => (
        <Box key={`${card.id}-custom-fields`} marginTop="lg">
          <Card>
            <Box padding="lg">
              <H2>{card.title}</H2>
              {card.isLoading && (
                <Box padding="lg">
                  <FlexList justifyContent="center">
                    <Spinner loading={card.isLoading} />
                  </FlexList>
                </Box>
              )}
              {!card.isLoading && (
                <TableBackendComponent
                  headers={tableHeaders}
                  data={card.data}
                  data_total={card.total}
                  current_page={card.page}
                  page_size={perPage}
                  paginator={changePage}
                  sorter={() => {}}
                  default_sort_column=""
                  default_sort_direction=""
                  show_no_data_message={true}
                  no_data_message={tCommon('NO_DATA')}
                  paginator_selector={card.id}
                />
              )}
            </Box>
          </Card>
        </Box>
      ))}
    </React.Fragment>
  );
};

export default CustomFieldsReportPage;
