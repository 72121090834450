import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Card, Flex, Spinner, Box, Button } from '@procore/core-react';
import TableBackendComponent from 'components/TableBackend';
import type { TableHeader } from 'components/TableBackend/table.interface';
import { AppContext } from 'contexts/AppContext';
import { IpaAlertContext } from 'contexts/IpaAlertContext';
import { useNavigate } from 'react-router-dom';
import {
  PaymentsServiceBusinessEntitiesApi,
  PaymentsFundingAccountApi,
} from '@procore/ipa-nt-api-client-ts';
import type {
  SentDisbursementDto,
  ExternalAccountDto,
} from '@procore/ipa-nt-api-client-ts';
import { getApi } from 'utils/api';
import {
  formatCurrency,
  getDisbursementStatus,
  getRouteForEnv,
} from 'pages/Payments/payments.helper';
import { PaymentsBusinessEntityContext } from 'contexts/PaymentsBusinessEntityContext';

const SentDisbursementsTab = () => {
  const { id: companyId } = useParams();
  const { t } = useTranslation(['payments'], {
    keyPrefix: 'BUSINESS_ENTITY.SENT_DISBURSEMENTS_TAB',
  });
  const { t: tCommon } = useTranslation(['common']);
  const { t: tStatus } = useTranslation(['payments'], {
    keyPrefix: 'STATUS',
  });
  const { userSettings } = useContext(AppContext);
  const alert = useContext(IpaAlertContext);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isInit, setIsInit] = useState<boolean>(false);
  const [total, setTotal] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(
    userSettings.default_page_size as number | 25,
  );
  const [sentDisbursemnts, setSentDisbursements] = useState<
    SentDisbursementDto[]
  >([]);

  const context = useContext(PaymentsBusinessEntityContext);

  const getSentDisbursements = async (
    companyId: number,
    currentPage: number,
    perPage: number,
  ) => {
    try {
      const businessEntityApi: PaymentsServiceBusinessEntitiesApi = getApi(
        PaymentsServiceBusinessEntitiesApi,
      );

      const result =
        await businessEntityApi.businessEntityControllerGetDisbursements(
          context.env,
          companyId,
          currentPage,
          perPage,
          '',
        );

      const fundingAccountsApi: PaymentsFundingAccountApi = getApi(
        PaymentsFundingAccountApi,
      );

      const externalAccounts =
        await fundingAccountsApi.fundingAccountControllerGetExternalAccounts(
          context.env,
          companyId,
        );

      if (result.data) {
        const disbursements = result.data.items.map(
          (item: SentDisbursementDto) => {
            const externalAccount = externalAccounts.data.data.find(
              (account: ExternalAccountDto) =>
                account.id === item.sendingExternalAccountId,
            );
            return {
              ...item,
              sendingExternalAccountDisplay: !externalAccount
                ? item.sendingExternalAccountId
                : `${externalAccount.bankName} (...${externalAccount.lastFour})`,
            };
          },
        );
        return {
          items: disbursements,
          total: result.data.total,
          page: currentPage,
          pageSize: perPage,
        };
      }
    } catch (e: any) {
      alert.error(e);
    }
  };

  const viewCell = (item: SentDisbursementDto) => {
    return (
      <span>
        <Button
          variant="secondary"
          size="sm"
          data-qa={`view-disbursement-${item.id}`}
          onClick={() =>
            navigate(
              getRouteForEnv(
                context.env,
                `/business-entity/${companyId}/disbursement/${item.id}`,
              ),
            )
          }
        >
          {tCommon('VIEW')}
        </Button>
      </span>
    );
  };

  const changePage = (page: number, _pageSize: number) => {
    setCurrentPage(page);
  };

  const tableHeaders: TableHeader[] = [
    {
      title: '',
      node: '',
      is_sortable: false,
      display_func: viewCell,
      column_width: 10,
    },
    {
      title: t('DISBURSEMENT_NUMBER'),
      node: 'disbursementIdentifier',
      is_text: true,
      is_sortable: false,
      column_width: 15,
    },
    {
      title: t('REFERENCE_ID'),
      node: 'id',
      is_text: true,
      is_sortable: false,
      column_width: 10,
    },
    {
      title: t('BANK_ACCOUNT'),
      node: 'sendingExternalAccountDisplay',
      is_text: true,
      is_sortable: false,
      column_width: 15,
    },
    {
      title: t('AMOUNT'),
      node: 'amount',
      is_sortable: false,
      display_func: (item: SentDisbursementDto) => {
        return (
          <span>{formatCurrency(item.currencyCode, item.amount / 100)}</span>
        );
      },
      column_width: 15,
    },
    {
      title: t('DISBURSMENT_STATUS'),
      node: 'status',
      is_sortable: false,
      display_func: (item: SentDisbursementDto) =>
        getDisbursementStatus(item.status, tStatus),
      column_width: 10,
    },
    {
      title: t('CREATED_BY'),
      node: 'paymentsUserName',
      is_sortable: false,
      is_text: true,
      column_width: 10,
    },
    {
      title: t('CREATED_ON'),
      node: 'createdAt',
      is_sortable: false,
      is_datetime: true,
      column_width: 15,
    },
  ];

  useEffect(() => {
    if (companyId) {
      if (!isInit) {
        setIsLoading(true);
      }

      const nextPage = async () => {
        const res = await getSentDisbursements(
          +companyId,
          currentPage,
          perPage,
        );

        if (res && res.items) {
          setSentDisbursements(res.items);
          setTotal(res.total);
          setIsLoading(false);
          setIsInit(true);
        }
      };
      nextPage();
    }
  }, [companyId, currentPage, perPage]);

  return (
    <Card>
      <Box padding="lg">
        {isLoading && (
          <Flex justifyContent="center">
            <Spinner loading={isLoading} />
          </Flex>
        )}

        {isInit && !isLoading && (
          <TableBackendComponent
            data={sentDisbursemnts}
            data_total={total}
            current_page={currentPage}
            page_size={perPage}
            default_sort_column=""
            default_sort_direction="asc"
            headers={tableHeaders}
            paginator={changePage}
            sorter={() => {}}
            no_data_message={tCommon('NO_DATA')}
            show_no_data_message={true}
            fixed_table={true}
          />
        )}
      </Box>
    </Card>
  );
};

export default SentDisbursementsTab;
