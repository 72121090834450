import type { PageConfig } from 'pages/page-config.interfaces';

const pageConfig: PageConfig = {
  label: 'PRIME_CONTRACT_SETTINGS',
  route: {
    page: 'prime-contract-settings',
    v1_path: '/prime-contract-settings/company/:company_id/project/:project_id',
  },
  endpoints: {
    view: ['projectControllerGetProjectPrimeContractSettings'],
    edit: ['projectControllerUpdateProjectPrimeContractSettings'],
  },
  cards: {
    PrimeContractSettings: {
      i18n: {
        keyPrefix: 'PROJECT_PRIME_CONTRACT_SETTINGS',
      },
      label: 'TITLE',
      fields: {
        number_of_prime_contract_change_order_tiers: {
          label: 'NUMBER_CHANGE_ORDER_TIERS',
        },
        allow_standard_to_create_prime_pco: {
          label: 'ALLOW_USERS_CREATE_PCOS',
        },
        enable_prime_editable_sov: {
          label: 'ENABLE_ALWAYS_EDITABLE_SCHEDULE_OF_VALUES',
        },
        change_order_statuses_cascade: {
          label: 'ENABLE_ORDER_CASCADING_CHANGE_ORDER_STATUSES',
        },
      },
    },
  },
};

export default pageConfig;
