import React, { createContext, useContext } from 'react';
import type { CompanyAccountStatusDto } from '@procore/ipa-nt-api-client-ts';
import type { IDatasource } from 'components/EditableCard';
import type { PageConfigTranslated } from 'pages/page-config.interfaces';
import { AppContext } from 'contexts/AppContext';
import { useParams, useSearchParams } from 'react-router-dom';

export interface CompanyContextProps {
  env: string;
  companyId: number;
  projectId?: number;
  contactId?: number;
  loginId?: number;
  dataSources: {
    companyStatus: IDatasource<CompanyAccountStatusDto>;
  };
  pageConfig: PageConfigTranslated;
  hasPermission: (permission: string[]) => boolean;
  reloadCompanyStatus: () => void;
}

interface ContextProps extends ContextConsumerProps {
  children: JSX.Element;
}

interface ContextConsumerProps {
  children: any;
}

export const CompanyContext = createContext({} as CompanyContextProps);

export const CompanyContextProvider = (
  props: Partial<CompanyContextProps & ContextProps>,
) => {
  const { env } = useContext(AppContext);
  const { company_id: id, page: _page } = useParams();
  const [searchParams] = useSearchParams();

  const context = {
    env,
    companyId: Number(id),
    contactId: +searchParams.get('contactId'),
    loginId: +searchParams.get('loginId'),
    projectId: +searchParams.get('projectId'),
    dataSources: {
      companyStatus: {
        id: 'companyStatus',
        data: {} as CompanyAccountStatusDto,
      },
    },
    pageConfig: {} as PageConfigTranslated,
    hasPermission: (permission: string[]) => true,
    reloadCompanyStatus: () => {},
  };

  return (
    <CompanyContext.Provider value={{ ...context, ...props }}>
      {props.children}
    </CompanyContext.Provider>
  );
};
