import type { PageConfig } from 'pages/page-config.interfaces';

const MonthlySandbox: PageConfig = {
  route: {
    path: '/monthly-sandbox',
  },
  label: 'TITLE',
  i18n: {
    keyPrefix: 'MONTHLY_SANDBOX',
  },
  cards: {
    MigrateApp: {
      i18n: {
        keyPrefix: 'MONTHLY_SANDBOX.MIGRATE_APP',
      },
      label: 'TITLE',
      fields: {
        app_id: {
          label: 'APP_ID',
          tooltip: 'APP_ID_TOOLTIP',
        },
      },
    },
  },
};

export default MonthlySandbox;
