import styled from 'styled-components';
import { colors, spacing } from '@procore/core-react';

export const PermissionsGridWrapper = styled.div`
  padding-bottom: ${spacing.md}px;
  .permissions-grid-header {
    padding-bottom: ${spacing.sm}px;
    font-weight: bold;
  }
  .permissions-grid-row {
    padding: ${spacing.sm}px 0;
  }
`;

export const styles = {
  oddRow: {
    background: colors.gray94,
  },
};
