import React, { useState, useContext, useCallback, useEffect } from 'react';
import { Link, Page, Card, ToolHeader, Box } from '@procore/core-react';
import { Check, Clear } from '@procore/core-icons';
import { CustomReportTabsForm } from './CustomReportTabsForm';
import { CustomReportTabsApi } from '@procore/ipa-nt-api-client-ts';
import { getApi } from 'utils/api';
import TableBackendComponent from 'components/TableBackend';
import type { TableHeader } from 'components/Table/table.interface';
import { useTranslation } from 'react-i18next';
import { IpaAlertContext } from 'contexts/IpaAlertContext';
import type { CustomReportTabsFormSearchProps } from './custom.reports.interface';
import { ipaAppStyles } from 'styles';
import EnvironmentSwitcher from 'components/EnvironmentSwitcher';

export const CustomReportTabsPage = () => {
  const { t } = useTranslation(['custom-report-tabs']);
  const alert = useContext(IpaAlertContext);
  const [reports, setReports] = useState<any>(null);
  const [reportsTotal, setReportsTotal] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [env, setEnv] = useState<string>('PROD');
  const [companyId, setCompanyId] = useState<number>(undefined);
  const [reportId, setReportId] = useState<number>(undefined);
  const [projectId, setProjectId] = useState<number>(undefined);
  const [userEmail, setUserEmail] = useState<string>(undefined);
  const [toolType, setToolType] = useState<string>(undefined);
  const [isSearching, setIsSearching] = useState<boolean>(false);

  const perPage = 25;

  const tableHeaders: TableHeader[] = [
    {
      title: t('REPORT_ID'),
      node: 'report',
      display_func: function (item) {
        return (
          <Link
            href={`/v2/company/${item.report.company.id}/custom-reports-tabs/${item.report.id}?env=${env}`}
            target="_blank"
          >
            {item.report.id}
          </Link>
        );
      },
      column_width: 5,
    },
    {
      title: t('COMPANY', { ns: 'common' }),
      node: 'report',
      is_sortable: false,
      display_func: (item) => {
        return item.report.company ? item.report.company.name : '';
      },
      column_width: 10,
    },
    {
      title: t('PROJECT', { ns: 'common' }),
      node: 'report',
      is_sortable: false,
      display_func: (item) => {
        return item.report.project ? item.report.project.name : '';
      },
      column_width: 10,
    },
    {
      title: t('CREATOR'),
      node: 'report',
      is_sortable: false,
      display_func: (item) => {
        return item.report.created_by ? item.report.created_by.login : '';
      },
      column_width: 10,
    },
    {
      title: t('CREATED_ON', { ns: 'common' }),
      node: 'created_at',
      is_sortable: false,
      is_date: true,
      column_width: 5,
    },
    {
      title: t('REPORT_NAME'),
      node: 'report',
      is_sortable: false,
      display_func: (item) => {
        return item.report.report_name;
      },
      column_width: 10,
    },
    {
      title: t('TAB_NAME'),
      node: 'tab_name',
      is_sortable: false,
      is_text: true,
      column_width: 10,
    },
    {
      title: t('TOOL'),
      node: 'tool_type',
      is_sortable: false,
      display_func: (item) => {
        return <span>{humanizeString(item.tool_type)}</span>;
      },
      column_width: 10,
    },
    {
      title: t('COLUMN_COUNT'),
      node: 'report_tab_columns_count',
      is_sortable: true,
      is_text: true,
      column_width: 5,
    },
    {
      title: t('GLOBAL'),
      node: 'report',
      is_sortable: false,
      display_func: (item) => {
        return item.report.is_global ? (
          <Check style={ipaAppStyles.success} />
        ) : (
          <Clear style={ipaAppStyles.failure} />
        );
      },
      column_width: 5,
    },
    {
      title: t('PUBLISHED'),
      node: 'report',
      is_sortable: false,
      display_func: (item) => {
        return item.report.is_published ? (
          <Check style={ipaAppStyles.success} />
        ) : (
          <Clear style={ipaAppStyles.failure} />
        );
      },
      column_width: 5,
    },
    {
      title: t('SCHEDULED'),
      node: 'report',
      is_sortable: false,
      display_func: (item) => {
        return item.report.scheduled ? (
          <Check style={ipaAppStyles.success} />
        ) : (
          <Clear style={ipaAppStyles.failure} />
        );
      },
      column_width: 5,
    },
    {
      title: t('TABS'),
      node: 'report',
      is_sortable: false,
      display_func: (item) => {
        return item.report.tab_count;
      },
      column_width: 5,
    },
  ];

  const changeEnv = (newEnv: string) => {
    setEnv(newEnv);
  };

  const doSearch = useCallback(
    async (values: CustomReportTabsFormSearchProps) => {
      if (values) {
        setCompanyId(values.companyId);
        setReportId(values.reportId);
        setProjectId(values.projectId);
        setUserEmail(values.userEmail);
        setToolType(values.toolType);
        setCurrentPage(1);
      }
    },
    [],
  );

  const fetchChangeHistoryData = useCallback(async () => {
    alert.closeAlert();

    try {
      setIsSearching(true);
      const api = getApi(CustomReportTabsApi);
      const result = await api.customReportTabsControllerGetCustomReports(
        companyId,
        env,
        projectId,
        reportId,
        userEmail,
        toolType,
        currentPage,
        perPage,
      );
      setReports(result.data.data);
      setReportsTotal(result.data.total_reports);
    } catch (e: any) {
      alert.error(e);
      setReports([]);
    } finally {
      setIsSearching(false);
    }
  }, [
    companyId,
    projectId,
    reportId,
    userEmail,
    toolType,
    env,
    currentPage,
    perPage,
  ]);

  const humanizeString = (string: string) => {
    if (string) {
      return string.replace(/_/g, ' ').replace(/(?: |\b)(\w)/g, function (key) {
        return key.toUpperCase();
      });
    } else return '';
  };

  const changePage = (page: number, _pageSize: number) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    if (companyId) {
      fetchChangeHistoryData();
    }
  }, [
    companyId,
    reportId,
    projectId,
    userEmail,
    toolType,
    currentPage,
    perPage,
    env,
  ]);

  return (
    <Page>
      <Page.Main style={{ flex: 'auto' }}>
        <Page.Header>
          <ToolHeader>
            <ToolHeader.Title>
              {t('TITLE', { ns: 'custom-report-tabs' })}
            </ToolHeader.Title>
          </ToolHeader>
        </Page.Header>
        <Page.Body>
          <Card>
            <Box padding="lg">
              <CustomReportTabsForm
                doSearch={doSearch}
                isSearching={isSearching}
              />
              <Box>
                <EnvironmentSwitcher initialValue={env} onChange={changeEnv} />
              </Box>
            </Box>
          </Card>
          {reportsTotal > 0 && (
            <Card>
              <Box marginTop="lg" padding="lg">
                <TableBackendComponent
                  data={reports}
                  data_total={reportsTotal}
                  headers={tableHeaders}
                  current_page={currentPage}
                  default_sort_column="created_at"
                  default_sort_direction="desc"
                  paginator={changePage}
                  sorter={() => {}}
                  page_size={perPage}
                  fixed_table={true}
                />
              </Box>
            </Card>
          )}
        </Page.Body>
      </Page.Main>
    </Page>
  );
};

export default CustomReportTabsPage;
