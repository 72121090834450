import type { PageConfig } from 'pages/page-config.interfaces';

const pageConfig: PageConfig = {
  label: 'MEETING_SETTINGS',
  route: {
    page: 'meeting-settings',
    v1_path: '/meeting-settings/company/:company_id/project/:project_id',
  },
  endpoints: {
    view: ['projectControllerGetMeetingSettings'],
  },
  cards: {
    MeetingSettings: {
      i18n: {
        keyPrefix: 'MEETING_SETTINGS_CARD',
      },
      label: 'TITLE',
      fields: {
        default_meeting_format: {
          label: 'MEETING_VIEW',
        },
        number_of_previous_meeting_minutes_shown_on_pdf: {
          label: 'NUMBER_OF_PREVIOUS_MEETING_MINUTES_SHOWN_ON_PDF',
          tooltip: 'NUMBER_OF_PREVIOUS_MEETING_MINUTES_SHOWN_ON_PDF_TOOLTIP',
        },
        show_historical_meeting_comments: {
          label: 'SHOW_HISTORICAL_MEETING_COMMENTS',
          tooltip: 'SHOW_HISTORICAL_MEETING_COMMENTS_TOOLTIP',
        },
        meeting_topics_have_descriptions: {
          label: 'MEETING_TOPICS_HAVE_DESCRIPTIONS',
          tooltip: 'MEETING_TOPICS_HAVE_DESCRIPTIONS_TOOLTIP',
        },
        use_meeting_wide_numbering: {
          label: 'USE_MEETING_WIDE_NUMBERING',
          tooltip: 'USE_MEETING_WIDE_NUMBERING_TOOLTIP',
        },
      },
    },
  },
};

export default pageConfig;
