import React, { useContext, useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Card, H2, Flex, Spinner } from '@procore/core-react';
import { PaymentsServiceBusinessEntitiesApi } from '@procore/ipa-nt-api-client-ts';
import type { IpaPaymentsEventHistoryDto } from '@procore/ipa-nt-api-client-ts';
import { getApi } from 'utils/api';
import TableComponent from 'components/Table';
import type { TableHeader } from 'components/TableBackend/table.interface';
import { IpaAlertContext } from 'contexts/IpaAlertContext';

export interface IpaPaymentsChangeHistoryCardProps {
  id: string;
}

export const IpaPaymentsChangeHistoryCard = (
  props: IpaPaymentsChangeHistoryCardProps,
) => {
  const { id: companyId } = useParams();
  const alert = useContext(IpaAlertContext);
  const [ipaPaymentsEventHistory, setIpaPaymentsEventHistory] = useState<
    IpaPaymentsEventHistoryDto[]
  >([]);
  const { t } = useTranslation(['payments'], {
    keyPrefix:
      'BUSINESS_ENTITY.CHANGE_HISTORY_TAB.IPA_PAYMENTS_CHANGE_HISTORY_CARD',
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isInit, setIsInit] = useState<boolean>(false);

  const getIpaPaymentsEventHistory = useCallback(async () => {
    try {
      setIsLoading(true);
      setIsInit(false);
      const api: PaymentsServiceBusinessEntitiesApi = getApi(
        PaymentsServiceBusinessEntitiesApi,
      );
      const res = await api.businessEntityControllerGetIpaPaymentsEventHistory(
        +companyId,
      );
      setIpaPaymentsEventHistory(res.data);
      setIsInit(true);
    } catch (e: any) {
      alert.error(e);
    } finally {
      setIsLoading(false);
    }
  }, [alert]);

  const tableHeaders: TableHeader[] = [
    {
      title: t('USER'),
      node: 'user',
      is_text: true,
      is_sortable: true,
    },
    {
      title: t('DATETIME'),
      node: 'inserted_at',
      is_sortable: true,
      is_datetime: true,
    },
    {
      title: t('ACTION'),
      node: 'event_type',
      is_sortable: true,
      is_text: true,
    },
    {
      title: t('RESOURCE_TYPE'),
      node: 'resource_name',
      is_sortable: true,
      is_text: true,
    },
    {
      title: t('RESOURCE_ID'),
      node: 'resource_id',
      is_sortable: true,
      is_text: true,
    },
    {
      title: t('REASON'),
      node: 'reason',
      is_sortable: true,
      is_text: true,
    },
  ];

  useEffect(() => {
    getIpaPaymentsEventHistory();
  }, [getIpaPaymentsEventHistory]);

  return (
    <Box marginBottom="lg">
      <Card>
        <Box padding="lg">
          <H2>{t('TITLE')}</H2>
          {isLoading && (
            <Flex justifyContent="center">
              <Spinner loading={isLoading} />
            </Flex>
          )}
          {!isLoading && isInit && (
            <TableComponent
              data={ipaPaymentsEventHistory}
              headers={tableHeaders}
              default_sort_column="inserted_at"
              default_sort_direction="asc"
              show_no_data_message={true}
            />
          )}
        </Box>
      </Card>
    </Box>
  );
};

export default IpaPaymentsChangeHistoryCard;
