import React, { useContext } from 'react';
import { EditableCard } from 'components/EditableCard';
import type { IEditableCard } from 'components/EditableCard';
import { Card, Box, Form, Spinner, Flex } from '@procore/core-react';
import { SubmittalSettingsContext } from '.';
import type { SubmittalSettingsCardProps } from './interfaces';
import { ProjectContext } from 'contexts/ProjectContext';

export const SubmittalSettingsCard = (props: SubmittalSettingsCardProps) => {
  const context = useContext(ProjectContext);
  const config = props.cardConfig;

  const {
    submittalSettingsDataSources: { submittalSettings },
  } = useContext(SubmittalSettingsContext);

  const data = submittalSettings?.data;

  const card: IEditableCard = {
    id: props.id,
    i18n: config.translate,
    label: config.label,
    canEdit: false,
    save: props.save,
    fields: [
      {
        source: submittalSettings.id,
        value: data?.default_submittal_manager.default_submittal_manager_name,
        name: 'default_submittal_manager',
        element: Form.Text,
        hideOnEdit: true,
        ...config.fields.default_submittal_manager,
      },

      {
        source: submittalSettings.id,
        value: data?.sort_submittal_spec_section_number_list,
        name: 'sort_submittal_spec_section_number_list',
        element: Form.Text,
        hideOnEdit: true,
        ...config.fields.sort_submittal_spec_section_number_list,
      },
      {
        source: submittalSettings.id,
        value: data?.sort_submittal_packages_section_list,
        name: 'sort_submittal_packages_section_list',
        element: Form.Text,
        hideOnEdit: true,
        ...config.fields.sort_submittal_packages_section_list,
      },
      {
        source: submittalSettings.id,
        value: data?.dynamic_approver_due_dates,
        name: 'dynamic_approver_due_dates',
        element: Form.Checkbox,
        hideOnEdit: true,
        ...config.fields.dynamic_approver_due_dates,
      },
      {
        source: submittalSettings.id,
        value: `${data?.new_submittals_due_in_days} ${data?.new_submittals_due_format}`,
        name: 'new_submittals_due_in_days',
        element: Form.Text,
        hideOnEdit: true,
        ...config.fields.new_submittals_due_in_days,
      },
      {
        source: submittalSettings.id,
        value: `${data?.submittal_log_default_design_team_review_time} Day(s)`,
        name: 'submittal_log_default_design_team_review_time',
        element: Form.Text,
        hideOnEdit: true,
        ...config.fields.submittal_log_default_design_team_review_time,
      },
    ],
  };

  return (
    <Box marginBottom="lg">
      <Card>
        {props.isCardLoading ? (
          <Flex justifyContent="center" padding="lg">
            <Spinner loading={props.isCardLoading} />
          </Flex>
        ) : (
          <Box padding="lg">
            <EditableCard card={card} />
          </Box>
        )}
      </Card>
    </Box>
  );
};

export default SubmittalSettingsCard;
