import { useContext, useEffect, useState, useCallback } from 'react';
import { Select, Tooltip } from '@procore/core-react';
import { Help } from '@procore/core-icons';
import { ProjectContext } from 'contexts/ProjectContext';
import { useNavigate } from 'react-router-dom';
import { generateUrl } from './urlGenerator';
import { useTranslation } from 'react-i18next';
import type { ISelectOption } from 'interfaces/common.interface';
import { getApi } from 'utils/api';
import { CompaniesApi, ProjectsApi } from '@procore/ipa-nt-api-client-ts';
import { IpaAlertContext } from 'contexts/IpaAlertContext';
import { StyledSelect } from 'styles';
import { TooltipWrapper } from './style';

interface ProjectSelectorComponentProps {
  page: string;
}

interface SelectedItem {
  action: string;
  event: any;
  group: boolean;
  item: ISelectOption;
}

export const ProjectSelectorComponent = (
  props: ProjectSelectorComponentProps,
) => {
  const context = useContext(ProjectContext);
  const alert = useContext(IpaAlertContext);
  const navigate = useNavigate();
  const { t } = useTranslation(['project']);

  const [searchLoading, setSearchLoading] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [value, setValue] = useState<ISelectOption>();
  const [projectsList, setProjectsList] = useState<ISelectOption[]>([]);
  const [initialProjectList, setInitialProjectList] = useState<ISelectOption[]>(
    [],
  );
  const pageSize = 100;

  const onSelect = (target: SelectedItem) => {
    setValue(target.item);
    navigate(
      generateUrl(props.page, {
        ...context,
        projectId: +target.item.id,
      }),
    );
  };

  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length >= 3) {
      setSearchLoading(true);
      setSearchTerm(event.target.value?.toLowerCase());
    } else if (
      document.querySelectorAll(
        '[data-qa="core-overlay-trigger-overlay-wrapper"]',
      ).length === 0 ||
      event.target.value.length < 3
    ) {
      setProjectsList(initialProjectList);
    }
  };

  const listProjects = async () => {
    const companyApi = getApi(CompaniesApi);
    const res = await companyApi.companyControllerGetProjects(
      context.env,
      context.companyId,
      pageSize,
    );

    if (Array.isArray(res.data)) {
      return res.data;
    }
  };

  const searchProjects = async () => {
    const companyApi = getApi(CompaniesApi);
    const res = await companyApi.companyControllerSearchProjects(
      context.env,
      context.companyId,
      1,
      pageSize,
      searchTerm,
    );

    if (Array.isArray(res.data.items)) {
      return res.data.items;
    }
  };

  const loadProjects = useCallback(async () => {
    alert.closeAlert();
    try {
      const items = searchTerm ? await searchProjects() : await listProjects();

      setSearchLoading(false);
      if (Array.isArray(items)) {
        const projects = items.map((project) => {
          return { id: project.id, label: project.project_name };
        });
        const currentProject = projects.find((i) => i.id === context.projectId);
        setProjectsList(projects);
        if (currentProject) {
          setValue(currentProject);
        } else if (!searchTerm) {
          const projectApi = getApi(ProjectsApi);
          const res = await projectApi.projectControllerGetProjectInfo(
            context.env,
            context.companyId,
            context.projectId,
          );
          projects.push({
            id: res.data.id,
            label: res.data.name.split(' - ')[1] || res.data.name, // adds project_number in front of the project name in some cases
          });
          setValue({
            id: res.data.id,
            label: res.data.name.split(' - ')[1] || res.data.name,
          });
        }
        if (!searchTerm) {
          setInitialProjectList(projects);
        }
      }
    } catch (e: any) {
      alert.error(e);
    }
  }, [context.companyId, context.env, context.projectId, searchTerm]);

  useEffect(() => {
    loadProjects();
  }, [loadProjects]);

  return (
    <div>
      <strong>{t('PROJECT', { ns: 'common' })}: </strong>
      <StyledSelect
        placeholder={t('SWITCH_PROJECT')}
        label={value?.label}
        onSearch={onSearch}
        onSelect={onSelect}
        loading={searchLoading}
      >
        {projectsList.map((item) => (
          <Select.Option
            key={item.id}
            value={item}
            selected={item.id === value?.id}
          >
            {`${item.label} (${item.id})`}
          </Select.Option>
        ))}
      </StyledSelect>
      <TooltipWrapper>
        <Tooltip
          overlay={t('PROJECT_SELECTOR_TOOLTIP')}
          trigger="hover"
          placement="top"
        >
          <Help size="sm" />
        </Tooltip>
      </TooltipWrapper>
    </div>
  );
};

export default ProjectSelectorComponent;
