import { Form, H2, Link, Title, Tooltip } from '@procore/core-react';
import { useContext } from 'react';
import React from 'react';
import { ipaAppStyles } from 'styles';
import { ExternalLink, Help } from '@procore/core-icons';
import { useTranslation } from 'react-i18next';
import type { ProjectSettingsProps } from '../interfaces';
import { ProjectInfoContext } from '..';
import { PageConfig } from 'pages/page-config.service';
import type { CardConfigTranslated } from 'pages/page-config.interfaces';

const ProjectLocationTab = (props: ProjectSettingsProps) => {
  const { t } = useTranslation(['common']);
  const config = PageConfig.get(
    t,
    'Project.ProjectLocation.ProjectLocation',
  ) as CardConfigTranslated;

  const {
    dataSources: { projectSettings },
  } = useContext(ProjectInfoContext);

  const projectLocation = projectSettings?.data?.location_settings;

  const labelStyle = {
    fontWeight: 'bold',
    marginRight: '8px',
    display: 'inline-block',
  };

  const LocationLink = (props: { latitude: any; longitude: any }) => {
    const zoomLevel = 16;

    return (
      <Link
        target="_blank"
        href={`https://www.google.com/maps/@${props.latitude},${props.longitude},${zoomLevel}z`}
      >
        {config.fields.location_latitude.label}: {props.latitude},{' '}
        {config.fields.location_longitude.label}: {props.longitude}
        <ExternalLink size="sm" style={{ verticalAlign: 'text-bottom' }} />
      </Link>
    );
  };

  return (
    <React.Fragment>
      <Title style={ipaAppStyles.titleMargin}>
        <Title.Text>
          <H2>{config.label}</H2>
        </Title.Text>
      </Title>
      {projectLocation && (
        <Form
          view="read"
          enableReinitialize={true}
          initialValues={projectLocation}
        >
          <Form.Form>
            <Form.Row>
              <Form.Text
                colStart={1}
                colWidth={2}
                name="country"
                label={config.fields.country.label}
              />
              <Form.Text
                colStart={6}
                colWidth={2}
                name="time_zone"
                label={config.fields.time_zone.label}
              />
            </Form.Row>
            <Form.Row>
              <Form.Text
                colStart={1}
                colWidth={2}
                name="address"
                label={config.fields.address.label}
                tooltip={config.fields.address.tooltip}
              />
              <Form.Text
                colStart={6}
                colWidth={2}
                name="city"
                label={config.fields.city.label}
              />
            </Form.Row>
            <Form.Row>
              <Form.Text
                colStart={1}
                colWidth={2}
                name="state"
                label={config.fields.state.label}
              />
              <Form.Text
                colStart={6}
                colWidth={2}
                name="zip"
                label={config.fields.zip.label}
              />
            </Form.Row>
            <Form.Row>
              <Form.Text
                colStart={1}
                colWidth={2}
                name="county"
                label={config.fields.county.label}
              />
              <Form.Text
                colStart={6}
                colWidth={2}
                name="phone"
                label={config.fields.phone.label}
              />
            </Form.Row>
            <Form.Row>
              <div>
                <span style={labelStyle}>{config.fields.location.label}</span>
                <Tooltip
                  overlay={config.fields.location.tooltip}
                  trigger="hover"
                  children={
                    <Help size="sm" style={ipaAppStyles.inlineTooltip} />
                  }
                />
              </div>
              {projectLocation.latitude && projectLocation.longitude && (
                <LocationLink
                  latitude={projectLocation.latitude}
                  longitude={projectLocation.longitude}
                />
              )}
              <Form.Text
                colStart={6}
                colWidth={2}
                name="fax"
                label={config.fields.fax.label}
              />
            </Form.Row>
          </Form.Form>
        </Form>
      )}
    </React.Fragment>
  );
};
export default ProjectLocationTab;
