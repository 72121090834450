import type { SectionConfig } from 'pages/page-config.interfaces';
import ProjectList from './ProjectList/page.config';
import ExpenseAllocations from './ExpenseAllocations/page.config';
import Submittals from './Submittals/page.config';

const projectSectionConfig: SectionConfig = {
  id: 'Project Management',
  label: 'Project Management',
  route: {
    path: '/v2/company/:company_id/project-management/:page?',
    v1_path: '/customer-success/project-management',
  },
  i18n: {
    namespace: 'project-management',
  },
  permissions: {
    view: ['company_tab_view', 'cs_view'],
    edit: ['company_tab_edit', 'cs_edit'],
  },
  endpoints: {
    view: ['companyCompanyInfoControllerGetCompanyAccountStatus'],
  },
  pages: {
    ProjectList,
    ExpenseAllocations,
    Submittals,
  },
};

export default projectSectionConfig;
