import React, { useCallback, useContext, useEffect, useState } from 'react';
import { EditableCard } from 'components/EditableCard';
import type { IEditableCard, IEditbleCardField } from 'components/EditableCard';
import {
  Banner,
  Box,
  Button,
  Card,
  Flex,
  Form,
  Grid,
  Spinner,
  Typography,
} from '@procore/core-react';
import { PaymentsBusinessEntityContext } from 'contexts/PaymentsBusinessEntityContext';
import { PaymentsBusinessEntityGeneralTabContext } from '.';
import type { FormattedPaymentsUser } from './interfaces';
import { MultiSelectWrapper } from 'styles';
import { Warning } from '@procore/core-icons';
import { styles } from './styles';
import ConfirmModal from 'components/ConfirmModal';

export interface IEditPermissions {
  paymentsUsers: {
    admins: FormattedPaymentsUser[];
    disbursers: FormattedPaymentsUser[];
  };
}

export interface PermissionsCardProps {
  id: string;
  isCardLoading: boolean;
  save: IEditableCard['save'];
  approveAdmins: (admins: FormattedPaymentsUser[]) => void;
}

export const PermissionsCard = (props: PermissionsCardProps) => {
  const context = useContext(PaymentsBusinessEntityContext);
  const config = context.pageConfig.card('Permissions');
  const t = config.translate;
  const {
    dataSources: { paymentsUsers, generalInformation, companyPeople },
  } = useContext(PaymentsBusinessEntityGeneralTabContext);
  const [showApproveAdminsModal, setShowApproveAdminsModal] = useState(false);
  const [adminOptions, setAdminOptions] = useState<FormattedPaymentsUser[]>([]);
  const [disburserOptions, setDisburserOptions] = useState<
    FormattedPaymentsUser[]
  >([]);

  const filterPaymentsUsersByRole = (
    role: string,
    users: FormattedPaymentsUser[],
  ) => {
    return users ? users.filter((user) => user.role === role) : [];
  };

  const getPendingAdmins = useCallback((): FormattedPaymentsUser[] => {
    return (
      paymentsUsers?.data?.filter(
        (user) => user.role === 'PAYMENT_ADMIN' && user.isPending,
      ) ?? []
    );
  }, [paymentsUsers]);

  const getPendingPaymentAdminsDisplay = () => {
    const pendingAdmins = getPendingAdmins();

    if (pendingAdmins.length <= 4) {
      return pendingAdmins.map((admin) => admin.label).join(', ');
    } else {
      const displayedAdmins = pendingAdmins
        .slice(0, 4)
        .map((admin) => admin.label)
        .join(', ');
      const remainingCount = pendingAdmins.length - 4;
      return `${displayedAdmins}, ... +${remainingCount} more`;
    }
  };

  const disburserFields: IEditbleCardField[] = [
    {
      source: paymentsUsers.id,
      value: filterPaymentsUsersByRole('DISBURSER', paymentsUsers.data ?? []),
      name: 'disbursers',
      element: <React.Fragment />,
      hideOnRead: true,
      elementFactory: (field) => (
        <MultiSelectWrapper>
          <Form.MultiSelect
            name={field.name}
            label={field.label}
            options={disburserOptions}
            data-qa="input-payments-disbursers"
          />
        </MultiSelectWrapper>
      ),
      ...config.fields.disbursers,
    },
    {
      source: paymentsUsers.id,
      value: filterPaymentsUsersByRole('DISBURSER', paymentsUsers.data ?? []),
      name: 'disbursers-read',
      element: <React.Fragment />,
      hideOnEdit: true,
      elementFactory: (field) => (
        <Grid gutterX="sm">
          <Grid.Row>
            <Typography as="label" intent="body" weight="bold">
              {t('DISBURSERS')}
            </Typography>
          </Grid.Row>
          {(field.value as FormattedPaymentsUser[]).map((user) => (
            <Grid.Row key={user.id}>
              <Grid.Col colWidth={{ tabletSm: 12 }}>
                <Typography intent="body">{user.label}</Typography>
              </Grid.Col>
            </Grid.Row>
          ))}
        </Grid>
      ),
      ...config.fields.disbursers,
    },
  ];

  const card: IEditableCard = {
    id: props.id,
    i18n: t,
    label: t('GENERAL'),
    canEdit: context.hasPermission(config.permissions.edit ?? []),
    save: props.save,
    fields: [
      {
        source: paymentsUsers.id,
        value: filterPaymentsUsersByRole(
          'PAYMENT_ADMIN',
          paymentsUsers.data ?? [],
        ),
        name: 'admins',
        element: <React.Fragment />,
        hideOnRead: true,
        elementFactory: (field) => (
          <MultiSelectWrapper>
            <Form.MultiSelect
              name={field.name}
              label={field.label}
              options={adminOptions}
              data-qa="input-payments-admins"
            />
          </MultiSelectWrapper>
        ),
        ...config.fields.admins,
      },
      {
        source: paymentsUsers.id,
        value: filterPaymentsUsersByRole(
          'PAYMENT_ADMIN',
          paymentsUsers.data ?? [],
        ),
        name: 'admins-read',
        element: <React.Fragment />,
        hideOnEdit: true,
        elementFactory: (field) => (
          <Grid gutterX="sm">
            <Grid.Row>
              <Typography as="label" intent="body" weight="bold">
                {t('ADMINS')}
              </Typography>
            </Grid.Row>
            {(field.value as FormattedPaymentsUser[]).map((user) => (
              <Grid.Row key={user.id}>
                <Grid.Col>
                  <Flex>
                    {user.isPending && (
                      <div style={styles.warningBackground}>
                        <Warning size="sm" style={styles.warningIcon} />
                      </div>
                    )}
                    <Typography intent="body">{user.label}</Typography>
                  </Flex>
                </Grid.Col>
              </Grid.Row>
            ))}
          </Grid>
        ),
        ...config.fields.admins,
      },
      ...(generalInformation.data?.capabilities === 'send_payments'
        ? disburserFields
        : []),
    ],
  };

  useEffect(() => {
    const adminOptions =
      companyPeople.data?.filter((person) => {
        const disburserUsers = filterPaymentsUsersByRole(
          'DISBURSER',
          paymentsUsers.data ?? [],
        );
        return !disburserUsers.some((user) => user.id === person.id);
      }) ?? [];

    const disburserOptions =
      companyPeople.data?.filter((person) => {
        const adminUsers = filterPaymentsUsersByRole(
          'PAYMENT_ADMIN',
          paymentsUsers.data ?? [],
        );
        return !adminUsers.some((user) => user.id === person.id);
      }) ?? [];

    setAdminOptions(adminOptions);
    setDisburserOptions(disburserOptions);
  }, [companyPeople, paymentsUsers]);

  return (
    <Box marginBottom="lg">
      <Card>
        {props.isCardLoading ? (
          <Flex justifyContent="center" padding="lg">
            <Spinner loading={props.isCardLoading} />
          </Flex>
        ) : (
          <Box padding="lg">
            {getPendingAdmins().length > 0 && (
              <Banner variant="info" style={styles.cardBanner}>
                <Banner.Icon icon={<Warning size="lg" />} />
                <Banner.Content>
                  <Banner.Title>{t('AWAITING_APPROVAL')}</Banner.Title>
                  <Banner.Body>
                    {t('APPROVAL_BANNER_COPY', {
                      pendingAdminsDisplay: getPendingPaymentAdminsDisplay(),
                    })}
                  </Banner.Body>
                </Banner.Content>
                {context.hasPermission(config.permissions.approve ?? []) && (
                  <Banner.Action>
                    <Button
                      onClick={() => setShowApproveAdminsModal(true)}
                      data-qa="approve-btn"
                    >
                      {t('APPROVE')}
                    </Button>
                  </Banner.Action>
                )}
              </Banner>
            )}

            <EditableCard card={card} />
            <ConfirmModal
              isOpen={showApproveAdminsModal}
              modalTitle={t('APPROVE_ADMINS_TITLE')}
              modalCopy={t('APPROVE_ADMINS_COPY')}
              closeModal={() => setShowApproveAdminsModal(false)}
              confirmModal={(values: FormattedPaymentsUser[]) =>
                props.approveAdmins(values)
              }
              includeReason={false}
            >
              <Form.Checkboxes
                name="approvedAdmins"
                label="Pending Admins to Approve"
                options={getPendingAdmins()}
              />
            </ConfirmModal>
          </Box>
        )}
      </Card>
    </Box>
  );
};

export default PermissionsCard;
