import type { PageConfig } from 'pages/page-config.interfaces';

const pageConfig: PageConfig = {
  id: 'bounce_receipts',
  label: 'TITLE',
  route: {
    page: 'list_bounce_receipts',
  },
  i18n: {
    namespace: 'super-area',
    keyPrefix: 'BOUNCE_RECEIPTS',
  },
  cards: {},
};

export default pageConfig;
