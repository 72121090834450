import styled from 'styled-components';
import type { CSSProperties } from 'react';
import { colors, spacing, Table } from '@procore/core-react';

interface ITableStyles {
  input: CSSProperties;
  row: CSSProperties;
  search: CSSProperties;
  iconSuccess: CSSProperties;
  iconError: CSSProperties;
}

export const TableWrapper = styled.div`
  .red-row {
    background-color: ${colors.red70};
  }
  .ipa-table-cell {
    word-break: break-all;
  }
`;

export const TooltipWrapper = styled.div`
  margin-left: 5px;
  display: inline-block;

  svg {
    color: ${colors.gray45};
  }
`;

export const PaginationWrapper = styled.div`
  display: flex;
  margin: ${spacing.sm}px 0;

  .page-size {
    padding: 0 ${spacing.sm}px;

    span {
      margin-right: ${spacing.sm}px;
    }

    & > div:nth-child(3) {
      position: relative;
      top: ${spacing.xxs}px;
    }

    .select {
      width: fit-content;
    }
  }
`;

export const styles: ITableStyles = {
  input: {
    boxSizing: 'border-box',
  },
  search: {
    marginTop: `${spacing.lg}px`,
  },
  row: {
    paddingBottom: `${spacing.sm}px`,
  },
  iconSuccess: {
    color: colors.green50,
  },
  iconError: {
    color: colors.red50,
  },
};

export const StyledTableHeaderCell = styled(Table.HeaderCell)<{
  $sticky?: boolean;
}>`
  background: ${colors.gray96};
  position: ${(props) => (props.$sticky ? 'sticky' : 'static')};
  top: 0;
  z-index: 10;
`;

export const StyledTableHeader = styled(Table.Header)<{
  $sticky?: boolean;
}>`
  background: ${colors.gray96};
  position: ${(props) => (props.$sticky ? 'sticky' : 'static')};
  top: 0;
  z-index: 9;
`;
