import type { PageConfig } from 'pages/page-config.interfaces';

export default <PageConfig>{
  label: 'SUPERUSER_PROMOTION_REPORT',
  route: {
    path: '/v2/su-promotion-report',
  },
  endpoints: {
    view: ['adminControllerGetSuperuserPromotionReport'],
  },
  cards: {},
};
