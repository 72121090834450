import type { PageConfig } from 'pages/page-config.interfaces';

const companySearchPage: PageConfig = {
  label: 'COMPANY_SEARCH',
  route: {
    path: '/company',
  },
  permissions: {
    view: ['company_tab_view'],
  },
  endpoints: {
    view: ['companyCompanyInfoControllerGetCompanyAccountStatus'],
  },
  cards: {},
};

export default companySearchPage;
