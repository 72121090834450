import React, { useState, useEffect, useContext } from 'react';
import { Breadcrumbs, Box, Page, ToolHeader } from '@procore/core-react';
import { useTranslation } from 'react-i18next';
import { getApi } from 'utils/api';
import {
  CompanyInfoApi,
  CustomReportTabsApi,
} from '@procore/ipa-nt-api-client-ts';
import type {
  CompanyAccountStatusDto,
  CustomReportDetailsDto,
} from '@procore/ipa-nt-api-client-ts';
import type {
  PolicyPermissions,
  UserPermissionDetails,
  FormattedTabPermissions,
  TabPermissions,
} from '../CustomReportTabs/custom.reports.interface';
import { useParams, useSearchParams } from 'react-router-dom';
import ReportDetailsCard from './ReportDetails';
import ReportPermissionsCard from './ReportPermissions';
import { IpaAlertContext } from 'contexts/IpaAlertContext';
import { AppContext } from 'contexts/AppContext';
import { can } from 'utils/permissions.helper';
import JitWidget from 'components/JitWidget';

export const CustomReportTabsDetailsPage = () => {
  const { t } = useTranslation(['custom-report-tabs-details']);
  const alert = useContext(IpaAlertContext);
  const { userConfig } = useContext(AppContext);
  const [reportDetails, setReportDetails] =
    useState<CustomReportDetailsDto | null>(null);
  const [permissionDetails, setPermissionDetails] =
    useState<UserPermissionDetails | null>(null);
  const { company_id: companyId, report_id: reportId } = useParams();
  const [searchParams] = useSearchParams();
  const env = searchParams.get('env') ?? 'PROD';
  const [companyStatus, setCompanyStatus] = useState<CompanyAccountStatusDto>();

  const humanizeString = (value: string) => {
    /*
      this will add spaces in between property names (both snake_case and camelCase)
      to make it readable and thus dont have to hard code properties
      eg: 'adminPermissionLevel' -> 'Admin Permission Level'
          'admin_permission_level' -> 'Admin Permission Level'
    */
    return value
      .replace(/([_-])/g, ' ')
      .trim()
      .replace(/\w\S*/g, function (str) {
        return str.charAt(0).toUpperCase() + str.substring(1);
      })
      .replace(/([a-z])([A-Z])/g, '$1 $2')
      .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2');
  };

  const getUserPermissions = (userId: number) => {
    getReportDetails(userId);
  };

  const formatAndSetPermissions = (details: CustomReportDetailsDto) => {
    if (
      !details ||
      (!details.creatorPermissions &&
        !details.userPermissions &&
        !details.tabsPermissions)
    ) {
      alert.error(t('NO_PERMISSION_ERROR'));
      return;
    }

    let policyPermissions: PolicyPermissions[] = [];
    if (details.creatorPermissions) {
      let userPermissionsKeys: string[] = [];
      let userPermissionValues: any[] = [];
      if (details.userPermissions) {
        userPermissionsKeys = Object.keys(details.userPermissions);
        userPermissionValues = Object.values(details.userPermissions);
      }

      for (const [key, value] of Object.entries(details.creatorPermissions)) {
        let userPermission = details.userPermissions
          ? userPermissionValues[userPermissionsKeys.indexOf(key)]
          : false;

        policyPermissions.push({
          name: humanizeString(key),
          creatorPermission: value,
          userPermission: userPermission,
        });
      }
    }

    let formattedTabPermissions: FormattedTabPermissions[] = [];
    if (details.tabsPermissions) {
      for (const tab of details.tabsPermissions) {
        let userTabPermissionsKeys: string[] = [];
        let userTabPermissionValues: any[] = [];
        let tabPermissions: TabPermissions[] = [];

        if (tab.userTabPermissions) {
          userTabPermissionsKeys = Object.keys(tab.userTabPermissions);
          userTabPermissionValues = Object.values(tab.userTabPermissions);
        }

        if (tab.creatorTabPermissions) {
          for (const [key, value] of Object.entries(
            tab.creatorTabPermissions,
          )) {
            tabPermissions.push({
              name: humanizeString(key),
              creatorPermission: value,
              userPermission:
                userTabPermissionValues[userTabPermissionsKeys.indexOf(key)],
            });
          }
        }

        formattedTabPermissions.push({
          tabName: tab.tabName,
          id: tab.id,
          permissions: tabPermissions,
        });
      }
    }

    policyPermissions.sort((a, b) => a.name.localeCompare(b.name));

    setPermissionDetails({
      policyPermissions: policyPermissions,
      formattedTabPermissions: formattedTabPermissions,
    });
  };

  const getReportDetails = async (userId?: number) => {
    alert.closeAlert();
    setReportDetails(null);
    setPermissionDetails(null);
    try {
      const result = await getApi(
        CustomReportTabsApi,
      ).customReportTabsControllerGetReportDetails(
        Number(companyId),
        env,
        Number(reportId),
        userId,
      );
      setReportDetails(result.data);
      if (userId) {
        formatAndSetPermissions(result.data);
      }
    } catch (error: any) {
      alert.error(error.message);
    }
  };

  const getCompanyStatus = async (companyId: number) => {
    const response = await getApi(
      CompanyInfoApi,
    ).companyCompanyInfoControllerGetCompanyAccountStatus(env, companyId);

    setCompanyStatus(response.data);
  };

  useEffect(() => {
    if (companyId && reportId) {
      getReportDetails();
      getCompanyStatus(Number(companyId));
    }
  }, []);

  return (
    <Page>
      <Page.Main style={{ flex: 'auto' }}>
        <Page.Header transparent={true}>
          <Page.Breadcrumbs>
            <Breadcrumbs>
              <a href="/v2/custom-reports-tabs">
                <Breadcrumbs.Crumb>
                  {t('CUSTOM_REPORT_TABS', { ns: 'custom-report-tabs' })}
                </Breadcrumbs.Crumb>
              </a>
              <Breadcrumbs.Crumb active={true}>{t('TITLE')}</Breadcrumbs.Crumb>
            </Breadcrumbs>
          </Page.Breadcrumbs>
          <ToolHeader>
            <ToolHeader.Title>{t('TITLE')}</ToolHeader.Title>
            {companyStatus && can({ user: userConfig }, 'jit_escalate') && (
              <ToolHeader.Actions>
                <JitWidget
                  env={env}
                  companyId={companyStatus.id}
                  companyName={companyStatus.name}
                  isPinRequired={companyStatus.is_pin_required_for_jit}
                />
              </ToolHeader.Actions>
            )}
          </ToolHeader>
        </Page.Header>
        <Page.Body>
          <ReportDetailsCard
            id={'reportDetailsCard'}
            reportDetails={reportDetails}
          />
          <Box marginTop="lg">
            <ReportPermissionsCard
              permissionDetails={permissionDetails}
              getUserPermissions={getUserPermissions}
            />
          </Box>
        </Page.Body>
      </Page.Main>
    </Page>
  );
};

export default CustomReportTabsDetailsPage;
