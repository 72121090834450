import type { ProjectContextProps } from 'contexts/ProjectContext';

type UrlContext = Pick<
  ProjectContextProps,
  'companyId' | 'projectId' | 'env' | 'contactId' | 'loginId'
>;
export const generateUrl = (page: string, context: UrlContext) => {
  let url = `/v2/company/${context.companyId}/project/${context.projectId}/${page}`;
  let params = [`env=${context.env}`];
  if (context.contactId) {
    params.push(`contactId=${context.contactId}`);
  }
  if (context.loginId) {
    params.push(`loginId=${context.loginId}`);
  }
  return `${url}?${params.join('&')}`;
};
