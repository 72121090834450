import { SegmentedController } from '@procore/core-react';
import { ENVIRONMENT_OPTIONS } from 'common/constants';
import { AppContext } from 'contexts/AppContext';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface IEnvironmentSwitcherProps {
  initialValue: string;
  onChange: (value: string) => void;
}

export const EnvironmentSwitcher = (props: IEnvironmentSwitcherProps) => {
  const { t } = useTranslation(['common']);
  const { userConfig } = useContext(AppContext);
  const [currentEnv, setCurrentEnv] = useState<string>(props.initialValue);

  const changeEnvironment = (input: string) => {
    const isValidEnvironment = ENVIRONMENT_OPTIONS.find(
      (env) => env.id === input,
    );
    if (!isValidEnvironment) {
      console.error(`Invalid environment: ${input}`);
    }
    const newEnv = isValidEnvironment ? input : ENVIRONMENT_OPTIONS[0].id;
    if (currentEnv !== newEnv) {
      setCurrentEnv(newEnv);
      props.onChange(newEnv);
    }
  };

  return (
    <React.Fragment>
      <SegmentedController aria-label={t('ENVIRONMENT')}>
        {ENVIRONMENT_OPTIONS.filter((env) =>
          userConfig.user.allowedZones.includes(env.id),
        ).map((env, idx) => (
          <SegmentedController.Segment
            key={idx}
            data-qa={`${env.id}_segment`}
            active={currentEnv === env.id}
            onClick={() => {
              changeEnvironment(env.id);
            }}
          >
            {env.label}
          </SegmentedController.Segment>
        ))}
      </SegmentedController>
    </React.Fragment>
  );
};

export default EnvironmentSwitcher;
