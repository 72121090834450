import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Card, Spinner, Box, FlexList, Button } from '@procore/core-react';
import { getApi } from 'utils/api';
import { CompanyDirectoryApi } from '@procore/ipa-nt-api-client-ts';
import type {
  ProjectPermissionTemplatesDto,
  AssignablePermissionTemplatesDto,
} from '@procore/ipa-nt-api-client-ts';
import { IpaAlertContext } from 'contexts/IpaAlertContext';
import { useTranslation } from 'react-i18next';
import { ContactContext } from 'contexts/ContactContext';
import type { TableHeader } from 'components/TableBackend/table.interface';
import TableComponent from 'components/Table';
import AssignableTemplatesModal from './AssignableTemplatesModal';

interface PermissionTemplate extends ProjectPermissionTemplatesDto {
  isLoading?: boolean;
}

export const ProjectPermissionTemplatesPage = () => {
  const { t } = useTranslation('contact', {
    keyPrefix: 'PROJECT_PERMISSION_TEMPLATES',
  });
  const context = useContext(ContactContext);
  const alert = useContext(IpaAlertContext);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isInit, setIsInit] = useState<boolean>(false);
  const [projectPermissionTemplates, setProjectPermissionTemplates] = useState<
    PermissionTemplate[]
  >([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedTemplate, setSelectedTemplate] =
    useState<ProjectPermissionTemplatesDto>(
      {} as ProjectPermissionTemplatesDto,
    );
  const [assignableTemplates, setAssignableTemplates] = useState<
    AssignablePermissionTemplatesDto[] | null
  >(null);

  const actionCell = (item: PermissionTemplate) => {
    return (
      <Button
        loading={item.isLoading}
        size="sm"
        onClick={async () => {
          setSelectedTemplate(item);
          setProjectPermissionTemplates((prev) =>
            prev.map((template) => {
              if (template.id === item.id) {
                return { ...template, isLoading: true };
              }
              return template;
            }),
          );
          const temp = await getAssignableTemplates(item);
          setAssignableTemplates(temp);
          setShowModal(true);
        }}
      >
        {t('VIEW_ASSIGNABLE_TEMPLATES')}
      </Button>
    );
  };

  const tableHeaders: TableHeader[] = [
    {
      title: 'Name',
      node: 'name',
      is_text: true,
      is_sortable: true,
      column_width: 60,
    },
    {
      title: 'Type',
      node: 'type',
      is_sortable: true,
      display_func: (item: PermissionTemplate) => {
        return t(item.type.toUpperCase());
      },
      column_width: 20,
    },
    { title: '', node: null, display_func: actionCell, column_width: 20 },
  ];

  const getAssignableTemplates = async (item: PermissionTemplate) => {
    try {
      const api = getApi(CompanyDirectoryApi);
      const result =
        await api.companyDirectoryControllerGetAssignablePermissionTemplates(
          context.env,
          context.companyId,
          item.id,
        );
      setProjectPermissionTemplates((prev) =>
        prev.map((template) => {
          if (template.id === item.id) {
            return { ...template, isLoading: false };
          }
          return template;
        }),
      );
      return result.data;
    } catch (e: any) {
      alert.error(e);
    }
  };

  const getProjectPermissionTemplates = useCallback(async () => {
    try {
      const api = getApi(CompanyDirectoryApi);
      const result =
        await api.companyDirectoryControllerGetProjectPermissionTemplates(
          context.env,
          context.companyId,
        );

      setProjectPermissionTemplates(
        result.data.map((item) => {
          return { ...item, isLoading: false };
        }),
      );
    } catch (e: any) {
      alert.error(e);
    } finally {
      setIsInit(true);
      setIsLoading(false);
    }
  }, [context]);

  useEffect(() => {
    if (!isInit) {
      getProjectPermissionTemplates();
    }
  }, [getProjectPermissionTemplates, isInit]);

  return (
    <Card>
      <Box padding="lg">
        {isLoading && (
          <Box>
            <FlexList justifyContent="center">
              <Spinner loading={isLoading} />
            </FlexList>
          </Box>
        )}
        {!isLoading && isInit && (
          <TableComponent
            headers={tableHeaders}
            data={projectPermissionTemplates}
            default_sort_column="name"
            default_sort_direction="asc"
            fixed_table={true}
            show_search={true}
            search_columns={['name']}
            show_no_data_message={true}
            no_data_message={t('NO_DATA')}
          />
        )}
        <AssignableTemplatesModal
          show={showModal}
          template={selectedTemplate}
          assignableTemplates={assignableTemplates}
          closeModal={() => setShowModal(false)}
        />
      </Box>
    </Card>
  );
};

export default ProjectPermissionTemplatesPage;
