import type { SectionConfig } from 'pages/page-config.interfaces';

export default {
  id: 'ConfigurableFieldSets',
  i18n: {
    namespace: 'configurable-field-sets',
  },
  route: {
    path: '/v2/company/:company_id/field-sets',
  },
  permissions: {
    view: ['company_tab_view', 'cs_view'],
    edit: [],
  },
  endpoints: {
    view: [],
  },
  pages: {
    FieldSets: {
      id: 'FieldSets',
      label: 'TITLE',
      i18n: {
        namespace: 'configurable-field-sets',
      },
      endpoints: {
        view: [],
      },
      cards: {},
    },
  },
} as SectionConfig;
