import type { SectionConfig } from 'pages/page-config.interfaces';

const sectionConfig: SectionConfig = {
  id: 'JIT',
  i18n: {
    namespace: 'jit',
  },
  permissions: {
    view: ['jit_escalate'],
    edit: ['jit_escalate'],
  },
  endpoints: {
    view: ['companyCompanyInfoControllerGetCompanyAccountStatus'],
    edit: ['jitControllerCreateSession', 'jitControllerTerminateSession'],
  },
  pages: {
    JitHome: {
      route: {
        path: '/v2/jit',
      },
      label: 'JIT_HOME_TITLE',
      cards: {
        SessionList: {
          label: 'SESSION_LIST',
          fields: {
            companyName: {
              label: 'COMPANY_NAME',
            },
            accessLevel: {
              label: 'LABEL_ACCESS_LEVEL',
            },
            expiresAt: {
              label: 'LABEL_EXPIRES',
            },
            extend: {
              label: 'LABEL_EXTEND',
            },
            deescalate: {
              label: 'LABEL_DEESCALATE',
            },
          },
          endpoints: {
            view: ['jitControllerGetActiveSessionsForUser'],
          },
        },
        JitAuditLog: {
          i18n: {
            namespace: 'jit-audit-log',
          },
          label: 'JIT_AUDIT_LOG_TITLE',
          fields: {
            startedAt: {
              label: 'START',
            },
            companyName: {
              label: 'COMPANY_NAME',
            },
            companyId: {
              label: 'COMPANY_ID',
            },
            salesForceCaseNumber: {
              label: 'SALESFORCE_CASE_NUMBER',
            },
            userEmail: {
              label: 'EMAIL',
            },
            env: {
              label: 'ENV',
            },
            accessLevel: {
              label: 'ACCESS_LEVEL',
            },
            endedAt: {
              label: 'DE_ESCALATED',
            },
            pinGeneratedBy: {
              label: 'PIN_GENERATED_BY',
            },
          },
          endpoints: {
            view: ['jitControllerFilterJitSessions'],
          },
        },
        EscalateModal: {
          label: 'MODAL_TITLE',
          fields: {
            escalation_length: {
              label: 'LABEL_ESCALATION_LENGTH',
            },
            access_level: {
              label: 'LABEL_ACCESS_LEVEL',
            },
            pdm_support: {
              label: 'LABEL_PDM_SUPPORT',
            },
            company_support_pin: {
              label: 'LABEL_PIN',
            },
            category: {
              label: 'LABEL_CATEGORY',
            },
            reason: {
              label: 'LABEL_DETAIL',
            },
          },
          endpoints: {
            view: [
              'companySettingsControllerGetCompanyAdvancedSecuritySettings',
              'jitControllerGetCompanySettings',
              'projectControllerGetProjectInfo',
              'companyDirectoryControllerCheckCompanyContact',
              'companySettingsControllerCheckStrategicAccount',
              'jitControllerGetCompanyPin',
              'companyControllerUserLookup',
            ],
          },
          permissions: {
            processExtractsEscalationLength: [
              'process_extract_escalation_length',
            ],
          },
        },
      },
    },
  },
};

export default sectionConfig;
