import type { PageConfig } from 'pages/page-config.interfaces';

const CompanyCostCodes: PageConfig = {
  label: 'TITLE',
  route: {
    page: 'cost-codes',
  },
  i18n: {
    namespace: 'contact',
    keyPrefix: 'COMPANY_COST_CODES',
  },
  permissions: {
    view: ['cs_view'],
  },
  endpoints: {
    view: [
      'companyDirectoryControllerGetProjectPermissionTemplates',
      'companyDirectoryControllerGetAssignablePermissionTemplates',
    ],
  },
  cards: {},
};

export default CompanyCostCodes;
