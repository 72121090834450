import React, { useEffect, useState } from 'react';
import type { FieldSetsTabType } from './index';
import type { PageConfigTranslated } from 'pages/page-config.interfaces';
import type { ConfigurableFieldSetApiDto } from '@procore/ipa-nt-api-client-ts';
import { Box, H2 } from '@procore/core-react';
import Subtype from './SubType';
import { useTranslation } from 'react-i18next';

export interface FieldSetProps {
  data: FieldSetsTabType;
  pageConfig: PageConfigTranslated;
}

export type SubtypeType = {
  id: string;
  name: string;
  fieldsets: ConfigurableFieldSetApiDto[][];
  isOpen: boolean;
};

export const FieldSet = (props: FieldSetProps) => {
  const { t } = useTranslation('configurable-field-sets');

  const pageConfig = props.pageConfig;
  const [fieldsets, setFieldsets] = useState<SubtypeType[]>([]);

  const allSubtypes = {
    contracts: {
      PrimeContract: 'PRIME_CONTRACTS',
      PurchaseOrderContract: 'PURCHASE_ORDER_CONTRACT',
      WorkOrderContract: 'SUBCONTRACTS',
    },
    dailylog: {
      ManpowerLog: 'MANPOWER_LOG',
      NotesLog: 'NOTES_LOG',
      DailyConstructionReportLog: 'DAILY_CONSTRUCTION_REPORT_LOG',
      WeatherLog: 'WEATHER_LOG',
      VisitorLog: 'VISITOR_LOG',
    },
    directory: {
      Contact: 'CONTACT',
      Vendor: 'VENDOR',
    },
    incidents: {
      Incident: 'INCIDENT',
      'Incident::Detail::Injury': 'INJURY_ILLNESS',
      'Incident::Detail::PropertyDamage': 'PROPERTY_DAMAGE',
      'Incident::Detail::Environmental': 'ENVIRONMENTAL',
      'Incident::Detail::NearMiss': 'NEAR_MISS',
      'Incident::WitnessStatement': 'WITNESS_STATEMENT',
      'Incident::Action': 'ACTION',
    },
  };
  const inspectionSubtypes = {
    null: 'Unassociated',
    7: 'Quality',
    8: 'Safety',
    9: 'Commissioning',
  };
  const observationSubtypes = {
    commissioning: 'COMMISSIONING',
    environmental: 'ENVIRONMENTAL',
    quality: 'QUALITY',
    safety: 'SAFETY',
    warranty: 'WARRANTY',
    work_to_complete: 'WORK_TO_COMPLETE',
  };

  useEffect(() => {
    if (props.data) {
      let subtypes: SubtypeType[] = [] as SubtypeType[];
      if (props.data.id in allSubtypes) {
        const tabSubtypes =
          allSubtypes[props.data.id as keyof typeof allSubtypes];
        for (const subtype in tabSubtypes) {
          let f: ConfigurableFieldSetApiDto[][] = [];
          props.data.fieldsets.forEach((fieldset) => {
            if (fieldset[0].class_name === subtype) {
              f.push(fieldset);
            }
          });
          if (f.length > 0) {
            subtypes.push({
              id: props.data.id,
              name: t(tabSubtypes[subtype as keyof typeof tabSubtypes]),
              fieldsets: f,
              isOpen: false,
            });
          }
        }
      } else if (props.data.id === 'inspections') {
        for (const subtype in inspectionSubtypes) {
          if (props.data.fieldsets.length > 0) {
            let f: ConfigurableFieldSetApiDto[] = [];
            props.data.fieldsets[0].forEach((fieldset) => {
              const type_id = !fieldset.inspection_type_id
                ? 'null'
                : fieldset.inspection_type_id.toString();
              if (type_id === subtype) {
                f.push(fieldset);
              }
            });
            if (f.length > 0) {
              subtypes.push({
                id: props.data.id,
                name: inspectionSubtypes[
                  subtype as keyof typeof inspectionSubtypes
                ],
                fieldsets: [f],
                isOpen: false,
              });
            }
          }
        }
      } else if (props.data.id === 'observations') {
        for (const subtype in observationSubtypes) {
          if (props.data.fieldsets.length > 0) {
            let f: ConfigurableFieldSetApiDto[] = [];
            props.data.fieldsets[0].forEach((fieldset) => {
              if (fieldset.category === subtype) {
                f.push(fieldset);
              }
            });
            if (f.length > 0) {
              subtypes.push({
                id: props.data.id,
                name: t(
                  observationSubtypes[
                    subtype as keyof typeof observationSubtypes
                  ],
                ),
                fieldsets: [f],
                isOpen: false,
              });
            }
          }
        }
      } else {
        let f: ConfigurableFieldSetApiDto[][] = [];
        props.data.fieldsets.forEach((fieldset) => {
          f.push(fieldset);
        });
        subtypes.push({
          id: props.data.id,
          name: '',
          fieldsets: f,
          isOpen: true,
        });
      }
      setFieldsets(subtypes);
    }
  }, [props]);

  return (
    <Box marginTop="lg">
      <H2>{`${t(props.data?.name)} ${t('FIELD_SETS')}`}</H2>
      {fieldsets?.map((subtype, i) => {
        return (
          <div key={`${subtype.id}`}>
            <Subtype subtype={subtype} pageConfig={pageConfig} />
          </div>
        );
      })}
    </Box>
  );
};
