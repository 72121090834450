import styled from 'styled-components';
import { spacing } from '@procore/core-react';

export const JitWidgetWrapper = styled.div`
  padding-bottom: ${spacing.xs}px;

  .jit-status {
    min-width: 230px;
  }
`;
