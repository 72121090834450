import type { PageConfig } from 'pages/page-config.interfaces';

export default <PageConfig>{
  label: 'OKTA_USERS_TITLE',
  route: {
    path: '/v2/users',
  },
  endpoints: {
    view: ['oktaControllerGetOktaUsers', 'oktaControllerGetInactiveOktaUsers'],
  },
  cards: {
    ActiveOktaUsers: {
      route: {
        tab: 'active',
      },
      label: 'ACTIVE_OKTA_USERS_TITLE',
      fields: {},
    },
    InactiveOktaUsers: {
      route: {
        tab: 'inactive',
      },
      label: 'INACTIVE_OKTA_USERS_TITLE',
      fields: {},
    },
  },
};
