import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  createContext,
} from 'react';
import { ProjectsApi } from '@procore/ipa-nt-api-client-ts';
import type { ProjectScheduleSettingsDto } from '@procore/ipa-nt-api-client-ts';
import { getApi } from 'utils/api';
import { IpaAlertContext } from 'contexts/IpaAlertContext';
import SettingsTab from './SettingsTab';
import { ProjectContext } from 'contexts/ProjectContext';
import type { IDatasource, IEditableCard } from 'components/EditableCard';
import {
  Card,
  Box,
  ToolHeader,
  Tabs,
  Flex,
  Spinner,
} from '@procore/core-react';
import PermissionsTab from './PermissionsTab';

export interface ProjectScheduleContextProps {
  projectId: number;
  companyId: number;
  env: string;
  dataSources: {
    scheduleSettings: IDatasource<ProjectScheduleSettingsDto>;
  };
}

export const ProjectScheduleContext = createContext(
  {} as ProjectScheduleContextProps,
);

const ProjectSchedulePage = () => {
  const alert = useContext(IpaAlertContext);
  const context = useContext(ProjectContext);
  const config = context.pageConfig;
  const t = config.translate;

  const [isInit, setIsInit] = useState<boolean>(false);
  const [isLoading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('settings');
  const [scheduleSettings, setScheduleSettings] =
    useState<ProjectScheduleSettingsDto>();
  const [cardLoading, setCardLoading] = useState<{ [key: string]: boolean }>(
    {},
  );

  const tabs = [
    { id: 'settings', name: t('PROJECT_SCHEDULE_SETTINGS.SETTINGS') },
    { id: 'permissions', name: t('PROJECT_SCHEDULE_SETTINGS.PERMISSIONS') },
  ];

  const dataSources: ProjectScheduleContextProps['dataSources'] = {
    scheduleSettings: {
      id: 'scheduleSettings',
      data: scheduleSettings!,
    },
  };

  const isTabActive = (tab: string) => {
    return tab === activeTab ? true : false;
  };

  const changeTab = (tab: string) => {
    if (tab !== activeTab) {
      window.history.pushState(null, '', `#${tab}`);
      setActiveTab(tab);
    }
  };

  const updateProjectScheduleSettingsRequest = async (
    values: any,
    reason?: string,
  ) => {
    const api = getApi(ProjectsApi);
    return api.projectControllerUpdateProjectScheduleSettings(
      context.env,
      context.companyId,
      context.projectId,
      {
        reason: reason ?? '',
        project_integration: values.scheduleSettings.project_integration,
      },
    );
  };

  const saveChanges = async (
    card: IEditableCard,
    values: any,
    reason?: string,
  ) => {
    alert.closeAlert();
    setCardLoading({
      ...cardLoading,
      [card.id]: true,
    });

    try {
      const res = await updateProjectScheduleSettingsRequest(values, reason);
      if (res.data) {
        setScheduleSettings(res.data);
      }
      alert.success(t('CARD_UPDATED', { CARD: card.label }));
    } catch (e: any) {
      alert.error(e);
    } finally {
      setCardLoading({
        ...cardLoading,
        [card.id]: false,
      });
    }
  };

  const saveEmailChanges = async (values: any) => {
    alert.closeAlert();
    try {
      const api = getApi(ProjectsApi);
      const res = await api.projectControllerUpdateProjectScheduleEmailSettings(
        context.env,
        context.companyId,
        context.projectId,
        {
          email_settings: values,
        },
      );
      if (res.data) {
        setScheduleSettings(res.data);
      }
      alert.success(t('PROJECT_SCHEDULE_SETTINGS.CARD_UPDATED'));
    } catch (e: any) {
      alert.error(e);
    }
  };

  const getProjectScheduleSettings = useCallback(async () => {
    setScheduleSettings(undefined);
    alert.closeAlert();
    setIsInit(false);
    setLoading(true);
    try {
      const api = getApi(ProjectsApi);
      const res = await api.projectControllerGetProjectScheduleSettings(
        context.env,
        context.companyId,
        context.projectId,
      );
      if (res.data) {
        setScheduleSettings(res.data);
      }
      setIsInit(true);
    } catch (e: any) {
      alert.error(e);
    } finally {
      setLoading(false);
    }
  }, [context.env, context.companyId, context.projectId]);

  useEffect(() => {
    if (window.location.hash !== '') {
      const hash = window.location.hash.substring(1);
      if (tabs.findIndex((t) => t.id === hash) !== -1) {
        changeTab(hash);
      }
    }
  }, []);

  useEffect(() => {
    getProjectScheduleSettings();
  }, [getProjectScheduleSettings]);

  return (
    <ProjectScheduleContext.Provider
      value={{
        dataSources: dataSources,
        projectId: context.projectId,
        companyId: context.companyId,
        env: context.env,
      }}
    >
      <div>
        <Card>
          <Box padding="lg" marginBottom="lg">
            <ToolHeader>
              <ToolHeader.Tabs>
                {tabs.map((tab, i) => (
                  <Tabs.Tab
                    active={isTabActive(tab.id)}
                    onClick={() => changeTab(tab.id)}
                    key={i}
                    data-qa={`project-info-${tab.id}-tab`}
                  >
                    <Tabs.Link>{tab.name}</Tabs.Link>
                  </Tabs.Tab>
                ))}
              </ToolHeader.Tabs>
            </ToolHeader>

            {activeTab === 'settings' &&
              (isLoading || cardLoading['settings']) && (
                <Flex justifyContent="center">
                  <Spinner loading={isLoading} />
                </Flex>
              )}

            {activeTab === 'settings' &&
              isInit &&
              !isLoading &&
              !cardLoading['settings'] &&
              scheduleSettings && (
                <SettingsTab
                  save={saveChanges}
                  saveEmailSettings={saveEmailChanges}
                  id="project-schedule-settings"
                />
              )}

            {activeTab === 'permissions' &&
              isInit &&
              !isLoading &&
              scheduleSettings && (
                <PermissionsTab
                  save={saveChanges}
                  id="project-schedule-permissions"
                />
              )}
          </Box>
        </Card>
      </div>
    </ProjectScheduleContext.Provider>
  );
};

export default ProjectSchedulePage;
