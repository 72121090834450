import { colors } from '@procore/core-react';

export const styles = {
  gridRow: {
    padding: '5px 0',
  },
  errorIcon: {
    color: colors.red50,
  },
  checkIcon: {
    color: colors.green50,
  },
  escalatedCheckbox: {
    marginTop: '10px',
  },
  gridLabel: {
    fontWeight: 'bold',
  },
  gridValue: {},
};
