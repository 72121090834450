import type { SectionConfig } from 'pages/page-config.interfaces';
import WorkflowUsageReportPage from './WorkflowUsageData/page.config';
import CompanyCustomToolReportPage from './CompanyCustomToolReport/page.config';
import ProjectCustomToolReportPage from './ProjectCustomToolReport/page.config';
import CompaniesInvalidSalesforceAccountPage from './CompaniesInvalidSalesforceAccount/page.config';
import CustomFieldsReportPage from './CustomFieldsReport/page.config';

export default <SectionConfig>{
  id: 'Insights',
  label: 'INSIGHTS',
  route: {
    path: '/v2/insights/:page?',
    v1_path: '/insights',
  },
  i18n: {
    namespace: 'insights',
  },
  permissions: {
    view: ['company_tab_view'],
  },
  pages: {
    WorkflowUsageReportPage,
    CompanyCustomToolReportPage,
    ProjectCustomToolReportPage,
    CompaniesInvalidSalesforceAccountPage,
    CustomFieldsReportPage,
  },
};
