import styled from 'styled-components';
import { colors, spacing } from '@procore/core-react';

export const styles = {
  settingsRow: {
    padding: `${spacing.xs}px ${spacing.sm}px`,
  },
  popover: {
    minWidth: '600px',
  },
};

export const UserMenuContent = styled.div`
  padding: ${spacing.sm}px 0;
  font-size: 14px;

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
      padding: ${spacing.xs}px ${spacing.sm}px;
      border-bottom: 1px solid ${colors.gray90};
    }
  }

  .logout {
    text-align: center;
    padding: ${spacing.sm}px ${spacing.sm}px ${spacing.xs}px ${spacing.sm}px;
  }
`;
