import React, { useContext } from 'react';
import { useDrop } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import { Clear } from '@procore/core-icons';
import { HomepageContext } from './HomepageContext';
import type { DropTargetProps } from './interfaces';
import {
  DropTargetWrapper,
  DraggableCard,
  DraggableModuleLabel,
} from './styles';

const DropTarget = (props: DropTargetProps) => {
  const { isEditMode, lastDroppedItem } = props;
  const { t } = useTranslation('homepage', { keyPrefix: 'MODULES' });
  const { updateUsedWidgets } = useContext(HomepageContext);

  const [{ isOver, isOverCurrent }, drop] = useDrop(
    () => ({
      accept: 'module',
      drop: props.onDrop,
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        isOverCurrent: monitor.isOver({ shallow: true }),
      }),
    }),
    [],
  );

  return (
    <DropTargetWrapper ref={drop}>
      {lastDroppedItem && (
        <DraggableCard className="dropped">
          <props.lastDroppedItem.icon size="lg" />
          <br />
          <DraggableModuleLabel intent="small">
            {t(lastDroppedItem.description)}
          </DraggableModuleLabel>
        </DraggableCard>
      )}
      {lastDroppedItem && isEditMode && (
        <div
          className="close"
          onClick={() => updateUsedWidgets(lastDroppedItem.name, 'delete')}
        >
          <Clear size="sm" />
        </div>
      )}
      <div>{props.children}</div>
    </DropTargetWrapper>
  );
};

export default DropTarget;
