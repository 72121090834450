import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card,
  Flex,
  Spinner,
  Box,
  EmptyState,
  colors,
  spacing,
  Grid,
  Typography,
} from '@procore/core-react';
import styled from 'styled-components';
import { getApi } from 'utils/api';
import { ProjectsApi } from '@procore/ipa-nt-api-client-ts';
import type { RestV10InternalSuperIpaProjectsProjectIdProjectManagementSubmittalSettingsGet200ResponseAvailableSubmittalResponsesInnerApiDto } from '@procore/ipa-nt-api-client-ts';
import { IpaAlertContext } from 'contexts/IpaAlertContext';
import { ProjectContext } from 'contexts/ProjectContext';
import { StyledGridColumn } from 'styles';

interface ProjectSubmittalResponsesDto {
  [
    key: string
  ]: RestV10InternalSuperIpaProjectsProjectIdProjectManagementSubmittalSettingsGet200ResponseAvailableSubmittalResponsesInnerApiDto[];
}

const SubmittalResponsesGrid = styled(Grid)`
  .ipa-grid-row {
    padding: ${spacing.lg}px 0;
    border-bottom: 1px solid ${colors.gray70};

    .name-col {
      text-transform: uppercase;
      font-weight: 600;
    }

    .box-item {
      background-color: ${colors.gray94};
      padding: ${spacing.sm}px;
    }
  }
`;

const SubmittalResponsesPage = () => {
  const alert = useContext(IpaAlertContext);
  const context = useContext(ProjectContext);
  const config = context.pageConfig.card('SubmittalResponses');

  const t = config.translate;
  const { t: tCommon } = useTranslation(['common']);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isInit, setIsInit] = useState<boolean>(true);
  const [projectSubmittalResponses, setProjectSubmittalResponses] =
    useState<ProjectSubmittalResponsesDto>({} as ProjectSubmittalResponsesDto);

  const settings = [
    {
      id: 'approved',
      name: config.fields.approved.label,
      only_one: false,
    },
    {
      id: 'approved as noted',
      name: config.fields.approved_as_noted.label,
      only_one: false,
    },
    {
      id: 'for record only',
      name: config.fields.for_record_only.label,
      only_one: false,
    },
    {
      id: 'forwarded for review',
      name: config.fields.forwarded_for_review.label,
      only_one: false,
    },
    {
      id: 'pending',
      name: config.fields.pending.label,
      only_one: true,
    },
    {
      id: 'rejected',
      name: config.fields.rejected.label,
      only_one: false,
    },
    {
      id: 'revise and resubmit',
      name: config.fields.revise_and_resubmit.label,
      only_one: false,
    },
    {
      id: 'submitted',
      name: config.fields.submitted.label,
      only_one: true,
    },
    {
      id: 'void',
      name: config.fields.void.label,
      only_one: false,
    },
  ];

  const getProjectSubmittalResponses = useCallback(async () => {
    setIsInit(false);
    setIsLoading(true);
    try {
      const api = getApi(ProjectsApi);
      const result = await api.projectControllerGetProjectSubmittalSettings(
        context.env,
        context.companyId,
        context.projectId,
      );

      if (
        result.data.available_submittal_responses &&
        result.data.available_submittal_responses.length > 0
      ) {
        let temp: any = {};
        result.data.available_submittal_responses.forEach((r) => {
          if (!temp[r.considered]) {
            temp[r.considered] = [];
          }
          temp[r.considered].push(r);
        });
        setProjectSubmittalResponses(temp);
      }
    } catch (e: any) {
      alert.error(e);
    } finally {
      setIsLoading(false);
      setIsInit(true);
    }
  }, [context.projectId, context.companyId, context.env]);

  useEffect(() => {
    getProjectSubmittalResponses();
  }, [getProjectSubmittalResponses]);

  return (
    <React.Fragment>
      <Card>
        <Box padding="0 lg lg lg">
          {(!isInit || isLoading) && (
            <Box marginBottom="lg">
              <Card>
                <Box padding="lg">
                  {!isInit && !isLoading && (
                    <EmptyState>
                      <EmptyState.NoItems />
                      <EmptyState.Title>{tCommon('NO_DATA')}</EmptyState.Title>
                    </EmptyState>
                  )}
                  {isLoading && (
                    <Flex justifyContent="center">
                      <Spinner loading={isLoading} />
                    </Flex>
                  )}
                </Box>
              </Card>
            </Box>
          )}
          {isInit && !isLoading && (
            <Typography intent="body">
              <SubmittalResponsesGrid>
                {settings.map((setting, i) => (
                  <Grid.Row key={i} className="ipa-grid-row box-item-row">
                    <StyledGridColumn
                      colWidth={{ tabletSm: 4 }}
                      className="name-col"
                    >
                      {setting.name}:
                    </StyledGridColumn>
                    <StyledGridColumn colWidth={{ tabletSm: 8 }}>
                      <Grid.Row>
                        {projectSubmittalResponses[setting.id] &&
                          projectSubmittalResponses[setting.id].map((s, j) => (
                            <StyledGridColumn
                              colWidth={{ tabletSm: 4 }}
                              key={j}
                            >
                              <div className="box-item">{s.name}</div>
                            </StyledGridColumn>
                          ))}
                      </Grid.Row>
                    </StyledGridColumn>
                  </Grid.Row>
                ))}
              </SubmittalResponsesGrid>
            </Typography>
          )}
        </Box>
      </Card>
    </React.Fragment>
  );
};

export default SubmittalResponsesPage;
