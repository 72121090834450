import React, { useContext, useEffect, useState } from 'react';
import { Box, Card, H2 } from '@procore/core-react';
import { SubmittalSettingsContext } from '.';
import TableComponent from 'components/Table';
import type { TableHeader } from 'components/TableBackend/table.interface';
import type { SubmittalSettingsCardProps } from './interfaces';

export const SubmittalEmailsCard = (props: SubmittalSettingsCardProps) => {
  const config = props.cardConfig;
  const t = config.translate;

  const [submittalEmails, setSubmittalEmails] = useState<any>();

  const {
    submittalSettingsDataSources: { submittalSettings },
  } = useContext(SubmittalSettingsContext);

  useEffect(() => {
    // Converting the submittal_notification_settings and nested recipients object(s) to a flat array of objects
    // that can be displayed as a table
    const submittalEmails =
      submittalSettings.data?.submittal_notification_settings?.map((s) => {
        const temp: { [key: string]: any } = { name: s.name };
        s.recipients.forEach((r) => {
          temp[r.name] = r.checked;
        });
        return temp;
      }) ?? [];
    setSubmittalEmails(submittalEmails);
  }, [submittalSettings]);

  const tableHeaders: TableHeader[] = [
    {
      title: t('TABLE_HEADER.ACTION'),
      node: 'name',
      is_text: true,
      is_sortable: true,
    },
    {
      title: t('TABLE_HEADER.CREATOR'),
      node: 'creator',
      is_sortable: true,
      is_boolean: true,
    },
    {
      title: t('TABLE_HEADER.SUBMITTAL_MANAGER'),
      node: 'manager',
      is_sortable: true,
      is_boolean: true,
    },
    {
      title: t('TABLE_HEADER.SUBMITTER'),
      node: 'submitter',
      is_sortable: false,
      is_boolean: true,
    },
    {
      title: t('TABLE_HEADER.APPROVER'),
      node: 'approver',
      is_sortable: false,
      is_boolean: true,
    },
    {
      title: t('TABLE_HEADER.REVIEWER'),
      node: 'reviewer',
      is_boolean: true,
      is_sortable: true,
    },
    {
      title: t('TABLE_HEADER.DISTRIBUTION_GROUP'),
      node: 'distribution_group',
      is_sortable: false,
      is_boolean: true,
    },
  ];

  return (
    <Box marginBottom="lg">
      <Card>
        <Box padding="lg">
          <H2>{config.label}</H2>
          <TableComponent
            data={submittalEmails}
            headers={tableHeaders}
            hide_pagination={true}
            default_sort_column="name"
            default_sort_direction="asc"
          />
        </Box>
      </Card>
    </Box>
  );
};

export default SubmittalEmailsCard;
