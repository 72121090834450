// PARAMETERS
// color: string
// text: string
//

import React from 'react';
import { Pill } from '@procore/core-react';
import type { PillColor } from '@procore/core-react';
import { PillWrapper } from './styles';

interface InlinePillComponentProps {
  color: PillColor;
  text: string;
}

export const InlinePillComponent = (props: InlinePillComponentProps) => {
  return (
    <PillWrapper>
      <Pill color={props.color}>{props.text}</Pill>
    </PillWrapper>
  );
};

export default InlinePillComponent;
