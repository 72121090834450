import React, { useState } from 'react';
import {
  Box,
  Button,
  Card,
  EmptyState,
  Form,
  H2,
  H3,
  Spinner,
} from '@procore/core-react';
import { useTranslation } from 'react-i18next';
import { PageConfig } from 'pages/page-config.service';
import { FormButtonWrapper } from 'styles';
import TableComponent from 'components/Table';
import type { TableHeader } from 'components/Table/table.interface';
import type { UserPermissionDetails } from 'pages/CustomReportTabs/custom.reports.interface';

interface ReportPermissionsProps {
  permissionDetails: UserPermissionDetails | null;
  getUserPermissions: (userId: number) => void;
}

export const ReportPermissionsCard = (props: ReportPermissionsProps) => {
  const { t } = useTranslation('custom-report-tabs-details');
  const config = PageConfig.getCard(
    t,
    'Reports',
    'CustomReportTabsDetails',
    'ReportPermissions',
  );

  const [userId, setUserId] = useState<number | null>(null);

  const [isLoadingPermissions, setIsLoadingPermissions] =
    useState<boolean>(false);

  const tableHeaders: TableHeader[] = [
    {
      title: '',
      node: 'name',
      is_sortable: false,
      is_text: true,
    },
    {
      title: t('USER', { ns: 'common' }),
      node: 'userPermission',
      is_sortable: false,
      is_text: true,
    },
    {
      title: config.translate('CREATOR'),
      node: 'creatorPermission',
      is_sortable: false,
      is_text: true,
    },
  ];

  const getUserPermissions = (userId: number) => {
    setIsLoadingPermissions(true);
    setUserId(userId);
    try {
      props.getUserPermissions(userId);
    } finally {
      setIsLoadingPermissions(false);
    }
  };

  return (
    <Card>
      <Box padding="lg">
        <H2>{config.translate('TITLE')}</H2>
        <Box marginTop="lg">
          <Form
            view="create"
            enableReinitialize={true}
            initialValues={{ userId: null }}
            onSubmit={(values) => getUserPermissions(Number(values.userId))}
          >
            {(props: any) => {
              const { dirty, values, handleSubmit } = props;
              return (
                <Form.Form
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' && values.userId !== userId) {
                      handleSubmit();
                    }
                  }}
                >
                  <Form.Row>
                    <Form.Text
                      name="userId"
                      id="userId"
                      label={`${t('USER_ID', { ns: 'common' })}:`}
                      type="number"
                      data-qa="user-field"
                      colStart={1}
                      colWidth={2}
                    />

                    <FormButtonWrapper>
                      <Button
                        type="submit"
                        variant="primary"
                        data-qa="get-permissions-btn"
                        disabled={!dirty || values.userId === ''}
                        loading={isLoadingPermissions}
                      >
                        {config.translate('GET_PERMISSIONS')}
                      </Button>
                    </FormButtonWrapper>
                  </Form.Row>
                </Form.Form>
              );
            }}
          </Form>
        </Box>
        {userId !== null ? (
          <React.Fragment>
            {props.permissionDetails !== null ? (
              <React.Fragment>
                <Box marginBottom="xl">
                  <H3>{config.translate('POLICY_PERMISSIONS')}</H3>
                  <TableComponent
                    headers={tableHeaders}
                    data={props.permissionDetails.policyPermissions}
                    hide_pagination={true}
                    show_no_data_message={true}
                    default_sort_column={''}
                    default_sort_direction={''}
                  />
                </Box>
                {props.permissionDetails.formattedTabPermissions.map((tab) => {
                  return (
                    <Box key={tab.id} marginBottom="xl">
                      <H3>{`${tab.tabName} ${t('PERMISSIONS', {
                        ns: 'custom-report-tabs',
                      })}`}</H3>
                      <TableComponent
                        headers={tableHeaders}
                        data={tab.permissions}
                        page_size={4}
                        hide_pagination={true}
                        horizontal_scroll={true}
                        show_no_data_message={true}
                        default_sort_column={''}
                        default_sort_direction={''}
                      />
                    </Box>
                  );
                })}
              </React.Fragment>
            ) : (
              <Spinner />
            )}
          </React.Fragment>
        ) : (
          <EmptyState>
            <EmptyState.NoResults />
            <EmptyState.Title>
              {config.translate('EMPTY_TEXT')}
            </EmptyState.Title>
          </EmptyState>
        )}
      </Box>
    </Card>
  );
};

export default ReportPermissionsCard;
