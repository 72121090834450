import type { PageConfig } from 'pages/page-config.interfaces';

export default <PageConfig>{
  label: 'BUSINESS_ENTITY.RECEIVED_PAYOUTS',
  endpoints: {
    view: [],
    edit: [],
  },
  cards: {},
};
