import styled from 'styled-components';
import { spacing, Link } from '@procore/core-react';

export const ActionButtonsStyle = {
  position: 'relative',
  bottom: spacing.xs,
} as React.CSSProperties;

export const AsideWrapper = styled.div`
  transition: width 0.3s;
  width: 400px;
  transition-timing-function: ease-in;
  position: relative;
  button.open {
    position: absolute;
    top: ${spacing.lg}px;
    left: ${spacing.sm}px;
  }
  &.closed {
    width: 50px;
    transition-timing-function: ease-out;
    aside > div {
      display: none;
    }
  }
`;

export const StyledLink = styled(Link)`
  border: none !important;
  outline: none !important;
  -moz-outline-style: none !important;
  box-shadow: none !important;

  &:hover {
    text-decoration: none;
  }
`;
