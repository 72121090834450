import styled from 'styled-components';
import { colors, spacing } from '@procore/core-react';

export const QuickHistoryWrapper = styled.div`
  h2 {
    padding: ${spacing.sm}px;
    border-radius: ${spacing.xs}px ${spacing.xs}px 0 0;
  }

  > ul {
    padding: 0px ${spacing.sm}px ${spacing.xs}px ${spacing.sm}px;
    list-style-type: none;

    > li {
      padding: ${spacing.xs}px;
      border: none;
      margin-bottom: ${spacing.xs}px;
      border-radius: ${spacing.xs}px;
      font-size: 14px;

      span {
        color: ${colors.gray50};
        font-size: 12px;
        display: block;
        margin-top: ${spacing.sm}px;
      }

      ul {
        margin-left: ${spacing.lg}px;
        list-style: disc;
      }
    }
  }
`;
