import React, { useCallback, useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card,
  Flex,
  Spinner,
  Box,
  EmptyState,
  Button,
} from '@procore/core-react';
import { getApi } from 'utils/api';
import { CompaniesApi } from '@procore/ipa-nt-api-client-ts';
import type { RestV10InternalSuperIpaCompaniesCompanyIdProcoreExpenseAllocationsGet200ResponseExpenseAllocationsInnerApiDto } from '@procore/ipa-nt-api-client-ts';
import { IpaAlertContext } from 'contexts/IpaAlertContext';
import { AppContext } from 'contexts/AppContext';
import { ProjectManagementContext } from '..';
import TableComponent from 'components/Table';
import type { TableHeader } from 'components/Table/table.interface';
import ConfirmModal from 'components/ConfirmModal';
import { can } from 'utils/permissions.helper';

interface ExpenseAllocations
  extends RestV10InternalSuperIpaCompaniesCompanyIdProcoreExpenseAllocationsGet200ResponseExpenseAllocationsInnerApiDto {}

const ExpenseAllocationsPage = () => {
  const { t } = useTranslation(['project-management']);
  const { t: tCommon } = useTranslation(['common']);
  const { userConfig } = useContext(AppContext);
  const { env, companyId, companyName, isDemoCompany, company } = useContext(
    ProjectManagementContext,
  );
  const alert = useContext(IpaAlertContext);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isInit, setIsInit] = useState<boolean>(true);
  const [companyExpenseAllocations, setCompanyExpenseAllocations] = useState<
    ExpenseAllocations[]
  >([]);
  const [selectedExpsenseAllocation, setSelectedExpenseAllocation] =
    useState<ExpenseAllocations>({} as ExpenseAllocations);
  const [showModal, setShowModal] = useState<boolean>(false);
  const canEdit = can(
    { user: userConfig, subject: company },
    ['cs_edit', 'company_tab_edit'],
    { allowDemoAccess: true },
  );
  const deleteCell = (item: ExpenseAllocations) => {
    return canEdit ? (
      <Button
        size="sm"
        disabled={false}
        onClick={() => deleteExpenseAllocation(item)}
      >
        {tCommon('DELETE')}
      </Button>
    ) : (
      <span></span>
    );
  };

  const deleteExpenseAllocation = (item: ExpenseAllocations) => {
    setSelectedExpenseAllocation(item);
    setShowModal(true);
  };

  const confirmDeleteExpenseAllocation = async (reason: string) => {
    try {
      const api = getApi(CompaniesApi);
      const result = await api.companyControllerDeleteCompanyExpenseAllocation(
        env,
        companyId,
        {
          expenseAllocationId: selectedExpsenseAllocation.id,
          reason: reason,
        },
      );
      setCompanyExpenseAllocations(result.data.expense_allocations);
      alert.success(t('DELETE_EXPENSE_ALLOCATIONS_SUCCESS'));
    } catch (e: any) {
      alert.error(e);
    } finally {
      setShowModal(false);
    }
  };

  const expenseAllocationsTableHeaders: TableHeader[] = [
    {
      title: tCommon('PROJECTS'),
      node: 'project_name',
      is_text: true,
      is_sortable: true,
      column_width: 15,
    },
    {
      title: t('EXPENSE_ALLOCATIONS_TABLE.ESTIMATED_CONTRACT_VALUE'),
      node: 'original_contract_value',
      is_currency: true,
      is_sortable: true,
      column_width: 10,
    },
    {
      title: tCommon('VALUE'),
      node: 'value',
      is_currency: true,
      is_sortable: true,
      column_width: 5,
    },
    {
      title: t('EXPENSE_ALLOCATIONS_TABLE.ESTIMATED_START_DATE'),
      node: 'estimated_start_date',
      is_text: true,
      is_sortable: true,
      column_width: 10,
    },
    {
      title: t('EXPENSE_ALLOCATIONS_TABLE.ESTIMATED_END_DATE'),
      node: 'estimated_end_date',
      is_text: true,
      is_sortable: true,
      column_width: 10,
    },
    {
      title: t('EXPENSE_ALLOCATIONS_TABLE.CONTRACT_BILLING_ORIGINAL_VALUE'),
      node: 'billing_period_original_value',
      is_currency: true,
      is_sortable: true,
      column_width: 10,
    },
    {
      title: t('EXPENSE_ALLOCATIONS_TABLE.CONTRACT_BILLING_REMAINING_VALUE'),
      node: 'billing_period_remaining_value',
      is_currency: true,
      is_sortable: true,
      column_width: 10,
    },
    {
      title: t('EXPENSE_ALLOCATIONS_TABLE.CONTRACT_BILLING_START_DATE'),
      node: 'billing_period_start_date',
      is_text: true,
      is_sortable: true,
      column_width: 10,
    },
    {
      title: t('EXPENSE_ALLOCATIONS_TABLE.CONTRACT_BILLING_END_DATE'),
      node: 'billing_period_end_date',
      is_text: true,
      is_sortable: true,
      column_width: 10,
    },
    { title: '', node: '', display_func: deleteCell, column_width: 10 },
  ];

  const getExpenseAllocations = useCallback(async () => {
    setIsInit(false);
    setLoading(true);

    try {
      const api = getApi(CompaniesApi);
      const result = await api.companyControllerGetCompanyExpenseAllocations(
        env,
        companyId,
      );
      setCompanyExpenseAllocations(result.data.expense_allocations);
    } catch (e: any) {
      alert.error(e);
    } finally {
      setLoading(false);
      setIsInit(true);
    }
  }, [companyId, env]);

  useEffect(() => {
    getExpenseAllocations();
  }, [getExpenseAllocations]);

  return (
    <React.Fragment>
      <Card>
        <Box padding="lg">
          {!isInit && !isLoading && (
            <EmptyState>
              <EmptyState.NoItems />
              <EmptyState.Title>{tCommon('NO_DATA')}</EmptyState.Title>
            </EmptyState>
          )}
          {isLoading && (
            <Flex justifyContent="center">
              <Spinner loading={isLoading} />
            </Flex>
          )}
          {isInit && !isLoading && (
            <>
              <TableComponent
                data_qa="pm-company-expense-allocations-table"
                data={companyExpenseAllocations}
                headers={expenseAllocationsTableHeaders}
                default_sort_column="project_name"
                show_no_data_message={true}
                no_data_message={tCommon('NO_DATA')}
                default_sort_direction={'desc'}
              />
              <ConfirmModal
                isOpen={showModal}
                modalTitle={t('DELETE_EXPENSE_ALLOCATION')}
                modalCopy={t('DELETE_EXPENSE_ALLOCATION_COPY', {
                  projectName: selectedExpsenseAllocation.project_name,
                  companyName: companyName,
                })}
                closeModal={() => setShowModal(false)}
                confirmModal={(values: any) =>
                  confirmDeleteExpenseAllocation(values.reason)
                }
                includeReason={true}
              />
            </>
          )}
        </Box>
      </Card>
    </React.Fragment>
  );
};
export default ExpenseAllocationsPage;
