import React, { useContext } from 'react';
import { ContactContext } from 'contexts/ContactContext';
import { Box, Card } from '@procore/core-react';
import { ChangeHistoryList } from './ChangeHistoryList';

const ChangeHistoryTab = () => {
  const context = useContext(ContactContext);

  const cards = [
    {
      id: 'change-history',
      component: ChangeHistoryList,
    },
  ];

  return (
    <ContactContext.Provider value={context}>
      {cards.map((card, idx) => (
        <Box marginBottom="lg" data-qa={`card-${card.id}`}>
          <Card>
            <Box>
              <card.component key={`card-${card.id}-key`} />
            </Box>
          </Card>
        </Box>
      ))}
    </ContactContext.Provider>
  );
};
export default ChangeHistoryTab;
