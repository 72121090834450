import React, { useState, useContext } from 'react';
import {
  Page,
  Card,
  Button,
  Title,
  H1,
  InfoBanner,
  Banner,
  Box,
  Form,
} from '@procore/core-react';
import * as yup from 'yup';
import type { FormikProps } from 'formik';
import sanitizeHtml from 'sanitize-html';
import { useTranslation } from 'react-i18next';
import { DevPortalApi } from '@procore/ipa-nt-api-client-ts';
import { IpaAlertContext } from 'contexts/IpaAlertContext';
import { getApi } from 'utils/api';
import { FormButtonWrapper, FormWrapper, StyledFormText } from 'styles';

interface IMonthlySandbox {
  app_version_id: string;
  dev_app_id: string;
}

export const MonthlySandboxPage = () => {
  const { t } = useTranslation(['dev-portal-monthly-sandbox']);
  const { t: tCommon } = useTranslation(['common']);
  const alert = useContext(IpaAlertContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSubmit = async (values: IMonthlySandbox) => {
    alert.closeAlert();

    try {
      setIsLoading(true);
      const api = getApi(DevPortalApi);
      const response: any = await api.devPortalControllerMigrate(
        sanitizeHtml(values.app_version_id, {
          allowedTags: [],
          allowedAttributes: {},
        }),
        sanitizeHtml(values.dev_app_id, {
          allowedTags: [],
          allowedAttributes: {},
        }),
      );
      const data = response.data;
      const status = data.status || response.status;

      if (status === 201) {
        alert.success(
          t('SUCCESS_MESSAGE', {
            appId: data.oauth_application.id,
            appUid: data.oauth_application.uid,
          }),
          true,
        );
      } else if (status === 422) {
        alert.error(t('ERROR_422', { error: data.error }));
      } else if (status === 401) {
        alert.error(t('ERROR_401'));
      } else if (status === 404) {
        alert.error(t('ERROR_404'));
      } else {
        alert.error(t('UNKNOWN_ERROR', { error: data }));
      }
    } catch (error: any) {
      alert.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Page>
      <Page.Main>
        <Page.Header transparent={true}>
          <Page.Title>
            <Title>
              <Title.Text>
                <H1>{t('TITLE')}</H1>
              </Title.Text>
            </Title>
          </Page.Title>
        </Page.Header>
        <Page.Body>
          <Page.Row>
            <Page.Column>
              <Card>
                <InfoBanner>
                  <Banner.Content>
                    <Banner.Body data-qa="banner-status-text">
                      {t('INFO_MESSAGE')}
                    </Banner.Body>
                  </Banner.Content>
                </InfoBanner>
              </Card>
              <Card>
                <Box padding="lg">
                  <FormWrapper>
                    <Form
                      enableReinitialize={true}
                      view="create"
                      initialValues={{
                        app_version_id: '',
                        dev_app_id: '',
                      }}
                      validationSchema={yup.object().shape(
                        {
                          app_version_id: yup.string().when('dev_app_id', {
                            is: (val: string) => !val,
                            then: yup.string().required(tCommon('REQUIRED')),
                            otherwise: yup.string(),
                          }),
                          dev_app_id: yup.string().when('app_version_id', {
                            is: (val: string) => !val,
                            then: yup.string().required(tCommon('REQUIRED')),
                            otherwise: yup.string(),
                          }),
                        },
                        [['app_version_id', 'dev_app_id']],
                      )}
                      onSubmit={(values) => handleSubmit(values)}
                    >
                      {(formProps: FormikProps<IMonthlySandbox>) => {
                        const { values } = formProps;
                        return (
                          <Form.Form>
                            <Form.Row>
                              <StyledFormText
                                label={t('APP_VERSION_ID')}
                                name="app_version_id"
                                placeholder={t('APP_VERSION_ID')}
                                data-qa="app-version-id-field"
                                disabled={!!values.dev_app_id}
                                colStart={1}
                                colWidth={3}
                              />
                              <StyledFormText
                                label={t('DEV_APP_ID')}
                                name="dev_app_id"
                                placeholder={t('DEV_APP_ID')}
                                data-qa="developer-app-id-field"
                                disabled={!!values.app_version_id}
                                colStart={4}
                                colWidth={3}
                              />
                              <FormButtonWrapper>
                                <Button
                                  type="submit"
                                  data-qa="copy-btn"
                                  loading={isLoading}
                                >
                                  {t('BUTTON_NAME')}
                                </Button>
                              </FormButtonWrapper>
                            </Form.Row>
                          </Form.Form>
                        );
                      }}
                    </Form>
                  </FormWrapper>
                </Box>
              </Card>
            </Page.Column>
          </Page.Row>
        </Page.Body>
      </Page.Main>
    </Page>
  );
};

export default MonthlySandboxPage;
