import React, { useEffect } from 'react';
import { Link, Popover } from '@procore/core-react';
import { useTranslation } from 'react-i18next';
import { IconWrapper } from 'components/AppHeader/styles';
import { ListBulleted } from '@procore/core-icons';
import { QuickHistoryState, type QuickHistoryItem } from './QuickHistoryState';
import { QuickHistoryWrapper } from './styles';

const state = new QuickHistoryState();

export const QuickHistory = () => {
  const { t } = useTranslation(['header']);

  const [items, setItems] = React.useState(state.getItems());

  useEffect(() => {
    window.addEventListener('storage', () => {
      setItems(state.getItems());
    });
  }, []);

  const getLink = (item: QuickHistoryItem) => {
    switch (item.type) {
      case 'company':
        return `/v2/company/${item.companyId}`;
      case 'project':
        return `/v2/company/${item.companyId}/project/${item.projectId}/project-info`;
      case 'contact':
        return `/v2/company/${item.companyId}/contact/${item.contactId}/login/0/contact-info`;
      default:
        return '/';
    }
  };

  const overlay = (
    <Popover.Content>
      <QuickHistoryWrapper>
        <h2>{t('QUICK_HISTORY.TITLE')}</h2>
        <ul>
          {!items.length && (
            <li key="empty">
              <h4>{t('QUICK_HISTORY.EMPTY')}</h4>
            </li>
          )}
          {items.map((item) => (
            <li key={item.title}>
              <Link href={getLink(item)}>{item.title}</Link>
            </li>
          ))}
        </ul>
      </QuickHistoryWrapper>
    </Popover.Content>
  );

  return (
    <Popover aria-labelledby="" overlay={overlay} placement="bottom-left">
      <IconWrapper>
        <ListBulleted />
      </IconWrapper>
    </Popover>
  );
};

export default QuickHistory;
