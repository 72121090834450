import { DevPortalApi } from '@procore/ipa-nt-api-client-ts';
import { getApi } from 'utils/api';
import type { Feature } from '../EntitlementsTab';

export type EntitlementsType = {
  id: string;
  label: string;
};

type Props = {
  features: EntitlementsType[];
  setValue: (features: EntitlementsType[]) => void;
  setOptionalFieldsVisibility: (newOptionalFieldsVisibility: string[]) => void;
};

export const onChangeMultiSelect = ({
  features,
  setValue,
  setOptionalFieldsVisibility,
}: Props) => {
  setValue(features);
  const newOptionalFieldsVisibility: string[] = [];
  features.forEach((feature: any) => {
    switch (feature.label) {
      case 'procore_enable_private_correspondence_types_and_custom_tools':
        newOptionalFieldsVisibility.push(
          'procore_enable_private_correspondence_types_and_custom_tools',
        );
        break;
      case 'procore_correspondence_types_limit':
        newOptionalFieldsVisibility.push('procore_correspondence_types_limit');
        break;
      case 'procore_exclude_bidding_stage_projects':
        newOptionalFieldsVisibility.push(
          'procore_exclude_bidding_stage_projects',
        );
        break;
      case 'procore_max_active_projects':
        newOptionalFieldsVisibility.push('procore_max_active_projects');
        break;
      case 'procore_annual_contract_volume_currency':
        newOptionalFieldsVisibility.push(
          'procore_annual_contract_volume_currency',
        );
        break;
      case 'procore_annual_contract_volume':
        newOptionalFieldsVisibility.push('procore_annual_contract_volume');
        break;
    }
  });
  setOptionalFieldsVisibility(newOptionalFieldsVisibility);
};

export const getDevPortalAddFeaturesCallResult = async (
  features: Feature[],
  companyId: string,
) => {
  const api = getApi(DevPortalApi);
  const options = { data: { features } };

  const response: any = await api.devPortalControllerAddFeatures(
    companyId,
    options,
  );
  const data = response.data;
  const status = data.status || response.status;

  return { status, data };
};
